import React, { useEffect, useState } from 'react';
import useInputForm from '../../components/CustonHooks/FormHook'
import { rewardProviderService } from '../../services/control/rewardProvider.service';

import { Link } from 'react-router-dom';
import {Button} from "rsuite";
import {Col, FormGroup, Label, Row} from "reactstrap";
import {FaSave, FaTimesCircle} from "react-icons/fa";

export function EditRewardProvider(props) {

    const onSave = () => {
        // debugger
        if (inputs.rewardProviderId && inputs.rewardProviderId > 0)
            rewardProviderService.edit(inputs);
        else
            rewardProviderService.create(inputs);
    };

    const [reward, setReward] = useState({
        rewardProviderId: 0,
        businessName: "",
        urlLogo: "",
        active: true,
        logoImage: null
    });

    useEffect(() => {
        if (props.rewardProviderId != 0) {
            rewardProviderService.getById(props.rewardProviderId).then((result) => {
                setReward({
                    rewardProviderId: result.rewardProviderId,
                    businessName: result.businessName,
                    urlLogo: result.urlLogo,
                    active: result.active,
                    logoImage: null
                });
            });
        }
    }, [props.reward]);
    const { handleSubmit, handleInputChange, handleInputFileChange, inputs, setInputs } = useInputForm(onSave, reward);

    return (
        <>
            <form onSubmit={handleSubmit} className="form-dashboard">
                <h3 className="text fnt_medium">{props.rewardProviderId != 0 ? "Editar proveedor" : "Nuevo proveedor"}</h3>
                <Row>
                    <Col lg={4} md={6} sm={12}>
                        <input type="hidden" name="rewardCategoryId" onChange={handleInputChange} value={inputs.rewardProviderId} />
                        <FormGroup className="form-dashboard-group">
                            <Label for="businessName">Nombre</Label>
                            <input type="text" name="businessName" className="form-control" onChange={handleInputChange} value={inputs.businessName} required />
                        </FormGroup>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                        <FormGroup className="form-dashboard-group">
                            <Label for="logoImage">Logotipo</Label>
                            <input type="file" name="logoImage" className="form-control" onChange={handleInputFileChange} {...(props.rewardProviderId == 0 && { required: true })} />
                        </FormGroup>
                    </Col>
                </Row>
                <hr />
                <div className="d-flex-between">
                    <Link className="btn-back" to="/operator/controlreward/2" >
                        <FaTimesCircle />
                        <p>Cancelar</p>
                    </Link>
                    <Button className="btn-dark" type="submit">
                        <p>Guardar</p>
                        <FaSave />
                    </Button>
                </div>
            </form>
        </>
    );
}   
