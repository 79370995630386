import React, { useEffect, useState } from 'react';
import useInputForm from '../../components/CustonHooks/FormHook'
import { useAnswerConfig } from '../../services/surveyAnswerConfig.service'
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { Button } from "rsuite";
import { Select } from 'antd';
import {FaChevronLeft, FaSave, FaTimesCircle} from "react-icons/fa";

export function EditAnswer(props) {
    const onSave = () => {
        if (inputs.SurveyAnswerConfigId && inputs.SurveyAnswerConfigId > 0)
            useAnswerConfig.updateAnswerConfig({ SurveyAnswerConfigId: parseInt(inputs.SurveyAnswerConfigId), SurveyQuestionId: parseInt(inputs.SurveyQuestionId), AnswerOrder: parseInt(inputs.AnswerOrder), Answer: inputs.Answer, IsRight: inputs.IsRight, IsImage: inputs.IsImage, StepAnswer: inputs.StepAnswer }).then((result) => {
                if (props.onSave)
                    props.onSave();
            });
        else
            useAnswerConfig.createAnswerConfig({ SurveyQuestionId: parseInt(inputs.SurveyQuestionId), AnswerOrder: parseInt(inputs.AnswerOrder), Answer: inputs.Answer, IsRight: inputs.IsRight, IsImage: inputs.IsImage, StepAnswer: inputs.StepAnswer }).then((result) => {
                if (props.onSave)
                    props.onSave();
            });
    };
    const handleOnCancel = () => {
        if (props.onCancelEdit)
            props.onCancelEdit();
    };
    const [answer, setAnswer] = useState({ SurveyAnswerConfigId: "", SurveyQuestionId: props.surveyQuestionId, AnswerOrder: "", Answer: "", IsRight: false, IsImage: false, StepAnswer: 0 });
    const [stepOptions, setStepOptions] = useState([]);
    const { handleSubmit, handleInputChange, inputs, setInputs } = useInputForm(onSave, answer);
    const { Option } = Select;

    useEffect(() => {
        let newP = {};
        if (props.currentAnswer) {
            newP = { SurveyAnswerConfigId: props.currentAnswer.SurveyAnswerConfigId.toString(), SurveyQuestionId: props.currentAnswer.SurveyQuestionId.toString(), AnswerOrder: props.currentAnswer.AnswerOrder.toString(), Answer: props.currentAnswer.Answer, IsRight: props.currentAnswer.IsRight, IsImage: props.currentAnswer.IsImage, StepAnswer: props.currentAnswer.StepAnswer.toString() };
            setAnswer(newP);
        }
        else
            setAnswer({ SurveyAnswerConfigId: "", SurveyQuestionId: props.surveyQuestionId, AnswerOrder: "", Answer: "", IsRight: false, IsImage: false, StepAnswer: 0 });
    }, [props.currentAnswer]);

    useEffect(() => {
        var filterData = props.surveyQuestions?.filter(item => item.surveyQuestionId > props.surveyQuestionId);
        setStepOptions(filterData)
    }, [props.surveyQuestionId]);

    const handleSelectState = (value) => setInputs({ ...inputs, StepAnswer: value });

    return (
        <Col xs={24}>
            <form onSubmit={handleSubmit} className="form-dashboard">
                <Row>
                    <Col sm={12}>
                        <div className="admin-heading">
                            <div></div>
                            <Button className="btn-back" type="button" onClick={handleOnCancel}>
                                <FaChevronLeft />
                                <p>Regresar</p>
                            </Button>
                        </div>
                    </Col>
                    <input type="hidden" name="SurveyAnswerConfigId" onChange={handleInputChange} value={inputs.SurveyAnswerConfigId} />
                    <Col md={4} sm={12}>
                        <FormGroup className="form-dashboard-group">
                            <Label for="">Orden</Label>
                            <Input type="number" name="AnswerOrder" min="1" onChange={handleInputChange} value={inputs.AnswerOrder} required />
                        </FormGroup>
                    </Col>
                    <Col md={8} sm={12}>
                        <FormGroup className="form-dashboard-group">
                            <label for="">Respuesta</label>
                            <Input type="text" name="Answer" onChange={handleInputChange} value={inputs.Answer} required />
                        </FormGroup>
                    </Col>
                </Row>
                {
                    props.isMultipleOption &&
                    <Row>
                        <Col md={8} sm={12}>
                            <FormGroup check>
                                <Label for="isImage" check>¿Es imagen?</Label>
                                <Input checked={inputs.IsImage} id='isImage' type="checkbox" name="IsImage" onChange={handleInputChange} />
                            </FormGroup>
                        </Col>
                        {
                            stepOptions.length > 0 &&
                            <Col md={8} sm={12}>
                                <FormGroup className="form-dashboard-group">
                                    <Label for="StepAnswer">Salto</Label>
                                    <select 
                                        className="form-control" 
                                        id="StepAnswer" 
                                        name="StepAnswer" 
                                        value={inputs.StepAnswer < 0 ? "[Finalizar Encuesta]" : stepOptions.find((i) => inputs.StepAnswer == i.surveyQuestionId)?.question} 
                                        onChange={handleSelectState}
                                    >
                                        <option value={0}></option>
                                        {
                                            stepOptions.filter(item => item.surveyQuestionId != stepOptions[0].surveyQuestionId).map(item => <option key={item.surveyQuestionId} value={item.surveyQuestionId}> {item.question}</option>)
                                        }
                                        <option value={-1}>[Finalizar Encuesta]</option>
                                    </select>
                                </FormGroup>
                            </Col>
                        }
                    </Row>
                }
                <div className="form-dashboard-footer">
                    <Button className="btn-dark" type="submit">
                        <p>Guardar</p>
                        <FaSave />
                    </Button>
                </div>
            </form>
        </Col>
    );
}  
