import React, { useEffect, useState } from 'react';
import useInputForm from '../../components/CustonHooks/FormHook'
import { rewardCategoryService } from '../../services/control/rewardCategory.service';

import { Link } from 'react-router-dom';
import {Col, FormGroup, Label, Row} from "reactstrap";
import {Button} from "rsuite";
import {FaSave, FaTimesCircle} from "react-icons/fa";

export function EditRewardCategory(props) {

    const onSave = () => {
        // debugger
        if (inputs.rewardCategoryId && inputs.rewardCategoryId > 0)
            rewardCategoryService.edit(inputs);
        else
            rewardCategoryService.create(inputs);
    };

    const [reward, setReward] = useState({
        rewardCategoryId: 0,
        description: "",
        urlIcon: "",
        active: true
    });

    useEffect(() => {
        if (props.rewardCategoryId != 0) {
            rewardCategoryService.getById(props.rewardCategoryId).then((result) => {
                setReward({
                    rewardCategoryId: result.rewardCategoryId,
                    description: result.description,
                    urlIcon: result.urlIcon,
                    active: result.active
                });
            });
        }
    }, [props.reward]);
    const { handleSubmit, handleInputChange, handleInputFileChange, inputs, setInputs } = useInputForm(onSave, reward);

    return (
        <>
            <form onSubmit={handleSubmit} className="form-dashboard">
                <h3>{(props.rewardCategoryId !== 0) ? "Editar categoría" : "Nueva categoría"}</h3>
                <Row>
                    <Col className="col-md-6 col-lg-4">
                        <input type="hidden" name="rewardCategoryId" onChange={handleInputChange} value={inputs.rewardCategoryId} />
                        <FormGroup className="form-dashboard-group">
                            <Label for="description">Descripción</Label>
                            <input type="text" name="description" className="form-control" onChange={handleInputChange} value={inputs.description} required />
                        </FormGroup>
                    </Col>
                    <Col md={6} lg={4} sm={12}>
                        <FormGroup className="form-dashboard-group">
                            <Label for="urlIcon">Ícono</Label>
                            <input type="text" name="urlIcon" className="form-control" onChange={handleInputChange} value={inputs.urlIcon} required />
                        </FormGroup>
                    </Col>
                </Row>
                <hr />
                <div className="d-flex-between">
                    <Link className="btn-back" to="/operator/controlreward/3" >
                        <FaTimesCircle />
                        <p>Cancelar</p>
                    </Link>
                    <Button className="btn-dark" type="submit">
                        <p>Guardar</p>
                        <FaSave />
                    </Button>
                </div>
            </form>
        </>
    );
}   
