import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { LayoutFull, LayoutLess } from "./components/layouts";

import * as Pages from "./pages";
import * as Admin from "./backoffice";
import * as Control from "./control";
import ReactGA from "react-ga";

export function App(props) {
  const location = useLocation();
  const [loadApp, setLoadApp] = useState(false);

  useEffect(() => {
    //Google Analytics()
    ReactGA.initialize(process.env.REACT_APP_TRACKING_ID_GA);
    ReactGA.plugin.require("displayfeatures");
    ReactGA.pageview(location.pathname);
    setLoadApp(true);
  }, []);

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, [location.pathname]);

  return (
    <Switch>
      <Route
        exact
        path="/login"
        render={(x) => <LayoutLess children={<Pages.Login {...x} />} />}
      />
      <Route
        exact
        path="/register/:referralToken?"
        render={(x) => <LayoutLess children={<Pages.Register {...x} />} />}
      />
      <Route
        exact
        path="/successfull-register"
        render={(x) => <LayoutLess children={<Pages.SuccessfulReg {...x} />} />}
      />
      <Route
        exact
        path="/verify-account"
        render={(x) => <LayoutLess children={<Pages.VerifyAccount {...x} />} />}
      />
      <Route
        exact
        path="/membership"
        render={(x) => <LayoutLess children={<Pages.Membership {...x} />} />}
      />
      <Route
        exact
        path="/paid-membership"
        render={(x) => (
          <LayoutLess children={<Pages.MembershipPayment {...x} />} />
        )}
      />
      <Route
        exact
        path="/error-membership"
        render={(x) => (
          <LayoutLess children={<Pages.MembershipPayment {...x} />} />
        )}
      />
      <Route
        exact
        path="/reset-password"
        render={(x) => <LayoutLess children={<Pages.ResetPassword {...x} />} />}
      />
      <Route
        exact
        path="/home"
        render={(x) => <LayoutFull children={<Pages.Home />} />}
      />
      <Route
        exact
        path="/surveys"
        render={(x) => <LayoutFull children={<Pages.Surveys />} />}
      />
      <Route
        exact
        path="/board"
        render={(x) => <LayoutFull children={<Pages.Board />} />}
      />
      <Route
        exact
        path="/messages"
        render={(x) => <LayoutFull children={<Pages.Messages />} />}
      />
      <Route
        exact
        path="/messages/:id"
        render={(x) => <LayoutFull children={<Pages.Messages />} />}
      />
      <Route
        exact
        path="/discount"
        render={(x) => <LayoutFull children={<Pages.Discount />} />}
      />{" "}
      {/* <Route
        exact
        path="/directory"
        render={(x) => <LayoutFull children={<Pages.Directory />} />}
      /> */}
      <Route
        exact
        path="/ambassador"
        render={(x) => <LayoutFull children={<Pages.Ambassador />} />}
      />
      <Route
        exact
        path="/pride-music"
        render={(x) => <LayoutFull children={<Pages.PrideMusic />} />}
      />
      <Route
        exact
        path="/interest-group"
        render={(x) => <LayoutFull children={<Pages.InterestGroup />} />}
      />
      <Route
        exact
        path="/details-directory/:DirectoryId"
        render={(x) => <LayoutFull children={<Pages.DetailsDirectory />} />}
      />
      <Route
        exact
        path="/register-services"
        render={(x) => <LayoutFull children={<Pages.RegisterServices />} />}
      />
      <Route
        exact
        path="/register-services/:id"
        render={(x) => <LayoutFull children={<Pages.RegisterServices />} />}
      />
      <Route
        exact
        path="/provider/:id"
        render={(x) => <LayoutFull children={<Pages.Provider {...x} />} />}
      />
      <Route
        exact
        path="/pending-payment"
        render={(x) => <LayoutFull children={<Pages.CashPayment />} />}
      />
      <Route
        exact
        path="/shopping-history"
        render={(x) => <LayoutFull children={<Pages.ShoppingHistory />} />}
      />
      {/* <Route
        exact
        path="/rewards"
        render={(x) => <LayoutFull children={<Pages.Rewards />} />}
      /> */}
      <Route
        exact
        path="/redemption-history"
        render={(x) => <LayoutFull children={<Pages.RedemptionHistory />} />}
      />
      <Route
        exact
        path="/account-status"
        render={(x) => <LayoutFull children={<Pages.AccountStatus />} />}
      />
      <Route
        exact
        path="/profile"
        render={(x) => <LayoutFull children={<Pages.Profile />} />}
      />
        <Route
            exact
            path="/blog-company"
            render={(x) => (
                <LayoutFull
                    children={
                        <Pages.BlogCompanyPrimary titleComponent="Preguntas frecuentes" {...x} />
                    }
                />
            )}
        />
      <Route
        exact
        path="/our-company/:id"
        render={(x) => (
          <LayoutFull
            children={
              <Pages.CompanyPostPrimary titleComponent="Preguntas frecuentes" {...x} />
            }
          />
        )}
      />
      <Route
        exact
        path="/our-company/:id/report/:reportId"
        render={(x, y, z) => (
          <LayoutFull
            children={
              <Pages.CompanyPostPrimary
                titleComponent="Preguntas frecuentes"
                {...x}
                {...y}
              />
            }
          />
        )}
      />
      <Route
        exact
        path="/our-company/:id/:postId"
        render={(x, y) => (
          <LayoutFull
            children={
              <Pages.CompanyPostPrimary
                titleComponent="Preguntas frecuentes"
                {...x}
                {...y}
              />
            }
          />
        )}
      />
        <Route
            exact
            path="/blog-company/gallery/:id?"
            render={(x) => (
                <LayoutFull
                    children={
                        <Pages.BlogGallery titleComponent="Galería Pride" {...x} />
                    }
                />
            )}
        />
        <Route
            exact
            path="/stories-pride"
            render={(x) => (
                <LayoutFull
                    children={
                        <Pages.BlogCompanySecondary titleComponent="Consultas" {...x} />
                    }
                />
            )}
        />
      <Route
        exact
        path="/stories-pride/:id"
        render={(x) => (
          <LayoutFull
            children={
              <Pages.CompanyPostSecondary titleComponent="Consultas" {...x} />
            }
          />
        )}
      />
      <Route
        exact
        path="/stories-pride/:id/report/:reportId"
        render={(x, y) => (
          <LayoutFull
            children={
              <Pages.CompanyPostSecondary
                titleComponent="Consultas"
                {...x}
                {...y}
              />
            }
          />
        )}
      />
      <Route
        exact
        path="/stories-pride/:id/:postId"
        render={(x, y) => (
          <LayoutFull
            children={
              <Pages.CompanyPostSecondary
                titleComponent="Consultas"
                {...x}
                {...y}
              />
            }
          />
        )}
      />
      <Route
        exact
        path="/help"
        render={(x) => <LayoutFull children={<Pages.Help />} />}
      />
      <Route
        exact
        path="/audience"
        render={(x) => <LayoutFull children={<Pages.Audience />} />}
      />
      {/* <Route
        exact
        path="/ecommerce"
        render={(x) => <LayoutFull children={<Pages.Ecommerce />} />}
      /> */}
      <Route
        exact
        path="/caring-community"
        render={(x) => <LayoutFull children={<Pages.CaringCommunity />} />}
      />
      <Route
        exact
        path="/caring-community/:id"
        render={(x) => <LayoutFull children={<Pages.Provider {...x} />} />}
      />
      <Route
        exact
        path="/job-bank"
        render={(x) => <LayoutFull children={<Pages.JobBank />} />}
      />
      <Route
        exact
        path="/applicant-sent"
        render={(x) => <LayoutFull children={<Pages.ApplicantSent />} />}
      />
      <Route
        exact
        path="/list-new-jobs"
        render={(x) => <LayoutFull children={<Pages.ListNewJobs />} />}
      />
      <Route
        exact
        path="/details-job"
        render={(x) => <LayoutFull children={<Pages.DetailsJobs />} />}
      />
      <Route
        exact
        path="/details-job/:id"
        render={(x) => <LayoutFull children={<Pages.DetailsJobs />} />}
      />
      <Route
        exact
        path="/register-job"
        render={(x) => <LayoutFull children={<Pages.RegisterJobs />} />}
      />
      <Route
        exact
        path="/edit-job/:id"
        render={(x) => <LayoutFull children={<Pages.EditJob />} />}
      />
      <Route
        exact
        path="/jobs-published"
        render={(x) => <LayoutFull children={<Pages.JobsPublished />} />}
      />
      <Route
        exact
        path="/candidates/:id"
        render={(x) => <LayoutFull children={<Pages.CandidatesNominated />} />}
      />
      <Route
        exact
        path="/order-details/:id"
        render={(x) => <LayoutFull children={<Pages.OrderDetail {...x} />} />}
      />
      <Route
        exact
        path="/canceled"
        render={(x) => (
          <LayoutFull children={<Pages.PaymentCanceled {...x} />} />
        )}
      />
      {/* == BACKOFFICE == */}
        <Route
            exact
            path="/admin"
            render={(x) => <LayoutFull children={<Admin.Admin />} />}
        />
      <Route
        exact
        path="/admin/users"
        render={(x) => <LayoutFull children={<Admin.Users />} />}
      />
      <Route
        exact
        path="/admin/users-details/:id"
        render={(x) => <LayoutFull children={<Admin.UserDetails {...x} />} />}
      />
      <Route
        exact
        path="/admin/admin-audience"
        render={(x) => <LayoutFull children={<Admin.AdminAudience />} />}
      />
      <Route
        exact
        path="/admin/bulk-load"
        render={(x) => <LayoutFull children={<Admin.BulkLoad />} />}
      />
      <Route
        exact
        path="/admin/AdminSurvey"
        render={(x) => <LayoutFull children={<Admin.AdminSurvey />} />}
      />
      <Route
        exact
        path="/admin/AdminSurveyScale/:surveyId"
        render={(x) => <LayoutFull children={<Admin.AdminSurveyScale />} />}
      />
      <Route
        exact
        path="/admin/AdminAnswerTypes"
        render={(x) => <LayoutFull children={<Admin.AdminAnswerTypes />} />}
      />
      <Route
        exact
        path="/admin/details-survey-scale/:surveyId/:id"
        render={(x) => <LayoutFull children={<Admin.DetailsSurveyScale {...x} />} />}
      />
      <Route
        exact
        path="/admin/details-survey/:id"
        render={(x) => <LayoutFull children={<Admin.DetailsSurvey {...x} />} />}
      />
      <Route
        exact
        path="/admin/survey-results/:id"
        render={(x) => <LayoutFull children={<Admin.SurveyResults {...x} />} />}
      />
      <Route
        exact
        path="/admin/AdminBoard"
        render={(x) => <LayoutFull children={<Admin.AdminBoard />} />}
      />
      <Route
        exact
        path="/admin/details-category"
        render={(x) => <LayoutFull children={<Admin.DetailsCategory />} />}
      />
      <Route
        exact
        path="/admin/details-category/:id"
        render={(x) => <LayoutFull children={<Admin.DetailsCategory />} />}
      />
      <Route
        exact
        path="/admin/control"
        render={(x) => <LayoutFull children={<Admin.Control />} />}
      />
      <Route
        exact
        path="/admin/CompanyEventType"
        render={(x) => (
          <LayoutFull children={<Pages.AdminCompanyEventType />} />
        )}
      />
      <Route
        exact
        path="/admin/CompanyPost"
        render={(x) => <LayoutFull children={<Admin.CompanyPost />} />}
      />
      <Route
        exact
        path="/admin/AdminGallery"
        render={(x) => <LayoutFull children={<Pages.AdminGallery />} />}
      />
      <Route
        exact
        path="/admin/CompanyGallery"
        render={(x) => <LayoutFull children={<Pages.CompanyGallery />} />}
      />
      <Route
        exact
        path="/admin/Notice"
        render={(x) => <LayoutFull children={<Admin.Notice />} />}
      />
      <Route
        exact
        path="/admin/admin-diary"
        render={(x) => <LayoutFull children={<Admin.AdminDiary />} />}
      />
      <Route
        exact
        path="/diary"
        render={(x) => <LayoutFull children={<Pages.Diary />} />}
      />
      <Route
        exact
        path="/admin/product-filter"
        render={(x) => (
          <LayoutFull children={<Admin.AdminProductFilter {...x} />} />
        )}
      />
      <Route
        exact
        path="/admin/detail-product-filter/:id"
        render={(x) => (
          <LayoutFull children={<Admin.DetailsProductFilter {...x} />} />
        )}
      />
      <Route
        exact
        path="/admin/admin-caring-community"
        render={(x) => (
          <LayoutFull children={<Admin.CaringCommunity {...x} />} />
        )}
      />
      {/* == OPERACIONES == */}
      <Route
        exact
        path="/operator/homeOps/:tap"
        render={(x) => (
          <LayoutFull children={<Control.ControlReward {...x} />} />
        )}
      />
      <Route
        exact
        path="/operator/controlreward/:tap"
        render={(x) => (
          <LayoutFull children={<Control.ControlReward {...x} />} />
        )}
      />
      <Route
        exact
        path="/operator/details-reward/:id"
        render={(x) => (
          <LayoutFull children={<Control.DetailsReward {...x} />} />
        )}
      />
      <Route
        exact
        path="/operator/load-coupons/:id"
        render={(x) => (
          <LayoutFull children={<Control.BulkLoadReward {...x} />} />
        )}
      />
      <Route
        exact
        path="/operator/details-rewardCategory/:id"
        render={(x) => (
          <LayoutFull children={<Control.DetailsRewardCategory {...x} />} />
        )}
      />
      <Route
        exact
        path="/operator/details-rewardProvider/:id"
        render={(x) => (
          <LayoutFull children={<Control.DetailsRewardProvider {...x} />} />
        )}
      />
      <Route
        exact
        path="/operator/AdminCompany"
        render={(x) => (
          <LayoutFull children={<Control.AdminCompany {...x} />} />
        )}
      />
      <Route
        exact
        path="/operator/details-company/:id"
        render={(x) => (
          <LayoutFull children={<Control.DetailsCompany {...x} />} />
        )}
      />
      <Route
        exact
        path="/admin/course"
        render={(x) => <LayoutFull children={<Admin.AdminCourse />} />}
      />
      <Route
        exact
        path="/course-view"
        render={(x) => <LayoutFull children={<Pages.CourseView />} />}
      />
      <Redirect from="/" to="/home" />
    </Switch>
  );
}
