import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { deparmentService } from '../../services/deparment.service';
import '../admin/admin.scss';
import {Table} from "reactstrap";
import {Button} from "rsuite";
import { Space } from 'antd';


const SearchDeparment = forwardRef((props, ref) => {

	const [dataGrid, setDataGrid] = useState([]);
	const handleDelete = (event, data) => {
		deparmentService.deletetDeparment(data).then((result) => { getDeparment(); });
		event.stopPropagation();
	};

	async function getDeparment() {
		deparmentService.getDeparment()
			.then((result) => {
				if (result) {
					setDataGrid(result);
				}
			});
	}

	useEffect(() => {
		getDeparment();
	}, []);

	const refreshDataGrid = () => {
		getDeparment();
	}

	useImperativeHandle(ref, () => ({
		refreshDataGrid: refreshDataGrid
	}));

	return (

		<div className="table-responsive">
			<Table className="admin-table" hover>
				<thead>
					<tr>
						<th>Grupo</th>
						<th>Estatus</th>
						<th>Se usa para el registro externo</th>
						<th>Acciones</th>
					</tr>
				</thead>
				<tbody>
					{dataGrid.map(item => (
						<tr key={item.departmentId} >
							<td>{item.departmentName}</td>
							<td>{item.active ? 'Activo' : 'Inactivo'}</td>
							<td>{item.isOnRegister ? 'Sí' : 'No'}</td>
							<td>
								{item.active ?
									<Space>
										<Button type="button" className="btn-icon-table" onClick={() => props.handleRowSelect(item)} style={{marginRight: '.5rem'}}>
											<i className="icon-edit"></i>
										</Button>
										<Button type="button" className="btn-icon-table delete" onClick={(event) => handleDelete(event, item)}>
											<i className="icon-trash"></i>
										</Button>
									</Space>
									: null}
							</td>
						</tr>
					))}
				</tbody>
			</Table>
		</div>

	);

});

export default SearchDeparment;
