import React, { useState, useEffect, useRef } from "react";
import C_H from "../../assets/imgs/company_Holiday.jpg";
import { Modal } from "reactstrap";
import { useCompanyHoliday } from "../../services/companyHoliday.service";
import { IoPlay } from "react-icons/io5";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "./sliderHome.scss";

export function SliderHome() {
  const [sliderItems, setSliderItems] = useState([]);
  const [video, setVideo] = useState({ type: 0, src: "" });

  useEffect(() => {
    getCompanyHolidays();
  }, []);

  async function getCompanyHolidays() {
    useCompanyHoliday.getCompanyHolidayFront(2).then((result) => {
      if (result) {
        var temp = [];
        result.map((ch) => {
          temp.push({
            key: ch.companyHolidayId.toString(),
            file: ch.imagePath,
            description: ch.description,
            isVideo: ch.isVideo,
            video: {
              type: ch.iframe ? 1 : 2,
              src: ch.iframe ? ch.iframe : ch.videoPath,
            },
          });
        });
        setSliderItems(temp);
      }
    });
  }

  const vidRef = useRef(null);

  const [modal, setModal] = useState(false);
  const toggle = (video) => {
    setVideo(video);
    setModal(!modal);
    vidRef.current.play();
  };

  return (
    <>
      <section className="splide-dashboard">
        <h3 className="ttls_in_muro">&#128227; Avisos importantes</h3>
        {sliderItems && sliderItems.length > 0 ? (
          <Splide
            options={{
              pagination: true,
              arrows: true,
              autoplay: true,
              rewind: true,
              autoHeight: true,
              perMove: 1,
              perPage: 1,
              rewindByDrag: true,
              speed: 1000,
              type: "fade",
              drag: true,
            }}
          >
            <ul className="splide__pagination"></ul>
            {sliderItems.map((item) => (
              <SplideSlide key={item.key}>
                {item.isVideo ? (
                  <div className="splide-dashboard-video">
                    <img src={item.file} alt="" />
                    <button type="button" className="splide-dashboard-video-play" onClick={() => toggle(item.video)}>
                      <IoPlay />
                    </button>
                  </div>
                ) : item.video.src ? (
                  <a href={item.video.src}>
                    <img className="splide-dashboard-slider" src={item.file} alt={item.description}/>
                  </a>
                ) : (
                  <img
                    className="splide-dashboard-slider"
                    src={item.file}
                    alt={item.description}
                  />
                )}
              </SplideSlide>
            ))}
          </Splide>
        ) : (
          <img
            className="splide-dashboard-slider"
            src={C_H}
            alt="Aviso"
            style={{
              width: "50%",
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
            }}
          />
        )}
      </section>
      <Modal isOpen={modal} toggle={toggle} className="wrapper_modal_cap">
        <div>
          <div className="toolbar_opt_modal">
            <span className="btns_modal" onClick={toggle}>
              <i className="far fa-window-close"></i> <span>CERRAR</span>
            </span>
          </div>
          {
            {
              1: (
                <div
                  dangerouslySetInnerHTML={{ __html: video.src }}
                  style={{ display: "contents" }}
                />
              ),
              2: (
                <video
                  ref={vidRef}
                  className="box_videos"
                  controls
                  preload
                  autoPlay
                >
                  <source src={video.src} type="video/mp4" />
                </video>
              ),
            }[video.type]
          }
        </div>
      </Modal>
    </>
  );
}
