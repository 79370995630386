import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { FormPersonal } from "./formPersonal";
import { FormAdditional } from "./formAdditional";
import { FormCheckoutPay } from "./formCheckoutPay";
import { MessageFinish } from "./messageFinish";
import { directoryInfoService } from "../../services/directoryService.service";
import { useParams } from "react-router-dom";
import "./registerServices.scss";
import {Col, Row} from "reactstrap";
import { Progress } from 'rsuite';
import { BsExclamation} from "react-icons/bs";

export function RegisterServices(props) {
  const location = useLocation();
  const data = location.state;

  const [activeStep3, setActiveStep3] = useState(false);
  const history = useHistory();
  let { id } = useParams();
  const [pubToEdit, setPublicationToEdit] = useState({});
  const [isEdit, setIdEdit] = useState(false);
  const [loadPicture, setLoadPicture] = useState(false);
  const [file, setFile] = useState(null);
  const [dataCompany, setDataCompany] = useState({});

  const [viewForm, setViewForm] = useState(1);
  const toggleView = (sec) => {
    if (viewForm === "2" && activeStep3 === true && sec !== "1") subirForm();
    if (viewForm === "2" && sec === "3") subirForm();
    if (viewForm !== sec)  setViewForm(sec);
  };
  
  const [register, setRegister] = useState({
    personal: {},
    additional: {},
    pay: {},
  });

  useEffect(() => {
    if (id !== "" && id !== undefined) {
      setLoadPicture(true);
      setIdEdit(true);
      directoryInfoService.getById(id).then((data) => setPublicationToEdit(data));
    }
    getDataCompany();
    if (data !== undefined) setActiveStep3(true);
  }, []);

  function getDataCompany() {
    directoryInfoService.getSettingsUser().then((result) => {

      if (result) {
        const object = JSON.parse(result.customizeValues);
        let showDays = result.directoryInfoCount < (parseInt(object.NoPost) + result.directoryInfoExceptionFreePost);
        setDataCompany({
          NoPost: object.NoPost,
          NoDaysGrace: object.NoDaysGrace,
          Amount: result.amount,
          HasTax: result.hasTax,
          DirectoryInfoCount: result.directoryInfoCount,
          IsShow: showDays
        });
      }
    });
  }

  function Datepersonal(event, name) {
    const prevRegister = register;
    prevRegister[name] = event;
    setRegister(prevRegister);
  }

  function subirForm() {
    let directoryContacts = [
      {
        contactTypeId: 1,
        contactValue: register.personal.contacto1,
        directoryContactId: register.personal?.contacto1DirectoryContactId,
      },
      {
        contactTypeId: 1,
        contactValue: register.personal.contacto2,
        directoryContactId: register.personal?.contacto1optDirectoryContactId,
      },
      {
        contactTypeId: 2,
        contactValue: register.personal.correo,
        directoryContactId: register.personal?.contacto2DirectoryContactId,
      },
    ];

    if (register.additional?.sitioweb) {
      directoryContacts.push({
        contactTypeId: 3,
        contactValue: register.additional?.sitioweb,
        directoryContactId: register.additional?.sitiowebId,
      });
    }
    if (register.additional?.linkedin) {
      directoryContacts.push({
        contactTypeId: 4,
        contactValue: register.additional?.linkedin,
        directoryContactId: register.additional?.linkedinId,
      });
    }
    if (register.additional?.facebook) {
      directoryContacts.push({
        contactTypeId: 5,
        contactValue: register.additional?.facebook,
        directoryContactId: register.additional?.facebookId,
      });
    }
    if (register.additional?.twitter) {
      directoryContacts.push({
        contactTypeId: 6,
        contactValue: register.additional?.twitter,
        directoryContactId: register.additional?.twitterId,
      });
    }
    if (register.additional?.instagram) {
      directoryContacts.push({
        contactTypeId: 7,
        contactValue: register.additional?.instagram,
        directoryContactId: register.additional?.instagramId,
      });
    }
    if (register.additional?.tiktok) {
      directoryContacts.push({
        contactTypeId: 8,
        contactValue: register.additional?.tiktok,
        directoryContactId: register.additional?.tiktokId,
      });
    }

    const formData = new FormData();
    formData.append("CompanyId", process.env.REACT_APP_COMPANY);
    formData.append("contactImage", file);
    formData.append("title", "lic.");
    formData.append("fullName", register.personal.nombre);
    formData.append("directoryDescription", register.personal.descripcion);
    formData.append("location", register.personal.location);
    formData.append("categoryServiceId", register.personal.categoria);
    formData.append("subCategoryServiceId", register.personal.subcategoria);
    formData.append("createUser", localStorage.getItem("userId"));

    directoryContacts.forEach((item, index) => {
      formData.append(
        `directoryContacts[${index}].contactTypeId`,
        item.contactTypeId
      );
      formData.append(
        `directoryContacts[${index}].contactValue`,
        item.contactValue
      );
      if (item.directoryContactId) {
        formData.append(
          `directoryContacts[${index}].directoryContactId`,
          item.directoryContactId
        );
      }
    });

    if (isEdit) {
      formData.append("directoryInfoId", pubToEdit.directoryInfoId);
      directoryInfoService.updateDirectory(formData).then((result) => {
        if (activeStep3) history.push(data?.origin !== undefined ? data.origin : "/directory");
        if (result?.data) setPublicationToEdit(result.data);
      });
    } else
      directoryInfoService.insertDirectory(formData).then((result) => {
        if (result?.data) {
          setPublicationToEdit(result.data);}
        const newResult = register;
        const lstContact = result.data.directoryContacts.filter(
          (element) => element.contactTypeId === 1
        );
        if (lstContact.length > 0) newResult.personal.contacto1DirectoryContactId = lstContact[0].directoryContactId;
        const lstContact2 = result.data.directoryContacts.filter(
          (element) => element.contactTypeId === 2
        );
        if (lstContact2.length > 0) newResult.personal.contacto2DirectoryContactId = lstContact2[0].directoryContactId;
        setRegister(newResult);
        setIdEdit(true);
      });
  }

  const [percentage, setPercentage] = useState(0);
  const calcPercentage = (value) => setPercentage(value);

  useEffect(() => {
    if(activeStep3 === false && viewForm === 2) setPercentage(percentage + 40);
    if(activeStep3 === false && viewForm === 4) setPercentage(percentage + 60);
  }, [])

  return (
    <article className="dashboard-container-page">
      <Row>
        <Col lg={12} className="d-flex-between">
          <h2>Registro de servicios</h2>
          <Link to="/directory" className="btn-back">
            <i className="icon-chevron-left"></i>
            <p>Ir a Emprendedores</p>
          </Link>
        </Col>
        <Col xl={3} lg={4} md={12} sm={12}>
          <aside className="register-services-aside">
            <div className="icon"><BsExclamation /></div>
            <div className="paragraph">
              <p>
                Ser proveedor en Romeh tiene un costo de <span>${dataCompany.Amount}</span> {dataCompany.HasTax === true ? (<span>+ I.V.A.</span>) : ""}  mensuales, se realiza
                un cargo recurrente al darte de alta. Tu servicio debe ser aprobado
                por el <span>Escuela Romeh</span>. <br />
              </p>
            </div>
            {/* <p>En caso de requerir factura solicitarla a: facturacion@pridemx.com</p> */}
            {dataCompany?.IsShow ? (<p className="badge-dashed green">Romeh te da los primeros {dataCompany.NoDaysGrace} días gratis</p>) : "" }
          </aside>
        </Col>
        <Col xl={9} lg={8} md={12} sm={12}>
          <section className="register-services-content">
            <article className="register-services-content-steps">
              <div className="steps-list">
                <ul>
                  {activeStep3 === true ?
                      (<>
                        {
                          {
                            1: (
                                <>
                                  <li>
                                    <input type="radio" name="step" checked readOnly/>
                                    <label>Información Personal y Profesional</label>
                                  </li>
                                  <li>
                                    <input type="radio" name="step2" disabled readOnly/>
                                    <label>Información adicional</label>
                                  </li>
                                  <li>
                                    <input type="radio" name="step3" disabled checked readOnly/>
                                    <label>Datos de tarjeta</label>
                                  </li>
                                </>
                            ),
                            2: (
                                <>
                                  <li>
                                    <input type="radio" name="step" disabled checked readOnly/>
                                    <label>Información Personal y Profesional</label>
                                  </li>
                                  <li>
                                    <input type="radio" name="step2" checked readOnly/>
                                    <label>Información adicional</label>
                                  </li>
                                  <li>
                                    <input type="radio" name="step3" checked disabled readOnly/>
                                    <label>Datos de tarjeta</label>
                                  </li>
                                </>
                            ),
                          }[viewForm]
                        }
                      </>) :
                      (<div className="form_info_service">
                        {
                          {
                            1: (
                                <>
                                  <li>
                                    <input type="radio" name="step" checked readOnly/>
                                    <label>Información Personal y Profesional</label>
                                  </li>
                                  <li>
                                    <input type="radio" name="step2" disabled readOnly/>
                                    <label>Información adicional</label>
                                  </li>
                                  <li>
                                    <input type="radio" name="step3" disabled readOnly/>
                                    <label>Datos de tarjeta</label>
                                  </li>
                                </>
                            ),
                            2: (
                                <>
                                  <li>
                                    <input type="radio" name="step" disabled checked readOnly/>
                                    <label>Información Personal y Profesional</label>
                                  </li>
                                  <li>
                                    <input type="radio" name="step2" checked readOnly/>
                                    <label>Información adicional</label>
                                  </li>
                                  <li>
                                    <input type="radio" name="step3" disabled readOnly/>
                                    <label>Datos de tarjeta</label>
                                  </li>
                                </>
                            ),
                            3: (
                                <>
                                  <li>
                                    <input type="radio" name="step" disabled checked readOnly/>
                                    <label>Información Personal y Profesional</label>
                                  </li>
                                  <li>
                                    <input type="radio" name="step2" disabled checked readOnly/>
                                    <label>Información adicional</label>
                                  </li>
                                  <li>
                                    <input type="radio" name="step3" checked readOnly/>
                                    <label>Datos de tarjeta</label>
                                  </li>
                                </>
                            ),
                            4:
                                <>
                                  <li>
                                    <input type="radio" name="step" disabled checked readOnly/>
                                    <label>Información Personal y Profesional</label>
                                  </li>
                                  <li>
                                    <input type="radio" name="step2" disabled checked readOnly/>
                                    <label>Información adicional</label>
                                  </li>
                                  <li>
                                    <input type="radio" name="step3" disabled checked readOnly/>
                                    <label>Datos de tarjeta</label>
                                  </li>
                                </>
                            ,
                          }[viewForm]
                        }
                      </div>)
                  }
                </ul>
              </div>
              <div className="steps-circle">
                {activeStep3 === true ?
                    {
                      1: (
                          <>
                            <Progress.Circle percent={60} strokeColor="#F7C246" />
                          </>
                      ),
                      2: (
                          <>
                            <Progress.Circle percent={100} strokeColor="#F7C246" />
                          </>
                      ),
                    }[viewForm]
                    :
                    {
                      1: (
                          <>
                            <Progress.Circle percent={0} strokeColor="#F7C246" />
                          </>
                      ),
                      2: (
                          <>
                            <Progress.Circle percent={40} strokeColor="#F7C246" />
                          </>
                      ),
                      3: (
                          <>
                            <Progress.Circle percent={65} strokeColor="#F7C246" />
                          </>
                      ),
                      4:
                          <>
                            <Progress.Circle percent={100} strokeColor="#F7C246" />
                          </>
                      ,
                    }[viewForm]
                }
              </div>
            </article>
            <article className="register-services-content-form">
              {activeStep3 === true ?

                  (<>
                    {
                      {
                        1: (
                            <FormPersonal
                                data={pubToEdit}
                                register={register}
                                viewForm={viewForm}
                                loadPicture={loadPicture}
                                setLoadPicture={setLoadPicture}
                                setFile={setFile}
                                Datepersonal={Datepersonal}
                                toggleView={() => toggleView("2")} //sig
                                activeTab3={false}
                            />
                        ),
                        2: (
                            <FormAdditional
                                viewForm={viewForm}
                                data={pubToEdit}
                                register={register}
                                Datepersonal={Datepersonal}
                                toBack={() => toggleView("1")}
                                toggleView={() => toggleView("-1")}
                                activeTab3={false}
                            />
                        ),
                      }[viewForm]
                    }
                  </>) :
                  (<div className="form_info_service">
                    {
                      {
                        1: (
                            <FormPersonal
                                data={pubToEdit}
                                register={register}
                                viewForm={viewForm}
                                loadPicture={loadPicture}
                                setLoadPicture={setLoadPicture}
                                setFile={setFile}
                                Datepersonal={Datepersonal}
                                toggleView={() => toggleView("2")} //sig
                                activeTab3={true}
                                calcPercentage={calcPercentage}
                                setPercentage={setPercentage}
                            />
                        ),
                        2: (
                            <FormAdditional
                                viewForm={viewForm}
                                data={pubToEdit}
                                register={register}
                                Datepersonal={Datepersonal}
                                toggleView={() => toggleView("3")}
                                toBack={() => toggleView("1")}
                                activeTab3={true}
                            />
                        ),
                        3: (
                            <FormCheckoutPay
                                viewForm={viewForm}
                                data={pubToEdit}
                                Datepersonal={Datepersonal}
                                toggleView={() => toggleView("4")}
                                toBack={() => toggleView("2")}
                            />
                        ),
                        4: <MessageFinish />,
                      }[viewForm]
                    }
                  </div>)
              }
            </article>
          </section>
        </Col>
      </Row>
    </article>
  );
}
