import React, { useEffect, useState } from 'react';
import { useJobRole } from '../../../services/jobRole.service';
import {Table} from "reactstrap";
import {Button} from "rsuite";
import { Space } from 'antd';

export function SearchJobRole(props) {
    const [jobsData, setJobsData] = useState({ jobs: [] });
    const handleDelete = (data) => {
        useJobRole.deleteJobRole(data).then((result) => { getJobRoles(); });
    };

    useEffect(() => {
        getJobRoles();
    }, [props.refresh]);

    async function getJobRoles() {
        useJobRole.getJobRoles().then((result) => {
            if (result) {
                setJobsData({ jobs: result });
            }
        });
    }

    return (
        <div className="table-responsive">
            <Table className="admin-table" hover>
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Descripción</th>
                        <th>Estatus</th>
                        <th>Se usa para el registro externo</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {jobsData.jobs.map(job => (
                        <tr key={job.jobRoleId} >
                            <td>{job.name}</td>
                            <td>{job.description}</td>
                            <td>{job.active ? 'Activo' : 'Inactivo'}</td>
                            <td>
								{job.isOnRegister ? 'Sí' : 'No'}
							</td>
                            <td>
                                <div className="d-flex-center">
                                    {job.active ?
                                        <Space>
                                            <Button className="btn-icon-table" onClick={() => props.onSelectJobRole(job)}>
                                                <i className="icon-edit"></i>
                                            </Button>
                                            <Button className="btn-icon-table delete" onClick={() => handleDelete(job)}>
                                                <i className="icon-trash"></i>
                                            </Button>
                                        </Space>
                                        : null
                                    }
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>

    );
}
