import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import Moment from "react-moment";
import { Table } from "reactstrap";
import { Button } from "rsuite";
import { useSurveyScale } from '../../services/surveyScale.service';
import { useParams } from 'react-router-dom';

export function SearchSurveyScale(props) {
    const [surveyScales, setSurveyScales] = useState([]);
    const handleDelete = (data) => {
        useSurveyScale.deleteSurveyScale(data).then((result) => { getSurveyScales(); });
    };
    //const [selectedSurvey, setSelectedSurvey] = useState();
    const { surveyId } = useParams();

    //let dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    useEffect(() => {
        getSurveyScales();
    }, [props.refresh]);

    async function getSurveyScales() {
        useSurveyScale.getSurveyScales().then((result) => {
            if (result) setSurveyScales(result);
        });
    }

    const goToSurvey = (id) => {
        window.location.href = `/admin/details-survey-scale/${surveyId}/${id}`;
    }

    return (
        <div className="table-responsive">
            <Table className="admin-table" hover>
                <thead>
                    <tr>
                        <th>ID Escala</th>
                        <th>Titulo de escala</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {surveyScales?.map((surveyScale, index) => (
                        <tr key={index}>
                            <td>
                                <div className="d-flex-center">
                                    <Button className="btn-link d-flex-center" onClick={() => goToSurvey(surveyScale.surveyScaleId)}>{surveyScale.surveyScaleId}</Button>
                                </div>
                            </td>
                            <td>
                                <div className="d-flex-center">
                                    <Button className="btn-link d-flex-center" onClick={() => goToSurvey(surveyScale.surveyScaleId)}>{surveyScale.description}</Button>
                                </div>
                            </td>
                            <td>
                                <div className="d-flex-center">
                                    <Button type="button" className="btn-icon-table delete" onClick={() => handleDelete(surveyScale)}>
                                        <i className="icon-trash"></i>
                                    </Button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div >

    );
}