import React, { useEffect, useState } from 'react';
import useInputForm from '../../components/CustonHooks/FormHook'
import { useQuestion } from '../../services/surveyQuestion.service'
import { SearchAnswer } from './searchAnswers';
import { EditAnswer } from './answer';
import { Button } from "rsuite";
import { SurveyTypes } from '../../enums/surveyTypes';
import {FaPlus} from "react-icons/fa";
import {Row} from "antd";

export function EditQuestionAnswer(props) {
    const onSave = () => {
        if (inputs.SurveyQuestionId && inputs.SurveyQuestionId > 0)
            useQuestion.updateQuestion({ SurveyQuestionId: parseInt(inputs.SurveyQuestionId), SurveyId: parseInt(inputs.SurveyId), SurveyAnswerTypeId: parseInt(inputs.SurveyAnswerTypeId), Question: inputs.Question }).then((result) => {
                if (props.onSave)
                    props.onSave();
            });
        else
            useQuestion.createQuestion({ SurveyId: parseInt(inputs.SurveyId), SurveyAnswerTypeId: parseInt(inputs.SurveyAnswerTypeId), Question: inputs.Question }).then((result) => {
                if (props.onSave)
                    props.onSave();
            });
    };

    const [question, setQuestion] = useState({ SurveyQuestionId: "", SurveyId: props.surveyId, SurveyAnswerTypeId: "", Question: "" });
    const [refreshAnswers, setRefreshAnswers] = useState(0);
    const [showEditAnswer, setShowEditAnswer] = useState(false);
    const [selectedAnswer, setSelectedAnswer] = useState();
    const { inputs } = useInputForm(onSave, question);

    useEffect(() => {
        let newP = {};
        if (props.currentQuestion) {
            newP = { SurveyQuestionId: props.currentQuestion.SurveyQuestionId.toString(), SurveyId: props.currentQuestion.SurveyId.toString(), SurveyAnswerTypeId: props.currentQuestion.SurveyAnswerTypeId.toString(), Question: props.currentQuestion.Question };
            setQuestion(newP);
        }
        else
            setQuestion({ SurveyQuestionId: "", SurveyId: props.surveyId, SurveyAnswerTypeId: "", Question: "" });

        setRefreshAnswers(prev => prev + 1);
        setSelectedAnswer(null);
        setShowEditAnswer(false);
    }, [props.currentQuestion]);

    const handleOnSelectAnswer = (answer) => {
        setShowEditAnswer(true);
        console.log(answer);
        setSelectedAnswer({ SurveyAnswerConfigId: answer.surveyAnswerConfigId, SurveyQuestionId: answer.surveyQuestionId, AnswerOrder: answer.answerOrder, Answer: answer.answer, IsRight: answer.isRight, IsImage: answer.isImage, StepAnswer: answer.stepAnswer });
    }
    const handleOnNewAnswer = () => {
        setSelectedAnswer(null);
        setShowEditAnswer(true);
    };
    const handleOnCancelEditAnswer = () => {
        setSelectedAnswer(null);
        setShowEditAnswer(false);
    }
    const handleOnSaveEditAnswer = () => {
        setRefreshAnswers(prev => prev + 1);
        setShowEditAnswer(false);
    }

    return (
        <>
            {question.SurveyAnswerTypeId != SurveyTypes.MULTIMEDIA && question.SurveyAnswerTypeId != SurveyTypes.OPEN ?
                <>
                    <div className="admin-heading">
                        <div></div>
                        {!showEditAnswer &&
                            <Button disabled={props.isSurveyAlreadyStarted} className="btn-dark" onClick={handleOnNewAnswer}>
                                <p>Agregar respuesta</p>
                                <FaPlus />
                            </Button>
                        }
                    </div>
                    <Row>
                        {
                            showEditAnswer &&
                                <EditAnswer surveyQuestions={props.surveyQuestions}
                                            isMultipleOption={question.SurveyAnswerTypeId == SurveyTypes.MULTIPLE_OPTION}
                                            surveyQuestionId={question.SurveyQuestionId}
                                            currentAnswer={selectedAnswer} onCancelEdit={handleOnCancelEditAnswer}
                                            onSave={handleOnSaveEditAnswer}
                                />
                        }
                    </Row>
                    <Row>
                        <SearchAnswer
                            isSurveyAlreadyStarted={props.isSurveyAlreadyStarted}
                            surveyQuestionId={question.SurveyQuestionId}
                            onSelectAnswer={handleOnSelectAnswer}
                            refresh={refreshAnswers}
                        />
                    </Row>
                </>
                :
                <div className="box_empty mt-5" style={{color:'black',background: 'rgba(0,0,0,.05)', padding: '1rem', borderRadius: '.5rem'}}>
                    <span><i className="fas fa-exclamation-triangle"></i></span>
                    <h6>Este tipo de pregunta no necesita añadir respuestas</h6>
                </div>
            }
        </>
    );
}   
