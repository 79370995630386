import React, { useState } from 'react';
import { SearchRewardProvider } from './searchRewardProvider';

import './rewardAdmin.scss'
import { Link } from 'react-router-dom';
import {Col, Row} from "reactstrap";
import {FaPlus} from "react-icons/fa";

export function AdminRewardProvider() {
    const [refresh, setRefresh] = useState(0);

    return (
        <>
            <div className="admin-subtitle">
                <div>
                    <h2>Proveedores</h2>
                    <h6>Operaciones</h6>
                </div>
                <Link className="btn-dark" to={"/operator/details-rewardProvider/0"}>
                    <p>Agregar Proveedor</p>
                    <FaPlus />
                </Link>
            </div>
            <Row>
                <Col sm={12}>
                    <SearchRewardProvider refresh={refresh} />
                </Col>
            </Row>

        </>
    );
}
