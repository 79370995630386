import React, { useState, useEffect, useRef } from "react";
import { backendService } from "./../../services/backend.service";
import { UserActivity } from "../../components/userActivity/userActivity";
import { genderIdentityService } from "../../services/genderIdentity.service";
import { userService } from "../../services/user.service";
import { Pending } from "./pending";
import { useForm } from "react-hook-form";
import "./profile.scss";
import Moment from "react-moment";
import "moment/locale/es";
import { useSelector } from "react-redux";
import {Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, Row} from "reactstrap";
import {FaFlag, FaUserEdit} from "react-icons/fa";
import {IoClose} from "react-icons/io5";
import {FooterHome} from "../../components/footer/footerHome";

export function Profile() {
  const trigger = undefined;
  const selectForm = useRef(null)
  const [disabled, setDisabled] = useState(true);
  const [statusGender, setStatusGender] = useState(false);
  const [profile, setProfileInfo] = useState();
  const [changeImage, setChangeImage] = useState(false);
  const [selectedIdentity, setSelectedIdentity] = useState(0);
  const [nickname, setNickname] = useState("");
  const [backupIdentity, setBackupIdentity] = useState({ id: 0, flag: "" });
  const [genderIdentityCatalog, setGenderIdentityCatalog] = useState([]);
  const [selectedGenderIdentityFlag, setSelectedGenderIdentityFlag] = useState(
      {}
  );
  const [identityFlag, setIdentityFlag] = useState("");
  const [btnImage, setBtnImage] = useState("fa fa-edit");

  const [originValues, setOriginValues] = useState({
    additionalEmail: "",
    nickname: "",
  });

  const [inputClass, setInputClass] = useState("form-control-plaintext text-primary");
  const [placeHolderText, setPlaceHolderText] = useState("");
  const { register, handleSubmit } = useForm();
  const companyId = useSelector((state) => state.userSettings.companyId);

  useEffect(() => {
    getProfileInfo();
    setDisabled(true);
    handleDisabled();
  }, [trigger]);

  const getProfileInfo = () => {
    genderIdentityService.getAll(process.env.REACT_APP_COMPANY).then((genderData) => {
      setGenderIdentityCatalog(genderData);
    })
    backendService.userProfile().then((data) => {
      setOriginValues({
        additionalEmail: data.additionalEmail ?? "",
        nickname: data.nickname ? data.nickname : "",
      });
      setProfileInfo(data);
      setIdentityFlag(data.genderIdentityFlag);
      setSelectedIdentity(data.genderIdentityId);
      setNickname(data.nickname);
    });
  };

  const handleChange = (event) => {
    setChangeImage(false);
    if (event.target.files.length > 0){
      setProfileInfo({
        ...profile,
        urlProfileImage: URL.createObjectURL(event.target.files[0]),
      });
      setTimeout(()=> handleSubmit((data) => onSubmit(data))(),800);
    }
  };

  const onSubmit = (data, e) => {
    if (selectedIdentity) data.GenderIdentityId = selectedIdentity;
    data.GenderIdentityFlag = identityFlag;

    data.Mobile = data.Mobile === undefined ? null : data.Mobile;
    data.nickname = nickname;
    data.Preferences = data.Preferences === undefined ? null : data.Preferences;

    userService.editProfile(data, companyId).then((result) => {
      if (result) {
        setChangeImage(false);
        setOriginValues({
          additionalEmail: profile.additionalEmail ?? "",
          nickname: profile.nickname ? profile.nickname : "",
        });
      } else {
        setProfileInfo({
          ...profile,
          additionalEmail: originValues.additionalEmail,
          nickname: originValues.nickname,
        });
      }

      setDisabled(true);
      setBtnImage("fa fa-edit");
      setInputClass("form-control-plaintext text-primary");
      setPlaceHolderText("");
    });
  };

  const onGenderChange = (event) => {
    genderIdentityCatalog.map(item => {
        if (item.genderIdentityId === parseInt(event.target.value)) setSelectedIdentity(item.genderIdentityFlag)
    })

    if (selectedIdentity) setBackupIdentity({ id: selectedIdentity, flag: identityFlag });

    setSelectedIdentity(event.target.value);

    genderIdentityCatalog.map((item) =>  {
      if(item.genderIdentityId === parseInt(event.target.value)) setIdentityFlag(item.identityFlagName);
    });

    if (profile.genderIdentityId === parseInt(event.target.value)) {
      setStatusGender(false);
    } else { setStatusGender(true); }

    const data = {
      ProfileImage: profile.urlProfileImage,
      GenderIdentityId: parseInt(event.target.value),
      GenderIdentityFlag: identityFlag,
      Mobile: null,
      nickname: profile.nickname,
      Preferences: profile.Preferences === undefined ? null : profile.Preferences
    }

    onSubmit(data)
  };

  const onNickChange = (event) => {
    setNickname(event.target.value)
    if (profile.nickname === event.target.value) {
      setStatusGender(false);
    } else { setStatusGender(true); }
  };

  const handleDisabled = () => {
    setDisabled(!disabled);
    if (disabled) {
      setBtnImage("fas fa-times");
      setInputClass("form-control");
      setPlaceHolderText("Ingrese un sobre nombre");
    } else {
      setBtnImage("fa fa-edit");
      setInputClass("form-control-plaintext text-primary");
      setPlaceHolderText("");
      setSelectedIdentity(backupIdentity.id);
      setIdentityFlag(backupIdentity.flag);
      setBackupIdentity({ id: 0, flag: "" });
      setNickname(originValues.originValues);
      setProfileInfo({
        ...profile,
        additionalEmail: originValues.additionalEmail,
        nickname: originValues.nickname,
      });
    }
  };

  const [asideShow, setAsideshow] = useState("");
  const activeAside = () => {
    setAsideshow(asideShow === "" ? " is-show" : "");
  };

  const ProfileImage = register("ProfileImage");

  const [modalUser, setModalUser] = useState(false);
  const toggleModalUser = () => setModalUser(!modalUser);

  return (
      <section className="dashboard-container-page">
        <Row>
          <Col lg={8} md={7} sm={12} xs={12}>
            <h2>Mi Perfil</h2>
            <div className="profile">
              <Row>
                <Col sm={12}>
                  <div className="flex-end"><button type="button" className="btn-white sm" onClick={toggleModalUser}><FaUserEdit /></button></div>
                </Col>
                <Col lg={4} md={12}>
                  <div className="profile-avatar">
                    {identityFlag ? (<div className={`flag ${identityFlag} rotating`}></div>) : ("")}
                    <img alt="" src={profile && profile.urlProfileImage} />
                    <Label for="file-upload" className="file">
                      <i className="icon-camera"></i>
                      <input id="file-upload" type="file" name="ProfileImage" accept="image/*"{...register("ProfileImage")} onChange={(e) => { ProfileImage.onChange(e);handleChange(e); }}/>
                    </Label>
                  </div>
                </Col>
                <Col lg={8} md={12}>
                  <div className="profile-info">
                    <h4>
                      {profile && profile.middleName}{" "}
                      {profile && profile.lastName}{" "}
                      {profile && profile.firstName}
                    </h4>
                    <h5>{profile && profile.nickname}</h5>
                    <h6>{profile && profile.jobRole}</h6>
                    <div className="profile-info-extra">
                      <p>
                        <span><i className="icon-birthday"></i></span>
                        <Moment locale="es" format="DD [de] MMMM  YYYY">{profile && profile.birthday}</Moment>
                      </p>
                      <p>
                        <span><i className="icon-clock"></i></span>
                        {profile && profile.seniority}
                      </p>
                      <p>
                        <span><FaFlag /></span>
                        {profile && profile.genderName}
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <Pending profile={profile} asideShow={asideShow} allClose={activeAside}/>
          </Col>
          <Col lg={4} md={5} sm={12} xs={12}>
            <h2>Mi Objetivo</h2>
            <div className="border-pointed orange">
              <p>Elige el Objetivo con el que te identifiques</p>
            </div>
            <Form className="my-flag">
              <ul>
                {genderIdentityCatalog && genderIdentityCatalog.map((gender) => (
                    <li key={gender.genderIdentityId} value={gender.genderIdentityId}>
                      <FormGroup check>
                        <Label onClick={onGenderChange} for={`flag${gender.genderIdentityId}`} check>
                          <Input type="radio" id={`flag${gender.genderIdentityId}`} name="flag" value={gender.genderIdentityId} />
                          <div className={`flag ${gender.identityFlagName} ${(gender.identityFlagName === identityFlag) ? 'active' : ''} `}></div>
                        </Label>
                        <p>{gender.genderName}</p>
                      </FormGroup>
                    </li>
                  ))
                }
              </ul>
            </Form>
          </Col>
        </Row>
        <Row>
          {/* <Col sm={12}>
            <div className="profile-tabs">
              <UserActivity />
            </div>
          </Col> */}
          <Col sm={12}>
            <FooterHome />
          </Col>
        </Row>
        <Modal isOpen={modalUser} toggle={toggleModalUser} centered={true} size="md" className="modal-profile" {...[nickname, onNickChange]}>
          <ModalBody>
            <button type="button" className="btn-close-modal" onClick={toggleModalUser}><IoClose /></button>
            <h3>Datos de usuario</h3>
            <Form row onSubmit={handleSubmit(onSubmit)}>
              <div className="form-dashboard-group">
                <label>Nickname</label>
                <Input type="text" value={nickname} onChange={onNickChange} />
              </div>
              <div className="flex-end" style={{marginTop: '.5rem'}}>
                <Button className="btn-gradients-orange">
                  <p>Guardar</p>
                  <div className="circle"><i className="icon-send"></i></div>
                </Button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
      </section>
  );
}
