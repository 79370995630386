import React, {useState} from "react";
import {Tooltip} from "reactstrap";
import {BsFacebook, BsInstagram, BsLink, BsLinkedin, BsTiktok, BsTwitter, BsYoutube} from "react-icons/bs";

export function ItemSocial(props) {

    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
      <>
          <a href={props.socialUrl} target="_blank" className="a_ico_social" id={'Tooltip-' + props.socialIcoName}>
              {props.socialIcoName === 'Instagram' ? <BsInstagram /> : ''}
              {props.socialIcoName === 'LinkedIn' ? <BsLinkedin /> : ''}
              {props.socialIcoName === 'Facebook' ? <BsFacebook /> : ''}
              {props.socialIcoName === 'Twitter' ? <BsTwitter /> : ''}
              {props.socialIcoName === 'TikTok' ? <BsTiktok /> : ''}
              {props.socialIcoName === 'Youtube' ? <BsYoutube /> : ''}
              {props.socialIcoName === 'Web' ? <BsLink /> : ''}
          </a>
          <Tooltip placement="top" isOpen={tooltipOpen} target={'Tooltip-' + props.socialIcoName} toggle={toggle}>
            {props.socialIcoName}
          </Tooltip>
      </>
  );
}
