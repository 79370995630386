import React, { useEffect, useState } from "react";
import { Label, Input, Row, Col, FormGroup } from "reactstrap";
import useInputForm from "../../../components/CustonHooks/FormHook";
import { SelectUsers } from "../../../components/Helpers/selectUsers";
import { AudienceUsers } from "../../../components/Helpers/audienceUsers";
import { useDiary } from "../../../services/diary.service";
import moment from "moment";
import swal from "sweetalert";
import { Button } from "rsuite";
import { companyEventTypeService } from "../../../services/companyEventType.service";
import { FaSave, FaTimesCircle} from "react-icons/fa";
import { EventTypes } from "../../../enums/eventTypes";

export function EditDiary(props) {
  const onSave = () => {

    if (isAudienceAddressed && userSelec.length <= 0) {
      return swal({ text: "Debes elegir al menos un usuario", icon: "error" });
    }

    if (!inputs.StartDate || !inputs.EndDate || moment(inputs.StartDate).isAfter(inputs.EndDate)) {
      return swal({ text: "Fechas inválidas, revisa por favor y vuelve a intentarlo", icon: "error" });
    }

    if (inputs.CompanyDiaryId && inputs.CompanyDiaryId > 0) {
      var data = { ...inputs };
      data.TypeId = selectedTypeId;
      data.Repeat = parseInt(data.Repeat);
      data.StartDate = data.StartDate + "T" + data.StartTime;
      data.EndDate = data.EndDate + "T" + data.EndTime;
      data.Active = !data.CancelEvent;
      data.Users = userSelec.map(u => parseInt(u.id));

      if (inputs.CancelEvent)
        swal({
          title: "¿Estás seguro que deseas cancelar este evento?",
          icon: "warning",
          dangerMode: true,
          buttons: ["Cancelar", "Si, aceptar"],
        }).then((willDelete) => {
          if (willDelete) {
            useDiary.updateCompanyDiary(data).then((result) => {
              if (props.onSave) props.onSave(result);
            });
          }
        });
      else {
        useDiary.updateCompanyDiary(data).then((result) => {
          if (props.onSave) props.onSave(result);
        });
      }
    } else {
      var data = { ...inputs };
      data.TypeId = selectedTypeId;
      data.Repeat = parseInt(data.Repeat);
      data.StartDate = data.StartDate + "T" + data.StartTime;
      data.EndDate = data.EndDate + "T" + data.EndTime;
      data.Users = userSelec.map(u => parseInt(u.id));
      data.companyId = process.env.REACT_APP_COMPANY;

      useDiary.createCompanyDiary(data).then((result) => {
        if (props.onSave) props.onSave(result);
      });
    }
    setUserSelec([]);
  };

  const handleOnCancel = () => {
    if (props.onCancelEdit) props.onCancelEdit();
    setUserSelec([]);
  };

  const [userSelec, setUserSelec] = useState([]);
  const [diaryItem, setDiaryItem] = useState({});
  const [selectedTypeId, setSelectedTypeId] = useState(0);
  const [isAudienceAddressed, setIsAudienceAddressed] = useState(false);
  const { handleSubmit, handleInputChange, inputs, setInputs } = useInputForm(
    onSave,
    diaryItem
  );
  useEffect(() => {
    getCompanyEventType();
    let newP = {};
    if (props.currentDiary) {
      newP = { ...props.currentDiary };
      newP.StartTime = moment(newP.StartDate).format("HH:mm");
      newP.EndTime = moment(newP.EndDate).format("HH:mm");
      newP.StartDate = moment(newP.StartDate).format("YYYY-MM-DD");
      newP.EndDate = moment(newP.EndDate).format("YYYY-MM-DD");
      newP.CancelEvent = false;
      setDiaryItem(newP);
    } else {
      setDiaryItem({
        CompanyDiaryId: 0,
        Name: "",
        DiaryTypeId: 0,
        Location: "",
        UrlString: "",
        StartDate: "",
        EndDate: "",
        StartTime: "00:00",
        EndTime: "00:00",
        Repeat: 0,
        AllDay: false,
        DepartmentId: 0,
        JobRoleId: 0
      });
    }
  }, [props.currentDiary]);

  const getCompanyEventType = () => {
    companyEventTypeService.getByCompanyAndEventType(process.env.REACT_APP_COMPANY, EventTypes.COMPANY_DIARY)
      .then((result) => {
        if (result) {
          setIsAudienceAddressed(result.isAudienceAddressed);
        }
      });
  };

  return (
    <>
      <div className="admin-subtitle">
        <div>
          <h2>Calendario</h2>
          <h6>{parseInt(inputs.CompanyDiaryId) === 0 ? "Nuevo evento" : "Editar evento"}</h6>
        </div>
        <Button className="btn-back" type="button" onClick={handleOnCancel}>
          <FaTimesCircle />
          <p>Cancelar</p>
        </Button>
      </div>
      <form onSubmit={handleSubmit} className="form-dashboard">
        <Input type="hidden" name="CompanyDiaryId" onChange={handleInputChange} value={inputs.CompanyDiaryId} />
        <Row>
          <Col lg={4} md={6}>
            <FormGroup className="form-dashboard-group">
              <Label for="Name">Título de evento</Label>
              <Input
                type="text"
                name="Name"
                onChange={handleInputChange}
                value={inputs.Name}
              />
            </FormGroup>
          </Col>
          <Col lg={4} md={6}>
            <FormGroup className="form-dashboard-group">
              <Label for="Location">Ubicaci&oacute;n</Label>
              <Input
                type="text"
                name="Location"
                placeholder="Ubicaci&oacute;n"
                onChange={handleInputChange}
                value={inputs.Location}
              />
            </FormGroup>
          </Col>
          <Col lg={4} md={6}>
            <FormGroup className="form-dashboard-group">
              <Label for="UrlString">Link</Label>
              <Input
                type="text"
                name="UrlString"
                placeholder="Link"
                onChange={handleInputChange}
                value={inputs.UrlString}
              />
            </FormGroup>
          </Col>
          <Col lg={4} md={6}>
            <FormGroup className="form-dashboard-group">
              <Label for="StartDate">Fecha de inicio</Label>
              <Input
                type="date"
                className="mb-2"
                name="StartDate"
                placeholder="Fecha inicial"
                onChange={handleInputChange}
                value={inputs.StartDate}
              />
              <Input
                type="time"
                name="StartTime"
                placeholder="Hora inicial"
                onChange={handleInputChange}
                value={inputs.StartTime}
              />
            </FormGroup>
          </Col>
          <Col lg={4} md={6}>
            <FormGroup className="form-dashboard-group">
              <Label for="EndDate">Fecha de fin</Label>
              <Input
                type="date"
                className="mb-2"
                name="EndDate"
                placeholder="Fecha final"
                onChange={handleInputChange}
                value={inputs.EndDate}
              />
              <Input
                type="time"
                name="EndTime"
                placeholder="Hora final"
                onChange={handleInputChange}
                value={inputs.EndTime}
              />
            </FormGroup>
          </Col>
          <Col lg={4} md={6} sm={12} className={'mb-3'}>
            {inputs.CompanyDiaryId != 0 ? (
                <Row>
                  <Col sm={12} className="form-dashboard-group">
                    <Label>Duración</Label>
                    <label className="toggler-wrapper dark" htmlFor={`duration`}>
                      <Input type="checkbox" id={`duration`} checked={inputs.AllDay} onChange={handleInputChange} name="AllDay"></Input>
                      <div className="toggler-slider">
                        <div className="toggler-knob"></div>
                      </div>
                      <p>Todo el día</p>
                    </label>
                  </Col>
                  <Col sm={12} className="form-dashboard-group">
                    <Label>Cancelar evento</Label>
                    <label className="toggler-wrapper dark" htmlFor={`cancelEvent`}>
                      <Input type="checkbox" id={`cancelEvent`} checked={inputs.CancelEvent} onChange={handleInputChange} name="CancelEvent"></Input>
                      <div className="toggler-slider">
                        <div className="toggler-knob"></div>
                      </div>
                      <p>Deseo cancelar este evento</p>
                    </label>
                  </Col>
                </Row>
            ) : null}
            </Col>
        </Row>

        {inputs.CompanyDiaryId > 0 ? (
          <AudienceUsers
            targetId={inputs.CompanyDiaryId}
            eventType="Agenda"
            selectUser={setUserSelec}
          />
        ) : isAudienceAddressed ? (
          <SelectUsers selectUser={setUserSelec} title={"Evento dirigido a:"} />
        ) : <h4>Todos podrán ver el evento.</h4>}
        <div className="flex-end">
          <Button className="btn-dark" type="submit">
            <p>Guardar</p>
            <FaSave />
          </Button>
        </div>
      </form>
    </>
  );
}
