import React from 'react'
import { useDropzone } from 'react-dropzone';
import { userProfileService } from '../../services/backoffice/userProfile.service'
import './users.scss'
import swal from "sweetalert"
import { useSelector } from 'react-redux';
import {Button} from "rsuite";
import {Col, Row} from "reactstrap";
import {Link} from 'react-router-dom';
import {FaChevronLeft, FaDownload, FaUpload} from "react-icons/fa";


export function BulkLoad() {
	const companyId = useSelector(state => state.userSettings.companyId)
	const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ multiple: false });

	const files = acceptedFiles.map(file => (
		<li key={file.path}>
			<i className="icon-doc"></i> {file.path} - {file.size} bytes
		</li>
	));

	const upload = () => {
		if (acceptedFiles.length === 0) {
			swal({ text: "Debes cargar un archivo para continuar", icon: "warning" });
		} else {
			userProfileService.uploadMigrationFile(companyId, acceptedFiles[0]);
		}
	}

	return (
		<section className="dashboard-container-page">
			<div className="admin-subtitle">
				<div>
					<h2>Usuario</h2>
					<h6>Carga masiva</h6>
				</div>
				<Link to="/admin" className='btn-back'>
					<FaChevronLeft />
					<p>Regresar</p>
				</Link>
			</div>
			<Row className="wrapper_dropzone">
				<Col sm={12} className="flex-end">
					<a className="btn-dark" href="https://storagervadmindev.blob.core.windows.net/ctnprimecontentmx/Common/TemplateUser.csv">
						<p>Descargar template</p>
						<FaDownload />
					</a>
				</Col>
				<Col sm={12} {...getRootProps({ className: 'dropzone' })} style={{margin: '1rem 0rem'}}>
					<input {...getInputProps()} accept=".csv" />
					<div className="box_dropzone">
						<span><i className="fas fa-download"></i></span>
						<p >Arrastre y suelte un archivo .cvs aquí, o haga clic para seleccionar un archivo.</p>
					</div>
				</Col>
				{
					(acceptedFiles.length === 0) ? '' :
						<Col lg={8}>
							<h5>Archivo seleccionado:</h5>
							<ul className="list-files">{files}</ul>
						</Col>
				}
				<Col {...(acceptedFiles.length === 0) ? 'md={4}' : 'md={12}'} className={`flex-end`}>
					<Button onClick={upload} className="btn-dark">
						<p>Subir archivo</p>
						<FaUpload />
					</Button>
				</Col>
			</Row>

		</section>
	)
}
