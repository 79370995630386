import React, { useEffect, useState } from 'react';
import { useQuestion } from '../../services/surveyQuestion.service';
import { Table } from "reactstrap";
import { Button } from "rsuite";
import { useSurveyScale } from '../../services/surveyScale.service';

export function SearchSurveyScaleAnswer(props) {
    const [surveyScaleAnswers, setSurveyScaleAnswers] = useState([]);
    const handleDelete = (data) => {
        useSurveyScale.deleteSurveyScaleAnswer(data).then((result) => { getSurveyScaleAnswers(props.surveyScale); });
    };
    const handleSelectAnswer = (data) => {
        if (props.onSelectAnswer)
            props.onSelectAnswer(data);
    };

    useEffect(() => {
        getSurveyScaleAnswers(props.surveyScale);
    }, [props.refresh]);

    async function getSurveyScaleAnswers(surveyScale) {
        if (surveyScale.surveyScaleId != "")
            useSurveyScale.getSurveyScaleAnswers(surveyScale.surveyScaleId).then((result) => {
                if (result) {
                    setSurveyScaleAnswers(result);
                }
            });
    }

    return (
        <div className="table-responsive">
            <Table className="admin-table" hover>
                <thead>
                    <tr>
                        <th>Respuesta</th>
                        <th>Valor</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {surveyScaleAnswers.map((scaleAnswer, index) => (
                        <tr key={index}>
                            <td> <Button className="btn-link d-flex-center" onClick={() => handleSelectAnswer(scaleAnswer)}>{scaleAnswer.description}</Button>		</td>
                            <td>{scaleAnswer.scaleValue}</td>
                            <td>
                                <span className="btn-icon-table delete" onClick={() => handleDelete(scaleAnswer)}>
                                    <i className="icon-trash"></i>
                                </span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>

    );
}
