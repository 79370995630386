import React from "react";
import { Link } from "react-router-dom";
import LogoCompany from "../../assets/imgs/RodrigoRomeh.png";
import "./register.scss";
import { ecommerceService } from '../../services/ecommerce.service'
import swal from "sweetalert"
import { useSelector } from "react-redux";
import { ImgCache } from "../../components/CustonHooks/imgCache"
import {Col, Row} from "reactstrap";
import {Legals} from "../../components/legals/legals";
import {FaChevronRight, FaTimesCircle} from "react-icons/fa";

export function Membership(props) {
    const companyLogo = useSelector(state => state.userSettings.companyLogo);
    const model = props.location.state;

    const payMembership = () => {
        const request = {
            UserId: model.userId,
            PaymentMethodId: 9,
            Products: [{
                ProductId: model.productId,
                Cant: 1
            }]
        };

        ecommerceService.payMembership(request)
            .then(result => {
                if (result != undefined) {
                    window.location.href = result
                } else {
                    swal({ text: "Ocurrió un error al abrir método de pago", icon: "error" })
                }
            });
    }

    return (
        <section className="membership-wrapper">
            <div className="membership-bg">
                <div className="membership-container">
                    <div className="membership-title">
                        <h2>Obtén tu Membresía</h2>
                        <ImgCache src={companyLogo ? companyLogo : LogoCompany} className="logo" alt="Kuimby Social" />
                    </div>
                    <div className="membership-content">
                        <div className="membership-content-pay">
                            <div className="form-membership">
                                <Row>
                                    <Col sm={12}>
                                        <div className="icon">
                                            <h4>Hola, <span>{model && model.userName}</span>{" "}</h4>
                                            <h2>¡Estás a punto de descubrir un mundo nuevo!</h2>
                                            <p>
                                                Al adquirir tu {model && model.productName} comenzarás a
                                                disfrutar de todos los beneficios que tiene para ti.
                                            </p>
                                            <p>
                                                Costo de membresía:{" "}
                                                <span className="price">${model && model.productPrice}</span>
                                            </p>
                                        </div>
                                    </Col>
                                    <Col sm={12}>
                                        <div className="form-membership-footer">
                                            <Link to="/login" className="btn-dark">
                                                <FaTimesCircle />
                                                <p>Cancelar</p>
                                            </Link>
                                            {model &&
                                                <button type="button" className="btn-dark" onClick={payMembership}>
                                                    <p>Obtener membresía</p>
                                                    <FaChevronRight />
                                                </button>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                    <div className="membership-footer">
                        <Legals />
                    </div>
                </div>
            </div>
        </section>
    );
}
