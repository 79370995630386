import React, { useEffect, useState } from "react";
import { useSurveyUserProfileAnswer } from "../../services/surveyUserProfileAnswer.service";
import swal from "sweetalert";
import { FormGroup } from "reactstrap";
import { useSurveyScale } from "../../services/surveyScale.service";
import { SurveyTypes } from "../../enums/surveyTypes";
import { BsCheckCircle } from "react-icons/bs";

export function AnswersSurvey({
  index,
  question,
  surveyUserProfileId,
  next,
  setQuestionIndexSave,
  questionIndexSave,
  setLastSave,
  getQuestionIndex,
  setPreviousClick,
  previousClick,
  previous,
  questionIndex
}) {
  const [userAnswer, setUserAnswer] = useState({});
  const [answerData, setAnswersData] = useState([]);
  const [SelectedAnswerId, setSelectedAnswerId] = useState("");
  const [SelectedValue, setSelectedValue] = useState("");
  const [scaleAnswers, setScaleAnswers] = useState([]);
  const [answersSelected, setAnswersSelected] = useState([]);
  const [imagesToUpload, setImagesToUpload] = useState([]);

  useEffect(() => {
    getAnswers(question);

    if (question.surveyAnswerTypeId == parseInt(SurveyTypes.SCALE)) {
      getScaleAsnwers();
    }
  }, [question]);

  useEffect(() => {
    if (index == questionIndex - 1) {
      getAnswers(question);
    }
  }, [questionIndex]);

  const getScaleAsnwers = () => {
    useSurveyScale.getScaleAnswers(question.surveyScaleId).then((data) => {
      setScaleAnswers(data);
      useSurveyUserProfileAnswer
        .getAnsweredByQuestionId(question.surveyQuestionId, surveyUserProfileId)
        .then((answersSelected) => {
          console.log(answersSelected);
          setAnswersSelected(answersSelected);
        });
    });
  };

  useEffect(() => {
    var filterData = answerData.filter(
      (item) => item.surveyAnswerConfigId == SelectedAnswerId
    );
    if (filterData.length > 0) {
      if (!filterData[0].active && previousClick) {
        setTimeout(() => {
          previous(question.surveyQuestionId);
        }, 400);
      }
      if (filterData[0].active && previousClick) {
        setPreviousClick(false);
      }
    }
  }, [userAnswer]);

  useEffect(() => {
    if (questionIndexSave == index) handleSubmit();
  }, [questionIndexSave]);

  const onSelectScale = (surveyAnswerConfigId, value) => {
    const alreadyExists = answersSelected.find(
      (s) => s.selectedAnswerConfigId == surveyAnswerConfigId
    );
    if (alreadyExists) {
      useSurveyUserProfileAnswer
        .updateSurveyUserProfileAnswer({
          SurveyUserProfileAnswerId: alreadyExists.surveyUserProfileAnswerId,
          SurveyUserProfileId: surveyUserProfileId,
          SurveyQuestionId: question.surveyQuestionId,
          SelectedAnswerConfigId: surveyAnswerConfigId,
          Value: typeof value != "string" ? value.toString() : value,
        })
        .then(() => {
          getScaleAsnwers();
        });
    } else {
      useSurveyUserProfileAnswer
        .createSurveyUserProfileAnswer({
          SurveyUserProfileId: surveyUserProfileId,
          SurveyQuestionId: question.surveyQuestionId,
          SelectedAnswerConfigId: surveyAnswerConfigId,
          Value: typeof value != "string" ? value.toString() : value,
        })
        .then(() => {
          getScaleAsnwers();
        });
    }
  };

  const getAnswers = async (question) => {
    useSurveyUserProfileAnswer
      .getSurveysUserProfileAnswersByQuestionId(
        question.surveyQuestionId,
        surveyUserProfileId
      )
      .then((result) => {
        if (result) {
          setAnswersData(result.answers);
          if (result.selectedAnswerId) {
            setSelectedAnswerId(result.selectedAnswerId);
            var ua = {
              SurveyUserProfileAnswerId: result.surveyUserProfileAnswerId,
              SurveyUserProfileId: surveyUserProfileId,
              SurveyQuestionId: question.surveyQuestionId,
              SelectedAnswerConfigId: result.selectedAnswerId,
              Value: result.answerValue,
            };
            setUserAnswer(ua);
          } else {
            var ua = {
              surveyUserProfileAnswerId: 0,
              surveyUserProfileId: 0,
              surveyQuestionId: question.surveyQuestionId,
              selectedAnswerConfigId: 0,
              value: "",
            };
            setUserAnswer(ua);

            if (question.surveyAnswerTypeId == parseInt(SurveyTypes.WARM_MAP)) {
              setSelectedAnswerId(result.answers[0]?.surveyAnswerConfigId);
            }

            if (question.surveyAnswerTypeId == parseInt(SurveyTypes.SCALE)) {
              setSelectedAnswerId(result.answers[1]?.surveyAnswerConfigId);
            }
          }
          if (result.answerValue) {
            setSelectedValue(result.answerValue);
            setLastSave(index);
          }
        }
      });
  };

  const handleTextChange = (event, answer) => {
    event.persist();
    setSelectedAnswerId(answer.surveyAnswerConfigId);
    setSelectedValue(event.target.value);
  };

  const handleOptionChange = (event, answer) => {
    event.persist();
    setSelectedAnswerId(answer.surveyAnswerConfigId);
  };

  const uploadImageAnswer = (e) => {
    const validFormats = [
      "image/jpeg",
      "image/jpg",
      "image/png"
    ];
    let isNotValid = false;
    if (e.target.files.length > 0) {
      Array.from(e.target.files).forEach((f) => {
        if (!validFormats.includes(f.type)) {
          return (isNotValid = true);
        }
      });

      if (isNotValid) {
        return swal({
          text: "Sólo puedes subir archivos de tipo PNG, JPG, JPEG.",
          icon: "error",
        });
      }

      setImagesToUpload(e.target.files);
    }
  };

  const isScaleOptionChecked = (value, id) => {
    return answersSelected.find(
      (a) => a.value == value && a.selectedAnswerConfigId == id
    )
      ? true
      : false;
  };

  const handleSubmit = () => {
    if (userAnswer.SurveyUserProfileAnswerId ?? null) {
      var upd = {
        SurveyUserProfileAnswerId:
          userAnswer.SurveyUserProfileAnswerId,
        SurveyUserProfileId: surveyUserProfileId,
        SurveyQuestionId: userAnswer.SurveyQuestionId,
        SelectedAnswerConfigId: SelectedAnswerId,
        Value: SelectedValue,
      };
      setUserAnswer(upd);
      useSurveyUserProfileAnswer
        .updateSurveyUserProfileAnswer(upd)
        .then((result) => {
          if (result != null) {
            if (result.isSurveyCompleted) {
              swal({
                text: "La encuesta se ha completado",
                icon: "success",
              }).then(() => window.location.reload());
            } else {
              setUserAnswer(result);
              nextStep();
            }
          } else setQuestionIndexSave(-1);
        })
        .catch((error) => setQuestionIndexSave(-1));
    } else {
      if (question.surveyAnswerTypeId == parseInt(SurveyTypes.MULTIMEDIA)) {
        if (
          imagesToUpload.length <= 0 &&
          (!userAnswer.Value || userAnswer.Value == "")
        ) {
          swal({
            text: "Debes subir al menos una imagen como respuesta",
            icon: "error",
          });
          return setQuestionIndexSave(-1);
        }

        if (!userAnswer.Value || userAnswer.Value == "") {
          Array.from(imagesToUpload).forEach((image) => {
            useSurveyUserProfileAnswer
              .addSurveyMultimedia(
                {
                  SurveyAnswerConfigId: question.surveyQuestionId,
                  SurveyId: surveyUserProfileId,
                },
                image
              )
              .then((url) => {
                useSurveyUserProfileAnswer
                  .createSurveyUserProfileAnswer({
                    SurveyUserProfileId: surveyUserProfileId,
                    SurveyQuestionId: question.surveyQuestionId,
                    SelectedAnswerConfigId: answerData[0].surveyAnswerConfigId,
                    Value: url,
                  })
                  .then((result) => {
                    if (result != null) {
                      if (result.isSurveyCompleted) {
                        swal({
                          text: "La encuesta se ha completado",
                          icon: "success",
                        }).then(() => window.location.reload());
                      } else {
                        setUserAnswer(result);
                        nextStep();
                      }
                    } else setQuestionIndexSave(-1);
                  })
                  .catch((error) => setQuestionIndexSave(-1));
              });
          });
        }
      }

      var ins = {
        SurveyUserProfileId: surveyUserProfileId,
        SurveyQuestionId: question.surveyQuestionId,
        SelectedAnswerConfigId: SelectedAnswerId,
        Value: SelectedValue,
      };
      setUserAnswer(ins);
      useSurveyUserProfileAnswer
        .createSurveyUserProfileAnswer(ins)
        .then((result) => {
          if (result != null) {
            if (result.isSurveyCompleted) {
              swal({
                text: "La encuesta se ha completado",
                icon: "success",
              }).then(() => window.location.reload());
            } else {
              setUserAnswer(result);
              nextStep();
            }
          } else setQuestionIndexSave(-1);
        })
        .catch((error) => setQuestionIndexSave(-1));
    }
  };

  const nextStep = () => {
    var step = 0;
    var filterData = answerData.filter(
      (item) => item.surveyAnswerConfigId == SelectedAnswerId
    );
    if (filterData.length > 0) {
      step = filterData[0].stepAnswer;
    }
    if (step == 0) {
      next();
    } else {
      getQuestionIndex(step);
    }
  };

  const AnswerComponent = (props) => {
    const { answerTypeId } = props;
    switch (answerTypeId.toString()) {
      case SurveyTypes.MULTIPLE_OPTION:
        return (
          <div className="wrapper_label_radio_answ">
            {answerData.map((answer, index) => answer.active && (
              <div className="form-check box_label_radio_answ" key={index}>
                <input
                  className="form-check-input"
                  type="radio"
                  name={"Q" + question.surveyQuestionId}
                  id={"Q" + answer.surveyAnswerConfigId}
                  value={answer.surveyAnswerConfigId}
                  checked={SelectedAnswerId === answer.surveyAnswerConfigId}
                  onChange={(event) => handleOptionChange(event, answer)}
                />
                <label
                  className="form-check-label label_radio_answ"
                  for={"Q" + answer.surveyAnswerConfigId}
                >
                  <span>
                    {answer.isImage ? (
                      <img
                        className="opt_img_answer"
                        src={answer.answer}
                        alt="Tu opinion en kuimby"
                      />
                    ) : (
                      answer.answer
                    )}
                  </span>
                </label>
              </div>
            ))}
          </div>
        );
      case SurveyTypes.SCALE:
        return (
          <div className="wrapper__groups table-responsive">
            <table className="table table-borderless align-middle">
              <thead>
                <tr className="table-active">
                  <th scope="col"></th>
                  {scaleAnswers.map((scaleAnswer, index) => (
                    <th key={index} scope="col">
                      {scaleAnswer.description}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {answerData.map((answer, index) => {
                  return answer.active && (
                    <tr key={index}>
                      <th className="text-start table-active" scope="row">
                        {answer.answer}
                      </th>
                      {scaleAnswers.map((sca, index) => (
                        <td key={index}>
                          <div className="form-check box__flex__center">
                            <input
                              className="form-check-input"
                              type="radio"
                              name={`radio-${index}-${answer.surveyAnswerConfigId}`}
                              id={`radio-${index}-${answer.surveyAnswerConfigId}`}
                              value={sca.description}
                              checked={isScaleOptionChecked(
                                `${sca.scaleValue}`,
                                answer.surveyAnswerConfigId
                              )}
                              onChange={() =>
                                onSelectScale(
                                  answer.surveyAnswerConfigId,
                                  sca.scaleValue
                                )
                              }
                            />
                          </div>
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        );
      case SurveyTypes.MULTIMEDIA:
        return imagesToUpload.length == 0 &&
          (!userAnswer?.Value || userAnswer?.Value == "") ? (
          <div className="wrapper_input_file">
            <input
              className="form-control"
              type="file"
              id="formFileMultiple"
              multiple={false}
              onChange={uploadImageAnswer}
              accept=".png, .jpg, .jpeg"
            />
          </div>
        ) : (
          <div className="wrapper_input_file files_up">
            <span>
              <BsCheckCircle />
            </span>
            <p>Respuesta guardada</p>
          </div>
        );
      case SurveyTypes.WARM_MAP:
        return (
          <div className="wrapper__heat_maps">
            <h1>mapas de calor</h1>
            <iframe
              src={answerData[1]?.answer}
              frameborder="0"
            ></iframe>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {question.surveyAnswerTypeId.toString() == SurveyTypes.OPEN ? (
        <FormGroup className="form-group">
          <input
            type="text"
            value={SelectedValue}
            onChange={(event) => handleTextChange(event, answerData[0])}
            className="form-control"
          />
        </FormGroup>
      ) : (
        <AnswerComponent answerTypeId={question.surveyAnswerTypeId} />
      )}
    </>
  );
}
