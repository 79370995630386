import React, { useState } from 'react';
import { EditRewardProvider } from './editRewardProvider'

export function DetailsRewardProvider(props) {

    return (
        <div className="dashboard-container-page">
            <div className="mb-4 divtop">
                <div className="box_ttl_opt mb-0">
                    <div>
                        <h2>Recompensas - Proveedores</h2>
                        <h6>{props.match.params.id != 0 ? "Editar proveedor" : "Nueva proveedor"}</h6>
                    </div>
                </div>
            </div>

            <EditRewardProvider rewardProviderId={props.match.params.id} />
        </div>
    );
}   
