import React, { useState } from 'react';
import { EditProductFilter } from './editProductFilter'

export function DetailsProductFilter(props) {

    return (
        <div className="dashboard-container-page">
            <div className="admin-title">
                <h2>Recompensas</h2>
                <h6>{props.match.params.id != 0 ? "Editar filtro" : "Nuevo filtro"}</h6>
            </div>
            <EditProductFilter rewardProductFilterId={props.match.params.id} />
        </div>
    );
}   
