import React, { useEffect, useState } from "react";
import { AsideCategoriesJobs } from "./asideCategoriesJobs";
import { TtlsJobBank } from "./ttlsJobBank";
import { Link } from "react-router-dom";
import { ApplicantForm } from "./applicantForm";
import { JobBankService } from "../../services/jobBank.service";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Moment from "react-moment";
import Swal from "sweetalert2";
import "./jobBank.scss";
import { postulanteService } from "../../services/postulantJob.service";
import { backendService } from "../../services/backend.service";
import {Button} from "reactstrap";
import {
  FaMapMarkerAlt,
} from "react-icons/fa";
import {
  BsCalendar,
  BsCalendar2,
  BsChevronLeft,
  BsMap,
  BsMapFill,
} from "react-icons/bs";
import {
  GrLocation
} from "react-icons/gr";
import {
  BiBuilding
} from "react-icons/bi";
export function DetailsJobs() {
  const roleName = useSelector((state) => state.userSettings.roleName);
  const [job, setJob] = useState({});
  const [showApplicantForm, setShowApplicantForm] = useState(false);
  const [hasAlreadyApplied, setHasAlreadyApplied] = useState(true);
  const [user, setUser] = useState();
  let { id } = useParams();

  useEffect(() => {
    getJobsById();
    verifyPostulant();

    backendService.userProfile().then((result) => {
      setUser(result);
    });

  }, []);

  const verifyPostulant = () => {
    postulanteService.verifyPostulant(id).then((result) => {
      if(result === "False")
        setHasAlreadyApplied(false);
      else
        setHasAlreadyApplied(true);
    })
  }

  const getJobsById = () => {
    JobBankService.getJobById(id).then((result) => {

      JobBankService.getJobSections().then(sections => {
        let sectionsDescriptions = result.jobSection.map(jobSection => {
          let nameSection = sections.find(section => section.jobSectionTypeId === jobSection.jobSectionTypeId).name;
          return "<h6>" + nameSection + ":</h6>" + jobSection.description;
        });

        result.description += sectionsDescriptions.join("");
        setJob(result);
      });
    });
  }

  const authorize = (e) => {
    JobBankService.authorizeJob(e.jobId, true).then((result) => {
      Swal.fire({text: "El trabajo ha sido aprobado", icon: "success"});
      getJobsById();
    });
  }

  const reject = (e) => {
    JobBankService.authorizeJob(e.jobId, false).then((result) => {
      Swal.fire({text: "El trabajo ha sido rechazado", icon: "success"});
      getJobsById();
    });
  }

  const closeForm = () => {
    setShowApplicantForm(false);
    verifyPostulant();
  }

  return (
    <section className="dashboard-container-page">
        {!showApplicantForm && 
          <>
            <article className="details-job-header">
              <Link to="/job-bank" className="btn-back">
                <i><BsChevronLeft /></i>
                <p>Ir a bolsa de trabajo</p>
              </Link>
              <section className="btns__box">
                {roleName === "Usuario" && job.status !== "Guardado" && job.createUser !== localStorage.getItem("userId") && !hasAlreadyApplied &&
                    <Button className="btn-gradient-green" onClick={() => setShowApplicantForm(true)}>
                      Aplicar ahora
                    </Button>
                }
                {roleName === "Recursos Humanos" && job.status === "Guardado" &&
                    <Button className="btn-gradient-green"  onClick={() => authorize(job)}>
                      Autorizar
                    </Button>
                }
                {roleName === "Recursos Humanos" && job.status === "Guardado" &&
                    <Button className="btn-gradient-green"  onClick={() => reject(job)}>
                      Rechazar
                    </Button>
                }
              </section>
            </article>
            <article className="details-job-content">
              <div className="header">
                <div className="info">
                  <h4>{job.title}</h4>
                  <p className="company"><span><BiBuilding /></span> {job.companyName}</p>
                  <p className="company"><span><FaMapMarkerAlt /></span> {job.location}</p>
                  <p className="company">
                    <span><BsCalendar2 /></span>
                    <Moment locale="es" format="DD [de] MMMM YYYY">{job.publicationDate}</Moment>
                  </p>
                </div>
                <div className="thumbnail">
                  <img src={job.companyImage} alt={"Logo empresa " + job.companyName}/>
                </div>
              </div>
              <div className="content">
                <div className="content-description">
                  <div dangerouslySetInnerHTML={{ __html: job.description }} />
                </div>
                {job.salary > 0 &&
                  <div className="salary">
                      <p>Oferta salarial</p>
                      <p className="quantity">${job.salary} MNX</p>
                  </div>
                }
              </div>
            </article>
          </>
        }
        {showApplicantForm && job && <ApplicantForm closeForm={closeForm} job={job} user={user}/>}
    </section>
  );
}
