import { callApi, callApiFileStorage } from './service'

export const courseService = {
    createCourse,
    updateCourse,
    getCourse,
    deleteCourse,
    getCourseFilesById
};

async function createCourse(params, files, createEventCalendar) {
    var data = new FormData();
    if (files) {
        for (var i = 0; i < files.length; i++) {
            var f = files[i];
            data.append('files', f, f.name);
        }
    }

    data.append("model", JSON.stringify(params));
    return callApiFileStorage(`/backoffice/Course?createCalendarEvent=${createEventCalendar}`, 'POST', data).then((result) => {
        return handleResponse(result);
    });
}

async function updateCourse(params, files) {
    var data = new FormData();

    if (files) {
        for (var i = 0; i < files.length; i++) {
            var f = files[i];
            data.append('files', f, f.name);
        }
    }
    
    data.append("model", JSON.stringify(params));

    return callApiFileStorage('/backoffice/Course', 'PUT', data).then((result) => {
        return handleResponse(result);
    });
}

async function getCourse() {
    return callApi('/backoffice/Course', 'GET').then((result) => {
        if (result.responseCode == '00') {
            return result.data;
        } else {
            alert(result.responseMessage);
            return null;
        }

    });
}
async function getCourseFilesById(courseId) {
    return callApi('/backoffice/Course/WithFiles/' + courseId, 'GET').then((result) => handleResponse(result));
}

async function deleteCourse(params) {
    return callApi('/backoffice/Course', 'DELETE', params);
}

function handleResponse(result) {
    if (result.responseCode === '00')
        return result.data;
    else {
        //alert(result.responseMessage);
        return null;
    }
}
