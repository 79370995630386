import React, { useEffect, useState } from 'react';
import Moment from "react-moment";
import { useSurvey } from '../../services/survey.service';
import {Space, Table} from "antd";
import Swal from 'sweetalert2';
import {Button} from "rsuite";

export function SearchSurvey(props) {
	const [surveysData, setSurveysData] = useState({ surveys: [] });
	const handleDelete = (data) => {
		Swal.fire({
			title:"¿Deseas desactivar encuesta?",
			confirmButtonText:"Si",
			icon: "warning",
			showCancelButton:true,
			allowOutsideClick: true, 
			allowEscapeKey: true,
			confirmButtonColor: '#3085d6',
  			cancelButtonColor: '#d33'
		}).then((result) =>{
			if (result.isConfirmed){
				useSurvey.deleteSurvey(data).then((result) => { getSurveys(); });
			}
		})
	};
	//const [selectedSurvey, setSelectedSurvey] = useState();

	//let dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
	const handleDownload = (surveyId) =>{
		useSurvey.downloadCSV(surveyId).then((result) => { getSurveys(); });
	}; 
	useEffect(() => {
		getSurveys();
	}, [props.refresh]);

	async function getSurveys() {
		useSurvey.getSurveys().then((result) => {
			if (result) setSurveysData({ surveys: result });
		});
	}

	const goToSurvey = (id) => {
		window.location.href = '/admin/details-survey/'+id;
	}

	const columnsSurveys = [
		{
			title: 'ID encuesta',
			key: 'id',
			dataIndex: 'id',
			fixed: 'left',
			width: 100
		},
		{
			title: 'Titulo de encuesta',
			key: 'title',
			dataIndex: 'title'
		},
		{
			title: 'No. Encuestados',
			key: 'no',
			dataIndex: 'no'
		},
		{
			title: 'Total de encuestados',
			key: 'total',
			dataIndex: 'total'
		},
		{
			title: 'Fecha límite',
			key: 'date',
			dataIndex: 'date'
		},
		{
			title: 'Estatus',
			key: 'status',
			dataIndex: 'status'
		},
		{
			title: 'Opciones',
			key: 'options',
			dataIndex: 'options',
		},
	];

	const dataSurveys = surveysData.surveys.map(item => {
		return (
			{
				"key": item.surveyId,
				id: item.surveyId,
				title: item.title,
				no: item.surveyedCount,
				total: item.answeredCount,
				date: <Moment locale="es" format="DD/MM/YYYY">{item.endDate}</Moment>,
				status: item.estatus,
				options: <Space size="middle">
					<Button className="btn-icon-table edit" onClick={() => goToSurvey(item.surveyId)}>
						<i className="icon-edit"></i>
					</Button>
					<Button type="button" className="btn-icon-table edit" onClick={() => handleDownload(item.surveyId)}>
						<i className="fas fa-download"></i>
					</Button>
					<Button type="button" className="btn-icon-table delete" onClick={() => handleDelete(item)} disabled={(!item.active === true)}>
						<i className="icon-trash"></i>
					</Button>
			
				</Space>
			}
		);
	});

	return (
		<>
			<Table scroll={{ x: '992px', y: 800 }} className="table-admin" columns={columnsSurveys} dataSource={dataSurveys} />
		</>

	);
}