import React, { useState } from 'react';
import { SearchCompany } from './searchCompany';

import './companyAdmin.scss'
import { Link } from 'react-router-dom';
import {Col, FormGroup, Label, Row} from "reactstrap";
import {FaPlus} from "react-icons/fa";

export function AdminCompany() {
    
    const [statusSelected, setStatus] = useState(true)
    const [refresh, setRefresh] = useState(0);
    const handleSelectChange = (event) => {
        setStatus(event.target.value);
    }
    return (
        <section className="dashboard-container-page">
            <div className="admin-title">
                <h2>Empresas</h2>
                <h6>Operaciones</h6>
            </div>
            <div className="admin-subtitle">
                <FormGroup className="form-dashboard-group">
                    <Label htmlFor="filterStatus">Estado</Label>
                    <select className="form-control" id="statusId" name="rvolCompany" onChange={handleSelectChange}>
                        <option value="true" selected>Activo</option>
                        <option value="false">Inactivo</option>
                    </select>
                </FormGroup>
                <div className="flex-end">
                    <Link className="btn-dark" to={"/operator/details-company/0"}>
                        <p>Agregar Empresa</p>
                        <FaPlus />
                    </Link>
                </div>
            </div>
            <Row>
                <Col sm={12}>
                    <SearchCompany statusValue={statusSelected} />
                </Col>
            </Row>
        </section>
    );
}
