import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {backendService} from "../../services/backend.service";
import {Link} from "react-router-dom";
import IcoHome from "../../assets/imgs/iconos/ico_home.png";
import IcoProfile from "../../assets/imgs/iconos/ico_profile.png";
import IcoMessage from "../../assets/imgs/iconos/ico_message.png";
import IcoSchedule from "../../assets/imgs/iconos/ico_schedule.png";
import IcoMundoPride from "../../assets/imgs/iconos/ico_mundo_pride.png";
import IcoOrgulloPride from "../../assets/imgs/iconos/ico_orgullo_pride.png";
import IcoCapacitacion from "../../assets/imgs/iconos/ico_capacitacion.png";
import IcoLearningCenter from "../../assets/imgs/iconos/ico_learning_center.png";
import IcoDiscount1 from "../../assets/imgs/iconos/ico_discount1.png";
import IcoPoll from "../../assets/imgs/iconos/ico_poll.png";
import IcoAyuda from "../../assets/imgs/iconos/ico_ayuda.png";
import {Col, Row} from "reactstrap";
import './admin.scss';
import { FaUser} from "react-icons/fa";
import {BsListColumns} from "react-icons/bs";
import {FooterHome} from "../../components/footer/footerHome";

export const Admin = () => {

    const roleName = useSelector((state) => state.userSettings.roleName);
    const [isOpen, setIsOpen] = useState(false);
    const toggleCollapse = () => setIsOpen(!isOpen);
    const [messages, setMessages] = useState([]);

    const [widgets, setWidgets] = useState({
        surveyWidget: {},
        messageWidget: {},
        rewardWidget: {},
        courseWidget: {},
        providerWidget: [{ id: 0, logoCrls: "" }],
    });

    useEffect(() => {
        backendService.widgets().then((data) => {
            if (data.surveyWidget != null) {
                let date = new Date();
                date.setDate(date.getDate() + data.surveyWidget.expirationDays);
                data.surveyWidget.expirationDate = date;
            }
            setWidgets(data);
        });
        // messageService
        //     .getMessage(0, "desc", "pending")
        //     .then((data) => setMessages(data));
    }, []);

    const [profile, setProfileInfo] = useState({});

    async function getProfileInfo() {
        backendService.userProfile().then((data) => {
            setProfileInfo(data);
        });
    }

    useEffect(() => {
        backendService.userSettings().then((result) => {
            getProfileInfo();
        });
    }, []);

    return (
        <section className="dashboard-container-page">
            {
                {
                    Operaciones: <h2>Operaciones</h2>,
                    "Recursos Humanos": <h2>Recursos Humanos</h2>,
                }[roleName]
            }
            <article className="admin-container">
                {
                    {
                        Operaciones: <MenuOperaciones profile={profile} messages={messages} widgets={widgets} />,
                        "Recursos Humanos": <MenuAdministrador profile={profile} />,
                        Usuario: <MenuUsuario isOpen={isOpen} toggleCollapse={toggleCollapse} />,
                    }[roleName]
                }
            </article>
            <Row>
                <Col sm={12}>
                    <FooterHome />
                </Col>
            </Row>
        </section>
    );
}

const MenuUsuario = ({widgets,messages}) => {
    return (
        <div>
            <ul>
                <li>
                    <Link className="menu_item" to="/home">
                        <span className="ico_imgs"><img src={IcoHome} alt="Menu" /></span>
                        <span>Muro</span>
                    </Link>
                </li>
                <li>
                    <Link className="menu_item" to="/profile">
                        <span className="ico_imgs"><img src={IcoProfile} alt="Menu" /></span>
                        <span>Mi Perfil</span>
                    </Link>
                </li>
                <li>
                    <Link className="menu_item" to="/messages">
                      <span className="ico_imgs"><img src={IcoMessage} alt="Menu" /></span>
                        <span>Mensajes<span className="dots_notif">{(messages) ? messages.length : ''}</span></span>
                    </Link>
                </li>
                <li>
                    <Link className="menu_item" to="/diary">
                        <span className="ico_imgs"><img src={IcoSchedule} alt="Menu" /></span>
                        <span> Calendario </span>
                    </Link>
                </li>
                <li>
                    <Link className="menu_item" to={`/our-company/1`}><span className="ico_imgs"><img src={IcoMundoPride} alt="Menu" /></span>
                        <span>Preguntas frecuentes</span>
                    </Link>
                </li>
                <li>
                    <Link className="menu_item" to={`/stories-pride/2`}>
                        <span className="ico_imgs"><img src={IcoOrgulloPride} alt="Menu" /></span>
                        <span>Consultas</span>
                    </Link>
                </li>
                <li>
                    <Link className="menu_item" to="/course-view">
                      <span className="ico_imgs"><img src={IcoCapacitacion} alt="Menu" /></span>
                        <span>Escuela Romeh<span className="dots_notif">{widgets ? widgets.courseWidget.quantity : "0"}</span></span>
                    </Link>
                </li>
                {/* <li>
                    <Link className="menu_item" to="/directory">
                      <span className="ico_imgs"><img src={IcoEnterpreneur} alt="Menu" /></span>
                    <span>Emprendedores</span>
                    </Link>
                </li> */}
                {/* <li>
                    <Link className="menu_item" to="/pride-music">
                      <span className="ico_imgs"><img src={IcoMusic} alt="Menu" /></span>
                    <span>Pride Music</span>
                    </Link>
                </li> */}
                {/* <li>
                    <Link className="menu_item" to="/interest-group">
                      <span className="ico_imgs"><img src={IcoInterestGroup} alt="Menu" /></span>
                    <span>Testimonios</span>
                    </Link>
                </li> */}
                <li>
                    <Link className="menu_item" to="/audience">
                          <span className="ico_imgs">
                            <img src={IcoLearningCenter} alt="Menu" />
                          </span>
                        <span>Mis rutinas</span>
                    </Link>
                </li>
                <li>
                    <Link className="menu_item" to="/discount">
                      <span className="ico_imgs">
                        <img src={IcoDiscount1} alt="Menu" />
                      </span>
                        <span>Beneficios</span>
                    </Link>
                </li>
                {/* <li>
                    <Link className="menu_item" to="/rewards">
                      <span className="ico_imgs">
                        <img src={IcoRewards} alt="Menu" />
                      </span>
                        <span>Rewards</span>
                    </Link>
                </li> */}
                {/* <li>
                    <Link
                        className="menu_item"
                        to="/caring-community"
                    >
                      <span className="ico_imgs">
                        <img src={IcoCaring} alt="Menu" />
                      </span>
                        <span>Comunidad Solidaria</span>
                    </Link>
                </li> */}
                {/* <li>
                    <Link className="menu_item" to="/job-bank">
                      <span className="ico_imgs">
                        <img src={IcoJob} alt="Menu" />
                      </span>
                        <span>Bolsa de Trabajo</span>
                    </Link>
                </li> */}
                <li>
                    <Link className="menu_item" to="/surveys">
                      <span className="ico_imgs">
                        <img src={IcoPoll} alt="Menu" />
                      </span>
                        <span>Tu Opinión{" "}
                            <span className="dots_notif">
                              {widgets ? widgets.surveyWidget.quantity : "0"}
                            </span>
                        </span>
                    </Link>
                </li>
                <li>
                    <Link className="menu_item" to="/help">
                      <span className="ico_imgs">
                        <img src={IcoAyuda} alt="Menu" />
                      </span>
                        <span>Ayuda</span>
                    </Link>
                </li>
            </ul>
        </div>
    );
};

const MenuOperaciones = ({profile}) => {
    return (
        <>
            <div className="admin-container-cards">
                <div className="columns-secondary">
                    <div className="card-user-admin">
                        <div className="card-user-avatar">
                            <div className={`rotating ${profile.genderIdentityFlag}`}></div>
                            <img alt="" src={profile.urlProfileImage} />
                        </div>
                        <h3>{profile.firstName} {profile.lastName}</h3>
                        <h4>Administrador</h4>
                        <p>{profile.email}</p>
                        <Link to="/profile" className="btn-dark">
                            <p>Mi Perfil</p>
                            <FaUser />
                        </Link>
                    </div>
                </div>
                <div className="columns-primary">
                    <div className="columns-primary-cards-first">
                        <div className="column">
                            <div className="columns-primary-card outline">
                                <h3>Empresas</h3>
                                <p>Descripcion para la tarjeta</p>
                                <div className="btns">
                                    <Link className="btn-show-more" to="/operator/AdminCompany">Ver <span></span></Link>
                                </div>
                                <i className="bg-icon"><span className="icon-business"></span></i>
                            </div>
                            <div className="columns-primary-card outline">
                                <h3>Rewards</h3>
                                <p>Descripcion para la tarjeta</p>
                                <div className="btns">
                                    <Link className="btn-show-more" to="/operator/controlreward/1">Ver <span></span></Link>
                                </div>
                                <i className="bg-icon"><span className="icon-gif"></span></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const MenuAdministrador = ({profile}) => {
    return (
        <div className="admin-container-cards">
            <div className="columns-secondary">
                <div className="card-user-admin">
                    <div className="card-user-admin-avatar">
                        <div className={`rotating ${profile.genderIdentityFlag}`}></div>
                        <img alt="" src={profile.urlProfileImage} />
                    </div>
                    <h3>{profile.firstName} {profile.lastName}</h3>
                    <h4>Administrador</h4>
                    <p>{profile.email}</p>
                    <Link to="/profile" className="btn-dark">
                        <p>Mi Perfil</p>
                        <FaUser />
                    </Link>
                </div>
            </div>
            <div className="columns-primary">
                <div className="columns-primary-cards-first">
                    <div className="column">
                        <div className="columns-primary-card outline">
                            <h3>Calendario</h3>
                            <p>Descripcion para la tarjeta</p>
                            <div className="btns">
                                <Link className="btn-show-more" to="/admin/admin-diary">Ver <span></span></Link>
                            </div>
                            <i className="bg-icon"><span className="icon-competences"></span></i>
                        </div>
                        <div className="columns-primary-card outline">
                            <h3>Escuela Romeh</h3>
                            <p>Descripcion para la tarjeta</p>
                            <div className="btns">
                                <Link className="btn-show-more" to="/admin/course">Ver <span></span></Link>
                            </div>
                            <i className="bg-icon"><span className="icon-fitness"></span></i>
                        </div>
                    </div>
                    <div className="column">
                       {/*  <div className="columns-primary-card outline">
                            <h3>Recompensas</h3>
                            <p>Descripcion para la tarjeta</p>
                            <div className="btns">
                                <Link className="btn-show-more" to="/admin/product-filter">Ver <span></span></Link>
                            </div>
                            <i className="bg-icon"><span className="icon-gif"></span></i>
                        </div>*/}
                        <div className="columns-primary-card outline">
                            <h3>Tu opinión</h3>
                            <p>Descripcion para la tarjeta</p>
                            <div className="btns">
                                <Link className="btn-show-more" to="/admin/AdminSurvey">Ver <span></span></Link>
                            </div>
                            <i className="bg-icon"><span className="icon-opinion"></span></i>
                        </div>
                    </div> 
                </div>
                <div className="columns-primary-cards-second">
                    <div className="columns-primary-card fill purple">
                        <h3>Control</h3>
                        <p>Descripcion para la tarjeta</p>
                        <div className="btns">
                            <Link className="btn-show-more" to="/admin/control">Ver <span></span></Link>
                        </div>
                        <i className="bg-icon"><BsListColumns /></i>
                    </div>
                    <div className="columns-primary-card fill darkblue">
                        <h3>Preguntas frecuentes</h3>
                        <p>Descripcion para la tarjeta</p>
                        <div className="btns">
                            <Link className="btn-show-more" to="/admin/CompanyPost">Ver <span></span></Link>
                        </div>
                        <i className="bg-icon"><span className="icon-man"></span></i>
                    </div>
                </div>
                <div className="columns-primary-cards-third">
                    <div className="columns-primary-card outline">
                        <h3>Mis rutinas</h3>
                        <p>Descripcion para la tarjeta</p>
                        <div className="btns">
                            <Link className="btn-show-more" to="/admin/admin-audience">Ver <span></span></Link>
                        </div>
                        <i className="bg-icon"><span className="icon-champion"></span></i>
                    </div>
                    <div className="columns-primary-card outline">
                        <h3>Usuarios</h3>
                        <p>Descripcion para la tarjeta</p>
                        <div className="btns">
                            <Link className="btn-show-more" to="/admin/users">Ver <span></span></Link>
                        </div>
                        <i className="bg-icon"><span className="icon-user"></span></i>
                    </div>
                    {/* <div className="columns-primary-card outline">
                        <h3>Comunidad Solidaria</h3>
                        <p>Descripcion para la tarjeta</p>
                        <div className="btns">
                            <Link className="btn-show-more" to="/admin/admin-caring-community">Ver <span></span></Link>
                        </div>
                        <i className="bg-icon"><span className="icon-community"></span></i>
                    </div> */}
                </div>
            </div>
        </div>
    );
};
