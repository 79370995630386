import React, { useState, useRef } from "react";
import moment from "moment";
// import { metaPixelService } from "../../services/metaPixel.service";
import InfiniteScroll from "react-infinite-scroll-component";
import {BsPlay} from "react-icons/bs";
import imgEmpty from '../../assets/imgs/no-image.jpeg';
import {Button} from "rsuite";
import {Col, Row, Tabs} from "antd";

export function BoxListAudience(props) {
  const timelineRef = useRef();
  
  const loadMore = () => {
    const lastitem = props.OldItems.slice(-1).pop().data;
    props.updateComplete(lastitem.conferenceDate,lastitem.auditoriumId)
  };


  const goToConference = (item) => {
    window.open(item.data.conferenceUrl,'_blank');

    // const data = {
    //   content_name: item.data.title,
    //   content_type: "product",
    //   contents: [
    //     {
    //       id: item.data.auditoriumId,
    //       quantity: 1,
    //       link: item.data.conferenceUrl,
    //     },
    //   ],
    // };
    // metaPixelService.trackEvent(
    //     "track",
    //     "ViewContent",
    //     data
    // );

  }

  const items = [
    {
      key: '1',
      label: `Ejercicios`,
      children:
        <InfiniteScroll
            dataLength={props.OldItems.length}
            next={() =>loadMore()}
            hasMore={true && !props.disabledHasMore}
            loader={<h4>No tienes ejercicios previos</h4>}
            scrollableTarget="scrollableDiv"
        >
          <Row gutter={8}>
            {props.OldItems.length > 0 && props.OldItems.sort((a, b) => a.data.conferenceDate < b.data.conferenceDate ? 1 : -1).map((item,i) => {
            return (
                <Col xl={6} lg={8} md={8} sm={12} xs={24}>
                  <Button onClick={() => goToConference(item)} className="card-audience" key={i+i}>
                  <div className="thumbnail">
                    <img src={item.files.length > 0 && item.files[0].path} alt={item.data.title}/>
                  </div>
                  <div className="info">
                    <div>
                      <h5>{item.data.title}</h5>
                      <p>{moment(item.data.conferenceDate).format("LLLL")}</p>
                    </div>
                    <div className="btns">
                      <p className="badge-audience">Charlas</p>
                      <a className="card-audience-link" href={item.data.conferenceUrl}
                        //  onClick={() => {
                        //    var data = {
                        //      content_name: item.data.title,
                        //      content_type: "product",
                        //      contents: [
                        //        {
                        //          id: item.data.auditoriumId,
                        //          quantity: 1,
                        //          link: item.data.conferenceUrl,
                        //        },
                        //      ],
                        //    };
                        //    metaPixelService.trackEvent(
                        //        "track",
                        //        "ViewContent",
                        //        data
                        //    );
                        //  }}
                         target="_blank"
                      >
                        <BsPlay />
                      </a>
                    </div>
                  </div>
                </Button>
                </Col>
           );
          })}
          </Row>
        </InfiniteScroll>
    },
    {
      key: '2',
      label: `En vivo`,
      children:
          <Row gutter={8}>
            {props.Items && props.Items.sort((a, b) => a.data.conferenceDate > b.data.conferenceDate ? 1 : -1).map((item,i) => (
                <Col xl={6} lg={8} md={8} sm={12} xs={24}>
                  <Button className="card-audience" onClick={() => goToConference(item)} key={i}>
                    <div className="thumbnail">
                    {moment(item.data.conferenceDate).isBefore(
                          new Date(),
                          "minutes"
                        ) && (
                          <span class="dots_notif">
                            <svg height="12" width="12" class="blinking">
                              <circle cx="6" cy="6" r="4" fill="red" />.
                            </svg>{" "}
                            En Vivo
                          </span>
                        )}
                      <img src={(item.files.length > 0 && item.files[0].path) ? item.files[0].path : imgEmpty} alt={item.data.title}/>
                    </div>
                    <div className="info">
                      <div>
                        <h5>{item.data.title}</h5>
                        <p>{moment(item.data.conferenceDate).format("LLLL")}</p>
                      </div>
                      <div className="btns">
                        <p className="badge-audience">Charlas</p>
                        <a className="card-audience-link" href={item.data.conferenceUrl}
                          //  onClick={() => {
                          //    var data = {
                          //      content_name: item.data.title,
                          //      content_type: "product",
                          //      contents: [
                          //        {
                          //          id: item.data.auditoriumId,
                          //          quantity: 1,
                          //          link: item.data.conferenceUrl,
                          //        },
                          //      ],
                          //    };
                          //    metaPixelService.trackEvent(
                          //        "track",
                          //        "ViewContent",
                          //        data
                          //    );
                          //  }}
                           target="_blank"
                        >
                          <BsPlay />
                        </a>
                      </div>
                    </div>
                  </Button>
                </Col>
            ))}
            {
              (props.Items.length === 0) ?
                  <div className="audience-tabs-empty">
                    <i className="icon-empty"></i>
                    <p>No hay eventos disponibles</p>
                  </div>
                  : ''
            }
          </Row>
      ,
    }
  ];


  return (
    <>
      <Tabs defaultActiveKey="1" items={items} />
    </>
  );
}
