import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import "moment/locale/es";
import { BoxListCourse } from "./boxListCourse";
import { SideAdvertising } from "../../components/sideAdvertising/sideAdvertising";
import "./courseView.scss";
import { courseViewService } from "../../services/courseView.service";
import { useSelector } from "react-redux";
import { AdvertisingSection } from "../../components/advertisingSection/advertisingSection";
import { Row, Col } from "reactstrap";
import { FooterHome } from "../../components/footer/footerHome";
import { useCompanyHoliday } from "../../services/companyHoliday.service";
import imgHeader from "../../assets/imgs/palomitas.png";
import ticket from "../../assets/imgs/dashboard/ticket.png";

export function CourseView(props) {
  const [dataGrid, setDataGrid] = useState([]);
  const calendarStrings = useSelector((state) => state.calendarStrings);
  const [activeAsideSurvey, setStateAsideSurvey] = useState("");
  const [userProfile, setUserProfile] = useState({});
  const [dataGridCompleted, setDataGridCompleted] = useState([]);

  const AsidePurchaseBox = () => {
    setStateAsideSurvey(activeAsideSurvey === "" ? " is-show" : "");
  };

  async function GetUserProfileCourse() {
    courseViewService.GetUserProfileCourse().then((result) => {
      if (result) {
        setUserProfile(result[0]);
      }
    });
  }

  async function getCourse() {
    courseViewService.getCourse().then((result) => {
      if (result) {
        const filteredResults = result.filter(course => course.courseMediaTypeId !== 1);
      setDataGrid(filteredResults);
      }
    });
  }

  async function getCourseCompleted() {
    courseViewService.getCourseComplete().then((result) => {
      if (result) {
        setDataGridCompleted(result);
      }
    });
  }

  useEffect(() => {
    getCourse();
    GetUserProfileCourse();
    getCourseCompleted();
    getCompanyHolidays();
  }, []);

  const [sliderItems, setSliderItems] = useState([]);
  const [banner, setBanner] = useState([]);
  const [defaultBanner, setDefaultBanner] = useState(true);
  const [holyday, setHolyday] = useState({ type: 0, src: "" });

  async function getCompanyHolidays() {
    if (8) {
      useCompanyHoliday.getCompanyHolidayFront(8).then((result) => {
        if (result) {
          var temp = [];
          result.map((ch) => {
            if (holyday.type !== 6) {
              temp.push({
                key: ch.companyHolidayId.toString(),
                file: ch.imagePath,
              });
              setDefaultBanner(false);
            }
          });
          setSliderItems(temp);
          const bannerItem = temp
            .sort(() => Math.random() - Math.random())
            .find(() => true);
          setBanner(bannerItem);
        }
      });
    }
  }

  function setCompleteCourse(course) {
    if (!course.isCompleted) {
      courseViewService.completeCourse(course.courseId).then((result) => {
        getCourse();
        getCourseCompleted();
        GetUserProfileCourse();
      });
    }
  }

  return (
    <section className="course-container-view">
      <Row>
        <Col sm={12}>
          <div className="course-view-title">
            <h2>Escuela Romeh</h2>
          </div>
        </Col>

        {/* {banner ? (
          <Col sm={12} style={{ padding: "0" }}>
            <AdvertisingSection HolType={8} />
          </Col>
        ) : (
          <Col sm={12} style={{ padding: "0" }}>
            <div className="course-view-alternative">
              <img src={ticket} alt="" />
              <div className="course-view-alternative-info">
                <h3>¿Hay algún evento que se nos esté pasando?</h3>
                <h4>¡Queremos saberlo!</h4>
                <p>
                  Escríbenos por chat a PrideMx Administrador para publicarlo en
                  la plataforma.
                </p>
                <p className="border-pointed course-view">🥇 Garantía Pride</p>
              </div>
              <div className="course-view-alternative-imgs">
                <img src={imgHeader} alt="" className="header" />
              </div>
            </div>
          </Col>
        )}
        {banner ? (
          <Col sm={12} style={{ padding: "0" }}>
            <div className="course-view-header">
              <div className="titles">
                <h3>¿Hay algún evento que se nos esté pasando?</h3>
                <h4>¡Queremos saberlo!</h4>
              </div>
              <div className="info">
                <div className="border-pointed">
                  <p className="course-view">🥇 Garantía Pride</p>
                </div>
              </div>
            </div>
          </Col>
        ) : (
          ""
        )} */}

        {/* <div className="content_ttls">
          <div className="minw">
            <h4 className="text fnt_medium ttl mb-3 text-white">
                Accede a nuestro contenido multimedia
            </h4>
            <p className="text fnt_medium">
                Próximos eventos.
            </p>
          </div>
          <div className="box_img_surveys">
            <img src={PictureCourse} alt="Capacitación" />
          </div>
        </div> */}

        <Col sm={12} style={{ padding: "0" }}>
          <h4>Haz una cita</h4>
          {dataGrid.length === 0 ? (
            <div className="empty-grid">
              <p>No hay publicaciones nuevas</p>
              <i className="icon-empty"></i>
            </div>) : ("")}
          <BoxListCourse
            dataGrid={dataGrid}
            setCompleteCourse={setCompleteCourse}
          />
        </Col>
        <Col sm={12}>
          <FooterHome />
        </Col>
      </Row>
    </section>
  );
}
