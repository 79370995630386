import React, { useEffect, useState } from 'react';
import { EditJobRole } from './editJobRole';
import { SearchJobRole } from './searchJobRoles';
import {Col, FormGroup, Input, Label, Row} from "reactstrap";
import {Button} from "rsuite";
import { useSelector } from 'react-redux';
import useInputForm from '../../../components/CustonHooks/FormHook'
import { useJobRole } from '../../../services/jobRole.service'
import {FaPlus, FaSave, FaTimesCircle} from "react-icons/fa";

export function AdminJobRoles() {
	const companyId = useSelector(state => state.userSettings.companyId)
	const [showEdit, setShowEdit] = useState(false);
	const [selectedJobRole, setSelectedJobRole] = useState();
	const [refresh, setRefresh] = useState(0);

	const handleOnSelected = (jobRole) => {
		setShowEdit(true);
		setSelectedJobRole({ JobRoleId: jobRole.jobRoleId, Description: jobRole.description, Name: jobRole.name, IsOnRegister: jobRole.isOnRegister });
	};
	const handleOnNew = () => {
		setSelectedJobRole(null);
		setShowEdit(true);
	};
	const handleOnCancelEdit = () => {
		setSelectedJobRole(null);
		setShowEdit(false);
	}
	const handleOnSaveEdit = () => {
		setRefresh(prev => prev + 1);
		setShowEdit(false);
	}

	const onSave = () => {
        if (inputs.JobRoleId && inputs.JobRoleId > 0)
            useJobRole.updateJobRole({ JobRoleId: parseInt(inputs.JobRoleId), Name: inputs.Name, Description: inputs.Description, IsOnRegister: isChecked, CompanyId: companyId }).then((result) => {
				setRefresh(prev => prev + 1);
            });
        else
            useJobRole.createJobRole({ Name: inputs.Name, Description: inputs.Description, IsOnRegister: isChecked, CompanyId: companyId }).then((result) => {
				setRefresh(prev => prev + 1);
            });
        setInputs({});
		setShowEdit(false);
    };
	const [isChecked, setIsChecked] = useState(false);
	const handleCheckboxChange = (isOnRegister) => {
		if(isOnRegister){
        setIsChecked(false)
			setInputs({...inputs, IsOnRegister : false})
		}else{
			setIsChecked(true)
			setInputs({...inputs, IsOnRegister : true})
		}
	};
	const [survey, setSurvey] = useState({ JobRoleId: "", Description: "", Name: "" });
	const { handleSubmit, handleInputChange, inputs, setInputs } = useInputForm(onSave, survey);

	useEffect(() => {
        let newP = {};
        if (selectedJobRole) {
            newP = { JobRoleId: selectedJobRole.JobRoleId.toString(), Description: selectedJobRole.Description, Name: selectedJobRole.Name, IsOnRegister: selectedJobRole.IsOnRegister };
            setSurvey(newP);
        }
        else
            setSurvey({ JobRoleId: "", Description: "", Name: "" });
    }, [selectedJobRole]);

    useEffect(()=>{ setIsChecked(inputs.IsOnRegister)}, [inputs]) 
	
	return (
		<>
			<div className="admin-heading">
				<h3>Lista de categorías</h3>
				{
					showEdit ?
						<Button className="btn-back" type="button" onClick={handleOnCancelEdit}>
							<FaTimesCircle />
							<p>Cancelar</p>
						</Button>
					:
						<Button type="button" className="btn-dark" onClick={handleOnNew}>
							<p>Agregar categoría</p>
							<FaPlus />
						</Button>

				}
			</div>
			{showEdit ?
					<>
                <form onSubmit={handleSubmit}>
                    <h3>{parseInt(inputs.JobRoleId) === 0 ? "Nueva categoría" : "Editar categoría"}</h3>
                    <Row>
                        <Col md={4}>
                            <input type="hidden" name="JobRoleId" onChange={handleInputChange} value={inputs.JobRoleId} />
                            <FormGroup className="form-dashboard-group">
                                <Label>Nombre</Label>
                                <Input type="text" name="Name" onChange={handleInputChange} value={inputs.Name} maxLength={100} required />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup className="form-dashboard-group">
                                <Label>Descripción</Label>
                                <Input type="text" name="Description" onChange={handleInputChange} value={inputs.Description} maxLength={50} required />
                            </FormGroup>
                        </Col>
                        <Col md={4} sm={12}>
									<FormGroup className="form-dashboard-group-checkbox">
										<div className="checkbox-checkmark">
										<label className="toggleButton" htmlFor='chkOrgullo'>

											<input type="checkbox" id="chkOrgullo" checked={isChecked} onChange={()=>handleCheckboxChange(inputs.IsOnRegister)}/>
											<div>
												<svg viewBox="0 0 44 44">
													<path d="M14,24 L21,31 L39.7428882,11.5937758 C35.2809627,6.53125861 30.0333333,4 24,4 C12.95,4 4,12.95 4,24 C4,35.05 12.95,44 24,44 C35.05,44 44,35.05 44,24 C44,19.3 42.5809627,15.1645919 39.7428882,11.5937758" transform="translate(-2.000000, -2.000000)"></path>
												</svg>
											</div>
											</label>
											<p>Se usa para el registro externo</p>
										</div>
									</FormGroup>
								</Col>
                    </Row>
                    <div className="flex-end">
                        <Button className="btn-dark" type="submit">
                            <p>Guardar</p>
                            <FaSave />
                        </Button>
                    </div>
                </form>
					</>
				: null
			}
			<SearchJobRole onSelectJobRole={handleOnSelected} refresh={refresh} />
		</>
	);
}
