import React, { Fragment, useEffect, useState } from "react";
import {TabContent, TabPane, Nav, NavItem, NavLink, Input, Row, Col, FormGroup, Label} from "reactstrap";
import classnames from "classnames";
import { GeneralUpload } from "../../../components/Helpers/generalUpload";
import useInputForm from "../../../components/CustonHooks/FormHook";
import { useCompanyHoliday } from "../../../services/companyHoliday.service";
import ImgInfog from "../../../assets/imgs/imgInfografia.jpg";
import ReactPlayer from "react-player";
import moment from "moment";
import {Button} from "rsuite";
import {FaChevronLeft, FaSave, FaTimes, FaTrash, FaUpload} from "react-icons/fa";
import Swal from "sweetalert2";

export function EditNotice(props) {
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const [holidayType, setHolidayType] = useState([]);
  const [selectedTypeId, setSelectedTypeId] = useState(1);

  const [CompanyHoliday, setCompanyHoliday] = useState({
    CompanyHolidayId: "",
    Description: "",
    Date: "",
    WorkingDay: false,
    StartDate: "",
    EndDate: "",
    TypeId: 0,
    IsVideo: false,
    Iframe: "",
  });

  const minDate = new Date(
    new Date().setFullYear(new Date().getFullYear() - 1)
  );
  const maxDate = new Date(
    new Date().setFullYear(new Date().getFullYear() + 1)
  );

  const onSave = () => {
    let localfiles = [];
    if (image.file) localfiles.push(image.file);
    if (video) if (video.file) localfiles.push(video.file);
    if (inputs.CompanyHolidayId && inputs.CompanyHolidayId > 0)
      useCompanyHoliday
        .updateCompanyHoliday(
          {
            CompanyHolidayId: parseInt(inputs.CompanyHolidayId),
            Description: inputs.Description,
            Date: inputs.Date,
            WorkingDay: inputs.WorkingDay,
            EndDate: inputs.EndDate,
            StartDate: inputs.StartDate,
            TypeId: selectedTypeId,
            IsVideo: inputs.IsVideo,
            Iframe: [1, 2, 3, 4, 5, 6, 7, 8, 9].includes(selectedTypeId) ? inputs.IframeLink : inputs.Iframe,
          },
          localfiles
        )
        .then((result) => {
          if (props.onSave) props.onSave(result);
        });
    else
      useCompanyHoliday
        .createCompanyHoliday(
          {
            Description: inputs.Description,
            Date: inputs.Date,
            WorkingDay: inputs.WorkingDay,
            EndDate: inputs.EndDate,
            StartDate: inputs.StartDate,
            TypeId: selectedTypeId,
            IsVideo: inputs.IsVideo,
            Iframe: [1, 2, 3, 4, 5, 6, 7, 8, 9].includes(selectedTypeId) ? inputs.IframeLink : inputs.Iframe,
          },
          localfiles
        )
        .then((result) => {
          if (props.onSave) props.onSave(result);
        });
  };
  const handleOnCancel = () => {
    if (props.onCancelEdit) props.onCancelEdit();
  };

  const handleOptionChange = (event, typeId) => {
    event.persist();
    setSelectedTypeId(typeId);
  };

  const { handleSubmit, handleInputChange, inputs, setInputs } = useInputForm(
    onSave,
    CompanyHoliday
  );

  const handleImageUpload = (e) => {

    const targetFile = e.target.files[0];
    const maxSize = (process.env.REACT_APP_MAX_FILESIZE * 1024);

    if (targetFile !== null) {

      if(targetFile === undefined) return

      const sizeCurrentFile = Math.round(targetFile.size / 1024);

      if (sizeCurrentFile > maxSize) {
        Swal.fire({
          icon: 'error',
          title: 'Ups...',
          text: 'Los archivos debe ser menores a ' + process.env.REACT_APP_MAX_FILESIZE + ' MB.',
        })
        e.target.value = null;
        return;
      }

      const currentImg = URL.createObjectURL(targetFile)
      let img: HTMLImageElement = document.createElement("img"), dimensions = {};
      img.src = currentImg;
      img.onload = function () {
        dimensions = {
          width: img.width,
          height: img.height
        };
        if((dimensions.width < 850 && dimensions.width > 850) || (dimensions.height < 375 && dimensions.height > 370)) {
          Swal.fire({
            icon: 'error',
            title: 'Ups...',
            text: 'La imagen excede las dimensiones permitidas',
          })
        } else {
          setImage({ url: URL.createObjectURL(targetFile), file: targetFile })
        }
      };

    } else setImage(null);
  };

  const handleImageUploadRemove = () => setImage(null);

  const handleVideoUpload = (files) => {
    if (files.length > 0)
      setVideo({ url: URL.createObjectURL(files[0]), file: files[0] });
    else setVideo(null);
  };

  useEffect(() => {
    if (props.currentCompanyHoliday) {
      setCompanyHoliday({
        CompanyHolidayId: props.currentCompanyHoliday.companyHolidayId.toString(),
        Description: props.currentCompanyHoliday.description,
        Date: moment(props.currentCompanyHoliday.date).format('yyyy-MM-DD'),
        WorkingDay: props.currentCompanyHoliday.workingDay,
        EndDate: moment(props.currentCompanyHoliday.endDate).format('yyyy-MM-DD'),
        StartDate: moment(props.currentCompanyHoliday.startDate).format('yyyy-MM-DD'),
        TypeId: props.currentCompanyHoliday.typeId,
        IsVideo: props.currentCompanyHoliday.isVideo,
        Iframe: props.currentCompanyHoliday.iframe,
        IframeLink: props.currentCompanyHoliday.iframe,
      });
      setSelectedTypeId(props.currentCompanyHoliday.typeId);
      setImage({ url: props.currentCompanyHoliday.imagePath, file: null });
      setVideo({ url: props.currentCompanyHoliday.videoPath, file: null });
      setShowVideo(props.currentCompanyHoliday.typeId == 2);
      setIsPub([1, 3, 4, 5, 6, 7, 8, 9].includes(props.currentCompanyHoliday.typeId));
      clickVideoType(props.currentCompanyHoliday.iframe ? "1" : "0");
      toggle(props.currentCompanyHoliday.iframe ? "2" : "1");
    } else {
      setCompanyHoliday({
        CompanyHolidayId: "",
        Description: "",
        Date: "",
        WorkingDay: false,
        StartDate: "",
        EndDate: "",
        TypeId: 0,
        IsVideo: false,
        Iframe: "",
        IframeLink: "",
      });
      clickVideoType("0");
      toggle("1");
      setIsPub(false);
    }
  }, [props.currentCompanyHoliday]);


  useEffect(() => {
    switch (parseInt(selectedTypeId)) {
      case 1:
        toggleFalse();
        setIsPub(true);
        break;
      case 2:
        toggleTrue();setIsPub(false);
        break;
      case 3:
        toggleFalse();setIsPub(true);
        break;
      case 4:
        toggleFalse();setIsPub(true);
        break;
      case 5:
        toggleFalse();setIsPub(true);
        break;
      case 6:
        toggleFalse();setIsPub(true);
        break;
      case 7:
        toggleFalse();setIsPub(true);
        break;
      case 8:
        toggleFalse();setIsPub(true);
        break;
      case 9:
        toggleFalse();setIsPub(true);
        break;
      default:
        toggleFalse();setIsPub(false);
        break;
    }
  }, [selectedTypeId]);

  const formatDate = (date) => {
    let datestring =
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2);
    return datestring;
  };

  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [myModal, setMyModal] = useState("");
  const toggleMyModal = () => setMyModal(myModal === "" ? "inShow" : "");

  const [showVideo, setShowVideo] = useState(false);
  const [isPub, setIsPub] = useState(false);
  const toggleTrue = () => setShowVideo(true);
  const toggleFalse = () => setShowVideo(false);

  const [disabled, setDisabled] = useState(true);
  const [disabledIF, setDisabledIF] = useState(true);
  const clickVideoType = (value) => {
    if (value == "0") {
      setInputs({ ...inputs, Iframe: "" });
      setDisabled(false);
      setDisabledIF(true);
    } else {
      setVideo(null);
      setDisabled(true);
      setDisabledIF(false);
    }
  };

  async function getCompanyHolidaysType() {
    useCompanyHoliday.getCompanyHolidayFront(0).then((result) => {
        if (result) {
            var temp = [];
            result.map((ch) => {
                temp.push({
                    key: ch.companyHolidayId.toString(),
                    file: ch.imagePath,
                    description: ch.description,
                    isVideo: ch.isVideo,
                    link: ch.iframe
                });
            });
            setHolidayType(temp)
        }
    });
  }

  const [open, setOpen] = React.useState(false);
  const [backdrop, setBackdrop] = React.useState('static');
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleRemoveFile = () => setImage(null);

  console.log(inputs)

  return (
    <>
      <form onSubmit={handleSubmit} className="form-dashboard">
        <div className="admin-subtitle">
          <h3>{inputs.CompanyHolidayId > 0 ? "Editar Aviso" : "Nuevo"}</h3>
          <Button className="btn-back" type="button" onClick={handleOnCancel}>
            <FaChevronLeft />
            <p>Regresar</p>
          </Button>
        </div>
        <Row>
            <Col md={4}>
              <FormGroup className="form-dashboard-group">
                <Label for="Description">Descripción</Label>
                <Input type="text" name="Description" onChange={handleInputChange} value={inputs.Description} required/>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup className="form-dashboard-group">
                <Label for="StartDate">Fecha de evento</Label>
                <Input
                    type="date"
                    name="Date"
                    onChange={handleInputChange}
                    value={moment(inputs.Date).format('yyyy-MM-DD')}
                    min={moment(minDate).format("yyyy-MM-DD")}
                    max={moment(maxDate).format("yyyy-MM-DD")}
                    required
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup className="form-dashboard-group">
                <Label for="Tipo">Tipo</Label>
                <select name={"TypeId"} onChange={(event) => handleOptionChange(event, event.target.value)}
                  selectvalue={selectedTypeId} value={selectedTypeId} required={props.required} disabled={false} >
                  <option value="">{"Selecciona una opción"}</option>
                  <option key={1} value={1}>Agenda</option>
                  <option key={2} value={2}>Aviso</option>
                  <option key={3} value={3}>Publicidad</option>
                  <option key={4} value={4}>Mis rutinas</option>
                  {/* <option key={5} value={5}>Tienda en línea</option> */}
                  {/* <option key={6} value={6}>Comunidad Solidaria</option> */}
                  {/* <option key={7} value={7}>Bolsa de trabajo</option> */}
                  <option key={8} value={8}>Escuela Romeh</option>
                  {/* <option key={9} value={9}>Tu Opinión</option> */}
                </select>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <FormGroup className="form-dashboard-group">
                <Label for="StartDate">Desde</Label>
                <Input type="date" name="StartDate" onChange={handleInputChange} value={inputs.StartDate}
                  min={moment(minDate).format("YYYY-MM-DD")}
                  max={moment(maxDate).format("YYYY-MM-DD")}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <div className="form-dashboard-group">
                <Label for="EndDate">Hasta</Label>
                <Input type="date" name="EndDate" onChange={handleInputChange} value={inputs.EndDate}
                  min={moment(inputs.StartDate).format("YYYY-MM-DD")}
                  max={moment(maxDate).format("YYYY-MM-DD")}
                  required
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={4} md={6}>
              {image ? (
                  <div className="notice-file-container">
                    <img src={image.url} alt="" className="img-file"  />
                    <Button className="btn-remove-file" onClick={handleImageUploadRemove}>
                      <FaTimes />
                    </Button>
                  </div>
              ) :
                  <div className="form-item-file">
                    {
                      (image) ?
                          <label onClick={handleRemoveFile} className="btn-trash"><FaTrash /></label>
                          :
                          <>
                            <div className="form-item-file-info">
                              <label htmlFor="imageNotice" className="btn-file">Subir imagen <FaUpload /></label>
                              <small>Dimensiones: 850x375px</small>
                            </div>
                          </>
                    }
                    <input
                        id="imageNotice"
                        type="file"
                        size="large"
                        onChange={handleImageUpload}
                        accept="image/*"
                        required={(inputs.CompanyHolidayId === 0)}
                    />
                  </div>
              }
              {
                selectedTypeId === 3 &&
                <span className="text-danger">* La imagen debe ser vertical</span>
              }

              { isPub && 
                <> 
                  <FormGroup className="form-dashboard-group">
                    <Label for="Description">Link</Label>
                    <Input type="text" name="IframeLink" onChange={handleInputChange} value={inputs.IframeLink} required/>
                  </FormGroup>
                </>
              }
            </Col>
          </Row>
          <Row className={`brox_for_video ${showVideo ? "is_show" : ""}`}>
            <Col lg={2} sm={6}>
              <label for="IsVideo">Tipo de aviso</label>
              <div className="">
                <input type="checkbox" id="IsVideo" name="IsVideo" onChange={handleInputChange} checked={inputs.IsVideo}/>
                <Label for="IsVideo">¿Es un video?</Label>
              </div>
            </Col>
            {inputs.IsVideo == true ? (
              <Col sm={12} className="box_tabs_avisos">
                <Nav tabs>
                  <NavItem>
                    <NavLink className={classnames({ active: activeTab === "1" })} onClick={() => {toggle("1");}}>
                      Videos en mi PC
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={classnames({ active: activeTab === "2" })} onClick={() => {toggle("2");}}>
                      Videos externos
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <div className="card_tabs">
                      <Row>
                        <Col sm={12}>
                          <FormGroup className="form-dashboard-group">
                              <input type="radio" name="videoActive" id="videoActive1" onClick={() => clickVideoType("0")} defaultChecked={!inputs.Iframe} />
                              <label className="cursor" for="videoActive1">Activo</label>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <GeneralUpload
                            TitleLabel={"Subir video local"}
                            handleOnChangeFiles={handleVideoUpload}
                            accept="video/mp4,video/x-m4v,video/*"
                            disabled={disabled}
                            required={inputs.CompanyHolidayId === 0}
                          />
                        </Col>
                        <Col md={6}>
                          <ReactPlayer
                            url={video && video.url}
                            width="100%"
                            height="100%"
                            controls={true}
                          />
                        </Col>
                      </Row>
                    </div>
                  </TabPane>
                  <TabPane tabId="2">
                    <div className="card_tabs">
                      <Row>
                        <Col sm={12}>
                          <FormGroup className="form-dashboard-group">
                              <Input type="radio" name="videoActive" id="videoActive2" onClick={() => clickVideoType("1")} defaultChecked={inputs.Iframe}/>{" "}
                              <Label className="cursor" for="videoActive2">Activo</Label>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup className="form-dashboard-group">
                            <Label for="exampleFormControlTextarea1">Iframe para video <i>(Youtube)</i></Label>
                            <textarea id="exampleFormControlTextarea1" rows="3" name="Iframe" onChange={handleInputChange} value={inputs.Iframe} disabled={disabledIF} required></textarea>
                          </FormGroup>
                          <div className="box_btn_modal">
                            <span className="c_primary cursor" onClick={toggleMyModal}>
                              ¿Cómo crear un iframe en youtube?
                            </span>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div dangerouslySetInnerHTML={{ __html: inputs.Iframe }} />
                        </Col>
                      </Row>
                    </div>
                  </TabPane>
                </TabContent>
              </Col>
            ):
              <FormGroup className="form-dashboard-group">
                <Label for="Description">Link</Label>
                <Input type="text" name="IframeLink" onChange={handleInputChange} value={inputs.IframeLink} required/>
              </FormGroup>
            }
          </Row>
          <div className="flex-end">
            <Button className="btn-dark" type="submit">
              <p>Guardar</p>
              <FaSave />
            </Button>
          </div>
      </form>
      <section className={`wrapper__modal_infog ${myModal}`}>
        <span className="btnInCLose" onClick={toggleMyModal}>CERRAR</span>
        <div className="modal_infog">
          <img className="imginmodal" src={ImgInfog} alt="Infografía Kuimby" />
        </div>
      </section>
    </>
  );
}
