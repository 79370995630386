import React, { useState } from "react";
import { useEffect } from "react";
import LogoCompany from "../../assets/imgs/RodrigoRomeh.png";
import "./register.scss";
import { userService } from "../../services/user.service";
import { useSelector } from "react-redux";
import { ImgCache } from "../../components/CustonHooks/imgCache";
import { Legals } from "../../components/legals/legals";
import { BoxSocialNetworks } from "../../components/boxSocialNetworks";
import {Aside} from "./aside";
import {NoticeMessage} from "./noticeMessage";



export function VerifyAccount(props) {
  const companyLogo = useSelector(state => state.userSettings.companyLogo);
  const [verify, setVerify] = useState();
  useEffect(() => {
    userService
      .verifyAccount({
        UserId: props.location.search.split("userId=")[1].split("&")[0],
        Code: props.location.search.split("code=")[1],
      })
      .then((result) => setVerify(result));
  }, [props.location.search]);

  return (
    <section className="register-wrapper">
      <article className="register-container">
        <Aside message="¡Gracias por registrarte!" />
        <div className="register-container-form">
          <div className="register-container-form-header">
          <ImgCache src={companyLogo ? companyLogo : LogoCompany} className="logo" alt="Kuimby Social" />
          </div>
          {
            verify &&
              <NoticeMessage title={`${verify.responseCode === '00' ? '¡Verificación exitosa!' : '¡Error al verificar cuenta!'}`}
                             subtitle={`${verify.responseCode === '00' ? '' : '¡Ups!' }`}
                             message={`${verify.responseCode === '00' ? '' : verify.responseMessage }`}
                             backLink="login"
                             success={`${verify.responseCode === '00' ? 1 : 0}`}
              />
          }
          <div className="register-container-form-social-media">
            <BoxSocialNetworks />
            <Legals />
          </div>
        </div>
      </article>
    </section>
  );
}
