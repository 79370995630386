import React, { useEffect, useRef, useState } from 'react';
import { Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import useInputSingleForm from '../../components/CustonHooks/inputFormHook';
import SearchDeparment from './searchDeparment';
import { deparmentService } from '../../services/deparment.service';
import { useSelector } from 'react-redux';
import { Button, Checkbox } from "rsuite";
import {FaChevronLeft, FaPlus, FaSave, FaTimesCircle} from "react-icons/fa";

export function AdminDeparment() {
	const companyId = useSelector(state => state.userSettings.companyId)

	const gridRef = useRef();
	let deparmentInit = { DepartmentId: 0, CompanyId: 0, DepartmentName: '' }
	const [showEdit, setShowEdit] = useState(false);
	const [isChecked, setIsChecked] = useState(false);
	const handleCheckboxChange = (isOnRegister) => {
		if(isOnRegister){
			setIsChecked(false)
			setInputs({...inputs, IsOnRegister : false})
		}else{
			setIsChecked(true)
			setInputs({...inputs, IsOnRegister : true})
		}

	};
	
	const onSave = () => {
		let model = {
			DepartmentId: parseInt(inputs.DepartmentId), 
			CompanyId: companyId, 
			DepartmentName: inputs.DepartmentName,
			IsOnRegister: isChecked
		}

		if (parseInt(inputs.DepartmentId) === 0) {
			deparmentService.createDeparment(model).then((result) => {
				gridRef.current.refreshDataGrid();

			});
		} else {
			deparmentService.updateDeparment(model).then((result) => {
				gridRef.current.refreshDataGrid();
			});
		}
		setInputs({});
		setShowEdit(false);

	};
	const { inputs, handleInputChange, handleSubmit, setInputs } = useInputSingleForm(onSave, {});

	const handleOnCancel = () => {
		setInputs({});
		setShowEdit(false);
	}

	const handleOnNewDeparment = () => {
		setInputs(deparmentInit);
		setShowEdit(true);
	};

	const handleRowSelect = (gridItem) => {
		setShowEdit(true);
		setInputs({ DepartmentId: gridItem.departmentId, CompanyId: gridItem.companyId, DepartmentName: gridItem.departmentName, IsOnRegister: gridItem.isOnRegister });
	}
	
	useEffect(()=>{ setIsChecked(inputs.IsOnRegister)}, [inputs]) 
	return (
		<>
			<div className='admin-heading'>
				<div></div>
				{
					(showEdit) ?
						<Button className="btn-back" type="button" onClick={handleOnCancel}>
							<FaTimesCircle />
							<p>Cancelar</p>
						</Button>
						:
						<Button type="button" className="btn-dark" onClick={handleOnNewDeparment}>
							<p>Agregar grupo</p>
							<FaPlus />
						</Button>
				}
			</div>
			<Row>

				<Col sm={12}>
					{showEdit ?
						<Form onSubmit={handleSubmit}>
							<h3>{inputs.DepartmentId == 0 ? "Nuevo grupo" : "Editar grupo"}</h3>
							<Row>
								<Col md={4} sm={12}>
									<FormGroup className="form-dashboard-group">
										<Label for="DepartmentName">Grupo</Label>
										<Input name="DepartmentName" onChange={handleInputChange} value={inputs.DepartmentName} required></Input>
									</FormGroup>
								</Col>
								<Col md={4} sm={12}>
									<FormGroup className="form-dashboard-group-checkbox">
										<div className="checkbox-checkmark">
										<label className="toggleButton" htmlFor='chkOrgullo'>
											{/* <Label for="DepartmentName">Es principal en registro</Label> */}
											<input type="checkbox" id="chkOrgullo" onChange={()=>handleCheckboxChange(inputs.IsOnRegister)} checked={isChecked}/>
											
											<div>
												<svg viewBox="0 0 44 44">
													<path d="M14,24 L21,31 L39.7428882,11.5937758 C35.2809627,6.53125861 30.0333333,4 24,4 C12.95,4 4,12.95 4,24 C4,35.05 12.95,44 24,44 C35.05,44 44,35.05 44,24 C44,19.3 42.5809627,15.1645919 39.7428882,11.5937758" transform="translate(-2.000000, -2.000000)"></path>
												</svg>
											</div>
											</label>
											<p>Se usa para el registro externo</p>
										</div>
									</FormGroup>
								</Col>
							</Row>
							<div className="flex-end">
								<Button className="btn-dark" type="submit">
									<p>Guardar</p>
									<FaSave />
								</Button>
							</div>
						</Form> : null}
					<SearchDeparment handleRowSelect={handleRowSelect} ref={gridRef} />
				</Col>
			</Row>

		</>
	);
}
