import React, { useEffect, useState } from 'react';
import useInputForm from '../../components/CustonHooks/FormHook'
import { companyService } from '../../services/control/company.service';
import { useDispatch } from 'react-redux';

import { Link } from 'react-router-dom';
import {Col, FormGroup, Label, Row} from "reactstrap";
import {FaSave, FaTimesCircle} from "react-icons/fa";

export function EditCompany(props) {
    const onSave = () => {
        if (inputs.companyId && inputs.companyId > 0)
            companyService.edit(inputs);
        else
        {
            inputs.companyId = parseInt(companyRVOL.CompanyId);
            companyService.create(inputs);
            setInputs({ ...inputs, CompanyId: 0, CompanyName:"" });
            setIsRvol(false)
        }
    };

    
    const [isRvol, setIsRvol] = useState(false);
    const [companyRVOL, setCompanyRVOL] = useState({
        CompanyId : 0,
        CompanyName : ""
    });

    const [company, setCompany] = useState({
        companyId: 0,
        companyName: "",
        businessName: "",
        primeDomain: "",
        telephone1: "",
        telephone2: "",
        username: "",
        password: "",
        active: true
    });

    useEffect(() => {
        if (props.companyId !== "0") {

            companyService.getById(props.companyId).then((result) => {
                setCompany({
                    companyId: result.companyId,
                    companyName: result.companyName,
                    businessName: result.businessName,
                    primeDomain: result.primeDomain,
                    telephone1: result.telephone1,
                    telephone2: result.telephone2,
                    username: result.userName,
                    password: result.password,
                    active: result.active
                });
            });
        }
            else {
                companyService.getRVOL()
                    .then((result) => {
                        setCatalogs({ rvolCompany: result });
                    });
            }
    }, [props.companyId]);

    const handleCheckChange = (event) =>
    {
        setIsRvol(event.target.checked);

    }

    const handleSelectChange = (event) => {
        setCompanyRVOL({ CompanyId: event.target.value });
        var index = event.nativeEvent.target.selectedIndex;        
        setInputs({ ...inputs, companyName: event.nativeEvent.target[index].text });
    }

    const { handleSubmit, handleInputChange, handleInputFileChange, inputs, setInputs } = useInputForm(onSave, company);

    const [catalogs, setCatalogs] = useState({ rvolCompany: [] });

    return (
        <div>
            <form onSubmit={handleSubmit} className="form-dashboard">
                <h3 className="">{props.companyId !== 0 ? "Editar " : "Nueva "}empresa</h3>
                { inputs.companyId === 0 &&
                <Row>
                    <Col lg={4} md={6} sm={12}>
                        <FormGroup className="d-flex-start">
                            <label className="toggler-wrapper dark" htmlFor="isRVOL">
                                <input type="checkbox" name="isRVOL" id="isRVOL" onChange={handleCheckChange} checked={isRvol}/>
                                <div className="toggler-slider">
                                    <div className="toggler-knob"></div>
                                </div>
                            </label>
                            <p style={{marginLeft: '.5rem'}}>¿Existe en Red Voucher Online?</p>
                        </FormGroup>
                    </Col>
                    {isRvol &&
                        <Col lg={4} md={6} sm={12}>
                            <FormGroup className="form-dashboard-group">
                                <Label htmlFor="rvolCompany">Empresas Red Voucher Online</Label>
                                <select className="form-control" id="rvolCompany" name="rvolCompany" onChange={handleSelectChange} value={companyRVOL.CompanyId}>
                                    <option key="0" value="0" >Selecciona una opción</option>
                                    {
                                        catalogs.rvolCompany.map(item => <option key={item.companyId} value={item.companyId}>{item.companyName}</option>)
                                    }
                                </select>
                            </FormGroup>
                        </Col>}
                </Row>
                }
                <Row>
                    <Col lg={4} md={6} sm={12}>
                        <input type="hidden" name="companyId" onChange={handleInputChange} value={inputs.companyId} />
                        <FormGroup className="form-dashboard-group">
                            <label htmlFor="companyName">Nombre</label>
                            <input type="text" name="companyName" className="form-control" onChange={handleInputChange} value={inputs.companyName} placeholder="Identificador de la empresa" required />
                        </FormGroup>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                        <FormGroup className="form-dashboard-group">
                            <Label htmlFor="businessName">Nombre de la Empresa</Label>
                            <input type="text" name="businessName" className="form-control" onChange={handleInputChange} value={inputs.businessName} placeholder="Nombre o razón social de la empresa" required />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} md={6} sm={12}>
                        <FormGroup className="form-dashboard-group">
                            <Label htmlFor="primeDomain">Dominio</Label>
                            <input type="text" name="primeDomain" className="form-control" onChange={handleInputChange} value={inputs.primeDomain} placeholder="Dominio corporativo" required />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} md={6} sm={12}>
                        <FormGroup className="form-group">
                            <Label htmlFor="telephone1">Teléfono oficina</Label>
                            <input type="text" name="telephone1" className="form-control" onChange={handleInputChange} value={inputs.telephone1} placeholder="Teléfono principal" required />
                        </FormGroup>
                    </Col>
                    <Col className="col-md-6 col-lg-4">
                        <FormGroup className="form-dashboard-group">
                            <Label htmlFor="telephone2">Teléfono alterno</Label>
                            <input type="text" name="telephone2" className="form-control" onChange={handleInputChange} value={inputs.telephone2} placeholder="Teléfono alterno" required />
                        </FormGroup>
                    </Col>
                </Row>
                {props.companyId === "0" ?
                    <Row>
                        <Col lg={4} md={6} sm={12}>
                            <FormGroup className="form-dashboard-group">
                                <Label htmlFor="username">Usuario de R.H inicial</Label>
                                <input type="email" name="username" className="form-control" onChange={handleInputChange} value={inputs.username} placeholder="Correo corporativo" required />
                            </FormGroup>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                            <FormGroup className="form-dashboard-group">
                                <Label htmlFor="password">Contraseña</Label>
                                <input type="password" name="password" className="form-control" onChange={handleInputChange} value={inputs.password} placeholder="Contraseña" required />
                            </FormGroup>
                        </Col>
                    </Row>
                    : null}
                <hr />
                <div className="d-flex-between">
                    <Link className="btn-back" to="/operator/AdminCompany" >
                        <FaTimesCircle />
                        <p>Cancelar</p>
                    </Link>
                    <button className="btn-dark" type="submit">
                        <p>Guardar</p>
                        <FaSave />
                    </button>
                </div>
            </form>
        </div>
    );
}   
