import React, { useEffect, useState } from 'react'
import { messageService } from '../../services/message.service'
import { backendService } from '../../services/backend.service'
import Moment from 'react-moment'
import AnswerMessage from './answerMessage'
import { MessageAttachment } from './messageAttachment'
import {Button, ButtonToolbar, Dropdown} from "rsuite";

export function ReadMessage({messageId, archived, mobileMsg, changeStatusMobile}) {
    const [message, setMessage] = useState()
    const [files, setFiles] = useState()
    const [isAnswer, setIsAnswer] = useState(false)
    const [profile, setProfileInfo] = useState()
    const [userMessages, setUserMessages] = useState([])
    const [dateFilter, setDateFilter] = useState({ title: 'Descendente', value: 'desc' })
    const [statusFilter, setStatusFilter] = useState({ title: 'Todos', value: '' })

    useEffect(() => {
        if(messageId) {
            messageService.getMessageAndUrlFilesById(messageId).then((data) => {
                setMessage(data.data);
                setFiles(data.files);
            })
        }
    }, [messageId]);

    useEffect(() => {
        backendService.userProfile()
            .then((data) => setProfileInfo(data))
    }, [])

    const isSending = () => {
        let myName = '', from = '';
        if (profile && message) {
            myName = profile.firstName + profile.middleName + profile.lastName
            from = message.messageUserFrom.middleName + message.messageUserFrom.lastName + message.messageUserFrom.firstName
        }
        return profile && message && myName === from
    }

    const archiveMessage = (messageDto) => {
        messageDto.message.isReceiverArchived = true

        const getMessages = () => {
            messageService.getMessage(0, dateFilter.value, statusFilter.value)
                .then((data) => setUserMessages(data))
        }

        messageService.updateMessage(messageDto.message)
            .then(() => getMessages())
        window.location.reload();
    }

    //const deleteMessage = (messageId) => messageService.deleteMessage(messageId).then(getMessages)

    return (
        <>
            <div className="messages-readed-header">
                <h2>Mensaje</h2>
                {
                    mobileMsg ?
                        <Button className="btn-circle-message" onClick={changeStatusMobile}>
                            <i className="icon-chevron-left"></i>
                        </Button> : ''
                }
            </div>
            {
                (!message) ?
                    <div className="messages-readed-content">
                        <div className="empty">
                            <i className="icon-empty"></i>
                            <p><i className="icon-chevron-left"></i>Selecciona un mensaje</p>
                        </div>
                    </div>
                    : ''
            }
            {message &&
                <div className="messages-readed-content">
                    <div className="messages-info">
                        <div className="messages-info-header">
                            <div className="profile">
                                <h4><i className="icon-envelope-open"></i> {message.message.subject}</h4>
                                <div className="user">
                                    <div className="info">
                                        <div>
                                            <div className="avatar-container">
                                                <img alt="Foto de perfil" src={message.messageUserFrom.urlProfileImage} className="contactphoto" />
                                                <div className="name">
                                                    <label>{message.messageUserFrom.name}</label>
                                                    <p><i className="icon-clock"></i><Moment locale="es" format="DD/MM/YYYY h:mm:ss a">{message.message.createDate}</Moment></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ButtonToolbar className="btn-circle-message dots">
                                        <Dropdown trigger={['click']} title={<i className='icon-dots-v'></i>} placement="leftStart">
                                            <Dropdown.Item onClick={() => setIsAnswer(!isAnswer)}>
                                                <i className="icon-answer-msg"></i>
                                                <p>Responder</p>
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={() => archiveMessage(message)} style={{marginTop: '.35rem'}}>
                                                <i className="icon-archive"></i>
                                                <p>Archivar</p>
                                            </Dropdown.Item>
                                        </Dropdown>
                                    </ButtonToolbar>
                                    {!isSending() &&
                                        <Button className="btn-circle-message" onClick={() => setIsAnswer(!isAnswer)}>
                                            <i className="icon-answer-msg"></i>
                                        </Button>
                                    }
                                    {
                                        (archived) &&
                                            <Button className="btn-circle-message" style={{marginLeft: '.5rem'}} onClick={() => archiveMessage(message)}>
                                                <i className="icon-archive"></i>
                                            </Button>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="messages-info-attachment">
                            {files &&
                                <>
                                    Archivos:
                                    <MessageAttachment files={files} isDeleted={false} />
                                </>
                            }
                        </div>
                        <div className="messages-info-body">
                            <div dangerouslySetInnerHTML={{ __html: message.message.messageText }} />
                        </div>
                    </div>
                    {isAnswer &&
                        <>
                            <div className="messages-answer">
                                <AnswerMessage
                                        originMessageId={message.message.messageId}
                                        messageUserFrom={message.message.userProfileId}
                                        messageUserTo={message.message.userProfileFrom}
                                        subject={message.message.subject}
                                        setShow={setIsAnswer}
                                    />
                            </div>
                        </>

                    }
                    {message.messagesList &&
                        <>
                            {message.messagesList.map(answerd =>
                                <ParentMessage key={answerd.messageId} messageId={answerd.messageId} />
                            )}
                        </>
                    }
                </div>
            }
        </>
    )
}

export default function ParentMessage({messageId}) {
    const [message, setMessage] = useState();
    const [files, setFiles] = useState();

    useEffect(() => {
        messageService.getMessageById(messageId).then((data) => {
            setMessage(data.data);
            setFiles(data.files);
        })
    }, []);

    return (
        <>
            {message &&
                <div className="messages-parent">
                    <div className="messages-parent-content">
                        {!message.message.originMessageId &&
                            <h4><i className="icon-envelope-open"></i>{message.message.subject}</h4>
                        }
                        <div className="user">
                            <div className="info">
                                <div className="avatar-container">
                                    <img src={message.messageUserFrom.urlProfileImage} className="contactphoto" alt="" onError="this.src = 'skins/elastic/images/contactpic.svg'; this.onerror = null" />
                                    <div className="name">
                                        <label>{message.messageUserFrom.name}</label>
                                        <p><i className="fas fa-clock"></i><Moment locale="es" format="DD/MM/YYYY h:mm:ss a">{message.message.createDate}</Moment></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="messages-parent-adjunts">
                        {files &&
                            <>
                                <MessageAttachment files={files} isDeleted={false}></MessageAttachment>
                            </>
                        }
                    </div>
                    <div className="messages-parent-body">
                        <div dangerouslySetInnerHTML={{ __html: message.message.messageText }} />
                    </div>
                </div>
            }
        </>
    )
}
