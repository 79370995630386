import React, { useState } from 'react';
import { EditSurveyScale } from './editSurveyScale';

export function DetailsSurveyScale(props) {
    return (
        <div className="dashboard-container-page">
            <div className="admin-title">
                <h2>Escalas de valoración</h2>
                <h6>{props.match.params.id !== "0" ? "Editar escala" : "Nueva escala"}</h6>
            </div>
            <EditSurveyScale surveyScaleId={props.match.params.id} />
        </div>
    );
}   
