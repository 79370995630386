import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { companyPostService } from '../../services/companyPost.service';
import { backendService } from "../../services/backend.service";
import Moment from 'react-moment'
import {Table} from "reactstrap";
import {Button} from "rsuite";
import { FaEdit, FaTrash } from 'react-icons/fa';
import { Space } from 'antd';

const SearchCompanyPost = forwardRef((props, ref) => {

    const [dataGrid, setDataGrid] = useState([]);
    const [originValues, setOriginValues] = useState({
        currentWriter: ""
    });
    const trigger = undefined;
    const handleDelete = (event, data) => {
        companyPostService.deletet(data).then((result) => { getAll(); });
        event.stopPropagation();
    };

    async function getAll() {
        companyPostService.getAll(0)
            .then((result) => {
                if (result) {
                    setDataGrid(result);
                }
            });
    }

    useEffect(() => {
        getAll();
    }, []);

    const refreshDataGrid = () => {
        getAll();
    }

    useImperativeHandle(ref, () => ({
        refreshDataGrid: refreshDataGrid
    }));

    useEffect(() => {
        getProfileInfo();
    }, [trigger]);

    const getProfileInfo = () => {
    backendService.userProfile().then((data) => {
        setOriginValues({
            currentWriter: data.firstName+' '+data.middleName+' '+data.lastName ?? ""
        });
    });
    };

    return (
        <div className="table-responsive">
            <Table className="admin-table" hover>
                <thead>
                    <tr>
                        <th>Título del artículo</th>
                        <th>Fecha de publicación</th>
                        <th>Vistas</th>
                        <th>Autor</th>
                        <th>Estatus</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {dataGrid.map(item => (
                        <tr key={item.companyPostId} >
                            <td>
                                {
                                    originValues.currentWriter == item.writter ? item.title : item.title
                                }
                            </td>
                            <td><Moment format="DD/MM/YYYY">{item.publishDate}</Moment></td>
                            <td>{item.totalViews}</td>
                            <td>{item.writter}</td>
                            <td>{item.active ? 'Guardada' : 'Inactivo'}</td>
                            <td>
                                <Space>
                                    <Button className="btn-icon-table edit" onClick={() => props.handleRowSelect(item)}>
                                        <FaEdit />
                                    </Button>
                                    {item.active ?
                                        <Button className="btn-icon-table delete" onClick={(event) => handleDelete(event, item)}>
                                            <FaTrash />
                                        </Button> : null
                                    }
                                </Space>
                            </td>
                        </tr>
                    ))}

                </tbody>
            </Table>
        </div>
    );

});

export default SearchCompanyPost;
