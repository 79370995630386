import React  from "react";
import { DiaryCalendar } from "./diaryCalendar";
import { DiarySlider } from "./diarySlider";
import Moment from "react-moment";
import "moment/locale/es";
import "./diary.scss";
import {Col, Row} from "reactstrap";
import {FooterHome} from "../../components/footer/footerHome";

export function Diary() {
  return (
    <article className="dashboard-container-page">
      <Row>
        <Col sm={12}>
          <h2>Calendario</h2>
        </Col>
        <Col md={7} sm={7} xs={12}>
          <div className="diary-title">
            <div className="border-pointed">
              <p><Moment locale="es" format="DD [de] MMMM  YYYY">{Date.now()}</Moment></p>
            </div>
          </div>
          <div className="diary-data">
            <div className="diary-data-item">
              <div className="circle event"><i className="icon-star"></i></div>
              <h6>Evento general</h6>
            </div>
            <div className="diary-data-calendar">
              <div className="circle birthday"><i className="icon-birthday"></i></div>
              <h6>Cumpleaños</h6>
            </div>
          </div>
        </Col>
        <Col md={5} sm={5} xs={12}><DiarySlider /></Col>
      </Row>
      <Row>
        <Col sm={12} style={{overflow: 'auto'}}><DiaryCalendar /></Col>
        <Col sm={12}>
          <FooterHome />
        </Col>
      </Row>
    </article>
  );
}
