import { callApi } from "./service";

export const useDiary = {
  getCompanyDiary,
  getCompanyDiaryById,
  getCompanyDiaryFiltered,
  getEventsDates,
  createCompanyDiary,
  updateCompanyDiary,
  deleteCompanyDiary,
  obtainCompanyDiaryReward
};
export const useFrontDiary = {
  getFrontCompanyDiary,
  getFrontCompanyDiaryFiltered,
  getFrontEventsDates,
  getCompanyHolidayFront,
};
async function getCompanyDiary() {
  return callApi("/backoffice/CompanyDiary", "GET").then((result) => {
    return handleResponse(result);
  });
}
async function getCompanyDiaryById(idCompanyDiary) {
  return callApi("/backoffice/CompanyDiary/" + idCompanyDiary, "GET").then(
    (result) => {
      return handleResponse(result);
    }
  );
}
async function getCompanyDiaryFiltered(desde, hasta, diaryTypeId) {
  var url = "/backoffice/CompanyDiary/Filtered?id=0";
  if (desde && hasta) {
    if (desde) url = url + "&Desde=" + encodeURIComponent(desde);
    if (hasta) url = url + "&Hasta=" + encodeURIComponent(hasta);
    if (diaryTypeId) url = url + "&DiaryTypeId=" + diaryTypeId;
    return callApi(url, "GET").then((result) => {
      return handleResponse(result);
    });
  }
}
async function getEventsDates(desde, hasta, diaryTypeId) {
  return callApi(
    "/backoffice/CompanyDiary/GetEventsDates?Desde=" +
    encodeURIComponent(desde) +
    "&Hasta=" +
    encodeURIComponent(hasta) +
    "&DiaryTypeId=" +
    diaryTypeId,
    "GET"
  ).then((result) => {
    return handleResponse(result);
  });
}

async function getFrontCompanyDiary() {
  return callApi("/backend/CompanyDiary", "GET").then((result) => {
    return handleResponse(result);
  });
}
async function getFrontCompanyDiaryFiltered(
  desde,
  hasta,
  diaryTypeId,
  updateNotification
) {
  var url = "/backend/CompanyDiary/Filtered?id=0";
  if (desde && hasta) {
    if (desde) url = url + "&Desde=" + encodeURIComponent(desde);
    if (hasta) url = url + "&Hasta=" + encodeURIComponent(hasta);
    if (diaryTypeId) url = url + "&DiaryTypeId=" + diaryTypeId;
    if (updateNotification) url = url + "&UpdNotif=" + updateNotification;
    return callApi(url, "GET").then((result) => {
      return handleResponse(result);
    });
  }
}
async function getFrontEventsDates(desde, hasta, diaryTypeId) {
  return callApi(
    "/backend/CompanyDiary/GetEventsDates?Desde=" +
    encodeURIComponent(desde) +
    "&Hasta=" +
    encodeURIComponent(hasta) +
    "&DiaryTypeId=" +
    diaryTypeId,
    "GET"
  ).then((result) => {
    return handleResponse(result);
  });
}
async function getCompanyHolidayFront(typeId) {
  return callApi("/backend/CompanyHoliday/filtered/" + typeId, "GET").then(
    (result) => {
      return handleResponse(result);
    }
  );
}

async function createCompanyDiary(params) {
  return callApi("/backoffice/CompanyDiary", "POST", params).then((result) => {
    return handleResponse(result);
  });
}
async function updateCompanyDiary(params) {
  return callApi("/backoffice/CompanyDiary", "PUT", params).then((result) => {
    return handleResponse(result);
  });
}
async function deleteCompanyDiary(params) {
  return callApi("/backoffice/CompanyDiary", "DELETE", params).then(
    (result) => {
      return handleResponse(result);
    }
  );
}
function handleResponse(result) {
  if (result.responseCode === "00") return result.data;
  else {
    alert(result.responseMessage);
    return null;
  }
}

async function obtainCompanyDiaryReward(eventTargetId) {
  return callApi(`/backoffice/CompanyDiary/Reward?eventTargetId=${eventTargetId}`, "POST").then((result) => {
    return result;
  });
}
