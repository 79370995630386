import React from "react";
import { Legals } from "../../components/legals/legals";
import {Link} from "react-router-dom";
import {Col, Row} from "reactstrap";
import {FaHome} from "react-icons/fa";

export function NoticeMessage ({title, subtitle,message,backLink, success}) {
    console.log(success)
    return (
        <div className="register-container-form-notice">
            <div className="content-notice">
                <div className="content-notice-header">
                    {success == 1 ?
                        <svg className="checkmark" viewBox="0 0 52 52">
                            <circle className="checkmark-circle" cx="26" cy="26" r="25" fill="none"/>
                            <path className="checkmark-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                        </svg>
                        :
                        <svg className="cross" viewBox="0 0 52 52">
                            <circle className="cross-circle" cx="26" cy="26" r="25" fill="none"/>
                            <path className="cross-path cross-path-right" fill="none" d="M16,16 l20,20"/>
                            <path className="cross-path cross-path-right" fill="none" d="M16,36 l20,-20"/>
                        </svg>
                    }
                    <h5 className={`${(success == 1) ? '' : 'error'}`}>{title}</h5>
                </div>
                <div className="content-notice-info">
                    <h3>{subtitle}</h3>
                    <p>{message}</p>
                    <div className="form-register-submit">
                        <Link className="btn-dark" to="/login">
                            <p>Iniciar sesión</p>
                            <FaHome />
                        </Link>
                        <Link to={`/${backLink}`} className="btn-outline-dark-login">Regresar</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
