import React, { Fragment, useState, useEffect } from 'react';
import {TabContent, TabPane, Nav, NavItem, NavLink, Input, Row, Col, FormGroup, Label} from "reactstrap";
import useInputForm from "../../components/CustonHooks/FormHook";
import { GeneralUpload } from "../../components/Helpers/generalUpload";
import { useCaringCommunity } from '../../services/caringCommunity.service';
import {Button} from "rsuite";
import {FaChevronLeft, FaSave} from "react-icons/fa";

export function EditCommunity(props) {
    const [image, setImage] = useState(null);
    const [Community, setCommunity] = useState({
        CaringCommunityId: 0,
        Name: "",
        Description: "",
        CompanyId: process.env.REACT_APP_COMPANY,
        Ligue1: "",
        Ligue2: "",
        Image: null,
        Active: false,
    });

    const onSave = () => {
        let formData = new FormData();
        formData.append("CaringCommunityId", inputs.CaringCommunityId);
        formData.append("Name", inputs.Name);
        formData.append("Description", inputs.Description);
        formData.append("CompanyId", inputs.CompanyId);
        formData.append("Ligue1", inputs.Ligue1);
        formData.append("Ligue2", inputs.Ligue2);
        formData.append("Image", inputs.Image.file);
        formData.append("Active", inputs.Active);
        if (inputs.CaringCommunityId && inputs.CaringCommunityId > 0){
            useCaringCommunity
                .updateCommunity(formData)
                .then((result) => {
                    if (props.onSave) props.onSave(result);
                });
        }
        else
        {
            useCaringCommunity
                .createCommunity(formData)
                .then((result) => {
                    if (props.onSave) props.onSave(result);
                });
        }
        
    }

    const { handleSubmit, handleInputChange, inputs, setInputs } = useInputForm(
        onSave,
        Community
      );
    
    const handleOnCancel = () => {
        if (props.onCancelEdit) props.onCancelEdit();
      };

      
    useEffect(() => {
        setDataFromCurrentCommunity(props.currentCommunity);
    }, [props.currentCommunity])

    const setDataFromCurrentCommunity = async (currentCommunity) => {
        let newC = {};
        if (currentCommunity) {
            let file = await getFileImage(currentCommunity.image);
            newC = {
                CaringCommunityId: currentCommunity.caringCommunityId,
                Name: currentCommunity.name,
                Description: currentCommunity.description,
                CompanyId: currentCommunity.companyId,
                Ligue1: currentCommunity.ligue1,
                Ligue2: currentCommunity.ligue2,
                Image: { url: currentCommunity.image, file: file },
                Active: currentCommunity.active,
            };
            setCommunity(newC);
            setImage({ url: currentCommunity.image, file: file });
        }
        else{
            setCommunity({
                CaringCommunityId: 0,
                Name: "",
                Description: "",
                CompanyId: process.env.REACT_APP_COMPANY,
                Ligue1: "",
                Ligue2: "",
                Image: null,
                Active: false,
            });
        }
    }

    const handleImageUpload = (files) => {
        if (files.length > 0){
            setInputs({...inputs, Image: { url: URL.createObjectURL(files[0]), file: files[0] }});
            setImage({ url: URL.createObjectURL(files[0]), file: files[0] });
        }
        else {
            setImage(null);
            setInputs({...inputs, Image: null});
        }
    };

    const getFileImage = async (url) => {

        let name = url.substring(url.lastIndexOf('/') + 1).replace("%", " ");
        let file = await fetch(url)
            .then(res => res.blob())
            .then(blob => {
            const file = new File([blob], name, blob)
             return file;
            })
        return file;
    }

    const handleDeleteImage = () => {
        setImage(null);
    }

    return (
        <>
            <form onSubmit={handleSubmit} className="form-dashboard">
                <div className="d-flex-between">
                    <h3>{inputs.CaringCommunityId > 0 ? "Editar comunidad" : "Nuevo"}</h3>
                    <button className="btn-back" type="button" onClick={handleOnCancel}>
                        <FaChevronLeft />
                        <p>Regresar</p>
                    </button>
                </div>
                <Row>
                    <Col lg={4} md={6} sm={12}>
                        <FormGroup className="form-dashboard-group">
                            <label for="Name">Título</label>
                            <Input
                            type="text"
                            name="Name"
                            onChange={handleInputChange}
                            value={inputs.Name}
                            required
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                        <FormGroup className="form-dashboard-group">
                            <label for="Ligue1">Link</label>
                            <Input type="text" name="Ligue1" onChange={handleInputChange} value={inputs.Ligue1} required/>
                        </FormGroup>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                        <FormGroup className="form-dashboard-group">
                            <label for="Ligue2">Link del marketplace</label>
                            <Input
                            type="text"
                            name="Ligue2"
                            onChange={handleInputChange}
                            value={inputs.Ligue2}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} md={6} sm={12}>
                        <FormGroup className="form-dashboard-group">
                            <Label style={{top: '-1.6rem'}} for="Description">Descripción</Label>
                            <textarea rows={5} name="Description" onChange={handleInputChange} value={inputs.Description} required/>
                        </FormGroup>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                        {
                            image ?
                                <FormGroup className="form-dashboard-group-upload">
                                    <div className="file">
                                        <div className="file-container">
                                            <img src={image.url} alt="" className="img-file" />
                                            <Button className="btn-remove-file" onClick={handleDeleteImage}>
                                                <i className="icon-trash"></i>
                                            </Button>
                                        </div>
                                    </div>
                                </FormGroup>
                            :
                                <GeneralUpload
                                    TitleLabel={"Subir imagen"}
                                    handleOnChangeFiles={handleImageUpload}
                                    accept="image/*"
                                    required={inputs.CaringCommunityId === 0}
                                />
                        }
                    </Col>
                </Row>
                <div className="flex-end">
                    <Button className="btn-dark" type="submit">
                        <p>Guardar</p>
                        <FaSave />
                    </Button>
                </div>
            </form>
        </>
    )
}
