import React, {useEffect, useState} from 'react';
import {TabContent, TabPane, Nav, NavItem, NavLink, Col, Row} from 'reactstrap';
import classnames from 'classnames';
import { AdminDeparment } from './adminDeparment'
import { AdminCompanyEventType } from './adminCompanyEventType'
import { BranchOffice } from './branchOffice/branchOffice'
import { AdminJobRoles } from './jobRoles/adminJobRole'
import { Notice } from './notice/notice'
import { CompanyCustomize } from './companyCustomize/companyCustomize'
import { useSelector } from 'react-redux';
import './control.scss'
import '../admin/admin.scss';
import {FooterHome} from "../../components/footer/footerHome";
import { Link } from "react-router-dom";
import {FaChevronLeft} from "react-icons/fa";

export function Control() {
	const [activeTab, setActiveTab] = useState(0);
	const companyId = useSelector(state => state.userSettings.companyId)
	const toggle = (tab) => setActiveTab(tab);

	useEffect(() => {
		toggle(1)
	}, [])

	return (
		<section className="dashboard-container-page">
			<div className="admin-subtitle">
				<div>
					<h3>Control</h3>
					<h6>{(activeTab === 1) ? 'Lista de Grupos' : (activeTab === 2) ? 'Tipos de eventos' : (activeTab === 3) ? 'Sucursales' : (activeTab === 4) ? 'Categorías' : (activeTab === 5) ? 'Avisos' : 'Personalización'}</h6>
				</div>
				<Link to="/admin" className="btn-back">
					<FaChevronLeft />
					<p>Regresar</p>
				</Link>
			</div>
			<Nav className="admin-tabs" tabs>
				<NavItem>
					<NavLink className={classnames({ active: activeTab === 1 })} onClick={() => { toggle(1); }}>
						Grupos
          			</NavLink>
				</NavItem>
				<NavItem>
					<NavLink className={classnames({ active: activeTab === 2 })} onClick={() => { toggle(2); }}>
						Tipos de evento
				 	</NavLink>
				</NavItem>
				<NavItem>
					<NavLink className={classnames({ active: activeTab === 3 })} onClick={() => { toggle(3); }}>
						Sucursales
          			</NavLink>
				</NavItem>
				<NavItem>
					<NavLink className={classnames({ active: activeTab === 4 })} onClick={() => { toggle(4); }}>
						Categorías
          			</NavLink>
				</NavItem>
				<NavItem>
					<NavLink className={classnames({ active: activeTab === 5 })} onClick={() => { toggle(5); }}>
						Avisos
          			</NavLink>
				</NavItem>
				<NavItem>
					<NavLink className={classnames({ active: activeTab === 6 })} onClick={() => { toggle(6) }}>
						Personalización
          			</NavLink>
				</NavItem>
			</Nav>
			<TabContent className="admin-tabs-content" activeTab={activeTab}>
				{{
					1: <TabPane tabId={1}>
						<div className="card-tabs">
							<AdminDeparment />
						</div>
						</TabPane>,
					2:
						<TabPane tabId={2}>
							<div className="card-tabs">
								<AdminCompanyEventType />
							</div>
						</TabPane>,
					3:
						<TabPane tabId={3}>
							<div className="card-tabs">
								<BranchOffice />
							</div>
						</TabPane>,
					4:
						<TabPane tabId={4}>
							<div className="card-tabs">
								<AdminJobRoles />
							</div>
						</TabPane>,
					5:
						<TabPane tabId={5}>
							<div className="card-tabs">
								<Notice />
							</div>
						</TabPane>,
					6:
						<TabPane tabId={6}>
							<div className="card-tabs">
								<CompanyCustomize companyId={companyId}  />
							</div>
						</TabPane>,

				}[activeTab]}
			</TabContent>
			<Row>
				<Col sm={12}>
					<FooterHome />
				</Col>
			</Row>
		</section>
	)
}
