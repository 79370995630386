import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { ItemSocial } from "./itemSocial";
import { directoryInfoService } from "../../services/directoryService.service";
import { Rating } from "./rating";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import ImgRecompensas from "../../assets/imgs/iconos/ico_recompensas.png";
import notImage from "../../assets/imgs/no-image.jpeg";

import {
  FaSave,
  FaMapMarkerAlt,
  FaPhone,
} from "react-icons/fa";
import {
  BsChevronLeft,
  BsChevronRight,
} from "react-icons/bs";
import {
  IoMail,
} from "react-icons/io5";
import {Col, FormGroup, Progress, Row} from "reactstrap";
import {FooterHome} from "../../components/footer/footerHome";

export function DetailsDirectory() {
  const location = useLocation();
  const data = location.state;
  const [directoryItem, setDirectoryItem] = useState([]);
  const [profileId, setProfileId] = useState(0);
  const roleName = useSelector((state) => state.userSettings.roleName);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [email, setEmail] = useState("");

  const { DirectoryId } = useParams();

  useEffect(() => {
    if (data?.Item) {
      var phonesArrays = data.Item.directoryContacts
        ?.filter(
          (f) =>
            f.contactTypeId == 1 &&
            f.contactValue != null &&
            f.contactValue !== "null"
        )
        .map((item) => item.contactValue);
      var emailvalue = data.Item.directoryContacts
        ?.filter(
          (f) =>
            f.contactTypeId == 2 &&
            f.contactValue != null &&
            f.contactValue !== "null"
        )
        ?.map((item) => item.contactValue);
      setPhoneNumbers(phonesArrays.join(","));
      setEmail(emailvalue[0]);
      isActiveDirectory(data?.Item.directoryInfoStatusId);
      setDirectoryItem(data?.Item);
    } else {
      directoryInfoService.getById(DirectoryId).then((response) => {
        var phonesArrays = response.directoryContacts
          ?.filter(
            (f) =>
              f.contactTypeId == 1 &&
              f.contactValue != null &&
              f.contactValue !== "null"
          )
          .map((item) => item.contactValue);
        var emailvalue = response.directoryContacts
          ?.filter(
            (f) =>
              f.contactTypeId == 2 &&
              f.contactValue != null &&
              f.contactValue !== "null"
          )
          ?.map((item) => item.contactValue);
        setPhoneNumbers(phonesArrays.join(","));
        setEmail(emailvalue[0]);
        isActiveDirectory(response.directoryInfoStatusId);
        setDirectoryItem(response);
      });
    }

    directoryInfoService.setDirectoryView(DirectoryId).then((response) => {
    });

    if (data?.profileId) {
      setProfileId(data?.profileId);
    } else {
      let id = localStorage["userId"];
      setProfileId(id);
    }
  }, []);

  function isActiveDirectory(status) {
    if (status != 2) window.location.href = "/directory";
  }

  const minValue = 1;
  const maxValue = 5;
  const [estrellas, setEstrellas] = useState([0, 0, 0, 0, 0]);
  const [avrScore, setAvrScore] = useState(0);
  const [avrText, setAvrText] = useState(0);
  const [avrScoreA, setAvrScoreA] = useState(0);
  const [avrScoreB, setAvrScoreB] = useState(0);
  const [avrScoreC, setAvrScoreC] = useState(0);
  const [edit, setEdit] = useState(true);
  const [editA, setEditA] = useState(true);
  const [editB, setEditB] = useState(true);
  const [editC, setEditC] = useState(true);
  const [isEval, setIsEval] = useState(false);
  const [evalA, setEvalA] = useState(0);
  const [evalB, setEvalB] = useState(0);
  const [evalC, setEvalC] = useState(0);

  const directoryContacts = directoryItem.directoryContacts;

  const directoryRatings = directoryItem.directoryRatings;

  function updateScore(score, type) {
    if (type == 1) setEvalA(score.rating);
    if (type == 2) setEvalB(score.rating);
    if (type == 3) setEvalC(score.rating);
  }

  useEffect(() => {
    if (directoryRatings && directoryRatings.length > 0) {
      var sum = directoryRatings.reduce(function (prev, current) {
        return prev + +current.score;
      }, 0);

      var average = sum / directoryRatings.length;
      var averagefloor = Math.floor(average);

      setAvrText(average.toFixed(1));
      setAvrScore(directoryRatings.length);

      var serviceRating = directoryRatings.filter(
        (item) => 1 == item.directoryRatingTypeId
      );
      var priceRating = directoryRatings.filter(
        (item) => 2 == item.directoryRatingTypeId
      );
      var qualityRating = directoryRatings.filter(
        (item) => 3 == item.directoryRatingTypeId
      );

      var uno = directoryRatings.filter((item) => 1 == item.score).length;
      var dos = directoryRatings.filter((item) => 2 == item.score).length;
      var tres = directoryRatings.filter((item) => 3 == item.score).length;
      var cuatro = directoryRatings.filter((item) => 4 == item.score).length;
      var cinco = directoryRatings.filter((item) => 5 == item.score).length;

      setEstrellas([uno, dos, tres, cuatro, cinco]);

      if (serviceRating.length > 0) {
        var service = infoType(serviceRating);
        setAvrScoreA(service[0]);
        setEditA(service[1]);
        setEvalA(service[2]);
      }
      if (priceRating.length > 0) {
        var service = infoType(priceRating);
        setAvrScoreB(service[0]);
        setEditB(service[1]);
        setEvalB(service[2]);
      }
      if (qualityRating.length > 0) {
        var service = infoType(qualityRating);
        setAvrScoreC(service[0]);
        setEditC(service[1]);
        setEvalC(service[2]);
      }
    }
  }, [directoryItem]);
  useEffect(() => {
    if (!editA && !editB && !editC) {
      setEdit(false);
    }
  }, [editA, editB, editC]);

  const infoType = (item) => {
    var data = [0, true, 0];
    var sum = item.reduce(function (prev, current) {
      return prev + +current.score;
    }, 0);
    var average = sum / item.length;
    var averagefloor = Math.floor(average);
    data[0] = averagefloor;
    var userScore = item.filter((item) => profileId == item.userProfileAsp);
    if (userScore.length > 0) {
      data[1] = false;
      data[2] = userScore[0].score;
    }
    return data;
  };

  async function setScoreGroup() {
    var A = insertScore(evalA, 1);
  }

  function insertScore(score, type) {
    var data = new FormData();
    data.append("DirectoryRatingId", 0);
    data.append("DirectoryInfoId", directoryItem.directoryInfoId);
    data.append("DirectoryRatingTypeId", type);
    data.append("UserProfileId", 0);
    data.append("UserProfileAsp", profileId);
    data.append("Score", score);
    data.append("Active", true);

    directoryInfoService.setDirectoryScore(data).then((response) => {
      if (response) {
        if (type == 1) {
          setEditA(false);
          insertScore(evalB, 2);
        }
        if (type == 2) {
          setEditB(false);
          insertScore(evalC, 3);
        }
        if (type == 3) {
          setEditC(false);
          directoryInfoService
            .getById(directoryItem.directoryInfoId)
            .then((response) => {
              setDirectoryItem(response);
            });
        }
        return true;
      }
    });
    return false;
  }

  const validaUsuario = (url, start) => {
    var res = url.toUpperCase().startsWith("HTTP");
    if (res) {
      return url;
    }
    return start + url;
  };

  const validaWeb = (url) => {
    var res = url.toUpperCase().startsWith("HTTP");
    if (res) {
      return url;
    }
    return "https://" + url;
  };

  const [activeBoxTooltip, setBoxTooltip] = useState("d-none");
  const showBoxTooltip = () => {
    setBoxTooltip(activeBoxTooltip === "" ? " is-show" : "");
  };

  const updateReward = () => {
    directoryInfoService
      .updateDirectoryReward(
        directoryItem.directoryInfoId,
        !directoryItem.isEnableRewards
      )
      .then((result) => {
        if (result.data === "ok") {
          setDirectoryItem({
            ...directoryItem,
            isEnableRewards: !directoryItem.isEnableRewards,
          });
        }
      });
  };

  return (
    <section className="dashboard-container-page">
      <article className="d-flex-between">
        <h2>Emprendedores</h2>
        <Link to="/directory" className="btn-back">
          <i className="icon-chevron-left"></i> <p>Volver</p>
        </Link>
      </article>
      <article className="card-directory-detail">
        <section className="thumb">
          <div className="title">
            <div className="tags">
              <span className="category">
                {directoryItem ? directoryItem.categoryServiceName : ""}
              </span>
              <span className="subcategory">
                {directoryItem ? directoryItem.subCategoryServiceName : ""}
              </span>
            </div>
          </div>
          <h4>{directoryItem ? directoryItem.fullName : ""}</h4>
          <hr />
          <p>{directoryItem ? directoryItem.directoryDescription : ""}</p>
          <div className="img-detail">
            <div className="thumb">
              <img src={(directoryItem.contactImage) ? directoryItem.contactImage : notImage} alt={directoryItem ? directoryItem.fullName : ""}/>
              {/* {directoryItem.isEnableRewards ? (
                  <Link to="/Rewards" className="rewards">
                    <img src={ImgRecompensas} alt="Este articulo tiene recompensas en Romeh"/>
                  </Link>
              ) : (
                  ""
              )} */}
            </div>
          </div>
        </section>
        <section className="info">
          <div className="content">
            <div className="content_info_a">
              <h5>Datos de contacto</h5>
              <div className="data">
                <div className="data-items">
                  <div className="item">
                    <span className="icodata">
                      <FaMapMarkerAlt></FaMapMarkerAlt>
                    </span>
                    <p>{directoryItem ? directoryItem.location : ""}</p>
                  </div>
                  <div className="item">
                    <span className="icodata">
                      <FaPhone></FaPhone>
                    </span>
                    <a className="alinks" href={`tel:${directoryItem && directoryItem.directoryContacts ? phoneNumbers : ""}`}>
                      {directoryItem && directoryItem.directoryContacts ? phoneNumbers : ""}
                    </a>
                  </div>
                  <div className="item">
                    <span className="icodata">
                      <IoMail></IoMail>
                    </span>
                    {directoryItem && directoryItem.directoryContacts ? (<a href={`mailto:${email}`}>{email}</a>) : ("")}
                  </div>
                </div>
                <ul className="social-media">
                  {directoryItem && directoryItem.directoryContacts
                    ? directoryItem.directoryContacts.map((itemSocial) =>
                        itemSocial.contactTypeId > 2 ? (
                          <li key={itemSocial.directoryContactId}>
                            <ItemSocial
                              socialIcoName={itemSocial.contactTypeName}
                              socialIco={itemSocial.iconImage}
                              socialUrl={
                                itemSocial.contactTypeId == 3
                                  ? validaWeb(itemSocial.contactValue)
                                  : validaUsuario(
                                      itemSocial.contactValue,
                                      itemSocial.urlString
                                    )
                              }
                            />
                          </li>
                        ) : (
                          ""
                        )
                      )
                    : ""}
                </ul>
                {roleName === "Recursos Humanos" ? (
                  <>
                    <hr />
                    <FormGroup className="d-flex-start">
                      <label className="toggler-wrapper dark" htmlFor="exampleCheck1">
                        <input type="checkbox" className="form-check-input" id="exampleCheck1"
                               checked={(directoryItem.isEnableRewards) ? 'checked' : ''} onChange={(e) => updateReward()}/>
                        <div className="toggler-slider">
                          <div className="toggler-knob"></div>
                        </div>
                      </label>
                      <p style={{marginLeft: '.5rem'}}>Habilitar recompensas</p>
                    </FormGroup>
                    <div className="">
                      <Link className="btn-outline-dark"
                        to={{
                          pathname: `/register-services/${directoryItem.directoryInfoId}`,
                          state: {
                            Item: directoryItem,
                            profileId: profileId,
                            origin: `/details-directory/${directoryItem.directoryInfoId}`,
                          },
                        }}
                      >
                        <p>Editar Publicación</p>
                        <div className="circle"><i className="icon-edit"></i></div>
                      </Link>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="content_info_b">
              <h5>Evaluaciones</h5>
              <div className="data">
                <div className="data-ranking">
                  {isEval ? (
                    <div className="services one">
                      <h6>Evaluación</h6>
                      <div className="service">
                        <span>{"Servicio"}</span>
                        <Rating
                          min={minValue}
                          max={maxValue}
                          onChange={(rating) => {
                            updateScore({ rating }, 1);
                          }}
                          value={evalA}
                          edit={editA}
                        />
                      </div>
                      <div className="service">
                        <span>{"Precio"}</span>
                        <Rating
                          min={minValue}
                          max={maxValue}
                          onChange={(rating) => {
                            updateScore({ rating }, 2);
                          }}
                          value={evalB}
                          edit={editB}
                        />
                      </div>
                      <div className="service">
                        <span>{"Calidad"}</span>
                        <Rating
                          min={minValue}
                          max={maxValue}
                          onChange={(rating) => {
                            updateScore({ rating }, 3);
                          }}
                          value={evalC}
                          edit={editC}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="services two">
                      <h6>{avrText} de 5</h6>
                      <div className="service">
                        <p>{"Servicio"}</p>
                        <Rating
                          min={minValue}
                          max={maxValue}
                          value={avrScoreA}
                          edit={false}
                        />
                      </div>
                      <div className="service">
                        <p>{"Precio"}</p>
                        <Rating
                          min={minValue}
                          max={maxValue}
                          value={avrScoreB}
                          edit={false}
                        />
                      </div>
                      <div className="service">
                        <p>{"Calidad"}</p>
                        <Rating
                          min={minValue}
                          max={maxValue}
                          value={avrScoreC}
                          edit={false}
                        />
                      </div>
                    </div>
                  )}
                  <div className="btns">
                    {edit ? (
                      <button type="button" onClick={() => setIsEval(!isEval)}>
                        {isEval ? (
                          <i>
                            <BsChevronLeft />
                          </i>
                        ) : (
                          ""
                        )}
                        {isEval ? "Volver" : "Evaluar"}
                        {isEval ? (
                          ""
                        ) : (
                          <i>
                            <BsChevronRight />
                          </i>
                        )}
                      </button>
                    ) : (
                      <button type="button" onClick={() => setIsEval(!isEval)}>
                        {isEval ? (
                          <i>
                            <BsChevronLeft />
                          </i>
                        ) : (
                          ""
                        )}
                        {isEval ? "Volver" : "Mi Evaluación"}
                        {isEval ? (
                          ""
                        ) : (
                          <i>
                            <BsChevronRight />
                          </i>
                        )}
                      </button>
                    )}
                    {isEval && evalA > 0 && evalB > 0 && evalC > 0 && edit && (
                      <button type="button" onClick={() => setScoreGroup()}>
                        {"Guardar"}
                        <i>
                          <FaSave />
                        </i>
                      </button>
                    )}
                  </div>
                </div>
                <div className={`data-ranking-tooltip`}>
                  <p>Total de evaluaciones: {avrScore}</p>
                  <div className="item">
                    <Rating
                      min={minValue}
                      max={maxValue}
                      value={5}
                      edit={false}
                    />
                    <span style={{ flex: 1 }}>
                      <Progress
                        variant="mycolor"
                        min={0}
                        max={avrScore}
                        value={estrellas[4]}
                      />
                    </span>
                    <span
                      style={{
                        minWidth: "10px",
                        textAlign: "right",
                        fontSize: "12px",
                        padding: "2px",
                      }}
                    >
                      {estrellas[4]}
                    </span>
                  </div>
                  <div className="item">
                    <Rating
                      min={minValue}
                      max={maxValue}
                      value={4}
                      edit={false}
                    />
                    <span style={{ flex: 1 }}>
                      <Progress
                        variant="mycolor"
                        min={0}
                        max={avrScore}
                        value={estrellas[3]}
                      />
                    </span>
                    <span
                      style={{
                        minWidth: "10px",
                        textAlign: "right",
                        fontSize: "12px",
                        padding: "2px",
                      }}
                    >
                      {estrellas[3]}
                    </span>
                  </div>
                  <div className="item">
                    <Rating
                      min={minValue}
                      max={maxValue}
                      value={3}
                      edit={false}
                    />
                    <span style={{ flex: 1 }}>
                      <Progress
                        variant="mycolor"
                        min={0}
                        max={avrScore}
                        value={estrellas[2]}
                      />
                    </span>
                    <span
                      style={{
                        minWidth: "10px",
                        textAlign: "right",
                        fontSize: "12px",
                        padding: "2px",
                      }}
                    >
                      {estrellas[2]}
                    </span>
                  </div>
                  <div className="item">
                    <Rating
                      min={minValue}
                      max={maxValue}
                      value={2}
                      edit={false}
                    />
                    <span style={{ flex: 1 }}>
                      <Progress
                        variant="mycolor"
                        min={0}
                        max={avrScore}
                        value={estrellas[1]}
                      />
                    </span>
                    <span
                      style={{
                        minWidth: "10px",
                        textAlign: "right",
                        fontSize: "12px",
                        padding: "2px",
                      }}
                    >
                      {estrellas[1]}
                    </span>
                  </div>
                  <div className="item">
                    <Rating
                      min={minValue}
                      max={maxValue}
                      value={1}
                      edit={false}
                    />
                    <span style={{ flex: 1 }}>
                      <Progress
                        variant="mycolor"
                        min={0}
                        max={avrScore}
                        value={estrellas[0]}
                      />
                    </span>
                    <span
                      style={{
                        minWidth: "10px",
                        textAlign: "right",
                        fontSize: "12px",
                        padding: "2px",
                      }}
                    >
                      {estrellas[0]}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </article>
      <Row>
        <Col sm={12}>
          <FooterHome />
        </Col>
      </Row>
    </section>
  );
}
