import React, {useEffect, useState} from "react";

import "./blogCompany.scss";
import {Col, Row} from "reactstrap";

import './blogCompany.scss';

import imgEmpty from '../../assets/imgs/no-image.jpeg';
import {BsArrowRight} from "react-icons/bs";
import {Link} from "react-router-dom";
import {companyPostViewService} from "../../services/compayPostView.service";
import Moment from "react-moment";
import {AsideBlogCompanySecondary} from "../companyPost/asideBlogCompanySecondary";
import {FooterHome} from "../../components/footer/footerHome";

export function BlogCompanySecondary() {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const [activeAsideSurvey, setStateAsideSurvey] = useState("");
    const [posts, setPosts] = useState([]);
    const [countingPosts, setCountingPosts] = useState([]);
    const [selectedPost, setSelectedPost] = useState({});
    let [currentFilter, setCurrentFilter] = useState("NaN-aN-aN");
    const [lastFiter, setLastFiter] = useState({});
    const [sectionActive, setSectionActive] = useState("1");
    const postCategoryId = 2;
    const postType = 0;
    const [comments, setComments] = useState(0);
    const [postComment, setPostComment] = useState({});

    const clearVariable = () => {
        setSelectedPost({});
        setPosts([]);
    };

    async function getPosts() {
        clearVariable();
        companyPostViewService
            .getByFilters(currentFilter === "NaN-aN-aN" ? undefined : currentFilter, 2)
            .then((result) => {
                if (result && result.length > 0) {
                    let item = {};
                    item = result[0];
                    handleSelectPost(item);
                    setPosts(result);
                }
            });
    };

    const handleSelectPost = (item) => {
        if (!item.isRead) {
            companyPostViewService
                .InsertRead({ CompanyPostId: item.companyPostId })
                .then((result) => {
                    if (result) {
                        setSelectedPost(result);
                        if (posts.length > 0) {
                            let items = posts.map((m) => {
                                if (m.companyPostId === item.companyPostId) m.isRead = true;
                                return m;
                            });
                            setPosts(items);
                        }
                    }
                });
        } else {
            getPost(item.companyPostId);
        }
    };

    const getPost = (id) => {
        companyPostViewService.getById(id).then((result) => {
            if (result.postType === 0) setSelectedPost(result);
            if (result.postType === 1) result.iconPath = result.urlImage;
        });
    };

    const formatDate = (date) => {
        let datestring =
            date.getFullYear() +
            "-" +
            ("0" + (date.getMonth() + 1)).slice(-2) +
            "-" +
            ("0" + date.getDate()).slice(-2);
        return datestring;
    };

    useEffect(() => {
        if (lastFiter.filterMonthDate !== undefined && postCategoryId === lastFiter.postType) {
            let datelastFilter = formatDate(new Date(lastFiter.filterMonthDate));
            if (currentFilter === datelastFilter) {
                getPosts();
            } else {
                getPostDateFilter(new Date(lastFiter.filterMonthDate));
            }
        }
    }, [lastFiter]);

    useEffect(() => {
        getPostDateFilter(new Date());
    }, []);

    const getPostDateFilter = (filterDate) => {
        const year = String(filterDate.getFullYear());
        const month = String(filterDate.getMonth() + 1).padStart(2, '0');
        const day = String(filterDate.getDate()).padStart(2, '0');
        const joined = [year,month, day].join('-');
        setCurrentFilter(currentFilter = joined);
        getPosts();
    };

    return (
        <article className="dashboard-container-page">
            <Row>
                <Col sm={12}><h2>Consultas</h2></Col>
                <Col lg={9}>
                    {
                        posts.slice(0,1).map((card,i) => {
                            return (
                                <Link key={i} to={`/stories-pride/1/${card.companyPostId}`} className="blog-secondary-main-card">
                                    <div className="thumb">
                                        {
                                            card.urlImage ?
                                                <img src={card.urlImage} alt="" />
                                                :
                                                <img src={imgEmpty} alt="" />
                                        }
                                    </div>
                                    <div className="content">
                                        {
                                            (card.title.length <= 120) ?
                                                <h3>{card.title}</h3>
                                                :
                                                <h3>{card.title.substring(0,120)}...</h3>
                                        }
                                        <h6><Moment date={card.publishDate} format="dddd D [de] MMMM [del] YYYY" /></h6>
                                        <div className="flex-end">
                                            <Link to={`/stories-pride/1/${card.companyPostId}`} className="btn-next">Leer <BsArrowRight /> </Link>
                                        </div>
                                    </div>
                                </Link>
                            );
                        })
                    }
                    <div className="blog-secondary-cards">
                        <Row>
                            <Col sm={12}><h3>Publicaciones del mes</h3></Col>
                            <Col sm={12}>
                                <div className="card-posts-secondary">
                                    {
                                        posts.slice(1).map((card,i) => {
                                            return (
                                                <Link key={i} to={`/stories-pride/1/${card.companyPostId}`} className="card-post-secondary">
                                                    <div className="card-post-thumb">
                                                        {
                                                            card.urlImage ?
                                                                <img src={card.urlImage} alt="" />
                                                                :
                                                                <img src={imgEmpty} alt="" />
                                                        }
                                                    </div>
                                                    <div className="card-post-content">
                                                        <div>
                                                            {
                                                                (card.title.length <= 60) ?
                                                                    <h3>{card.title}</h3>
                                                                :
                                                                    <h3>{card.title.substring(0,60)}...</h3>
                                                            }
                                                            <h6>16 de Enero 2023</h6>
                                                        </div>
                                                        <div className="flex-end">
                                                            <Link to={`/stories-pride/1/${card.companyPostId}`} className="btn-next">Leer <BsArrowRight /></Link>
                                                        </div>
                                                    </div>
                                                </Link>
                                            );
                                        })
                                    }
                                    {
                                        (posts.slice(1).length === 0) ?

                                            <div className="empty">
                                                <i className="icon-empty"></i>
                                                <p>No hay más publicaciones</p>
                                            </div>
                                            : ''
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col lg={3}>
                    <div className={`blog-secondary-aside`}>
                        <h3>Otras Publicaciones</h3>
                        <AsideBlogCompanySecondary
                            getPost={getPostDateFilter}
                            activeAsideSurvey={activeAsideSurvey}
                            postCategoryId={postCategoryId}
                            setLastFiter={setLastFiter}
                            postType={postType}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <FooterHome />
                </Col>
            </Row>
        </article>
    );
}
