import React, { useState } from 'react';
import { EditCommunity } from './editCommunity'
import { ListCommunity } from './listCommunity'

import '../admin/admin.scss';
import {Col, Row} from "reactstrap";
import {FooterHome} from "../../components/footer/footerHome";

export function CaringCommunity() {
    const [showEdit, setShowEdit] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [selectedCommunity, setSelectedCommunity] = useState();

    const handleOnCommunitySelected = (community) => {
        setSelectedCommunity(community);
        setShowEdit(true);
    };
    
    const handleOnNewCommunity = () => {
        setSelectedCommunity(null);
        setShowEdit(true);
    };
    const handleOnCancelEdit = () => {
        setShowEdit(false);
    }
    const handleOnSaveEdit = (result) => {
        setRefresh(prev => prev + 1);
        if (result)
            setShowEdit(false);
    }

    return (
        <section className="dashboard-container-page">
			<div className="admin-title">
                <h2>Comunidad Solidaria</h2>
			</div>
            {showEdit && <EditCommunity currentCommunity={selectedCommunity} onCancelEdit={handleOnCancelEdit} onSave={handleOnSaveEdit} />}
            {!showEdit && <ListCommunity onNewClicked={handleOnNewCommunity} onSelectCommunity={handleOnCommunitySelected} refresh={refresh} />}
            <Row>
                <Col sm={12}>
                    <FooterHome />
                </Col>
            </Row>
        </section>
    )
}
