import React, { useEffect, useState } from 'react';
import useInputForm from '../../components/CustonHooks/FormHook'
import { productFilterService } from '../../services/backoffice/rewardProductFilter.service';

import { Link } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import {Col, FormGroup, Input, Row} from "reactstrap";
import {Button} from "rsuite";
import {FaSave} from "react-icons/fa";

export function EditProductFilter(props) {
    //const dispatch = useDispatch();

    const onSave = () => {
        inputs.BranchOfficeId = parseInt(inputs.BranchOfficeId);
        inputs.DepartmentId = parseInt(inputs.DepartmentId);
        inputs.JobRoleId = parseInt(inputs.JobRoleId);
        inputs.RewardProductId = parseInt(inputs.RewardProductId);
        inputs.Points = parseInt(inputs.Points)
        inputs.RewardCount = parseInt(inputs.RewardCount)
        inputs.ParentStock = parseInt(inputs.ParentStock)
        if (inputs.RewardProductFilterId && inputs.RewardProductFilterId > 0)
            productFilterService.edit(inputs);
        else
            productFilterService.create(inputs);
    };

    const [showEdit, setShowEdit] = useState(false);
    const [minCouponCount, setMinCouponCount] = useState(0);


    //const [stock, setStock] = useState(0);
    const [catalogs, setCatalog] = useState({

        branchOfficeCatalog: [],
        departmentCatalog: [],
        jobRoleCatalog: [],
        productCatalog: []

    });

    const [productFilter, setProductFilter] = useState({
        RewardProductFilterId: 0,
        CompanyId: 0,
        RewardProductId: null,
        BranchOfficeId: 0,
        DepartmentId: 0,
        JobRoleId: null,
        Points: 0,
        Active: null,
        RewardCount: 0,
        FilterStock: 0
    });
    useEffect(() => {
        productFilterService.getCatalogs().then((result) => {
            setCatalog({
                branchOfficeCatalog: result.branchOffice,
                departmentCatalog: result.department,
                jobRoleCatalog: result.jobRole,
                productCatalog: result.rewardProduct
            })
        });
    }, []);
    useEffect(() => {
            if (props.rewardProductFilterId != 0) {
                setShowEdit(true);
                productFilterService.getById(props.rewardProductFilterId).then((result) => {
                    setMinCouponCount(result.filterStock * -1);
                    setProductFilter({
                        RewardProductFilterId : result.rewardProductFilterId,
                        CompanyId: result.companyId,
                        RewardProductId: result.rewardProductId,
                        BranchOfficeId: result.branchOfficeId,
                        DepartmentId: result.departmentId,
                        JobRoleId: result.jobRoleId,
                        Points: result.points,
                        Active: result.active,
                        RewardCount: result.rewardCount,
                        FilterStock: result.filterStock,
                        ParentStock: result.parentStock,
                        
                    });
                });
            }
            else  {
                setMinCouponCount(1);
                setShowEdit(false);
            }
    }, [props.rewardProductFilterId]);
    const { handleSubmit, handleInputChange, inputs, setInputs } = useInputForm(onSave, productFilter);

    const onChange = (e) => {
        handleInputChange(e);
        var index = e.target.selectedIndex;
        var optionElement = e.target.childNodes[index]
        var option =  optionElement.getAttribute('stock');
        inputs.ParentStock = option;
        //setStock(option);
    }

    return (
        <>
            <form onSubmit={handleSubmit} className="form-dashboard">
                <Row>
                    <Col sm={12} className="d-flex-between">
                        <h3>{props.rewardProductFilterId !== 0 ? "Editar filtro" : "Nuevo filtro"}</h3>
                        <Link className="btn-back" to="/admin/product-filter" ><i className="icon-chevron-left"></i>Cancelar</Link>
                    </Col>
                    <Col md={6} sm={12}>
                        <FormGroup className="form-dashboard-group">
                            <label for="branchOfficeId">Sucursal</label>
                            <select id="branchOfficeId" name="BranchOfficeId" value={inputs.BranchOfficeId} onChange={handleInputChange}>
                                <option>Todas</option>
                                {
                                    catalogs.branchOfficeCatalog.map(item => <option key={item.value} value={item.value}> {item.label}</option>)
                                }
                            </select>
                        </FormGroup>
                    </Col>
                    <Col md={6} sm={12}>
                        <FormGroup className="form-dashboard-group">
                            <label for="departmentId">Grupo</label>
                            <select id="departmentId" name="DepartmentId" value={inputs.DepartmentId} onChange={handleInputChange} >
                                <option>Todos</option>
                                {
                                    catalogs.departmentCatalog.map(item => <option key={item.value} value={item.value}> {item.label}</option>)
                                }
                            </select>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} sm={12}>
                        <FormGroup className="form-dashboard-group">
                            <label for="jobRoleId">Categoría</label>
                            <select id="jobRoleId" name="JobRoleId" value={inputs.JobRoleId} onChange={handleInputChange} >
                                <option>Todos</option>
                                {
                                    catalogs.jobRoleCatalog.map(item => <option key={item.value} value={item.value}> {item.label}</option>)
                                }
                            </select>
                        </FormGroup>
                    </Col>
                    <Col md={6} sm={12}>
                        <FormGroup className="form-dashboard-group">
                            <label for="rewardProductId">Producto</label>
                            <select id="rewardProductId" name="RewardProductId" value={ inputs.RewardProductId } onChange={onChange} required  disabled = {showEdit} >
                            <option value="">Seleccione </option>
                                {
                                    catalogs.productCatalog.map(item => <option key={item.value} value={item.value} stock={item.stock} > {item.label + ' | Existencia en bolsa: ' + item.stock}</option>)
                                }
                            </select>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                {showEdit ?
                    <Col md={6} sm={12}>
                        <FormGroup className="form-dashboard-group">
                            <label for="FilterStock">Stock del filtro</label>
                            <Input type="number" name="FilterStock" onChange={handleInputChange} value={inputs.FilterStock} required disabled />
                        </FormGroup>
                    </Col>
                    : null   }
                    <Col md={6} sm={12}>
                        <FormGroup className="form-dashboard-group">
                            <label for="RewardCount">Cant. Cupones</label>
                            <Input type="number" name="RewardCount" onChange={handleInputChange} min={minCouponCount} max={inputs.ParentStock} value={inputs.RewardCount} required />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} sm={12}>
                        <FormGroup className="form-dashboard-group">
                            <label for="points">Puntos</label>
                            <Input type="number" name="Points" onChange={handleInputChange} min="0" value={inputs.Points} required />
                        </FormGroup>
                    </Col>
                </Row>
                <div className="flex-end">
                    <Button className="btn-dark" type="submit">
                        <p>Guardar</p>
                        <FaSave />
                    </Button>
                </div>
            </form>
        </>
    );
}   
