import { callApi, callApiFileStorage } from './service'

export const storageService = {
    uploadFiles,
    deleteStorage,
    addStorage,
    updateStorage,
    download
};

async function uploadFiles(files) {

    var data = new FormData();
    data.append("model", JSON.stringify({ Id: 1, Des: 'aaaaa' }));

    for (var i = 0; i < files.length; i++) {
        var f = files[i];
        data.append('files', f, f.name);
    }

    return callApiFileStorage('/backoffice/uploadFiles', 'POST', data);
}
async function deleteStorage(params) {
    return callApi('/backoffice/Storage', 'DELETE', params).then((result) => handleRsponse(result));
}
async function addStorage(params, file) {
    var data = new FormData();
    data.append("model", JSON.stringify(params));
    data.append('files', file, file.name);
    return callApiFileStorage('/backoffice/Storage', 'POST', data).then((result) => handleRsponse(result));
}
async function updateStorage(params, file) {
    var data = new FormData();
    data.append("model", JSON.stringify(params));
    data.append('files', file, file.name);
    return callApiFileStorage('/backoffice/Storage', 'PUT', data).then((result) => handleRsponse(result));
}

async function download(url) {
    return fetch(url).then(res => {
        return res.blob()
    });
}

function handleRsponse(result) {
    if (result.responseCode === '00')
        return result.data;
    else {
        alert(result.responseMessage);
        return null;
    }
}