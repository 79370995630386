import React, { useEffect, useState } from "react";
import { courseViewService } from "../../services/courseView.service";
import "moment/locale/es";
import { useSelector } from "react-redux";
import { Col, Modal, ModalBody, Row } from "reactstrap";
import ReactPlayer from "react-player";
import {  FaTimes } from "react-icons/fa";
import { BsEye } from "react-icons/bs";

import { Button } from "rsuite";
import pdfImg from "../../assets/imgs/pdf.png";
import noImage from "../../assets/imgs/no-image.jpeg";
import excellImage from "../../assets/imgs/excel.png";
import wordImage from "../../assets/imgs/word.png";
import pdfImage from "../../assets/imgs/pdf.png";
import iconYT from "../../assets/imgs/icon-yt.png";

export function BoxListCourse({ dataGrid, setCompleteCourse }) {
  //const calendarStrings = useSelector((state) => state.calendarStrings);
  const [course, setCourse] = useState({ type: 0, value: "", fileType: "" });
  const [modalConfig, setModalConfig] = useState({ fullscreen: false, size: 'lg' });
  const imageExt = ["jpg", "jpeg", "png", "webp", "svg"];
  const [video, setVideo] = useState(null);

  const handleOnFiles = (file, course) => {
    courseViewService
      .getFileById(file.entityId, file.entityTypeId, file.sequence)
      .then((result) => {
        if (result) {
          setCompleteCourse(course);
          const fileType = result.fileName.split(".")[1];
          if (fileType == "pdf")
            toggleCourse(
              1,
              `data:application/${fileType};base64,${result.file}`,
              fileType
            );
          else if (fileType == "mp4") handleVideoUrl(file.path, fileType);
          else downloadFile(file.fileName, result.file);
        }
      });
  };

  const handleComplete = (course) => {
    setCompleteCourse(course)
  };

  const handleUrl = (event, course, type) => {
    setCompleteCourse(course);
    toggleCourse(type, course.urlMedia);
    event.preventDefault();
  };

  const toggleCourse = (type, value, fileType) => {
    setCourse({ type, value, fileType });
    toggle(null, true, '');
  };

  const downloadFile = (fileName, fileb64) => {
    const linkSource = `data:application/octet-stream;base64,${fileb64}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const [modal, setModal] = useState(false);
  const toggle = (video, fullscreen, size) => {
    setModalConfig({ fullscreen, size });
    setVideo(video);
    setModal(!modal);
  };

  const handleVideoUrl = (link, fileType) => {
    if (link.length > 0) {
      const video = (
        <div className="boxImage icon">
          <p>video</p>
        </div>
      );
      return video;
    }
  };

  const imageBox = (itemFile) => {
    let image = imageLink("document-outline");
    const re = /(?:\.([^.]+))?$/;
    const ext = re.exec(itemFile.fileName)[1];

    switch (ext) {
      case 'xslx':
        image = <img src={excellImage} alt="Imagen de cartelera" />;
        break;

      case 'docx':
        image = <img src={wordImage} alt="Imagen de cartelera" />;
        break;

      case 'pdf':
        image = <img src={pdfImage} alt="Imagen de cartelera" />;
        break;

      default:
        image = <img src={noImage} alt="Imagen de cartelera" />;
        break;
    }
    return image;
  };

  const imageBoxLink = (itemFile) => {
    let image = imageLink("unlink-outline");
    const re = /(?:\.([^.]+))?$/;
    const ext = re.exec(itemFile.fileName)[1];
    const isPdf = itemFile.fileName.slice(-4);

    if (isPdf !== ".pdf") {
      image = (
        <img
          src={itemFile.path ? itemFile.path : noImage}
          alt="Imagen de cartelera"
        />
      );
    }

    if (isPdf === ".pdf") {
      image = <img src={pdfImg} alt="Archivo de cartelera" />;
    }
    return image;
  };

  const imageLink = (icon) => {
    const image = (
      <div className="boxImage icon">
        <p>
          <ion-icon name={icon}></ion-icon>
        </p>
      </div>
    );
    return image;
  };

  const imageYouTube = (frame) => {
    let image = imageLink("play-circle-outline");
    let url = "";
    let youtubeid = "";
    const first = frame.split('src="');
    if (first.length > 1) {
      const second = first[1].split('"');
      if (second.length > 0) {
        url = second[0];
        const third = url.split("embed/");
        if (third.length > 0) {
          youtubeid = third[1];
          const videoThumb =
            "https://img.youtube.com/vi/" + youtubeid + "/0.jpg";
          const imageBack = { backgroundImage: `url(${videoThumb})` };
          image = <div className="thumb-youtube" style={imageBack} />;
        }
      }
    }
    return image;
  };

  const [idBillboardSelected, setIdBillboardSelected] = useState(0);
  const [billboardCurrentSelected, setBillboardCurrentSelected] = useState(0);
  const selectedBillboardId = (id) => {
    setIdBillboardSelected(id);
    setBillboardCurrentSelected(id);
  };

  const [modalCard, setModalCard] = useState(false);
  const [itemModal, setItemModal] = useState(null);

  const currentCardSelected = (item) =>  setBillboardCurrentSelected(item.courseId);

  const toggleCard = (item) => {
    setItemModal(item);
    setModalCard(!modalCard);
  };

  function getWidthDimensions() {
    const { innerWidth: width } = window;
    return width;
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWidthDimensions()
  );

  useEffect(() => {
    const handleResize = () => setWindowDimensions(getWidthDimensions());
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const loadCurrentBillboard = () => {
    dataGrid.slice(0, 1).map((item) => {
      setIdBillboardSelected(item.courseId);
      setBillboardCurrentSelected(item.courseId);
    });
  };

  if (!idBillboardSelected && windowDimensions >= 992)
    setTimeout(() => loadCurrentBillboard(), 100);

  return (
    <>
      <Row>
        <Col lg={7} md={12} sm={12} style={{ padding: "0" }}>
          <div className="course-view-scroll">
            {dataGrid.map((item, i) => (
              <Button
                type="button"
                className="card-billboard-btn"
                onClick={
                  windowDimensions >= 992
                    ? () => selectedBillboardId(item.courseId)
                    : () => currentCardSelected(item)
                }
                key={i}
              >
                <div className="card-billboard">
                  {
                    {
                      1: imageBox({ fileName: item.fileName, path: item.path }),
                      2: imageBoxLink({
                        fileName: item.fileName,
                        path: item.path,
                      }),
                      3: imageYouTube(item.urlMedia),
                    }[item.courseMediaTypeId]
                  }
                  <div className={`card-billboard overlay ${billboardCurrentSelected === item.courseId ? "current" : ""}`}>
                    <button type="button" onClick={windowDimensions <= 992 ? () => toggleCard(item) : ""}>
                      <BsEye />
                    </button>
                    {
                      {
                        1: (
                          <a
                            target="_blank"
                            onClick={() => handleOnFiles(item.courseFiles[0], item)}
                            className="btn-outline-white"
                          >
                            Ver PDF
                          </a>
                        ),
                        2: (
                          <a
                            href={item.urlMedia}
                            target="_blank"
                            className="btn-outline-white"
                            onClick={(event) => handleComplete(item)}
                          >
                            Ver más <span></span>
                          </a>
                        ),
                        3: (
                          <Button
                            onClick={() => toggle(item.urlMedia, false, 'lg')}
                            target="_blank"
                            className="btn-outline-white"
                          >
                            Ver video <span></span>
                          </Button>
                        ),
                      }[item.courseMediaTypeId]
                    }
                  </div>
                </div>
              </Button>
            ))}
          </div>
        </Col>
        {windowDimensions <= 992 ? (
          ""
        ) : (
          <Col lg={5} md={12} sm={12} style={{ padding: "0" }}>
            <div className="course-view-aside">
              {dataGrid.map((item) => {
                return item.courseId === idBillboardSelected ? (
                  <div className="card-billboard-body">
                    <div className="card-billboard-body-thumb">
                      {item.courseMediaTypeId === 1 ? (
                        <img
                          src={
                            item.fileName.slice(-4) === ".pdf"
                              ? pdfImg
                                : item.path
                              ? item.path
                                : noImage
                          }
                          alt="Thumbnail cartelera"
                        />
                      ) : item.courseMediaTypeId === 2 ? (
                        <img
                          src={item.path ? item.path : noImage}
                          alt="Thumbnail cartelera"
                        />
                      ) : (
                        <img src={iconYT} alt="Thumbnail cartelera" />
                      )}
                    </div>
                    <div className="card-billboard-content">
                      <div className="card-billboard-title">
                        <h3>{item.name}</h3>
                      </div>
                      <div className="card-billboard-description">
                        <p>{item.description} </p>
                      </div>
                      {item.courseMediaTypeId === 1 ? (
                        <div className="d-flex-center">
                          <Button
                            onClick={() => handleOnFiles(item.courseFiles[0], item)}
                            className="btn-outline-login"
                          >
                            Ver PDF
                          </Button>
                        </div>
                      ) : item.courseMediaTypeId === 2 ? (
                        <div className="d-flex-center">
                          <a
                            href={item.urlMedia}
                            target="_blank"
                            className="btn-outline-login"
                            onClick={(event) =>
                              handleComplete(item)
                            }
                          >
                            Ver Link
                          </a>
                        </div>
                      ) : (
                        <div className="d-flex-center">
                          <Button
                            onClick={() => toggle(item.urlMedia, false, 'lg')}
                            className="btn-outline-login"
                          >
                            Ver Video
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                );
              })}
            </div>
          </Col>
        )}
      </Row>
      <Modal className="dropmodal" isOpen={modal} toggle={toggle} centered={true} fullscreen={modalConfig.fullscreen} size={modalConfig.size}>
        <ModalBody>
          <div>
            <Button className="close__in_modal" onClick={toggle}>
              <i className="icon-close"></i>
            </Button>
          </div>
          {video ? (
            <ReactPlayer
              className="video-billboard"
              url={video}
              width="100%"
              height="100%"
              controls={true}
            />
          ) : (
            <iframe width="100%" height="100%" src={course.value}></iframe>
          )}
        </ModalBody>

        {/*
              <>
                <Button onClick={toggle}>
                  <i className="icon-close"></i>
                </Button>
                <div dangerouslySetInnerHTML={{ __html: course.value }} style={{ display: "contents" }} />
              </>*/}
      </Modal>
      <Modal className="dropmodal" isOpen={modalCard} toggle={toggleCard} centered={true}>
        <ModalBody>
          <button
            type="button"
            className="close__in_modal"
            onClick={toggleCard}
          >
            <FaTimes />
          </button>
          <div className="modal-card-billboard">
            <div className="card-billboard-body">
              <div className="card-billboard-content">
                <div className="card-billboard-title">
                  <h3>{itemModal ? itemModal.name : ""}</h3>
                </div>
                <div className="card-billboard-description">
                  <p>{itemModal ? itemModal.description : ""} </p>
                </div>
                {(itemModal ? itemModal.courseMediaTypeId === 2 : "") ? (
                  <div className="d-flex-center">
                    <a
                      href={itemModal.urlMedia}
                      target="_blank"
                      onClick={toggle}
                      className="btn-outline-login"
                    >
                      Ver Link
                    </a>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}
