import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { rewardCategoryService } from '../../services/control/rewardCategory.service';
import {Table} from "reactstrap";
import {Button} from "rsuite";

export function SearchRewardCategory(props) {
    const [rewardsData, setRewardsData] = useState({ rewards: [] });
    const handleDelete = (id) => {
        rewardCategoryService.deleteCategory(id).then((result) => { getRewardcategories(); });
    };

    useEffect(() => {
        getRewardcategories();
    }, [props.refresh]);

    async function getRewardcategories() {
        rewardCategoryService.get().then((result) => {
            if (result) {
                setRewardsData({ rewards: result });
            }
        });
    }

    return (
        <div className="table-responsive">
            <Table className="admin-table" hover>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>descripción</th>
                        <th>Ícono</th>
                        <th>Activo</th>
                    </tr>
                </thead>
                <tbody>
                    {rewardsData.rewards.map(reward => (
                        <tr key={reward.rewardCategoryId}>
                            <td>
                                <Link className="btn-link d-flex-center" to={"/operator/details-rewardCategory/" + reward.rewardCategoryId}>{reward.rewardCategoryId}</Link>
                            </td>
                            <td>
                                <Link className="btn-link d-flex-center" to={"/operator/details-rewardCategory/" + reward.rewardCategoryId}>{reward.description}</Link>
                            </td>
                            <td>
                                <Link className="btn-link d-flex-center" to={"/operator/details-rewardCategory/" + reward.rewardCategoryId}>{reward.urlIcon}</Link>
                            </td>
                            <td>{reward.active ? "Activo" : "Inactivo"}</td>
                            <td>
                                <Button className="btn-icon-table delete" onClick={() => handleDelete(reward.rewardCategoryId)}>
                                    <i className="icon-trash"></i>
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>

    );
}
