import React, { Component } from "react";
import Slider from "react-slick";
import { useQuestion } from "../../services/surveyQuestion.service";
import { AnswersSurvey } from "./answersSurvey";
import { Button } from "rsuite";
import { GrClose } from "react-icons/gr";
export default class BoxSurveys extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: [],
      questionIndex: 1,
      questionCount: 0,
      questionIndexSave: -1,
      previousClick: false,
    };
    this.lastSave = 0;
  }

  componentWillMount() {
    this.getQuestions(this.props.survey);
    this.setState({ questionCount: this.props.survey.questionCount });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.getQuestions(nextProps.survey);
      this.setState({
        questionCount: nextProps.survey.questionCount,
        questionIndex: 1,
        questionIndexSave: -1,
      });
    }
  }

  getQuestions = async (survey) => {
    useQuestion.getFrontQuestions(survey).then((result) => {
      if (result) {
        this.setState({ questions: result });
      }
    });
  };

  setQuestionIndexSave = (value) => {
    this.setState({ questionIndexSave: value });
  };

  setLastSave = (index) => {
    if (this.lastSave < index) {
      this.lastSave = index;
      this.slider.slickGoTo(this.lastSave + 1);
    }
  };

  handleQuestionChange = (questionSave) => {
    this.setQuestionIndexSave(questionSave ? this.state.questionIndex - 1 : -1);
    if (!questionSave) {
      this.slider.slickPrev();
      this.setPreviousClick(true);
    }
  };

  next = () => {
    this.slider.slickNext();
  };

  getQuestionIndex = (id) => {
    let index = this.state.questions.findIndex(
      (x) => x.surveyQuestionId === id
    );
    this.slider.slickGoTo(index);
  };

  previous = (id) => {
    let index = this.state.questions.findIndex(
      (x) => x.surveyQuestionId === id
    );
    this.slider.slickGoTo(index - 1);
  };

  setPreviousClick = (value) => {
    this.setState({ previousClick: value });
  };

  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      beforeChange: (current, next) =>
        this.setState({ questionIndex: next + 1 }),
      adaptiveHeight: true,
      swipe: false,
    };

    const {
      questions,
      questionIndex,
      questionCount,
      questionIndexSave,
      previousClick,
    } = this.state;

    const handleKeyDown = (e) => {
      if (e.keyCode === 9) {
        e.preventDefault();
      }
    };

    return (
      <section className="surveys-section">
        <div className="sec__ttl_surveys">
          <div className="drawer__ttl_info_a">
            <span className="ico__doc">
              <i className="icon-doc"></i>
            </span>
            <div className="box__ttl_survey">
              <h6>{this?.props?.survey?.title}</h6>
              <span>{this?.props?.survey?.reward} puntos</span>
            </div>
          </div>

          <div className="drawer__ttl_info_b">
            <div
              className="close_surveys"
              onClick={() => window.location.reload()}
            >
              <GrClose />
            </div>
            <div>
              Pregunta {questionIndex}/{questionCount}
            </div>
          </div>
        </div>

        <Slider ref={(c) => (this.slider = c)} {...settings}>
          {questions.map((question, index) => (
            <div key={question.surveyQuestionId}>
              <h5 className="question">{question.question}</h5>
              <div className="answers" onKeyDown={handleKeyDown}>
                <AnswersSurvey
                  index={index}
                  question={question}
                  surveyUserProfileId={this.props.survey.surveyUserProfileId}
                  next={this.next}
                  setQuestionIndexSave={this.setQuestionIndexSave}
                  questionIndexSave={questionIndexSave}
                  setLastSave={this.setLastSave}
                  getQuestionIndex={this.getQuestionIndex}
                  previous={this.previous}
                  setPreviousClick={this.setPreviousClick}
                  previousClick={previousClick}
                  questionIndex={questionIndex}
                />
              </div>
            </div>
          ))}
        </Slider>

        <div className="box_arrows">
          <Button
            className="arrow lf"
            onClick={() => this.handleQuestionChange(false)}
          >
            <i className="icon-chevron-left"></i> Anterior
          </Button>
          <Button
            className="arrow rg"
            onClick={() => this.handleQuestionChange(true)}
          >
            {questionIndex != questionCount ? "Siguiente" : "Finalizar"}{" "}
            <i className="icon-chevron-right"></i>
          </Button>
        </div>
      </section>
    );
  }
}
