import React, {useEffect, useState} from 'react';
import { companyCustomizeService } from '../../../services/backoffice/companyCustomize.service';
import {Col, Form, Input, Row, ColorPicker,Button,Image} from "antd";
import {FaSave, FaTrash, FaUpload} from "react-icons/fa";
import Swal from "sweetalert2";

export function CompanyCustomize(props) {

	const [values, setValues] = useState({
		CompanyLogoFile: null,
		CompanyCustomizeId: 0,
		customizeValues: "",
		CompanyId: 0,
		CompanyLogo: "",
		PrimaryColor: "",
		SecondaryColor: "",
		NoPost:0,
		NoDaysGrace:0,
		Amount:0
	});

	const onSave = () => {

		if(!values.CompanyLogo) {
			Swal.fire({
				icon: 'error',
				title: 'Ups...',
				text: 'No hay un logotipo para el sitio',
			})
			return;
		}

		values.CustomizeValues = JSON.stringify({
			"CompanyLogoFile": values.CompanyLogoFile,
			"CompanyLogo": values.CompanyLogo,
			"PrimaryColor": values.PrimaryColor,
			"SecondaryColor": values.SecondaryColor,
			"NoPost": values.NoPost,
			"NoDaysGrace": values.NoDaysGrace,
			"Amount": values.Amount
		})
		if (values.CompanyCustomizeId > 0)
			companyCustomizeService.edit(values)
				.then((result) => {
					if (result) window.location.reload();
				});
		else
			companyCustomizeService.create(values)
				.then((result) => {
					if (result) window.location.reload();
				});
	};

	useEffect(() => {
		companyCustomizeService.getByCompanyId(props.companyId).then((result) => {
			if (result) {
				const data = JSON.parse(result.customizeValues);
				setValues({
					CompanyCustomizeId: parseInt(result.companyCustomizeId),
					CompanyId: props.companyId,
					CustomizeValues: result.customizeValues,
					CompanyLogoFile: null,
					CompanyLogo: data.CompanyLogo,
					PrimaryColor: data.PrimaryColor,
					SecondaryColor: data.SecondaryColor,
					NoPost: data.NoPost,
					NoDaysGrace: data.NoDaysGrace,
					Amount: data.Amount
				});
			} else console.log('error:'+result)
		});
	}, []);

	const handleRemoveFile = () => setValues({
		...values,
		CompanyLogoFile: null,
		CompanyLogo: ''
	});

	const maxSize = (process.env.REACT_APP_MAX_FILESIZE * 1024);
	const handleOnChangeFile = (e) => {
		const targetFile = e.target.files[0];
		if (targetFile !== null) {

			if(targetFile === undefined) return

			const sizeCurrentFile = Math.round(targetFile.size / 1024);

			if (sizeCurrentFile > maxSize) {
				Swal.fire({
					icon: 'error',
					title: 'Ups...',
					text: 'Los archivos debe ser menores a ' + process.env.REACT_APP_MAX_FILESIZE + ' MB.',
				})
				e.target.value = null;
				return;
			}

			const currentImg = URL.createObjectURL(targetFile)
			let img: HTMLImageElement = document.createElement("img"), dimensions = {};
			img.src = currentImg;
			img.onload = function () {
				dimensions = {
					width: img.width,
					height: img.height
				};
				if(dimensions.width > 450 || dimensions.height > 150) {
					Swal.fire({
						icon: 'error',
						title: 'Ups...',
						text: 'La imagen excede las dimensiones permitidas',
					})
				} else {
					setValues( {
						...values,
						CompanyLogoFile: targetFile,
						CompanyLogo: currentImg
					})
				}
			};

		}
	}

	const handleColorPrimary = (value,hex) => setValues({...values, PrimaryColor: hex.slice(1)})

	const handleColorSecondary = (value,hex) => setValues({...values, SecondaryColor: hex.slice(1)})

	const handleInput = (e) => setValues({...values, [e.target.name]: e.target.value })

	return (
		<>
			<Form
				onFinish={onSave}
			  	className="form-pride"
				layout="vertical"
				fields={[
					{
						name: ['PrimaryColor'],
						value: `#${values.PrimaryColor}`
					},
					{
						name: ['SecondaryColor'],
						value: `#${values.SecondaryColor}`
					},
					{
						name: ['NoPost'],
						value: values.NoPost
					},
					{
						name: ['NoDaysGrace'],
						value: values.NoDaysGrace
					},
					{
						name: ['Amount'],
						value: values.Amount
					},
				]}
			>
				<h3>Personalización</h3>
				<Row gutter={16}>
					<Col lg={6} md={6} sm={12} xs={12}>
						<Form.Item
							label="Color Primario"
							name="PrimaryColor"
							rules={[
								{
									required: true,
									message: '*Campo Requerido'
								}
							]}
						>
							<ColorPicker
								name="PrimaryColor"
								size="large"
								onChange={handleColorPrimary}
								format="hex"
								placement="bottom"
							/>
						</Form.Item>
					</Col>
					<Col lg={6} md={6} sm={12} xs={12}>
						<Form.Item
							label="Color Secundario"
							name="SecondaryColor"
							rules={[
								{
									required: true,
									message: '*Campo Requerido'
								}
							]}
						>
							<ColorPicker
								name="SecondaryColor"
								size="large"
								format="hex"
								placement="bottom"
								onChange={handleColorSecondary}
							/>
						</Form.Item>
					</Col>
					<Col lg={12} md={12} sm={24} xs={24}>
						<Form.Item label="Logotipo de la empresa">
							<div className="form-item-file">
								{
									(values.CompanyLogo) ?
										<label onClick={handleRemoveFile} className="btn-trash"><FaTrash /></label>
										:
										<label htmlFor="logo" className="btn-file">Subir logo <FaUpload /></label>
								}
								<input
									id="logo"
									type="file"
									size="large"
									name="CompanyLogoFile"
									onChange={handleOnChangeFile}
								/>
								<p>Dimensiones máximas: 450x150</p>
								{
									(values.CompanyLogo) ? <Image width={200} src={values.CompanyLogo}/> : null
								}
							</div>
						</Form.Item>
					</Col>
					<Col lg={8} md={12} sm={12} xs={24}>
						<Form.Item
							label="Número de publicaciones gratis"
							name="NoPost"
							rules={[
								{
									required: true,
									message: '*Campo Requerido'
								}
							]}
						>
							<Input
								type="text"
								name="NoPost"
								size="large"
								onKeyPress={(e) => {
									e = e || window.e;
									const charCode = e.keyCode || e.which;
									const charStr = String.fromCharCode(charCode);
									if (isNaN(charStr)) e.preventDefault();
								}}
								onChange={handleInput}
							/>
						</Form.Item>
					</Col>
					<Col lg={8} md={12} sm={12} xs={24}>
						<Form.Item
							label="Número de días de gracia"
							name="NoDaysGrace"
							rules={[
								{
									required: true,
									message: '*Campo Requerido'
								}
							]}
						>
							<Input
								type="text"
								name="NoDaysGrace"
								minLength={1}
								onKeyPress={(e) => {
									e = e || window.e;
									const charCode = e.keyCode || e.which;
									const charStr = String.fromCharCode(charCode);
									if (isNaN(charStr)) e.preventDefault();
								}}
								onChange={handleInput}
								size="large"
							/>
						</Form.Item>
					</Col>
					<Col lg={8} md={12} sm={12} xs={24}>
						<Form.Item
							label="Costo de publicación emprendedores"
							name="Amount"
							rules={[
								{
									required: true,
									message: '*Campo Requerido'
								}
							]}
						>
							<Input
								type="text"
								name="Amount"
								onChange={handleInput}
								size="large"
								onKeyPress={(e) => {
									e = e || window.e;
									const charCode = e.keyCode || e.which;
									const charStr = String.fromCharCode(charCode);
									if (isNaN(charStr)) e.preventDefault();
								}}
								minLength={1}
							/>
						</Form.Item>
					</Col>
				</Row>
				<div className="flex-end">
					<Button className="btn-submit" htmlType="submit" type="primary">
						<p>Guardar</p>
						<FaSave />
					</Button>
				</div>
			</Form>
		</>
	);
}   
