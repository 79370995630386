import React, { useEffect, useState } from "react";
import { HubConnectionBuilder } from "@microsoft/signalr";
import {useHistory, useLocation} from "react-router-dom";
import {} from "react-share";
import "./footer.scss";

import { userService } from "../../services/user.service";
import { useSelector } from "react-redux";
import { backendService } from "../../services/backend.service";
import {
  isPushNotificationSupported,
  sendNotification,
  initializePushNotifications,
} from "./footer.notification";
// import {WrapperChats} from "../wrapperChats/wrapperChats";
import {ButtonToolbar, Dropdown} from "rsuite";
// import {messageService} from "../../services/message.service";

export function Footer({ MyFun, activeBtn, userProfile, windowDimensions, messages }) {

  const history = useHistory();
  const roleName = useSelector((state) => state.userSettings.roleName);
  const [notificationInfo, setNotificationInfo] = useState({
    notificationInfoData: [],
  });

  const companyLogo = useSelector((state) => state.userSettings.companyLogo);
  const [connection, setConnection] = useState(null);

  /* states for dropdowns */
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  /* end states for dropdowns */
  const logout = () => {
    var style = document.getElementsByTagName("style");
    document
      .getElementsByTagName("head")[0]
      .removeChild(style[style.length - 1]);
    userService.logout().then(() => {
      history.push("/");
    });
  };

  const miPerfil = () => history.push("profile");

  useEffect(() => {
    if (performance.navigation.type !== 1) {
      let isFirstTime = localStorage["isFirstTime"];
      if (isFirstTime === undefined) {
        localStorage["isFirstTime"] = true;
        if (isPushNotificationSupported()) {
          initializePushNotifications().then(function (dataConsent) {
            if (dataConsent === "granted") GetnotificationApi(true, false);
          });
        }
      }
    }
    GetnotificationApi(false, true);
  }, []);

  const GetnotificationApi = (ShowPush, ShowNotif) => {
    backendService.GetNotificationData().then((result) => {
      if (result) {
        if (ShowPush) {
          sendNotification(result);
        }
        if (ShowNotif) {
          setNotificationInfo({ notificationInfoData: result });
        }
      }
    });
  };

  useEffect(url => {
    const connection = new HubConnectionBuilder()
        .withUrl(`${process.env.REACT_APP_HUB_URL}/notification`, {
          headers: {NoBlocking: true},
        })
      .withAutomaticReconnect()
      .build();

    setConnection(connection);
  }, []);

  function getWidthDimensions() {
    const { innerWidth: width } = window;
    return width;
  }

  const [widthDimensions, setWidthDimensions] = useState(getWidthDimensions());

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          connection.on("beNotified", (notification) => {
            const listaEventos = [];
            listaEventos.push(notification);
            sendNotification(listaEventos);
            GetnotificationApi(false, true);
          });
        })
        .catch((error) => console.log(error));
    }
    getCurrentRoute()
    const handleResize = () => setWidthDimensions(getWidthDimensions())
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);

    if(widthDimensions >= 768) setMenuMobile(!menuMobile)
  }, [connection, widthDimensions]);

  const goHome = () => window.location.href = '/home';
  const goAdminDashboard = () => window.location.href = '/admin';

  const [menuMobile, setMenuMobile] = useState(false);

  const showMenu = () => setMenuMobile(!menuMobile)

  const [activeLink, setActiveLink] = useState('');
  const [pathname, setPathname] = useState(useLocation())

  const getCurrentRoute = () => {
    if (pathname.pathname === '/audience'
        || pathname.pathname === '/pride-music'
        || pathname.pathname === '/blog-company'
        || pathname.pathname == '/course-view'
    ) {
      setActiveLink('categoriesPride')
    }
    if (pathname.pathname === '/messages'
        || pathname.pathname == '/rewards'
        || pathname.pathname == '/profile'
        || pathname.pathname == '/diary'
        || pathname.pathname == '/surveys'
    ) {
      setActiveLink('userInfo')
    }
    if(pathname.pathname === '/interest-group'
        
        || pathname.pathname ==  '/caring-community'
        || pathname.pathname ==  '/discount'
        || pathname.pathname ==  '/directory'
        || pathname.pathname ==  '/job-bank'
        || pathname.pathname ==  '/register-job'
        || pathname.pathname ==  '/directory'
        || pathname.pathname == '/stories-pride'
    ) {
      setActiveLink('communityPride')
    }
    if(pathname.pathname === '/home') setActiveLink('home')
    if(pathname.pathname === '/admin') setActiveLink('admin')
  }

  useEffect(() => {
    getCurrentRoute()
  }, [pathname.pathname, activeLink])

  const [widgets, setWidgets] = useState({
    surveyWidget: {},
    messageWidget: {},
    rewardWidget: {},
    courseWidget: {},
    providerWidget: [{ id: 0, logoCrls: "" }],
  });
  // const [messages,setMessages] = useState([]);

  useEffect(() => {
    backendService.widgets().then((data) => {
      if (data.surveyWidget != null) {
        let date = new Date();
        date.setDate(date.getDate() + data.surveyWidget.expirationDays);
        data.surveyWidget.expirationDate = date;
      }
      setWidgets(data);
    });
    // messageService
    //     .getMessage(0, "desc", "pending")
    //     .then((data) => setMessages(data));
  }, []);

  return (
    <footer className="dashboard-footer">
      {/* {
        (windowDimensions <= 992) ?
            <ButtonToolbar className="dashboard-footer-user">
              <Dropdown className="button-user"
                title={
                  (userProfile && userProfile.genderIdentityFlag) ? (
                      <div className={`avatar`}>
                        <div className={`avatar-box rotating ${userProfile.genderIdentityFlag}`}></div>
                        <img src={userProfile && userProfile.urlProfileImage} alt="Kuimby" />
                        <span className="quantity">{notificationInfo?.notificationInfoData?.length}</span>
                      </div>
                  ) : (
                      <div className={`avatar`}>
                        <div className={`avatar-box rotating`}></div>
                        <img src={userProfile && userProfile.urlProfileImage} alt="Kuimby" />
                        <span className="quantity">{notificationInfo?.notificationInfoData?.length}</span>
                      </div>
                  )
                }
              >
                <Dropdown.Item href="/profile" onClick={miPerfil}>
                  <i className="icon-user"></i>
                  <p>Mi Perfil</p>
                </Dropdown.Item>
                {(windowDimensions <= 992) ?
                    <Dropdown className="dropdown-notifications" placement="leftStart" title={
                      <>
                        <span style={{background: '#6f6f6f', borderRadius: '.25rem', width: '25px', height: '20px', color: 'white', marginLeft: '.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>{notificationInfo?.notificationInfoData?.length}</span>
                        <p>Notificaciones</p>
                        <i className="icon-notifications"></i>
                      </>
                    }>
                      <Dropdown.Menu title={
                        <>
                          <div className="notifications-body">
                            <div className="notifications-title">Notificaciones</div>
                            <ul className="notifications-content">
                              {notificationInfo?.notificationInfoData?.length > 0 ? (
                                  notificationInfo.notificationInfoData.map((item, index) => (
                                      <li className="notifications-content-item" key={index}>
                                        <a href={item.link} onClick={toggle}>
                                          <span>{item.companyEventTypeNotificationMessage}</span>
                                          <p>{item.message}</p>
                                        </a>
                                      </li>
                                  ))
                              ) : (
                                  <div className="notifications-empty">
                                    <FaBellSlash />
                                    <span>No hay nuevas notificaciones</span>
                                  </div>
                              )}
                            </ul>
                          </div>
                        </>
                      }>
                      </Dropdown.Menu>
                    </Dropdown>
                    : ''
                }
                <Dropdown.Item href="/logout" onClick={logout}>
                  <i><FaSignOutAlt /></i>
                  <p> Cerrar sesión</p>
                </Dropdown.Item>
              </Dropdown>
            </ButtonToolbar>
            :
            ''
      } */}
      {
        (windowDimensions <= 992) ?
            <div className="dashboard-footer-menu">
              <ul>
                <li>
                  <ButtonToolbar onClick={goHome} className={`btn-menu dashboard ${activeLink === 'home' ? 'dashboardLink' : ''}`}>
                    <i className="icon-home"></i>
                  </ButtonToolbar>
                </li>
                <li>
                  <ButtonToolbar className="btn-menu user">
                    <Dropdown className={`btn-menu user ${activeLink === 'userInfo' ? 'userLink' : ''}`} trigger={['click']}
                              title={
                              <>
                                <i className="icon-profile"></i>
                                {
                                  // widgets.surveyWidget || widgets.rewardWidget || messages.length ?
                                  messages.unreadCount ?
                                      <div className="circle-notification"></div> : ''
                                }
                              </>}
                    >
                      <Dropdown.Item className="user" href="/profile">
                        <div className="sublinks">
                          <i className="icon-user"></i>
                          <p> Mi Perfil</p>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item href="/messages" className="user">
                        <div className="sublinks">
                          <span><i className="icon-envelope"></i></span>
                          <p>Mensajes
                            {
                              messages ?
                                  <span className="notification">{messages.unreadCount}</span>
                                  : ''
                            }
                          </p>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item href="/diary" className="user">
                        <div className="sublinks">
                          <span><i className="icon-competences"></i></span>
                          <p>Calendario</p>
                        </div>
                      </Dropdown.Item>
                      {/* <Dropdown.Item href="/rewards" className="user">
                        <div className="sublinks">
                          <span><i className="icon-gif"></i></span>
                          <p>Rewards
                            {
                              widgets.rewardWidget ?
                                  <span className="notification">{widgets.rewardWidget.quantity}</span>
                                  : ''
                            }
                          </p>
                        </div>
                      </Dropdown.Item> */}
                      <Dropdown.Item href="/surveys" className="user">
                        <div className="sublinks">
                          <span><i className="icon-opinion"></i></span>
                          <p>Tu Opinión
                            {
                              widgets.surveyWidget ?
                                  <span className="notification">{widgets.surveyWidget.quantity}</span>
                                  : ''
                            }
                          </p>
                        </div>
                      </Dropdown.Item>
                    </Dropdown> 
                  </ButtonToolbar>
                </li>
                <li>
                  <ButtonToolbar className="btn-menu categories">
                    <Dropdown className={`btn-menu categories ${activeLink === 'categoriesPride' ? 'categoriesLink' : ''}`}
                              title={
                              <>
                                <i className='icon-categories'></i>
                                {
                                  widgets.courseWidget ?
                                      <div className="circle-notification"></div> : ''
                                }
                              </>
                    }
                              trigger={['click']}
                    >
                      <Dropdown.Item href="/audience" className="categories">
                        <div className="sublinks">
                          <i className="icon-champion"></i>
                          <p>Mis rutinas</p>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item href="/blog-company" className="categories">
                        <div className="sublinks">
                          <i className="icon-man"></i>
                          <p>Preguntas frecuentes</p>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item href="/course-view" className="categories">
                    <div className="sublinks">
                      <i className="icon-fitness"></i>
                      <p>Escuela Romeh
                        {
                          widgets.courseWidget ?
                              <span className="notification">{widgets.courseWidget.quantity}</span>
                              : ''
                        }
                      </p>
                    </div>
                  </Dropdown.Item>
                      
                      {/* <Dropdown.Item href="/pride-music" className="categories">
                        <div className="sublinks">
                          <i className="icon-rewards"></i>
                          <p>Pride Music</p>
                        </div>
                      </Dropdown.Item> */}
                    </Dropdown>
                  </ButtonToolbar>
                </li>
                <li>
                  <ButtonToolbar className="btn-menu community">
                    <Dropdown className={`btn-menu community ${activeLink === 'communityPride' ? 'communityLink' : ''}`}
                              title={<i className="icon-community"></i>} trigger={['click']}
                    >
                      {/* <Dropdown.Item href="/directory" className="community">
                        <div className="sublinks">
                          <i className="icon-entrepreneurs"></i>
                          <p>Emprendedores</p>
                        </div>
                      </Dropdown.Item> */}
                      {/* <Dropdown.Item className="community" href="/interest-group">
                        <div className="sublinks">
                          <i className="icon-testimony"></i>
                          <p>Testimonios</p>
                        </div>
                      </Dropdown.Item> */}
                      <Dropdown.Item href="/stories-pride" className="community">
                    <div className="sublinks">
                      <i className="icon-champion"></i>
                      <p>Consultas</p>
                    </div>
                  </Dropdown.Item>
                      <Dropdown.Item href="/discount" className="community">
                        <div className="sublinks">
                          <i className="icon-discounts"></i>
                          <p>Beneficios</p>
                        </div>
                      </Dropdown.Item>
                      {/* <Dropdown.Item href="/ecommerce" className="community">
                    <div className="sublinks">
                      <i className="icon-store"></i>
                      <p>Tienda en Línea</p>
                    </div>
                  </Dropdown.Item> */}
                  
                      {/* <Dropdown.Item href="/caring-community" className="community">
                        <div className="sublinks">
                          <i className="icon-hand-heart"></i>
                          <p style={{fontSize: '.75rem'}}>Comunidad Solidaria</p>
                        </div>
                      </Dropdown.Item> */}
                      {/* <Dropdown.Item href="/job-bank" className="community">
                        <div className="sublinks">
                          <i className="icon-jobs"></i>
                          <p>Bolsa de trabajo</p>
                        </div>
                      </Dropdown.Item>*/}
                    </Dropdown> 
                  </ButtonToolbar>
                </li>
                {
                  {
                    "Recursos Humanos":
                        <li>
                          <ButtonToolbar
                              onClick={goAdminDashboard}
                              className={`btn-menu admin ${activeLink === 'admin' ? 'adminLink' : ''}`}
                          >
                            <i className="icon-user-admin"></i>
                          </ButtonToolbar>
                        </li>
                  }[roleName]
                }
              </ul>
            </div>
            :
            ''
      }
      {/* <div className="container-chat">
        <WrapperChats windowDimensions={windowDimensions} />
      </div> */}
      {/* <script src="/service-worker.js" /> */}
    </footer>
  );
}
