import React, { useState, useEffect } from "react";
import { HeadRewards } from "./headRewards";
import "./rewards.scss";
import { rewardsService } from "../../services/rewards.service";
import { FormGroup, Label, Input, Row, Col } from "reactstrap";
import Moment from "react-moment";
import { FooterHome } from "../../components/footer/footerHome";
import moment from "moment";
import "moment/locale/es";

export function RedemptionHistory() {
  const [rewardsFilterByDate, setRewardsFilterByDate] = useState([]);
  const [reward, setReward] = useState([]);
  const [redemptionHistory, setRedemptionHistory] = useState([]);

  useEffect(() => {
    rewardsService.rewardsFilterByDate().then((result) => {
      setRewardsFilterByDate(result);
      getRedemptionHistory(result[0].startDate + "|" + result[0].endDate);
    });
  }, []);

  const getRedemptionHistory = async (dateRange) => {
    rewardsService.redemptionHistory(dateRange).then((result) => {
      setReward(result.reward);
      setRedemptionHistory(result.items);
    });
  };

  const downloadCoupon = (orderId) => {
    rewardsService.downloadCoupon(orderId);
  };

  return (
    <section className="dashboard-container-page">
      <HeadRewards reward={reward} />

      <div className="container-redemption">
        <Row>
          <Col sm={12}>
            <h3>Historial de canje</h3>
          </Col>
          <Col sm={12}>
            <div className="container-redemption-header">
              <FormGroup>
                <Label for="exampleSelectMulti">Filtro de fechas</Label>
                <Input
                  type="select"
                  onChange={(event) => getRedemptionHistory(event.target.value)}
                >
                  {rewardsFilterByDate.map((item) => (
                    <option value={item.startDate + "|" + item.endDate}>
                      {moment(item.startDate).format("MMMM YYYY")} -{" "}
                      {moment(item.endDate).format("MMMM YYYY")}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </div>
          </Col>
          <Col sm={12}>
            <div className="wrapper_list_history">
              {redemptionHistory.map((item) => (
                <div className="list_history">
                  <div className="item_list_history">
                    <img
                      className="img_cupon"
                      src={item.productUrlImage}
                      alt="Cupon"
                    />
                  </div>
                  <div className="item_list_history">
                    <h6>{item.productTitle}</h6>
                    <h6>{item.poductDescription}</h6>
                  </div>
                  <div className="item_list_history">
                    <h6>Fecha de redención</h6>
                    <p>
                      <Moment format="DD/MM/YYYY">{item.date}</Moment>
                    </p>
                  </div>
                  <div className="item_list_history">
                    <h6>Puntos redimidos</h6>
                    <p>{item.reward}</p>
                  </div>
                  <div className="item_list_history">
                    <h6>Acción</h6>
                    {item.status == 2 ? (
                      <p>
                        <button
                          className="btn secundary btndown"
                          onClick={() => {
                            downloadCoupon(item.rewardOrderUserProfileId);
                          }}
                        >
                          <i className="fas fa-download"></i> Descargar
                        </button>
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </div>
      <div className="row">
        <div className="col-12">
          <FooterHome />
        </div>
      </div>
    </section>
  );
}
