import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/es";
import { useFrontDiary } from "../../services/diary.service";
import "./boxSchedule.scss";
import { Splide, SplideSlide } from "@splidejs/react-splide";

export function BoxSchedule() {
  const [DiaryData, setDiaryData] = useState({ Diary: [] });

  const getCompanyDiary = () => {
    useFrontDiary
      .getFrontCompanyDiaryFiltered(
        moment().format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
        null,
        false
      )
      .then((result) => {
        if (result) {
          result.forEach((d) => {
            d.startDate = new Date(d.startDate);
            d.endDate = new Date(d.endDate);
          });
          setDiaryData({ Diary: result });
        }
      });
  };

  useEffect(() => {
    getCompanyDiary();
  }, []);

  return (
    <div className="box-schedule-container schelude__slide__prdmx">
      <div className="box-schedule-container schelude__slide__prdmx">
        {DiaryData.Diary.length === 0 ? (
          ""
        ) : (
          <>
            <h3 className="ttls_in_muro">
              <Link to="/diary">Calendario</Link>
            </h3>
            <Splide
              options={{
                autoplay: true,
                arrows: true,
                perPage: 4,
                perMove: 1,
                width: "100%",
                pagination: false,
                speed: 500,
                rewind: true,
                drag: true,
                rewindByDrag: true,
                type: "slide",
                breakpoints: {
                  1550: {
                    perPage: 3,
                  },
                  1050: {
                    perPage: 2,
                  },
                  575: {
                    perPage: 1,
                  },
                },
              }}
            >
              {DiaryData.Diary.map((item, i) => (
                <SplideSlide key={i}>
                  <Link to="/diary" className="box-schedule-container-item">
                    <div className="date">
                      <p className="day">
                        {moment(new Date(item.startDate)).format("D")}
                      </p>
                      <p className="month">
                        {moment(new Date(item.startDate)).format("MMMM")}
                      </p>
                    </div>
                    <div className="info">
                      <p className="name">
                        {item.name.length <= 45
                          ? item.name
                          : item.name.substring(0, 45) + "..."}
                      </p>
                      <p className="location">
                        {item.location ? item.location : ""}
                      </p>
                    </div>
                  </Link>
                </SplideSlide>
              ))}
            </Splide>
          </>
        )}
      </div>
    </div>
  );
}
