import React, { useEffect, useState } from 'react';
import { useAnswerConfig } from '../../services/surveyAnswerConfig.service';
import { Table } from "reactstrap";
import { Button } from "rsuite";
import {Col, Space} from "antd";

export function SearchAnswer(props) {
    const [answerData, setAnswersData] = useState([]);

    const handleDelete = (data) => {
        useAnswerConfig.deleteAnswerConfig(data).then((result) => { getAnswers(); });
    };

    const handleSelectAnswer = (data) => {
        if (props.onSelectAnswer)
            props.onSelectAnswer(data);
    };

    useEffect(() => {
        if (props.surveyQuestionId != "") getAnswers();
    }, [props.refresh]);

    async function getAnswers() {
        useAnswerConfig.getAnswerConfigs(props.surveyQuestionId).then((result) => {
            if (result) {
                setAnswersData(result);
            }
        });
    }

    return (
        <Col xs={24} className="table-responsive">
            <Table className="admin-table" hover>
                <thead>
                    <tr>
                        <th>Orden</th>
                        <th>Respuesta</th>
                        <th>Estatus</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {answerData.map(answer => (
                        <tr key={answer.surveyAnswerConfigId}>
                            <td>{answer.answerOrder}</td>
                            <td>{answer.answer}</td>
                            <td>{answer.estatus}</td>
                            <td>
                                <Space>
                                    {
                                        !props.isSurveyAlreadyStarted &&
                                        <Button type="button" className="btn-icon-table delete" onClick={() => handleDelete(answer)}>
                                            <i className="icon-trash"></i>
                                        </Button>
                                    }
                                    <Button type="button" className="btn-icon-table" onClick={() => handleSelectAnswer(answer)}>
                                        <i className="icon-edit"></i>
                                    </Button>
                                </Space>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Col>

    );
}
