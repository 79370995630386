import React, { useEffect, useState } from "react";
import { AsideCategoriesJobs } from "./asideCategoriesJobs";
import { ItemJobPublished } from "./itemJobPublished";
import { JobBankService } from "../../services/jobBank.service";
import { TtlsJobBank } from "./ttlsJobBank";
import "./jobBank.scss";
import {Col, Row} from "reactstrap";
import {FooterHome} from "../../components/footer/footerHome";

export function JobBank() {
  const [jobs, setJobs] = useState([]);
  const [pages, setPages] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [order, setOrder] = useState("Fecha");

  useEffect(() => {
    searchPage();
  }, []);

  const searchPage = (page = 1, order = "Fecha", occupationFilter = "", locationFilter = "") => {
    setOrder(order);
    JobBankService.getJobAll(page, 8, order, occupationFilter, locationFilter).then((result) => {
      if(!result) return;

      setJobs(result.list || []);
      setTotalCount(result.totalCount);
      setCurrentPage(page);
      setTotalPages(result.totalPages);

      let rows = [];
      for (let index = 1; index <= result.totalPages; index++) {
        rows.push(
          <li className={"page-item" + (page === index ? " active" : "")} key={index}>
            <a className="page-link" onClick={() => searchPage(index, order)}>
              {index}
            </a>
          </li>
        );
      }
      setPages(rows)
    });
  };

  const returnPreviousPage = () => {
    if (currentPage > 1) searchPage(currentPage - 1);
  };

  const advanceNextPage = () => {
    if (currentPage < totalPages) searchPage(currentPage + 1);
  };

  const searchByFilters = () => {
    let occupationFilter = document.getElementById("occupationFilter").value;
    let locationFilter = document.getElementById("locationFilter").value;

    searchPage(1, order, occupationFilter, locationFilter);
  }


  return (
    <section className="dashboard-container-page">
      <TtlsJobBank tituloInJobs="Bolsa de trabajo"  searchByFilters={searchByFilters}  />
      <AsideCategoriesJobs />
      <Row>
          <Col sm={12}>
            <div className="job-bank-options">
              <div className="counter">
                <p><span>{totalCount}</span> empleos</p>
              </div>
              <div className="filters">
                <label>Ordenar por </label>
                <select onChange={(e) => searchPage(1, e.target.value)}>
                  <option value="Fecha">Fecha</option>
                  <option value="Salario">Salario</option>
                </select>
              </div>
            </div>
          </Col>
        </Row>
      <div className="job-bank-cards">
        {
          jobs.length === 0 ?
              <div className="empty">
                <i className="icon-empty"></i>
                <p>No hay empleos disponibles</p>
              </div>
          :
              jobs.map((job, index) => (
                  <ItemJobPublished jobPublished={false} key={job.jobId} job={job} index={index} misEmpl={false} />
              ))
        }
      </div>
      {
        jobs.length === 0 ?
            ''
            :
            <div className="job-bank-pagination">
              <nav className="pagination-jobs">
                <ul className="pagination">
                  <li className="page-item">
                    <a className="page-link" onClick={returnPreviousPage} aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                      <span className="sr-only">Previous</span>
                    </a>
                  </li>
                  {pages}
                  <li className="page-item">
                    <a className="page-link" onClick={advanceNextPage} aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                      <span className="sr-only">Next</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
      }
      <Row>
        <Col sm={12}>
          <FooterHome />
        </Col>
      </Row>
    </section>
  );
}
