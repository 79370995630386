import React from 'react'
import { storageService } from '../../services/storage.service'
import { Button } from "rsuite";

export function MessageAttachment(props) {
  const handleDelete = (event, data) => {
    storageService.deleteStorage({ EntityId: data.entityId, EntityTypeId: data.entityTypeId, Sequence: data.sequence })
      .then(() => {
        props.setFiles(
          props.files.filter((storage) => storage.sequence !== data.sequence)
        )
      })
    event.stopPropagation()
  };

  const downloadFile = (e, url, fileName) => {
    storageService.download(url).then(file => {
      const aElement = document.createElement('a');
      aElement.setAttribute('download', fileName);
      const href = URL.createObjectURL(file);
      aElement.href = href;
      aElement.setAttribute('target', '_blank');
      aElement.click();
      URL.revokeObjectURL(href);
    });

    e.stopPropagation();
  };

  return (
    <>
      {props.files != null && props.files.length > 0 &&
        <span className="files">
          {props.files.map(item => (
            <div key={item.entityId + '-' + item.entityTypeId + '-' + item.sequence} className="item-file">
              <Button className="item" onClick={(e) => downloadFile(e, item.file, item.fileName)}>
                <span className="icon"><i className="icon-download-file"></i></span>
                <p>Descargar</p>
              </Button>
              {props.isDelete != null && props.isDelete &&
                <Button className="btn-delete" onClick={(event) => handleDelete(event, item)}>
                  <i className="icon-trash"></i>
                </Button>
              }
            </div>
          ))}
        </span>
      }
    </>
  );
}
