import { data } from "jquery";
import React, { useEffect, useState } from "react";

import "./registerServices.scss";
import {Button, Col, Row} from "reactstrap";
import {BsChevronLeft} from "react-icons/fa";

export function FormAdditional(props) {
  //debugger
  let {
    website,
    facebook,
    instagram,
    linkedin,
    twitter,
    tiktok
  } = props.data;

  const activeTab3 = props.activeTab3;

  const [datos, setDatos] = useState({
    sitioweb: null,
    sitiowebId: null,
    facebook: null,
    facebookId: null,
    instagram: null,
    instagramId: null,
    linkedin: null,
    linkedinId: null,
    twitter: null,
    twitterId: null,
    tiktok: null,
    tiktokId: null
  })
  const [facebookEjemplo, setFacebookEjemplo] = useState(false);
  const [instagramEjemplo, setInstagramEjemplo] = useState(false);
  const [linkedinEjemplo, setLinkedinEjemplo] = useState(false);
  const [twitterEjemplo, setTwitterEjemplo] = useState(false);
  const [tikTokEjemplo, setTikTokEjemplo] = useState(false);

  const handleInputChange = (event) => {
    let nombre = event.target.name;
    nombre += "Id";
    setDatos({
      ...datos,
      [nombre]: event.target.id
    })

    setDatos({
      ...datos,
      [event.target.name]: event.target.value
    })

    props.Datepersonal({
      ...datos,
      [nombre]: event.target.id
    }, "additional");
    props.Datepersonal({
      ...datos,
      [event.target.name]: event.target.value
    }, "additional");
  }

  useEffect(() => {
    let Data = {};
    if (props.register.personal.hasOwnProperty("facebook")) {
      let reg = props.register.personal;
      Data = {
        sitioweb: reg.website,
        facebook: reg.facebook,
        instagram: reg.instagram,
        linkedin: reg.linkedin,
        twitter: reg.twitter,
        tiktok: reg.tiktok,
      };
      setDatos(Data);
    }


    if (props.data?.directoryContacts) {
      let directoryContacts = props.data.directoryContacts;
      directoryContacts.forEach(element => {
        switch (element.contactTypeId) {
          case 3: Data.sitioweb = element.contactValue;
            Data.sitiowebId = element.directoryContactId;
            break;
          case 4: Data.linkedin = element.contactValue;
            Data.linkedinId = element.directoryContactId;
            break;
          case 5: Data.facebook = element.contactValue;
            Data.facebookId = element.directoryContactId;
            break;
          case 6: Data.twitter = element.contactValue;
            Data.twitterId = element.directoryContactId;
            break;
          case 7: Data.instagram = element.contactValue;
            Data.instagramId = element.directoryContactId;
            break;
          case 8: Data.tiktok = element.contactValue;
            Data.tiktokId = element.directoryContactId;
            break;
          default: break;
        }
      });
      setDatos(Data);
    }
  }, [props.data]);




  function enviadatos() {
    props.Datepersonal(datos, "additional");
    props.toggleView();
  }

  return (
    <>
        <Row>
          <Col sm={12}>
            <div className="flex-end">
              <Button className="btn-back" onClick={props.toBack}>
                <i className="icon-chevron-left"></i>
                <p>Volver</p>
              </Button>
            </div>
          </Col>
          <Col sm={12}>
            <h3>Información adicional</h3>
            <p className="badge-dashed green">En redes solo agregar el usuario</p>
          </Col>
          <Col lg={12}>
            <div className="form-dashboard-group">
              <label id="inputGroup-sizing-default">Sitio web</label>
              <input
                type="url" value={datos.sitioweb == null || datos.sitioweb ==undefined ? "" : datos.sitioweb}
                aria-describedby="inputGroup-sizing-default" id={datos.sitiowebId}
                name="sitioweb" onChange={handleInputChange}
              />
            </div>
          </Col>
          <Col sm={12}>
            <div className="form-dashboard-group">
                <label id="inputGroup-sizing-default">Facebook</label>
                <input
                  type="url"
                  value={datos.facebook == null || undefined? "" : datos.facebook}
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  id={datos.facebookId}
                  name="facebook" onChange={handleInputChange}
                  onFocus={() => setFacebookEjemplo(true)}
                  onBlur={() => setFacebookEjemplo(false)}
                />
                {
                  facebookEjemplo &&
                  <p className="badge-dashed green">
                    {"https://www.facebook.com/"}
                    <b>{datos.facebook}</b>
                  </p>
                }
            </div>
          </Col>
          <Col sm={12}>
            <div className="form-dashboard-group">
                <label id="inputGroup-sizing-default">Instagram</label>
                <input
                  type="url"
                  value={datos.instagram == null || datos.instagram === undefined ? "" : datos.instagram}
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  id={datos.instagramId}
                  name="instagram" onChange={handleInputChange}
                  onFocus={() => setInstagramEjemplo(true)}
                  onBlur={() => setInstagramEjemplo(false)}
                />
            </div>
            {instagramEjemplo && <p className="badge-dashed green">{"https://www.instagram.com/"}<b>{datos.instagram}</b></p>}
          </Col>
          <Col sm={12}>
            <div className="form-dashboard-group">
              <label id="inputGroup-sizing-default">Linkedin</label>
              <input
                type="url"
                value={datos.linkedin == null || datos.linkedin == undefined ? "" : datos.linkedin}
                aria-label="Default"
                aria-describedby="inputGroup-sizing-default"
                id={datos.linkedinId}
                name="linkedin" onChange={handleInputChange}
                onFocus={() => setLinkedinEjemplo(true)}
                onBlur={() => setLinkedinEjemplo(false)}
              />
            </div>
            {linkedinEjemplo && <p className="badge-dashed green">{"https://www.linkedin.com/in/"}<b>{datos.linkedin}</b></p>}
          </Col>
          <Col sm={12}>
            <div className="form-dashboard-group">
              <label id="inputGroup-sizing-default">Twitter</label>
              <input
                type="url"
                value={datos.twitter == null || datos.twitter == undefined ? "" : datos.twitter}
                aria-describedby="inputGroup-sizing-default"
                id={datos.twitterId}
                name="twitter" onChange={handleInputChange}
                onFocus={() => setTwitterEjemplo(true)}
                onBlur={() => setTwitterEjemplo(false)}
              />
            </div>
            {twitterEjemplo && <p className="badge-dashed green">{"https://twitter.com/"}<b>{datos.twitter}</b></p>}
          </Col>
          <Col sm={12}>
            <div className="form-dashboard-group">
              <label id="inputGroup-sizing-default" >TikTok</label>
              <input
                type="url"
                value={datos.tiktok == null || datos.tiktok == undefined ? "" : datos.tiktok}
                aria-label="Default"
                aria-describedby="inputGroup-sizing-default"
                id={datos.tiktokId}
                name="tiktok" onChange={handleInputChange}
                onFocus={() => setTikTokEjemplo(true)}
                onBlur={() => setTikTokEjemplo(false)}
              />
            </div>
            {tikTokEjemplo && <p className="badge-dashed green">{"https://www.tiktok.com/@"}<b>{datos.tiktok}</b></p>}
          </Col>
          <Col sm={12}>
           <div className="flex-end">
             <Button type="button" className="btn-gradients-green" onClick={enviadatos}>
               <p>{activeTab3 === false ? "Guardar" : "Siguiente"}</p>
               <div className="circle"><i className={`${activeTab3 === false ? 'icon-save' : 'icon-chevron-right'}`}></i></div>
             </Button>
           </div>
          </Col>
        </Row>
    </>
  );
}
