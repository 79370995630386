import React, { Component } from "react";
import Slider from "react-slick";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useQuestion } from "../../services/surveyQuestion.service";
import { useSurvey } from "../../services/survey.service";
import { SurveyChart } from "./surveyChart";
import { SurveyAnswerList } from "./surveyAnswerList";
import { Link } from 'react-router-dom';
 

import "./surveyResult.scss";
import {Col, Divider, Row, Space} from "antd";
import {Button} from "rsuite";
import {FaChevronLeft, FaPlus} from "react-icons/fa";
import {FooterHome} from "../../components/footer/footerHome";

export class SurveyResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      surveyInfo: [],
      questions: [],
      questionIndex: 1,
      questionCount: 0,
      questionIndexSave: -1,
      chartGrouping: 1,
    };
  }

  componentWillMount() {
    this.getSurveyInfo(this.props.match.params.id);
  }
  componentWillReceiveProps(nextProps) {
      if (this.props !== nextProps) {
          this.getSurveyInfo(nextProps.match.params.id);
      }
  }

  handleToggleGroupChange = (event, value) => {
    this.setState( {chartGrouping: value} );
  };

  getSurveyInfo = async (surveyId) => {
    useSurvey.getSurveysInfoById(surveyId).then((result) => {
      if (result) {
        this.setState({
          surveyInfo: result,
          questionCount: result.questionCount,
          questions: result.questionList,
          questionIndex: 1,
        });
      }
    });
  };

  setQuestionIndexSave = (value) => {
      this.setState({ questionIndexSave: value })
  }

  handleQuestionChange = (isNext) => {
    if (!isNext) 
        this.slider.slickPrev();
    else 
        this.slider.slickNext();

    this.setState({chartGrouping: 1})
     this.setQuestionIndexSave(isNext ? this.state.questionIndex - 1 : -1)
  };

  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      beforeChange: (current, next) =>
        this.setState({ questionIndex: next + 1 }),
      adaptiveHeight: true,
      swipe: false,
    };

    const { questions, questionIndex, questionCount, questionIndexSave, surveyInfo, chartGrouping
    } = this.state;
    return (
      <>
        <section className="dashboard-container-page">
          <div className="admin-subtitle">
            <div className="">
              <h2>Resultado de la encuesta</h2>
              <h6><i className="fas fa-chart-line"></i> &nbsp;{surveyInfo.title}</h6>
            </div>
            <Link className="btn-back" to={"/admin/details-survey/" + this.props.match.params.id}>
              <FaChevronLeft />
              <p>Regresar</p>
            </Link>
          </div>
          <div className="admin-heading">
            <div></div>
            <Link className="btn-dark" to={"/admin/details-survey/" + this.props.match.params.id}>
              <p>Agregar encuesta</p>
              <FaPlus />
            </Link>
          </div>
          
          
          <div className="content_profile" >
            <div className="box_main_info_profile">
              <div className="box_details_account card">
                <Divider><Link className="text-decoration-none"> Pregunta <span>{questionIndex} </span>/ <span>{questionCount}</span></Link></Divider>
                <div className="drawer_acount_details">
                  <div className="section_surveys">
                    <Space className="d-flex-between" size="middle">
                      <Button className="btn-dark arrow lf" onClick={() => this.handleQuestionChange(false)}>
                        <i className="fas fa-angle-left mr-2"></i>
                        <p>Anterior</p>
                      </Button>
                      <Button className="btn-dark arrow rg" onClick={() => this.handleQuestionChange(true)}>
                        <p>Siguiente</p>
                        <i className="fas fa-angle-right ml-2"></i>
                      </Button>
                    </Space>
                    <Slider className="my-3" ref={(c) => (this.slider = c)} {...settings}>
                            {questions.map((question, index) => (
                                    <div className="w-100">
                                        <div className="outline"  >
                                        {parseInt(question.surveyAnswerTypeId) === 2 ?
                                          <div>
                                              <ToggleButtonGroup color="primary" value={this.state.chartGrouping} exclusive onChange={this.handleToggleGroupChange} >
                                                  <ToggleButton value="1">General</ToggleButton>
                                                  <ToggleButton value="2">Por Género</ToggleButton>
                                                  <ToggleButton value="3">Por Edad</ToggleButton>
                                                  <ToggleButton value="4">Por Segmento</ToggleButton>
                                              </ToggleButtonGroup>
                                            </div>
                                            :  <h5 className="mb-0 text fnt_medium">Pregunta de respuesta abierta</h5>
                                            }
                                            <div className="answers">
                                              {question.surveyAnswerTypeId == 2 ? 
                                                <SurveyChart surveyId = {surveyInfo.surveyId} surveyQuestionId={question.surveyQuestionId} chartGrouping={chartGrouping} questionTitle = {question.question} anwerTypeId = {question.surveyAnswerTypeId} ></SurveyChart>
                                                : 
                                                <SurveyAnswerList questionId= {question.surveyQuestionId}> </SurveyAnswerList>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ))}     
                        </Slider>
                        <Space className="d-flex-between" size="middle">
                          <Button className="btn-dark arrow lf" onClick={() => this.handleQuestionChange(false)}>
                            <i className="fas fa-angle-left mr-2"></i>
                            <p>Anterior</p>
                          </Button>
                          <Button className="btn-dark arrow rg" onClick={() => this.handleQuestionChange(true)}>
                            <p>Siguiente</p>
                            <i className="fas fa-angle-right ml-2"></i>
                          </Button>
                        </Space>
                  </div>


                </div>
              </div>
            </div>
          </div>

          <Row>
            <Col xs={24}>
              <FooterHome />
            </Col>
          </Row>
          
        </section>
      </>
    );
  }
}
