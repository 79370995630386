import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { productFilterService } from '../../services/backoffice/rewardProductFilter.service';
import { useDispatch } from 'react-redux';
import swal from "sweetalert"
import {Table} from "reactstrap";
import {Button} from "rsuite";

export function SearchProductFilter(props) {
    const [rewardProductFilterData, setRewardProductFilterData] = useState({ rewardProductFilter: [] });
    const handleDelete = (data) => {
        swal({
            title: "¿Estás seguro que deseas borrar esta recompensa?",
            icon: "warning",
            dangerMode: true,
            buttons: ["Cancelar", "Si, aceptar"]
        })
            .then((willDelete) => {
                if (willDelete) {
                    productFilterService.deleteProductFilter(data).then((result) => {  getRewardProductFilters(); });
                }
            });
    };

    useEffect(() => {
        getRewardProductFilters();
    }, [props.refresh]);

    async function getRewardProductFilters() {
        productFilterService.getAll().then((result) => {
            if (result) {
                setRewardProductFilterData({ rewardProductFilter: result });
            }
        });
    }

    const goToDetailProduct = (id) => {
        window.location.href= '/admin/detail-product-filter/'+id;
    }

    return (
        <div className="table-responsive card shadows p-3">
            <Table className="admin-table" hover>
                <thead>
                    <tr>
                        <th>ID </th>
                        <th>Producto </th>
                        <th>Sucursal </th>
                        <th>Grupo </th>
                        <th>Categoría </th>
                        <th>Stock </th>
                        <th>Puntos</th>
                        <th>Activo</th>
                    </tr>
                </thead>
                <tbody>
                    {rewardProductFilterData.rewardProductFilter.map(pf => (
                        <tr key={pf.rewardProductFilterId}>
                            <td>
                                <div className="d-flex-center">
                                    <Button className="btn-link d-flex-center" onClick={() => goToDetailProduct(pf.rewardProductFilterId)}>{pf.rewardProductFilterId}</Button>
                                </div>
                            </td>
                            <td>
                                <div className="d-flex-center">
                                    <Button className="btn-link d-flex-center" onClick={() => goToDetailProduct(pf.rewardProductFilterId)}>{pf.productDescription}</Button>
                                </div>
                            </td>
                            <td>
                                <div className="d-flex-center">
                                    {pf.branchOfficeName == null ? "<Todas>" : pf.branchOfficeName}
                                </div>
                            </td>
                            <td>
                                <div className="d-flex-center">
                                    {pf.departmentName == null ? "<Todos>" : pf.departmentName}
                                </div>
                            </td>
                            <td>
                                <div className="d-flex-center">
                                    {pf.roleName == null ? "<Todos>" : pf.roleName}
                                </div>
                            </td>
                            <td>
                                <div className="d-flex-center">
                                    {pf.filterStock}
                                </div>
                            </td>
                            <td>
                                <div className="d-flex-center">
                                    {pf.points}
                                </div>
                            </td>
                            <td>
                                <div className="d-flex-center">
                                    {pf.active ? "Activo" : "Inactivo"}
                                </div>
                            </td>
                            <td>
                                <div className="d-flex-center">
                                    <Button type="button" className="btn-icon-table delete" onClick={() => handleDelete(pf.rewardProductFilterId)}>
                                        <i className="icon-trash"></i>
                                    </Button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
}
