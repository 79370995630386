import React from "react";

export const BoxSocialNetworks = () => {
  return (
    <div className="comp_social_networks">
      <a
        target="_blank"
        className=""
        href="https://www.facebook.com/RomehRodrigo/"
      >
        <i className="fa fa-facebook-f"></i>
      </a>

      <a
        target="_blank"
        className=""
        href="https://instagram.com/Rodrigo_Romeh"
      >
        <i className="fa fa-instagram"></i>
      </a>

      <a
        target="_blank"
        className=""
        href="https://twitter.com/Rodrigo_Romeh"
      >
        <i className="fa fa-twitter"></i>
      </a>

      {/* <a
        target="_blank"
        className=""
        href="https://www.youtube.com/@pridemx"
      >
        <i className="fab fa-youtube"></i>
      </a>

      <a
        target="_blank"
        className=""
        href="https://www.linkedin.com/company/82353363"
      >
        <i className="fab fa-linkedin-in"></i>
      </a>

      <a
        target="_blank"
        className=""
        href="https://www.tiktok.com/@somos_pridemx"
      >
        <i className="fab fa-tiktok"></i>
      </a> */}
    </div>
  );
};
