import React, { useState } from 'react';
import { SearchReward } from './searchReward';

import './rewardAdmin.scss'
import { Link } from 'react-router-dom';
import {Col} from "reactstrap";
import {Row} from "rsuite";
import {FaPlus} from "react-icons/fa";

export function AdminReward() {
    const [refresh, setRefresh] = useState(0);

    return (
        <>
            <div className="admin-subtitle">
                <div>
                    <h2>Productos</h2>
                    <h6>Operaciones</h6>
                </div>
                <div>
                    <Link className="btn-dark" to={"/operator/details-reward/0"}>
                        <p>Agregar recompensa</p>
                        <FaPlus />
                    </Link>
                </div>
            </div>
            <Row>
                <Col sm={12}>
                    <SearchReward refresh={refresh} />
                </Col>
            </Row>
        </>
    );
}
