import React, { useEffect, useState } from 'react';
import useInputForm from '../../components/CustonHooks/FormHook'
import { useQuestion } from '../../services/surveyQuestion.service'
import { SelectCustom } from '../../components/Helpers/SelectCustom'
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { Button } from "rsuite";
import { Link } from 'react-router-dom/cjs/react-router-dom';
import swal from 'sweetalert';
import { BiPlus } from "react-icons/bi";
import { FaChevronLeft, FaChevronRight, FaPlus, FaSave, FaTimesCircle } from 'react-icons/fa';


export function EditQuestion(props) {
	const onSave = () => {
		if (!inputs.SurveyAnswerTypeId || inputs.SurveyAnswerTypeId == 0) {
			return swal({ text: "Seleccione un tipo de respuesta.", icon: "error" });
		}

		if (inputs.SurveyAnswerTypeId == "3") {
			if (!inputs.SurveyScaleId || inputs.SurveyScaleId == 0) {
				return swal({ text: "Seleccione un tipo de escala.", icon: "error" });
			}
		}

		if (inputs.SurveyQuestionId && inputs.SurveyQuestionId > 0)
			useQuestion.updateQuestion({ SurveyQuestionId: parseInt(inputs.SurveyQuestionId), SurveyId: parseInt(inputs.SurveyId), SurveyAnswerTypeId: parseInt(inputs.SurveyAnswerTypeId), Question: inputs.Question, SurveyScaleId: inputs.SurveyScaleId ? parseInt(inputs.SurveyScaleId) : 0 }).then((result) => {
				if (props.onSave)
					props.onSave();
			});
		else
			useQuestion.createQuestion({ SurveyId: parseInt(inputs.SurveyId), SurveyAnswerTypeId: parseInt(inputs.SurveyAnswerTypeId), Question: inputs.Question, SurveyScaleId: inputs.SurveyScaleId ? parseInt(inputs.SurveyScaleId) : 0 }).then((result) => {
				if (props.onSave)
					props.onSave();
			});
	};
	const handleOnCancel = () => {
		if (props.onCancelEdit)
			props.onCancelEdit();
	};
	const [question, setQuestion] = useState({ SurveyQuestionId: "", SurveyId: props.surveyId, SurveyAnswerTypeId: "", Question: "", SurveyScaleId: "" });
	const [refreshAnswers, setRefreshAnswers] = useState(0);
	const [showEditAnswer, setShowEditAnswer] = useState(false);
	const [selectedAnswer, setSelectedAnswer] = useState();
	const { handleSubmit, handleInputChange, inputs } = useInputForm(onSave, question);

	useEffect(() => {
		let newP = {};
		if (props.currentQuestion) {
			newP = { SurveyQuestionId: props.currentQuestion.SurveyQuestionId.toString(), SurveyId: props.currentQuestion.SurveyId.toString(), SurveyAnswerTypeId: props.currentQuestion.SurveyAnswerTypeId.toString(), Question: props.currentQuestion.Question, SurveyScaleId: props.currentQuestion.SurveyScaleId.toString() };
			setQuestion(newP);
		}
		else
			setQuestion({ SurveyQuestionId: "", SurveyId: props.surveyId, SurveyAnswerTypeId: "", Question: "", SurveyScaleId: "" });
		setRefreshAnswers(prev => prev + 1);
		setSelectedAnswer(null);
		setShowEditAnswer(false);
	}, [props.currentQuestion]);

	return (
		<>
			<form onSubmit={handleSubmit} className="form-dashboard">
				<Row>
					<Col sm={12}>
						<div className="admin-heading">
							<div></div>
							<Button className="btn-back" type="button" onClick={handleOnCancel}>
								<FaTimesCircle />
								<p>Cancelar</p>
							</Button>
						</div>
					</Col>
					<input type="hidden" name="SurveyQuestionId" onChange={handleInputChange} value={inputs.SurveyQuestionId} />
					<input type="hidden" name="SurveyId" onChange={handleInputChange} value={inputs.SurveyId} />
					<Col sm={12}>
						<FormGroup className="form-dashboard-group">
							<Label for="">Pregunta</Label>
							<Input type="text" name="Question" onChange={handleInputChange} value={inputs.Question} required />
						</FormGroup>
					</Col>
					<Col sm={12}>
						<FormGroup className="form-dashboard-group">
							<Label for="">Tipo de respuesta</Label>
							<SelectCustom disabled={props.isSurveyAlreadyStarted} method="SurveyAnswerType" textField="description" valueField="surveyAnswerTypeId" selectName="SurveyAnswerTypeId" handleOnChange={handleInputChange} selectValue={inputs.SurveyAnswerTypeId} />
						</FormGroup>
					</Col>
					{inputs.SurveyAnswerTypeId == "3" &&
						<Col sm={12}>
							<FormGroup className="form-dashboard-group">
								<Label for="">Tipo de escala</Label>
								<SelectCustom disabled={props.isSurveyAlreadyStarted} method="SurveyScale" textField="description" valueField="surveyScaleId" selectName="SurveyScaleId" handleOnChange={handleInputChange} selectValue={inputs.SurveyScaleId} />
							</FormGroup>
							<div className='flex-end'>
							<Link to={`/admin/AdminSurveyScale/${props.surveyId}`} className='btn-dark'>
								<p>Agregar tipo de escala</p>
								<FaPlus />
							</Link>
							</div>
						</Col>
					}
				</Row>
				<div className="form-dashboard-footer">
					<Button className="btn-dark" type="submit">
						<p>Guardar</p>
						<FaSave />
					</Button>
				</div>
			</form>
		</>

	);
}   
