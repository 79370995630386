import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { companyPostService } from '../../services/companyPost.service';
import Moment from 'react-moment'
import {Table} from "reactstrap";
import {Button} from "rsuite";
import { FaEdit } from 'react-icons/fa';
import { Space } from 'antd';

const SearchGalleries = forwardRef((props, ref) => {

    const [dataGrid, setDataGrid] = useState([]);
    const handleDelete = (event, data) => {
        companyPostService.deletet(data).then((result) => { getAll(); });
        event.stopPropagation();
    };

    async function getAll() {
        companyPostService.getAll(1)
            .then((result) => {
                if (result) {
                    setDataGrid(result);
                }
            });
    }

    useEffect(() => {
        getAll();
    }, []);

    const refreshDataGrid = () => {
        getAll();
    }

    useImperativeHandle(ref, () => ({
        refreshDataGrid: refreshDataGrid  
    }));

    return (
        <div className="table-responsive">
            <Table className="admin-table" hover>
                <thead>
                    <tr>
                        <th>Título de álbum</th>
                        <th>Fecha de publicación</th>
                        <th>Elementos</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {dataGrid.map(item => (
                        <tr key={item.companyPostId}>
                            <td>
                                <span className="btn-image-table" onClick={() => props.handleRowSelect(item)}>
                                    <img alt="" src={item.iconPath} style={{ width: '40px', height: '40px', marginRight: '10px', borderRadius: '50%' }} />
                                </span>
                                {item.title}
                            </td>
                            <td><Moment format="DD/MM/YYYY">{item.publishDate}</Moment></td>
                            <td>
                                <div className="d-flex-center">
                                    {item.galleryImageCount} Elemento(s)
                                </div>
                            </td>
                            <td>
                                <Space>
                                    <Button className="btn-icon-table edit" onClick={() => props.handleRowSelect(item)}>
                                        <FaEdit />
                                    </Button>
                                    {item.active ?
                                            <Button className="btn-icon-table delete" onClick={(event) => handleDelete(event, item)}>
                                                <i className="icon-trash"></i>
                                            </Button> : null
                                    }
                                </Space>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );

});

export default SearchGalleries;
