import React, { Fragment, useState } from 'react'
import { useHistory } from 'react-router-dom'
import useInputSingleForm from '../../components/CustonHooks/inputFormHook'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { GeneralUpload } from '../../components/Helpers/generalUpload'
import { messageService } from '../../services/message.service'
import { EditorMessage } from './editorMessage'
import {Button} from "rsuite";
import {set} from "react-ga";
import {FaTimesCircle} from "react-icons/fa";


export default function AnswerMessage(props) {
    const [localfiles, setLocalFiles] = useState([])
    const [preview, setPreview] = useState([])
    const history = useHistory()

  const onSave = () => {
    inputs.originMessageId = props.originMessageId
    inputs.userProfileFrom = props.messageUserFrom
    inputs.subject = props.subject
    inputs.globalMessage = false
    let usersTo = [props.messageUserTo]
    let data = { message: inputs, usersTo: usersTo }
    props.setShow(false)
    createMessage(data)
  }

  const createMessage = (data) => messageService.createMessage(data, localfiles)
    .then(() => history.push('/messages'))

  const { inputs, handleInputChange, handleSubmit } = useInputSingleForm(onSave, { messageText: '' })


    const uploadFiles = (files) => {
        let convertingFile = URL.createObjectURL(files[0]);
        setPreview(convertingFile);
        setLocalFiles(files);
    }

    const handleImageUploadRemove = () => {
        setPreview([]);
        setLocalFiles([])
    };

  return (
    <div className="messages-answer-content">
      <h4><i className="fas fa-share"></i>Responder</h4>
      <form onSubmit={handleSubmit} id="message_form">
        <EditorMessage content={inputs.messageText} name="messageText" handleInputChange={handleInputChange} /> <br />

          {
              preview.length > 0 ?
                  <div className="messages-file-container">
                      <img src={preview} alt="" className="img-file"  />
                      <Button className="btn-remove-file" onClick={handleImageUploadRemove}>
                          <i className="icon-trash"></i>
                      </Button>
                  </div>
                  :
                  <GeneralUpload TitleLabel={"Adjuntos:"} IsMultipe={true} handleOnChangeFiles={uploadFiles} />
          }
      </form>
      <div className="messages-footer">
        <Button className="btn-back" onClick={() => props.setShow(false)}>
            <FaTimesCircle />
            <p>Cancelar</p>
        </Button>
        <Button className="btn-gradients-orange" onClick={onSave}>
            <p>Enviar</p>
            <div className="circle"><i className="icon-send"></i></div>
        </Button>
      </div>
    </div>

  )
}
