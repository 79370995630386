import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Col, Row } from "reactstrap";
import { FooterHome } from "../../components/footer/footerHome";
import { SearchSurveyScale } from './searchSurveyScale';
import {FaPlus} from "react-icons/fa";

export function AdminSurveyScale(props) {
    const [refresh, setRefresh] = useState(0);
    
    const { surveyId } = useParams();

    return (
        <section className="dashboard-container-page">
            <div className="admin-subtitle">
                <div>
                    <h2>Escalas de valoración</h2>
                    <h6>Administrador</h6>
                </div>
                <Link className="btn-back" to={`/admin/details-survey/${surveyId}`} ><i className="icon-chevron-left"></i><p>Regresar</p></Link>
            </div>
            <Row>
                <Col sm={12}>
                    <div className='flex-end'>
                    <Link className="btn-dark" to={`/admin/details-survey-scale/${surveyId}/0`}><p>Agregar escala</p><FaPlus /></Link>
                    </div>
                </Col>
            </Row>
            <SearchSurveyScale refresh={refresh} />
            <Row>
                <Col sm={12}>
                    <FooterHome />
                </Col>
            </Row>
        </section>
    );
}
