import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { TtlsJobBank } from "./ttlsJobBank";
import { JobBankService } from "../../services/jobBank.service";
import Moment from "react-moment";
import "./jobBank.scss";
import Swal from "sweetalert2";
import { IoClose } from "react-icons/io5";
import { BiCheck } from "react-icons/bi";

export function ListNewJobs() {
  const [jobs, setJobs] = useState([]);
  const [pages, setPages] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    GetJobsToApprove();
  }, []);

  const GetJobsToApprove = () => {
    JobBankService.GetJobsToApprove(1, 8).then((result) => {
      if (!result) return;
      setJobs(result.list || []);
      setTotalCount(result.totalCount);
      setTotalPages(result.totalPages);
      let rows = [];
      for (let index = 1; index <= result.totalPages; index++) {
        rows.push(
          <li
            className={"page-item" + (1 === index ? " active" : "")}
            key={index}
          >
            <a className="page-link" onClick={() => searchPage(index)}>
              {index}
            </a>
          </li>
        );
      }
      setPages(rows);
    });
  };

  const deleteJob = (jobId) => {
    JobBankService.deleteJob(jobId).then((result) => GetJobsToApprove());
  };

  const authorize = (e) => {
    JobBankService.authorizeJob(e.jobId, true).then((result) => {
      Swal.fire({ text: "El trabajo ha sido aprobado", icon: "success" });
      GetJobsToApprove();
    });
  };

  const reject = (e) => {
    JobBankService.authorizeJob(e.jobId, false).then((result) => {
      Swal.fire({ text: "El trabajo ha sido rechazado", icon: "success" });
      GetJobsToApprove();
    });
  };

  const searchPage = (page) => {
    JobBankService.GetJobsToApprove(page, 8).then((result) => {
      if (!result) return;
      setCurrentPage(page);
      setJobs(result.list || []);
      setTotalCount(result.totalCount);

      let rows = [];
      for (let index = 1; index <= result.totalPages; index++) {
        rows.push(
          <li
            className={"page-item" + (page === index ? " active" : "")}
            key={index}
          >
            <a className="page-link" onClick={() => searchPage(index)}>
              {index}
            </a>
          </li>
        );
      }
      setPages(rows);
    });
  };

  const returnPreviousPage = () => {
    if (currentPage > 1) {
      searchPage(currentPage - 1);
    }
  };

  const advanceNextPage = () => {
    if (currentPage < totalPages) {
      searchPage(currentPage + 1);
    }
  };

  return (
    <section className="wrapper_jobbank padd">
      <TtlsJobBank tituloInJobs="Empleos por autorizar" />
      <Link to="/job-bank" className="btn-back maxw">
        <i className="icon-chevron-left"></i>
        <span>Ir a Bolsa de trabajo </span>
      </Link>
      <br></br>
      <div className="card_shadows pb-0">
        <div className="sec_list_new_jobs">
          <div className="table-responsive">
            <table className="wrapper_table table table-hover">
              <thead className="thead-dark">
                <tr>
                  <th>#</th>
                  <th>Título de empleo</th>
                  <th>Empresa</th>
                  <th>Categoría</th>
                  <th>Ubicación</th>
                  <th>Publicado por</th>
                  <th>Fecha de publicación</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {jobs.map((job, index) => (
                  <tr key={job.jobId}>
                    <td>{index + 1}</td>
                    <th>
                      <Link
                        to={"/details-job/" + job.jobId}
                        className="link_data"
                      >
                        {job.title}
                      </Link>
                    </th>
                    <td>{job.companyName}</td>
                    <td>{job?.jobCategory?.[0]?.name}</td>
                    <td>{job.location}</td>
                    <td>{job.publicatedBy}</td>
                    <td>
                      <Moment locale="es" format="DD/MMMM/YYYY">
                        {job.publicationDate}
                      </Moment>
                    </td>
                    <td>
                      <button
                        className="bnt__tables_ico successfull_ico"
                        onClick={() => authorize(job)}
                      >
                        <BiCheck></BiCheck>
                      </button>
                      <button
                        className="bnt__tables_ico cancel_ico "
                        onClick={() => reject(job)}
                      >
                        <IoClose></IoClose>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="mt-3">
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                <li className="page-item">
                  <a
                    className="page-link"
                    onClick={returnPreviousPage}
                    aria-label="Previous"
                  >
                    <span aria-hidden="true">&laquo;</span>
                    <span className="sr-only">Previous</span>
                  </a>
                </li>
                {pages}
                <li className="page-item">
                  <a
                    className="page-link"
                    onClick={advanceNextPage}
                    aria-label="Next"
                  >
                    <span aria-hidden="true">&raquo;</span>
                    <span className="sr-only">Next</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </section>
  );
}
