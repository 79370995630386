import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LogoCompany from "../../assets/imgs/RodrigoRomeh.png";
import { ImgCache } from "../../components/CustonHooks/imgCache"
import { userService } from "../../services/user.service";
import { useForm } from "react-hook-form";
import {Legals} from "../../components/legals/legals";
import {IoEyeOffOutline, IoEyeOutline, IoKey} from "react-icons/io5";
import {BoxSocialNetworks} from "../../components/boxSocialNetworks";
import {Button} from "rsuite";

export function ResetPassword(props) {
  const { register, handleSubmit } = useForm();
  const companyLogo = useSelector((state) => state.userSettings.companyLogo);

  const onSubmit = (data, e) => {
    userService.resetPassword(data);
  };

  const [showPass, setShowPass] = useState(false);
  const [showIco, setShowIco] = useState(false);

  const setInputPass = () => {
    setShowPass(!showPass);
    setShowIco(!showIco);
  };

  let gradients = ["animationBg1", "animationBg2", "animationBg3"];
  const colorLoaded = Math.floor(Math.random() * gradients.length);

  return (
      <section className="register-wrapper">
        <article className="register-container">
          <div className="register-container-img">
            <div className={`bg-gradients ${gradients[colorLoaded]}`}>
              <h1>¡Cambia tu contraseña!</h1>
              <Legals />
            </div>
          </div>
          <div className="register-container-form">
            <div className="register-container-form-header-recover">
              {/* <img className="logo" src={LogoCompany} alt="" /> */}
              <ImgCache src={companyLogo ? companyLogo : LogoCompany} className="trans logoLogin" alt="Kuimby Social" />
            </div>
            <div className="form-recover">
              <div className="form-title">
                <h2>Cambio de contraseña</h2>
              </div>
              <form className="form-recover-form" onSubmit={handleSubmit(onSubmit)}>
                <input
                    type="hidden"
                    id="code"
                    name="Code"
                    value={props.location.search.split("code=")[1]}
                    {...register("Code")}
                />
                <input
                    type="hidden"
                    id="email"
                    name="Email"
                    value={props.location.search.split("email=")[1].split("&")[0]}
                    {...register("Email")}
                />

                  <label form="pass" className="form-recover-form-input">
                    <span className="form-recover-form-icon">
                      <IoKey />
                    </span>
                    <input
                        id="password"
                        placeholder="********"
                        type={`${showPass ? "text" : "password"}`}
                        name="Password"
                        {...register("Password")}
                    />
                    <span className="form-recover-form-password" onClick={setInputPass}>
                      {showIco ? (
                          <IoEyeOffOutline />
                      ) : (
                          <IoEyeOutline />
                      )}
                    </span>
                  </label>
                  <label form="pass" className="form-recover-form-input">
                    <span className="form-recover-form-icon">
                      <IoKey />
                    </span>
                    <input
                        id="new_password"
                        placeholder="********"
                        type={`${showPass ? "text" : "password"}`}
                        name="ConfirmPassword"
                        {...register("ConfirmPassword")}
                    />
                    <span className="form-recover-form-password" onClick={setInputPass}>
                      {showIco ? (
                          <IoEyeOffOutline />
                      ) : (
                          <IoEyeOutline />
                      )}
                    </span>
                  </label>
                <div className="form-recover-form-submit">
                  <Button type="submit" className="btn-outline-login">
                    Guardar contraseña
                  </Button>
                  <Link to="/login" className="btn-outline-dark-login">Regresar</Link>
                </div>
              </form>
            </div>
            <div className="register-container-form-social-media">
              <BoxSocialNetworks />
              <Legals />
            </div>
          </div>
        </article>
      </section>
  );
}
