import React, { useEffect, useState } from "react";
import { userService } from "../../services/user.service";
import { useSelector } from "react-redux";

export function  Preferences(props) {
  const companyId = useSelector((state) => state.userSettings.companyId);
  const [changePreference, setChangePreference] = useState(false);
  const [userNotification, setUserNotification] = useState(false);
  const [profile, setProfileInfo] = useState(props.profile);
  const [userEmailNotification, setUserEmailNotification] = useState(false);
  const [pref, setPreferences] = useState({
    EmailNotification: false,
    Notification: false,
  });

  const handleChange = (event) => {
    debugger;
    if (event.target.id == "notification") {
      setChangePreference(!changePreference);
      setUserNotification(event.target.checked);
      setPreferences({ ...pref, Notification: event.target.checked });
      const data = {
        Preferences: event.target.checked ? JSON.stringify({...pref, Notification: event.target.checked}) : '0',
        Notification: event.target.checked,
        EmailNotification: userEmailNotification
      }
      onSubmit(data)
    }
    if (event.target.id == "emailNotification") {
      setChangePreference(!changePreference);
      setUserEmailNotification(event.target.checked);
      setPreferences({ ...pref, EmailNotification: event.target.checked });
      const data = {
        Preferences: event.target.checked ? JSON.stringify({...pref, EmailNotification: event.target.checked}) : '0',
        Notification: userNotification,
        EmailNotification: event.target.checked
      }
      onSubmit(data)
    }
  };

  const [originValues, setOriginValues] = useState({
    additionalEmail: "",
    nickname: "",
  });

  const onSubmit = (data) => {
    userService.updateStyle(data).then((result) => {
      if (result) {
        setOriginValues({
          additionalEmail: props.profile.additionalEmail ?? "",
          nickname: props.profile.nickname ? props.profile.nickname : "",
        });
      } else {
        setProfileInfo({
          ...props.profile,
          additionalEmail: originValues.additionalEmail,
          nickname: originValues.nickname,
        });
      }
    });
  };

  const load = async () => {
    if (props.profile) {
      if (props.profile && props.profile.preferences != null) {
        setPreferences({
          Preferences: props.profile.preferences,
          Notification: props.profile.notification,
          EmailNotification: props.profile.emailNotification,
        });
        setUserNotification(props.profile.notification);
        setUserEmailNotification(props.profile.emailNotification);
        } else {
          setPreferences({
            Preferences: props.profile.preferences,
            Notification: props.profile.notification,
            EmailNotification: props.profile.emailNotification,
          });
          setUserNotification(props.profile.notification);
          setUserEmailNotification(props.profile.emailNotification);
      }
    }
  }

  useEffect(() => {
    load();
  }, [props.profile]);

  return (
    <div className="preferences-container">
        <form>
          <div className="preferences-container-checkbox">
            <label htmlFor="notification" onClick={handleChange} className="notification">
              <input type="checkbox" checked={userNotification} name="Notification" id="notification"/>
              <div className="notification-slider">
                <div className="notification-knob"></div>
              </div>
            </label>
            <p>Deseo recibir notificaciones</p>
          </div>
          <div className="preferences-container-checkbox" style={{marginTop: '1rem'}}>
            <label htmlFor="emailNotification" className="notification" onClick={handleChange}>
              <input
                  type="checkbox"
                  name="EmailNotification"
                  id="emailNotification"
                  checked={userEmailNotification}
              />
              <div className="notification-slider">
                <div className="notification-knob"></div>
              </div>
            </label>
            <p>Deseo recibir correo de notificación de mensajes</p>
          </div>
        </form>
    </div>
  );
}
