import React, { useEffect, useState } from 'react';
import { useCompanyHoliday } from '../../../services/companyHoliday.service';
import {ModalBody, Modal, Table} from "reactstrap";
import {Button} from "rsuite";
import {FaPlus, FaTimes} from "react-icons/fa";
import {Space} from "antd";

export function ListNotice(props) {
    const [CompanyHolidaysData, setCompanyHolidaysData] = useState([]);
    const handleDelete = (data) => {
        useCompanyHoliday.deleteCompanyHoliday(data).then((result) => { getCompanyHolidays(); });
    };
    useEffect(() => {
        getCompanyHolidays();
    }, [props.refresh]);

    async function getCompanyHolidays() {
        useCompanyHoliday.getCompanyHolidays().then((result) => {
            if (result) {
                setCompanyHolidaysData(result);
            }
        });
    }

    const [modal, setModal] = useState(false);
    const toggle = (img) => {
        setModal(!modal);
        setImageModal(img)
    }

    const [imgModal, setImageModal] = useState('');

    return (
        <div>
            <div className="admin-subtitle">
                <h3>Lista de avisos</h3>
                <Button type="button" className="btn-dark" onClick={props.onNewClicked}>
                    <p>Agregar aviso</p>
                    <FaPlus />
                </Button>
            </div>
            <div className="table-responsive">
                <Table className="admin-table" hover>
                    <thead>
                        <tr>
                            <th>Imagen</th>
                            <th>Aviso</th>
                            <th>Fecha de evento</th>
                            <th>Desde</th>
                            <th>Hasta</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {CompanyHolidaysData.map(companyHoliday => (
                            <tr key={companyHoliday.companyHolidayId} >
                                <td>
                                    <a href="#" onClick={() => toggle(companyHoliday.imagePath)}>
                                        <img src={companyHoliday.imagePath} style={{ width: '60px' }} alt="" />
                                    </a>
                                </td>
                                <td>{companyHoliday.description}</td>
                                <td>{companyHoliday.date}</td>
                                <td>{companyHoliday.startDate}</td>
                                <td>{companyHoliday.endDate}</td>
                                <td>
                                    <Space>
                                        <Button type="button" className="btn-icon-table" onClick={() => props.onSelectCompanyHoliday(companyHoliday)} >
                                            <i className="icon-edit"></i>
                                        </Button>
                                        <Button type="button" className="btn-icon-table delete" onClick={() => handleDelete(companyHoliday)}>
                                            <i className="icon-trash"></i>
                                        </Button>
                                    </Space>
                                </td>
                            </tr>
                        ))}
                        {
                            (CompanyHolidaysData.length === 0) ?
                                <tr className="table-empty">
                                    <td colSpan="6" className="text-center"><p>No hay avisos disponibles</p></td>
                                </tr> : null
                        }
                    </tbody>
                </Table>

                <Modal isOpen={modal} toggle={toggle} centered={true} className="modal-images">
                    <ModalBody>
                        <Button className="btn-close-modal" onClick={toggle}>
                            <FaTimes />
                        </Button>
                        <img src={imgModal} width="100%" style={{maxHeight: '300px', objectFit: 'contain'}} alt="" />
                    </ModalBody>
                </Modal>

            </div>
        </div>

    )
}
