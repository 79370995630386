import React, { useState } from 'react';
import { ShowDiaryDates } from './diaryCalendar'
import { EditDiary } from './editDiary'
import './adminDiary.scss'
import {Col, Row} from "reactstrap";
import {FooterHome} from "../../../components/footer/footerHome";

export function AdminDiary() {
    const [showEdit, setShowEdit] = useState(false);
    const [selectedDiary, setSelectedDiary] = useState();
    const [refresh, setRefresh] = useState(0);

    const handleOnNewDiary = () => {
        setSelectedDiary(null);
        setShowEdit(true);
    };
    const handleOnCancelEdit = () => {
        setSelectedDiary(null);
        setShowEdit(false);
    }
    const handleOnSaveEdit = (result) => {
        setRefresh(prev => prev + 1);
        if (result) {
            setSelectedDiary(null);
            setShowEdit(false);
        }
    }
    const handleOnEventSelected = (Diary) => {
        if (Diary.diaryTypeId != 1) {
            setSelectedDiary({ 
                CompanyDiaryId: Diary.companyDiaryId, 
                Name: Diary.name, 
                DiaryTypeId: Diary.diaryTypeId, 
                Location: Diary.location,
                UrlString: Diary.urlString, 
                StartDate: Diary.startDate, 
                EndDate: Diary.endDate, 
                Repeat: Diary.repeat, 
                IsAudienceAddressed: Diary.isAudienceAddressed,
                AllDay: Diary.allDay });
            setShowEdit(true);
        }
    }
    return (
        <section className="dashboard-container-page">
            <div style={{ display: showEdit ? "initial" : "none" }}> <EditDiary currentDiary={selectedDiary} onCancelEdit={handleOnCancelEdit} onSave={handleOnSaveEdit} /> </div>
            <div style={{ display: showEdit ? "none" : "block" }}> <ShowDiaryDates onNewClicked={handleOnNewDiary} refresh={refresh} onEventSelected={handleOnEventSelected} /></div>
            <Row>
                <Col sm={12}>
                    <FooterHome />
                </Col>
            </Row>
        </section>
    )
}
