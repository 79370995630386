import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {Table, Row, Col, Input,} from "reactstrap";
import {Button, ButtonToolbar, Dropdown, Pagination} from "rsuite";
import "rsuite/dist/styles/rsuite-default.css";
import "./users.scss";
import "../admin/admin.scss";
import { userProfileService } from "../../services/backoffice/userProfile.service";
import moment from "moment";
import {FooterHome} from "../../components/footer/footerHome";
import {FaChevronLeft, FaEdit, FaUser, FaUserPlus, FaUsers} from "react-icons/fa";
import { Space } from "antd";

export function Users() {
  const [tableData, setTableData] = useState({
    list: [],
    totalPages: 1,
    totalCount: 0,
    pageIndex: 1,
    filterBy: "",
    filter: "",
    orderBy: "EmployeeNo",
    ascending: false,
  });
  const [widthS,setWidthS] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const btnSearch = useRef();
  const [emptyTable, setEmptyTable] =useState(false);
  useEffect(() => {
    getUsers(
      1,
      tableData.filterBy,
      tableData.filter,
      tableData.orderBy,
      tableData.ascending
    );
  }, []);

  const getUsers = (pageIndex, filterBy, filter, orderBy, ascending) => {
    userProfileService
      .getAll(pageIndex, 20, filterBy, filter, orderBy, ascending)
      .then((result) => {
        if (result.list.length > 0){
          setEmptyTable(false)
          setTableData({
            list: result.list,
            totalPages: result.totalPages,
            totalCount: result.totalCount,
            pageIndex,
            filterBy,
            filter,
            orderBy,
            ascending,
          });
        }else {
         setEmptyTable(true)
        }
      });
  };

  const handleSelectPage = (pageIndex) => {
    getUsers(
      pageIndex,
      tableData.filterBy,
      tableData.filter,
      tableData.orderBy,
      tableData.ascending
    );
  };

  const handleFind = () => {
    const filterBy = tableData.filter == "" ? "" : "Name";
    getUsers(
      tableData.pageIndex,
      filterBy,
      tableData.filter,
      tableData.orderBy,
      tableData.ascending
    );
  };

  const handleOrdering = (orderBy) => {
    const ascending = orderBy == tableData.orderBy ? !tableData.ascending : false;
    getUsers(
      tableData.pageIndex,
      tableData.filterBy,
      tableData.filter,
      orderBy,
      ascending
    );
  };

  const goUserDetail = (id) => window.location.href = "/admin/users-details/"+id;

  function getWidthDimensions() {
    const { innerWidth: width } = window;
    return width;
  }

  useEffect(() => {
    const handleResize = () => setWidthS(getWidthDimensions())
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <section className="dashboard-container-page">
      <div className="admin-subtitle">
          <div>
            <h2>Usuarios</h2>
            <h6>Listado</h6>
          </div>
          <Link className="btn-back" to="/admin">
            <FaChevronLeft />
            <p>Regresar</p>
          </Link>
      </div>

      <div className="admin-heading">
        <div></div>
        <ButtonToolbar>
          <Dropdown className="btn-dark-toggle" trigger={['click']} placement={`${(parseInt(widthS) < 765) ? 'bottomStart' : 'bottomEnd'}`} title={
            <>
              <FaUserPlus />
              <p>Cargar usuarios</p>
            </>
          }>
            <Dropdown.Item href="/admin/users-details/new">
              <FaUser />
              <p>Carga individual</p>
            </Dropdown.Item>
            <Dropdown.Item href="/admin/bulk-load">
              <FaUsers /> 
              <p>Carga masiva</p>
            </Dropdown.Item>
          </Dropdown>
        </ButtonToolbar>
      </div>

      <Row>
        <Col sm={12} style={{padding: '0'}}>
          <div className="admin-heading">
            <h6>Total de usuarios:{" "}
              <div className="border-pointed"><h6>{tableData.totalCount}</h6></div>
            </h6>
            <div className="form-dashboard-group-search">
              <Input type="text" ref={btnSearch} onChange={(e) => setTableData({ ...tableData, filter: e.target.value })}/>
              <Button onClick={handleFind}><i className="fas fa-search"></i></Button>
            </div>
          </div>
        </Col>
      </Row>

      <div className="table-responsive">
        <Table className="admin-table" hover>
          <thead>
            <tr>
              <th onClick={() => handleOrdering("EmployeeNo")}>
               <div className="d-flex">
                 No. Usuario
                 {tableData.orderBy == "EmployeeNo" && (
                     <i className={`fas fa-sort-${tableData.ascending ? "up" : "down"}`} style={{marginLeft: '.5rem'}}></i>
                 )}
               </div>
              </th>
              <th onClick={() => handleOrdering("Name")} style={{ width: '250px'}}>
                <div className="d-flex">
                  Nombre completo
                  {tableData.orderBy == "Name" && (
                      <i className={`fas fa-sort-${tableData.ascending ? "up" : "down"}`} style={{marginLeft: '.5rem'}}></i>
                  )}
                </div>
              </th>
              <th onClick={() => handleOrdering("Gender")}>
                <div className="d-flex">
                  Género
                  {tableData.orderBy == "Gender" && (
                      <i className={`fas fa-sort-${tableData.ascending ? "up" : "down"}`} style={{marginLeft: '.5rem'}}></i>
                  )}
                </div>
              </th>
              <th onClick={() => handleOrdering("Age")}>
                <div className="d-flex">
                  Edad
                  {tableData.orderBy == "Age" && (
                      <i className={`fas fa-sort-${tableData.ascending ? "down" : "up"}`} style={{marginLeft: '.5rem'}}></i>
                  )}
                </div>
              </th>
              <th onClick={() => handleOrdering("Seniority")}>
                <div className="d-flex">
                  Antigüedad
                  {tableData.orderBy == "Seniority" && (
                      <i className={`fas fa-sort-${tableData.ascending ? "down" : "up"}`} style={{marginLeft: '.5rem'}}></i>
                  )}
                </div>
              </th>
              <th onClick={() => handleOrdering("BranchOffice")}>
                <div className="d-flex">
                  Sucursal
                  {tableData.orderBy == "BranchOffice" && (
                      <i className={`fas fa-sort-${tableData.ascending ? "up" : "down"}`} style={{marginLeft: '.5rem'}}></i>
                  )}
                </div>
              </th>
              <th onClick={() => handleOrdering("Department")}>
                <div className="d-flex">
                  Grupo
                  {tableData.orderBy == "Department" && (
                      <i className={`fas fa-sort-${tableData.ascending ? "up" : "down"}`} style={{marginLeft: '.5rem'}}></i>
                  )}
                </div>
              </th>
              <th onClick={() => handleOrdering("JobRole")}>
                <div className="d-flex">
                  Categoría
                  {tableData.orderBy == "JobRole" && (
                      <i className={`fas fa-sort-${tableData.ascending ? "up" : "down"}`} style={{marginLeft: '.5rem'}}></i>
                  )}
                </div>
              </th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody>{emptyTable ?
        <tr>
          <td colSpan={9}>
            <div className="d-flex-center">
              <p>No se encontraron registros</p>
            </div>
          </td>
        </tr>  
         :
         <>
            {tableData.list.map((user, id) => (
              <tr key={id}>
                <td>{user.employeeNo}</td>
                <td style={{ width: '250px', whiteSpace: 'nowrap' }} title={user.name}><span>{user.name}</span></td>
                <td>{user.gender}</td>
                <td>{moment().diff(user.birthday, "years")}</td>
                <td>{user.seniority}</td>
                <td>{user.branchOfficeName}</td>
                <td>{user.departmentName}</td>
                <td>{user.jobRoleName}</td>
                <td>
                  <Space>
                    <Button onClick={() => goUserDetail(user.aspNetUserId)} className="btn-icon-table edit">
                      <FaEdit />
                    </Button>
                  </Space>
                </td>
              </tr>
            ))}
            </>
            }
          </tbody>
        </Table>
        <div className="admin-table-footer">
          <Pagination
              prev
              last
              next
              first
              size="lg"
              ellipsis
              maxButtons={10}
              boundaryLinks
              pages={tableData.totalPages}
              activePage={tableData.pageIndex}
              onSelect={handleSelectPage}
          />
        </div>
      </div>
      <Row>
        <Col sm={12}>
          <FooterHome />
        </Col>
      </Row>
    </section>
  );
}
