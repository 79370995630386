import React, {useRef, useState} from "react";
import Cards from "../../assets/imgs/red-pay.png";
import visaLogo from "../../assets/imgs/visa_logo.png";
import masterCardLogo from "../../assets/imgs/mastercard_logo.png";
import amexLogo from "../../assets/imgs/amex_logo.png";
import BtnRP from "../../assets/imgs/red-pay-logo.png";
import { paymentService } from "../../services/payment.service";
// import { metaPixelService } from "../../services/metaPixel.service";

import "./registerServices.scss";
import {Button, Col, Row} from "reactstrap";
import {BsChevronLeft} from "react-icons/fa";

export function FormCheckoutPay(props) {
  const [datos, setDatos] = useState({});
  const [cardTypeName, setcardTypeName] = useState("");
  const [cvvMaxLenght, setcvvMaxLenght] = useState(3);
  const [cardImage, setCardImage] = useState(Cards);
  const [cvvPlaceholder, setCvvPlaceholder] = useState("xxx");

  const card_american = /^(?:3[47][0-9]{13})$/;
  const card_visa = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
  const card_master =
    /^5[1-5]\d{14}$|^2(?:2(?:2[1-9]|[3-9]\d)|[3-6]\d\d|7(?:[01]\d|20))\d{12}$/;

  const handleInputChange = (event) => {
    
    if (event.target.name === "cardNumber"){
      let definedCardType = fnGetCardTypeReq(event.target.value)
      setcardTypeName(definedCardType);
    }
    setDatos({
      ...datos,
      [event.target.name]: event.target.value,
    });
    props.Datepersonal(
      {
        ...datos,
        [event.target.name]: event.target.value,
      },
      "pay"
    );
  };

  function fnGetCardTypeReq(cardNumber) {
    const card_american = /^(?:3[47][0-9]{13})$/;
    const card_visa = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
    const card_master = /^5[1-5]\d{14}$|^2(?:2(?:2[1-9]|[3-9]\d)|[3-6]\d\d|7(?:[01]\d|20))\d{12}$/;
    let cardType = "";
    if (cardNumber.toString().match(card_visa)) {
      cardType = "VISA";
      setcvvMaxLenght(3);
      setCardImage(visaLogo);
      setCvvPlaceholder("xxx");
    } else if (cardNumber.toString().match(card_master)) {
      cardType = "MASTERCARD";
      setcvvMaxLenght(3);
      setCardImage(masterCardLogo);
      setCvvPlaceholder("xxx");
    } else if (cardNumber.toString().match(card_american)) {
      cardType = "AMERICAN EXPRESS";
      setcvvMaxLenght(4);
      setCardImage(amexLogo);
      setCvvPlaceholder("xxxx");
    }
    else {
      cardType = "UNDEFINED";
      setcvvMaxLenght(3);
      setCardImage(Cards);
      setCvvPlaceholder("xxx");
    }
    return cardType;
  }

  function getCardType(cardNumber) {
    const card_american = /^(?:3[47][0-9]{13})$/;
    const card_visa = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
    const card_master =/^5[1-5]\d{14}$|^2(?:2(?:2[1-9]|[3-9]\d)|[3-6]\d\d|7(?:[01]\d|20))\d{12}$/;
    let cardType = "";
    if (cardNumber.match(card_visa)) {
      cardType = "001";
    } else if (cardNumber.match(card_master)) {
      cardType = "002";
    } else if (cardNumber.match(card_american)) {
      cardType = "003";
    }
    return cardType;
  }

  function enviadatos() {
    //debugger;
    // var pixelData = {content_category: 'Directorio', currency: 'MXN', value: 10.0}
    // metaPixelService.trackEvent('track','AddPaymentInfo', pixelData)
    props.Datepersonal(datos, "pay");
    datos.cardType = getCardType(datos.cardNumber);
    paymentService
      .sendRedPayCardToken(datos, props.data.directoryInfoId)
      .then((result) => {
      });
    props.toggleView();
  }

    const name = useRef('');
    const lastname = useRef('');
    const cardNumber = useRef(0);
    const month = useRef(0);
    const year = useRef(0);
    const cvv = useRef(0);

    return (
    <form onSubmit={enviadatos} className="form-register-services form-checkout">
      <>
        <div className="flex-end">
            <Button className="btn-back" onClick={props.toBack}>
                <i className="icon-chevron-left"></i>
                <p>Volver</p>
            </Button>
        </div>
        <div className="header">
            <h3>Datos de tarjeta</h3>
            <img src={cardImage} alt="" />
        </div>
        <div className="group">
        <div className="form-dashboard-group mr">
            <label for="FirstName">Nombre(s)</label>
                <input
                    ref={name}
                    type="text"
                    id="FirstName"
                    required={true}
                    name="firstName"
                    onChange={handleInputChange}
                />
        </div>
        <div className="form-dashboard-group">
            <label for="LastName">Apellidos</label>
            <input
                ref={lastname}
                type="text"
                id="LastName"
                required={true}
                name="LastName"
                onChange={handleInputChange}
            />
        </div>
            </div>
            <div className="group-columns">
                <div className="columns mr">
                  <div className="form-dashboard-group">
                      <label for="CardNumber">Número de tarjeta</label>
                      <input
                          ref={cardNumber}
                          type="text"
                          id="CardNumber"
                          placeholder="xxxx xxxx xxxx xxxx"
                          maxLength={16}
                          minLength={15}
                          onKeyPress={(e) => {
                              e = e || window.event;
                              const charCode = e.keyCode || e.which;
                              const charStr = String.fromCharCode(charCode);
                              if (isNaN(charStr)) e.preventDefault();
                          }}
                          required={true}
                          name="cardNumber"
                          onChange={handleInputChange}
                      />
                  </div>
                  <div className="form-dashboard-group">
                      <label for="CardExpirationMonth">Mes de expiración</label>
                      <input
                          ref={month}
                          type="number"
                          id="CardExpirationMonth"
                          placeholder="xx"
                          max={12}
                          min={1}
                          required={true}
                          name="CardExpirationMonth"
                          onChange={handleInputChange}
                      />
                  </div>
                  <div className="form-dashboard-group">
                      <label for="CardExpirationYear">Año de expiración</label>
                      <input
                          ref={year}
                          type="text"
                          id="CardExpirationYear"
                          placeholder="xxxx"
                          maxLength={4}
                          minLength={4}
                          required={true}
                          onKeyPress={(e) => {
                              e = e || window.event;
                              const charCode = e.keyCode || e.which;
                              const charStr = String.fromCharCode(charCode);
                              if (isNaN(charStr)) e.preventDefault();
                          }}
                          name="cardExpirationYear"
                          onChange={handleInputChange}
                      />
                  </div>
                  <div className="form-dashboard-group">
                      <label for="Cvv">CVV</label>
                      <input
                          ref={cvv}
                          type="text"
                          id="Cvv"
                          max={999}
                          pattern="(/[0-9]{3}$/g"
                          minLength={cvvMaxLenght}
                          maxLength={cvvMaxLenght}
                          required={true}
                          placeholder={cvvPlaceholder}
                          name="cvv"
                          onKeyPress={(e) => {
                              e = e || window.event;
                              const charCode = e.keyCode || e.which;
                              const charStr = String.fromCharCode(charCode);
                              if (isNaN(charStr)) e.preventDefault();
                          }}
                          onChange={handleInputChange}
                      />
                  </div>
                </div>
                    <div className="card-credit">
                      <label>{(name.current.value) ? name.current.value : 'Nombre'} {(lastname.current.value) ? lastname.current.value : 'Apellidos'}</label>
                      <div className="card-credit-number">
                          <p>{(cardNumber.current.value) ? cardNumber.current.value : '0000-0000-0000-0000'}</p>
                      </div>
                      <div className="card-credit-date">
                          <p>{(month.current.value) ? month.current.value : 'Mes'}/{(year.current.value) ? year.current.value : 'Año'}</p>
                          <span>{(cvv.current.value) ? cvv.current.value : 'CVV'}</span>
                      </div>
                    </div>
                    </div>
                <div>
                <div className="paragraph">
                    <div className="badge-dashed green">
                        <p>El cobro será efectuado hasta obtener la aprobación de tu servicio, una vez publicado, el cargo se realizará de forma automática.</p>
                    </div>
                </div>
            </div>
            <div>
                <div className="flex-end">
                    <Button type="submit" className="btn-gradients-green">
                        <p>Terminar Registro</p>
                        <div className="circle"><i className="icon-send"></i></div>
                    </Button>
                </div>
            </div>
            <div className="d-flex-center">
                <div className="red-pay">
                    <p>powered by </p>
                    <img src={BtnRP} alt="Métodos de pagos en Red Voucher Online, Red Pay Pasarela de pago, México" />
                </div>
            </div>
        </>
    </form>
  );
}
