import React, { useEffect, useState } from 'react';
import { useQuestion } from '../../services/surveyQuestion.service';
import { Table } from "reactstrap";
import { Button } from "rsuite";
import {Space} from "antd";

export function SearchQuestion(props) {
    const [questionsData, setQuestionsData] = useState({ questions: [] });
    const handleDelete = (data) => {
        if (props.isSurveyAlreadyStarted) {
            return;
        }

        useQuestion.deleteQuestion(data).then((result) => { getQuestions(props.survey); });
    };
    const handleSelectQuestion = (data) => {
        if (props.onSelectQuestion)
            props.onSelectQuestion(data);
    };

    useEffect(() => {
        getQuestions(props.survey);
    }, [props.refresh]);

    async function getQuestions(survey) {
        if (survey.surveyId != "")
            useQuestion.getQuestions(survey).then((result) => {
                if (result) {
                    setQuestionsData({ questions: result });
                    props.setSurveyQuestions(result);
                }
            });
    }

    return (
        <div className="table-responsive">
            <Table className="mt-5 admin-table">
                <thead>
                    <tr>
                        <th>Pregunta</th>
                        <th>Tipo de pregunta</th>
                        <th>Estatus</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {questionsData.questions.map(question => (
                        <tr key={question.surveyQuestionId}>
                            <td title={question.question}>{question.question}</td>
                            <td>{question.answerType}</td>
                            <td>{question.estatus}</td>
                            <td>
                                <Space>
                                    {
                                        !props.isSurveyAlreadyStarted &&
                                        <Button type="button" className="btn-icon-table delete" onClick={() => handleDelete(question)}>
                                            <i className="icon-trash"></i>
                                        </Button>
                                    }
                                    <Button type="button" className="btn-icon-table" onClick={() => handleSelectQuestion(question)}>
                                        <i className="icon-edit"></i>
                                    </Button>
                                </Space>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>

    );
}
