import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { adminAudienceService } from '../../services/adminAudience.service';
import Moment from 'react-moment'
import {Table} from "reactstrap";
import {Button} from "rsuite";

const SearchAdminAudience = forwardRef((props, ref) => {

    const [dataGrid, setDataGrid] = useState([]);
    const handleDelete = (event, data) => {
        adminAudienceService.deletet(data).then((result) => { getAll(); });
        event.stopPropagation();
    };

    async function getAll() {
        adminAudienceService.getAll()
            .then((result) => {
                if (result) setDataGrid(result);
            });
    }

    useEffect(() => {
        getAll();
    }, []);

    const refreshDataGrid = () => {
        getAll();
    }

    useImperativeHandle(ref, () => ({
        refreshDataGrid: refreshDataGrid
    }));

    return (
        <div className="table-responsive">
            <Table className="admin-table" hover>
                <thead>
                    <tr>
                        <th>Título del evento</th>
                        <th>Fecha de evento</th>
                        <th>Recompensa</th>
                        <th>URL</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {dataGrid.map(item => (
                        <tr key={item.auditoriumId } >
                            <td title={item.title}>
                                <span>
                                   {item.title }
                                </span>
                            </td>
                            <td>
                                <span>
                                    <Moment format="DD/MM/YYYY">{item.conferenceDate }</Moment>
                                </span>
                            </td>
                            <td>
                                <span>
                                    {item.reward }
                                </span>
                            </td>
                            <td title={item.conferenceUrl}>
                                <a href={item.conferenceUrl} target="_blank">
                                    {item.conferenceUrl}
                                </a>
                            </td>
                            <td>
                                <span className="d-flex-center">
                                    <Button type="button" className="btn-icon-table edit" onClick={() => props.handleRowSelect(item)}>
                                        <i className="icon-edit"></i>
                                    </Button>
                                    {item.active ?
                                        <Button type="button" className="btn-icon-table delete" onClick={(event) => handleDelete(event, item)}>
                                            <i className="icon-trash"></i>
                                        </Button> : null
                                    }
                                </span>
                            </td>
                        </tr>
                    ))}

                </tbody>
            </Table>
        </div>
    );

});

export default SearchAdminAudience;
