import React, { useEffect, useState } from 'react';
import useInputForm from '../../components/CustonHooks/FormHook'
import { rewardCouponService } from '../../services/control/rewardCoupon.service';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {Col, FormGroup, Label, Row} from "reactstrap";
import {FaSave, FaTimesCircle} from "react-icons/fa";

export function BulkLoadReward(props) {

    const onSave = () => {
        // debugger
        inputs.rewardProductId = parseInt(inputs.rewardProductId);
        inputs.companyId = parseInt(inputs.companyId);
        rewardCouponService.create(inputs);
    };
    const [reward, setReward] = useState({
        rewardProductId: props.match.params.id,
        companyId: "",
        startDate: "",
        endDate: "",
        couponData: null
    });

    useEffect(() => {
        rewardCouponService.getCatalogs()
            .then((result) => {
                setCatalogs(result);
            });

    }, [props.reward]);
    const { handleSubmit, handleInputChange, handleInputFileChange, inputs, setInputs } = useInputForm(onSave, reward);
    const [catalogs, setCatalogs] = useState({ rewardProduct: [], company: [] });


    
    return (
        <div className="dashboard-container-page">
            <form onSubmit={handleSubmit} className="form-dashboard">
                <fieldset >
                    <h3>Carga de folios</h3>
                    <Row>
                        <Col lg={4} md={6} sm={12}>
                            <FormGroup className="form-dashboard-group">
                                <Label for="couponData">Seleccionar archivo</Label>
                                <input type="file" name="couponData" className="form-control" accept='.txt, .csv' onChange={handleInputFileChange} required/>
                            </FormGroup>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                            <FormGroup className="form-dashboard-group">
                                <Label htmlFor="rewardProductId">Beneficio</Label>
                                <select disabled className="form-control" name="rewardProductId" onChange={handleInputChange} selectvalue={inputs.rewardProductId} value={inputs.rewardProductId} >
                                    <option value="">Selecciona una opción</option>
                                    {catalogs.rewardProduct.map(item => (
                                        <option key={item.value} value={item.value}>
                                            {item.label}
                                        </option>
                                    ))}
                                </select>
                                {/*<input type="text" id="rewardProductId" name="rewardProductId" className="form-control" onChange={handleInputChange} value={inputs.rewardProductId} readOnly="readonly" />*/}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4} md={6} sm={12}>
                            <div className="form-group">
                                <label for="StartDate">Vigencia desde</label>
                                <input className="form-control"
                                    type="date"
                                    id="startDate"
                                    name="startDate"
                                    onChange={handleInputChange}
                                    value={moment(inputs.startDate).format('YYYY-MM-DD')}
                                    required />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4} md={6} sm={12}>
                            <FormGroup className="form-dashboard-group">
                                <label for="EndDate">Vigencia hasta</label>
                                <input
                                    className="form-control"
                                    type="date"
                                    id="endDate"
                                    name="endDate"
                                    onChange={handleInputChange}
                                    value={moment(inputs.endDate).format('YYYY-MM-DD')}
                                    required />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <FormGroup className="form-dashboard-group">
                            <Label for="companyId">Empresa</Label>
                            <select className="form-control" name="companyId" onChange={handleInputChange} selectvalue={inputs.companyId} value={inputs.companyId}>
                                <option value="">Selecciona una opción</option>
                                {catalogs.company.map(item => (
                                    <option key={item.value} value={item.value}>
                                        {item.label}
                                    </option>
                                ))}
                            </select>
                        </FormGroup>
                    </Row>
                    <hr />
                    <div className="d-flex-between">
                        <Link className="btn-back" to="/operator/controlreward/1" >
                            <FaTimesCircle />
                            <p>Cancelar</p>
                        </Link>
                        <button className="btn-dark" type="submit">
                            <p>Guardar</p>
                            <FaSave />
                        </button>
                    </div>
                </fieldset>
            </form>
        </div>
    );
}   
