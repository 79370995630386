import React, { Fragment, useEffect, useState } from 'react';
import { SelectUsers } from "../../components/Helpers/selectUsers";
import { messagePrivilegeService } from '../../services/messagePrivilige.service'
import {Col, FormGroup, Input, Row, Table} from "reactstrap";
import {Button} from "rsuite";
import {FaPlus, FaTrash} from "react-icons/fa";

export default function ConfigurePrivileges() {
    const [userSelec, setUserSelec] = useState([]);//USUARIOS QUE VIENEN DEL DROPDOWN
    const [userNoPrivilege, setUserNoPrivilege] = useState([]);//ARRAY PARA REMOVER PRIVILEGIOS A USUARIOS
    const [userPrivilige, setUserPrivilige] = useState([])//USUARIOS CON PRIVILEGIOS

    useEffect(() => {
        messagePrivilegeService.getAllUserPrivileges().then((data) => {
            setUserPrivilige(data);
        })
    }, [])

    const addPrivileges = () => {
        let body = [];
        userSelec.forEach(element => {
            body.push({ id: element.id })
        });
        messagePrivilegeService.addMessagePrivileges(body).then(() => { updateUserList() })
    }

    const removePrivileges = () => {
        userNoPrivilege.forEach(element => {
            messagePrivilegeService.deleteMessagePrivileges(element.userProfileId).then(() => {
                updateUserList()
            })
        });
        setUserNoPrivilege([])
    }

    const handleSelectUser = (event, data) => {
        data.check = event.target.checked;
        if (data.check) {
            setUserNoPrivilege([...userNoPrivilege, { userProfileId: data.id }])
        } else {
            let result = userNoPrivilege.filter((item) => item.userProfileId != data.id);
            setUserNoPrivilege(result);
        }
        event.stopPropagation()
    }

    const updateUserList = () => {
        messagePrivilegeService.getAllUserPrivileges().then((data) => {
            setUserPrivilige(data);
        })
    }

    return (
        <>
            <Row>
                <Col sm={12}>
                    <FormGroup className="form-dashboard-group">
                        <SelectUsers selectUser={setUserSelec} title="Administrador:" usersFilter={userPrivilige} filter={true} />
                    </FormGroup>
                </Col>
                <Col sm={12}>
                    <label>Privilegios</label>
                </Col>
                <Col sm={12}>
                    <div className="d-flex-start">
                        <Button className="btn-dark" onClick={addPrivileges}>
                            <p>Agregar</p>
                            <FaPlus />
                        </Button>
                        <Button className="btn-dark" style={{marginLeft: '1rem'}} onClick={() => removePrivileges()}>
                            <p>Eliminar</p>
                            <FaTrash />
                        </Button>
                    </div>
                </Col>
            </Row>

            {/* Tabla de usuarios habilitados para enviar mensajes */}
            <div className="table-responsive">
                <Table className="messages-table-config" hover>
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Permisos</th>
                        </tr>
                    </thead>
                    <tbody>
                    {userPrivilige &&
                        <>
                            {userPrivilige.map((user, index) =>
                                <tr key={index}>
                                    <td>
                                        <div className="d-flex-start">
                                            <div className="avatar-container" style={{width: 'unset'}}>
                                                <div className="avatar">
                                                    <img src={user.urlProfileImage} alt="Avatar usuario" />
                                                </div>
                                            </div>
                                            <p>{user.name}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <FormGroup className="d-flex-start">
                                            <label className="toggler-wrapper dark" htmlFor={`checkboxAll${index}`}>
                                                <Input type="checkbox" id={`checkboxAll${index}`} onClick={(event) => handleSelectUser(event, user)} checked={user.check}></Input>
                                                <div className="toggler-slider">
                                                    <div className="toggler-knob"></div>
                                                </div>
                                            </label>
                                        </FormGroup>
                                    </td>
                                </tr>
                            )}
                        </>
                    }
                    </tbody>
                </Table>
            </div>
            {/* Aside con la informacion del usuario seleccionado */}
        </>
    )
}
