import React, {useState} from 'react'
import {Dropdown, DropdownMenu, DropdownToggle} from "reactstrap";
import {FaSort} from "react-icons/fa";

export function DropDownDate(props) {
  let { title, setFilter } = { ...props }

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle><FaSort /> Ordenar: <span>{title}</span></DropdownToggle>
      <DropdownMenu>
          <button onClick={() => setFilter({ title: 'Los más recientes', value: 'desc' })} >Los más recientes</button>
          <button onClick={() => setFilter({ title: 'Los más antiguos', value: 'asc' })} >Los más antiguos</button>
      </DropdownMenu>
    </Dropdown>
  )
}

export function DropDownStatus(props) {
    let { title, setFilter } = { ...props }

    const [dropdownOpenOrder, setDropdownOpenOrder] = useState(false);
    const toggleOrder = () => setDropdownOpenOrder((prevState) => !prevState);

  return (
      <Dropdown isOpen={dropdownOpenOrder} toggle={toggleOrder}>
      <DropdownToggle> <FaSort /> Mostrar: <span>{title}</span></DropdownToggle>
      <DropdownMenu>
          <button onClick={() => setFilter({ title: 'Todos', value: '' })} >Todos</button>
          <button onClick={() => setFilter({ title: 'Leidos', value: 'read' })} >Leidos</button>
          <button onClick={() => setFilter({ title: 'No leidos', value: 'pending' })} >No leidos</button>
      </DropdownMenu>
    </Dropdown>
  )
}
