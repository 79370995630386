import React, { useState, useEffect } from "react";
import { Row, Col, FormGroup, Label } from "reactstrap";
import { userProfileService } from "../../services/backoffice/userProfile.service";
import moment from "moment";
import { useForm } from "react-hook-form";
import "./users.scss";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "rsuite";
import swal from "sweetalert";
import {FaChevronLeft, FaChevronRight, FaSave} from "react-icons/fa";

export function UserDetails(props) {
  const companyId = useSelector((state) => state.userSettings.companyId);
  const [catalogs, setCatalogs] = useState({
    aspNetRolesCatalog: [],
    branchOfficeCatalog: [],
    departmentCatalog: [],
    jobRoleCatalog: [],
  });
  const [isNew, setIsNew] = useState(false);
  const [IsConfirmed, setIsConfirmed] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const { register, reset, handleSubmit } = useForm();

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    userProfileService.getCatalogs().then((result) => {
      setCatalogs(result);
      setIsNew(props.match.params.id == "new");
      if (!isNew) {
        getById(props.match.params.id);
      }
    });
  }, [props.match.params.id]);

  const getById = (id) => {
    userProfileService.getById(id).then((result) => {
      result.userId = result.aspNetUserId;
      result.birthday = moment(result.birthday).format("YYYY-MM-DD");
      result.hiringDate = moment(result.hiringDate).format("YYYY-MM-DD");
      setIsConfirmed(result.emailConfirmed);
      reset(result);
    });
  };

  const fnEmailConfirm = () => {
    let userId = props.match.params.id;
    userProfileService.VerifyAccount(userId).then((result) => {
      if (result) {
        setIsConfirmed(result.succeded);
      }
    });
  };

  const isPasswordValid = (pass) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=!])(?!.*\s)(?!.*([A-Za-z0-9])\1{2})[A-Za-z\d@#$%^&+=!]{8,}$/;
    return passwordRegex.test(pass);
  }

  const userNumberIsValid = (userNumber) => {
    const userNumberRegex = /[$&+,:;=?@#|'<>.^*()%!-]/;
    return !userNumberRegex.test(userNumber);
  }

  const onSubmit = (data, e) => {
    if (data.employeeNo.length > 20) {
      return swal({ text: 'El No. de empleado no puede superar los 20 carácteres.', icon: "error" });
    }

    if (data.phonenumber) {
      if (data.phonenumber.length > 10) {
        return swal({ text: 'Tel. de oficina inválido, el número sólo puede contener 10 carácteres.', icon: "error" });
      }
    }

    if (data.mobile.length > 10) {
      return swal({ text: 'Tel. Personal inválido, el número sólo puede contener 10 carácteres.', icon: "error" });
    }

    if (!userNumberIsValid(data.employeeNo)) {
      return swal({ text: 'El No. de empleado no puede contener carácteres especiales.', icon: "error" });
    }

    if (isNew && !isPasswordValid(data.password)) {
      return swal({
        text: `
      - La contraseña debe tener un largo mínimo de 8 caracteres.
      - La contraseña debe contener al menos un símbolo.
      - La contraseña debe incluir al menos un dígito ('0'-'9').
      - La contraseña debe incluir al menos una letra minúscula ('a'-'z').
      - La contraseña debe incluir al menos una letra MAYÚSCULA ('A'-'Z').
      - La contraseña debe incluir al menos un carácter especial.
      `, icon: "error"
      });
    }

    data.active = isNew ? true : data.active;
    data.companyId = companyId;
    data.notification = data.notification == "true";
    data.branchOfficeId = parseInt(data.branchOfficeId);
    data.departmentId = parseInt(data.departmentId);
    data.jobRoleId = parseInt(data.jobRoleId);
    data.IsCorporateUser = true;

    if (isNew) userProfileService.create(data);
    else userProfileService.edit(data);
  };

  return (
    <section className="dashboard-container-page">
      <div className="admin-subtitle">
        <div>
          <h2>Usuarios</h2>
          <h6>{isNew ? "Usuario nuevo" : "Detalle del usuario"}</h6>
        </div>
        <Link className="btn-back" to="/admin/users">
          <FaChevronLeft />
          <p>Regresar</p>
        </Link>
      </div>

      <div className="admin-heading">
        <h3>Datos de la cuenta</h3>
      </div>

      <div className="card-tabs">
        <form onSubmit={handleSubmit(onSubmit)} className="form-dashboard">
          <input type="hidden" {...register("userId")} />
          <input type="hidden" {...register("preferences")} />
          <input type="hidden" {...register("nickname")} />
          <input type="hidden" {...register("notification")} />
          <input type="hidden" {...register("urlProfileImage")} />

          <Row>
            <Col lg={3} md={6} sm={12}>
              <FormGroup style={{ marginTop: '1.5rem' }} className="form-dashboard-group">
                <Label for="roleId">Usuario</Label>
                <label className={`toggler-wrapper dark ${isNew ? 'disabled' : ''}`} htmlFor="active">
                  <input type="checkbox" id="active" checked={isNew} disabled={isNew}
                    {...register("active")}
                  />
                  <div className="toggler-slider">
                    <div className="toggler-knob"></div>
                  </div>
                  <p>Activo</p>
                </label>
              </FormGroup>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <FormGroup className="form-dashboard-group">
                <Label for="roleId">Perfil</Label>
                <select{...register("roleId")}>
                  {catalogs.aspNetRolesCatalog.map((item) => (
                    <option key={item.value} value={item.value}>{" "}{item.label}</option>
                  ))}
                </select>
              </FormGroup>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <FormGroup className="form-dashboard-group">
                <label htmlFor="email">Correo electrónico</label>
                <input
                  required
                  type="email"
                  className="form-control"
                  aria-describedby="emailHelp"
                  {...register("email")}
                />
              </FormGroup>
            </Col>
            {isNew ? (
              <Col lg={3} md={6} sm={12}>
                <FormGroup className="form-dashboard-group">
                  <label for="exampleInputPassword1">Password</label>
                  <input
                    required
                    type="password"
                    className="form-control"
                    {...register("password")}
                  />
                </FormGroup>
              </Col>
            ) : (
              ""
            )}
          </Row>
          <h3>Datos de usuario</h3>
          <Row>
            <Col lg={3} md={6} sm={12}>
              <FormGroup className="form-dashboard-group">
                <Label for="">No. Usuario</Label>
                <input required type="text"{...register("employeeNo")} />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={3} md={6} sm={12}>
              <FormGroup className="form-dashboard-group">
                <Label for="">Nombre(s)</Label>
                <input required type="text"{...register("firstName")} />
              </FormGroup>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <FormGroup className="form-dashboard-group">
                <Label for="">Apellido paterno</Label>
                <input required type="text" {...register("middleName")} />
              </FormGroup>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <FormGroup className="form-dashboard-group">
                <Label for="">Apellido materno</Label>
                <input required type="text" {...register("lastName")} />
              </FormGroup>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <FormGroup className="form-dashboard-group">
                <Label for="gender">Género</Label>
                <select{...register("gender")}>
                  <option value="M">Masculino</option>
                  <option value="F">Femenino</option>
                </select>
              </FormGroup>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <FormGroup className="form-dashboard-group">
                <Label for="birthday">Fecha de nacimiento</Label>
                <input required type="date" min="1900-01-01" max={moment(new Date()).format("YYYY-MM-DD")}{...register("birthday")} />
              </FormGroup>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <FormGroup className="form-dashboard-group">
                <Label for="">Tel. Personal</Label>
                <input required type="number"{...register("mobile")} />
              </FormGroup>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <FormGroup className="form-dashboard-group">
                <Label for="">Tel. Oficina</Label>
                <input type="number"{...register("phoneNumber")} />
              </FormGroup>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <FormGroup className="form-dashboard-group">
                <Label for="">Ext.</Label>
                <input type="number"{...register("extension")} />
              </FormGroup>
            </Col>
            <Col md={3} sm={12}>
              <FormGroup className="form-dashboard-group">
                <Label for="hiringDate">Fecha de ingreso</Label>
                <input required type="date"{...register("hiringDate")} />
              </FormGroup>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <FormGroup className="form-dashboard-group">
                <Label for="additionalEmail">Correo adicional</Label>
                <input type="text"{...register("additionalEmail")} />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={3} md={6} sm={12}>
              <FormGroup className="form-dashboard-group">
                <Label for="branchOfficeId">Sucursal</Label>
                <select{...register("branchOfficeId")}>
                  {catalogs.branchOfficeCatalog.map((item) => (
                    <option key={item.value} value={item.value}>
                      {" "}
                      {item.label}
                    </option>
                  ))}
                </select>
              </FormGroup>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <FormGroup className="form-dashboard-group">
                <Label for="departmentId">Grupo</Label>
                <select{...register("departmentId")}>
                  {catalogs.departmentCatalog.map((item) => (
                    <option key={item.value} value={item.value}>
                      {" "}
                      {item.label}
                    </option>
                  ))}
                </select>
              </FormGroup>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <FormGroup className="form-dashboard-group">
                <Label for="jobRoleId">Categoría</Label>
                <select{...register("jobRoleId")}>
                  {catalogs.jobRoleCatalog.map((item) => (
                    <option key={item.value} value={item.value}>{" "}{item.label}</option>
                  ))}
                </select>
              </FormGroup>
            </Col>
          </Row>
          {IsConfirmed ?
            <div className="info">
              <div className="border-pointed">
                <p >Cuenta confirmada</p>
              </div>
            </div>
            :
            !isNew &&
            <Row>
              <div className="form-dashboard-footer">
                <Button type="button" color="violet" appearance="primary" className="btn-dark" onClick={() => fnEmailConfirm()}>
                  <p>Confirmar Cuenta</p>
                  <FaChevronRight />
                </Button>
              </div>
            </Row>
          }

          <div className="form-dashboard-footer">
            <Button type="submit" className="btn-dark">
              <p>Guardar</p>
              <FaSave />
            </Button>
          </div>
        </form>
      </div>
    </section>
  );
}
