import React, { useState, useEffect } from "react";
import {Button, Collapse} from "reactstrap";
import {BiChevronDown, BiChevronRight} from "react-icons/bi";

export function ItemMorePost({ filter, getPost, year, months}) {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const [counterSelected, setCounterSelected] = useState(0);
    const [yearsCounting, setYearCounting] = useState([]);

    useEffect(() => {
        const anio = new Date().getFullYear();
        if (anio === parseInt(year)) setIsOpen(true);
        countingPostsByYear()
    }, []);

    const formattingDate = (date,index) => {
        getPost(new Date(date));
        setCounterSelected(index);
    }

    const countingPostsByYear = () => {
        let counter = 0;
        months.map((month, i) => (
            counter += month.count
        ))
        setYearCounting(counter)
    }

  return (
    <div key={year}>
      <Button onClick={toggle} className="btn-publication-white">
          <p>{filter.year} <span>{yearsCounting}</span></p>
          <BiChevronDown />
      </Button>
      <Collapse className={`box_collapse`} isOpen={isOpen}>
        <ul>
          {filter.months.map((item, i) => (
            <li key={i+i+i+i}>
                <Button className={`btn-publication-white ${(i === counterSelected) ? 'active' : ''}`} onClick={() => formattingDate(item.filterMonthDate, i)}>
                    <p>{item.month}
                        {months.map((month, index) => {
                            return (
                                (month.month === item.month) ? <span key={index}>{month.count}</span> : ''
                            )
                        })}
                    </p>
                    <BiChevronRight />
                </Button>
            </li>
          ))}
        </ul>
      </Collapse>
    </div>
  );
}
