import React, {useEffect} from "react";
import { Link } from "react-router-dom";
// import { metaPixelService } from "../../services/metaPixel.service";

import "./registerServices.scss";
import {FaUser} from "react-icons/fa";

export function MessageFinish() {
  // useEffect(() => {
  //   var pixelData = {currency: 'MXN', value: 10.0}
  //   metaPixelService.trackEvent('track','Subscribe', pixelData)
  // }, []);
  return (
    <div className="message-finish">
        <h2>¡Ya estás cerca!</h2>
        <p>
            Consulta el estatus de tu anuncio en <b>Mi perfil/Servicios publicados</b>
            <br></br>
            Visualiza tus servicios publicados en <b>Emprendedores</b>
        </p>
        <div className="message-finish-footer">
          <Link to="/directory" className="btn-back">
              <i className="icon-chevron-left"></i>
              <p>Ir a Emprendedores</p>
          </Link>
          <Link to="/profile" className="btn-dark">
            <p>Mi Perfil</p>
            <FaUser />
          </Link>
        </div>
    </div>
  );
}
