import React, { useEffect, useState, useRef } from "react";
import useInputSingleForm from "../../components/CustonHooks/inputFormHook";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { GeneralUpload } from "../../components/Helpers/generalUpload";
import { SelectUsers } from "../../components/Helpers/selectUsers";
import { messageService } from "../../services/message.service";
import { EditorMessage } from "./editorMessage";
import { MessageAttachment } from "./messageAttachment";
import {FaArchive, FaTimesCircle} from "react-icons/fa";
import { GrDocumentVerified} from "react-icons/gr";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import {Button} from "rsuite";
import {Col, FormGroup, Input, Label, Row} from "reactstrap";

export default function ComposeNewMessage(props, {}) {
  const roleName = useSelector((state) => state.userSettings.roleName);
  const [message] = useState({ subject: "", messageText: "", globalMessage: false });
  const [userSelec, setUserSelec] = useState([]);
  const [localfiles, setLocalFiles] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const buttonRef = useRef(null);

  useEffect(() => {
    if (props.draft.messageId) {
      setInputs({
        subject: props.draft.subject,
        messageText: props.draft.messageText,
      });
      messageService.getMessageById(props.draft.messageId).then((data) => {
        setAttachments(data.files);
      });
    } else {
      setInputs({ subject: "", messageText: "" });
    }
  }, [props.draft]);

  useEffect(() => {  
      setInputs({
        ... inputs,
        globalMessage: false, 
      }
  )}, []);

  const onSave = (mode) => {
    if (mode === "draft"){
      draftMessage(mode);
      }
    else sendMessage();
  };

  const draftMessage = (mode) => {
    inputs.isDraft = true;
    if (props.draft.messageId) {
      inputs.messageId = props.draft.messageId;
      updateMessage();
    } else {
      swal({
        text: "Este mensaje se guardara sin destinatarios",
        icon: "info",
      }).then((value) => {
        if (value) {
          setUserSelec([]);
          createMessage(localfiles, mode);
        }
      });
    }
  };

  const sendMessage = () => {
    if (userSelec.length < 1)
      return swal({ text: "Debes elegir al menos un usuario", icon: "error" });

    if (inputs.subject === "")
      return swal({ text: "El mensaje debe tener un asunto", icon: "error" });

    if (userSelec.length > 0 && inputs.subject !== "") {
      buttonRef.current.disabled = true;
      let files = localfiles;
      if (attachments) {
        attachments.forEach((attachments) => {
          let blob = b64toBlob(attachments.file);
          blob.name = attachments.fileName;
          files.push(blob);
        });
      }
      createMessage(files);
      setLocalFiles([]);
    }
  };

  const b64toBlob = (
    b64Data,
    contentType = "application/octet-stream",
    sliceSize = 512
  ) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const createMessage = (files, mode) => {
    let usersTo = [];
    if (mode !== "draft") {
      if (inputs.globalMessage == true) 
        { usersTo.push(1)} else 
      {userSelec.forEach((user) => usersTo.push(user.id))}
    }
    
      let data = { message: inputs, usersTo: usersTo };
    messageService.createMessage(data, files).then((data) => {
      buttonRef.current.disabled = false;
      if (data != false) {
        props.setDraft({});
        goMessages();
      }
    });
  };

  const updateMessage = () =>
    messageService.updateDraftMessage(inputs, localfiles).then(() => {
      props.setDraft({});
      goMessages();
    });

  const goMessages = () => props.setIsActive(1);
  const { inputs, handleInputChange, handleSubmit, setInputs } =
    useInputSingleForm(onSave, message);

  const handleGlobalMessage = (event) => {
    if (inputs.globalMessage) {
      setInputs({... inputs, globalMessage: false})  
    }
    else {
      setInputs({... inputs, globalMessage: true})
      setUserSelec([1]);
    }
  };

  const handleImageUpload = (files) => {
    if (files.length > 0)
      setLocalFiles(files);
    else setLocalFiles({});
  };


  const handleImageUploadRemove = () => {
    setLocalFiles([]);
  };
  
  return (  
    <>
        <form onSubmit={handleSubmit} id="message_form" className="form-dashboard">
          <Row>
          {roleName == "Recursos Humanos" && (
            <>
            <Col md={6} sm={9} xs={9} style={{paddingLeft: '0', paddingRight: '0', display: 'flex'}}>
              <label className="toggler-wrapper style-14">
                <input type="checkbox" name="globalMessage" id="globalM" onChange={handleGlobalMessage} value={inputs.globalMessage} required />
                <div className="toggler-slider">
                  <div className="toggler-knob"></div>
                </div>
              </label>
              <Label className="ms-2" for="globalM">Mensaje global</Label>
            </Col>
              <Col md={6} sm={12}  style={{paddingLeft: '0', paddingRight: '0', display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                <Button className="btn-dark" onClick={() => onSave("draft")}>
                  <FaArchive />
                  <p>Borrador</p>
                </Button>
              </Col>
              </>
            )}
          {!inputs.globalMessage ? 
            <Col sm={12} style={{marginTop: '1rem', background: 'rgba(0,0,0,.05)', borderRadius: '.5rem', padding: '1rem'}}>
              <SelectUsers selectUser={setUserSelec} />
            </Col>
            : null}
            <Col lg={5} md={6} sm={12} style={{ paddingLeft: '0', paddingRight: '0', marginTop: '1rem'}}>
              <FormGroup className="form-dashboard-group">
                <Label>Asunto: </Label>
                <Input type="text" name="subject" onChange={handleInputChange} value={inputs.subject} required/>
              </FormGroup>
            </Col>
            <Col lg={7} md={6} sm={12} style={{paddingRight: '0', marginTop: '1rem'}}>
                <div className="messages-file">
                  {
                    localfiles.length > 0  ? 
                    (
                      localfiles.map((file, index) => ( 
                        <div key={index} className="messages-file-container">
                          <GrDocumentVerified/>
                           {/* <img key={index} src={file.url} alt="" className="img-file"  /> */}
                        <Button key={index} className="btn-remove-file" onClick={handleImageUploadRemove}>
                          <i className="icon-trash"></i>
                        </Button>
                      </div>
                      )
                    )
                    

                  ) :
                      <GeneralUpload TitleLabel={"Adjuntos:"} IsMultipe={true} handleOnChangeFiles={setLocalFiles}/>
                  }
                </div>
            </Col>
            <Col lg={6} md={12} sm={12} style={{paddingLeft: '0', paddingRight: '0'}}>
              <div className="messages-info-attachment">
              <span className="files">
                {props.draft.messageId && (
                  <MessageAttachment files={attachments} setFiles={setAttachments} isDelete={true}/>
                )}
              </span>
              </div>
            </Col>
          </Row>
          <EditorMessage content={inputs.messageText} name="messageText" handleInputChange={handleInputChange}/>
          <Row>
            <Col sm={12} style={{ paddingLeft: '0', paddingRight: '0', marginTop: '1rem'}}>
              <div className="message-footer">
                <Button className="btn-back" onClick={() => props.setIsActive(props.draft.messageId ? 4 : 1)}>
                  {props.draft.messageId ?
                      <i className="icon-chevron-left"></i>
                      :
                      <FaTimesCircle />
                  }
                  <p>{props.draft.messageId ? "Regresar" : "Cancelar"}</p>
                </Button>
                <Button className="btn-gradients-orange" ref={buttonRef} onClick={() => onSave("send")}>
                  <p>Enviar</p>
                  <div className="circle"><i className="icon-send"></i></div>
                </Button>
              </div>
            </Col>
          </Row>
        </form>
    </>
  );
};

