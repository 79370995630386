import React from "react";
import "./successfulReg.scss";
import { Legals } from "../../components/legals/legals";
import { BoxSocialNetworks } from "../../components/boxSocialNetworks";
import {Aside} from "../register/aside";
import {NoticeMessage} from "../register/noticeMessage";
import {ImgCache} from "../../components/CustonHooks/imgCache";

export function SuccessfulReg() {
  return (
    <section className="register-wrapper">
      <article className="register-container">
        <Aside message="¡Gracias por unirte!" />
        <div className="register-container-form">
          <div className="register-container-form-header">
            <ImgCache />
          </div>
          <NoticeMessage title="Registro Exitoso"
                         subtitle="¡Ya eres parte de nuestra comunidad!"
                         message="Te enviamos un link de confirmación a tu correo electrónico, por favor verifícalo para iniciar sesión"
                          backLink="login"
                         success={1}
          />
          <div className="register-container-form-social-media">
            <BoxSocialNetworks />
            <Legals />
          </div>
        </div>
      </article>
    </section>
  );
}
