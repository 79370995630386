import React, { useRef, useState, useEffect } from 'react';
import {Col, Form, FormGroup, Label, Input, Row} from "reactstrap";
import useInputSingleForm from '../../../components/CustonHooks/inputFormHook';
import SearchBranchOffice from './searchBranchOffice';
import { branchOfficeService } from '../../../services/branchOffice.service';
import '../../admin/admin.scss';
import { useSelector } from 'react-redux';
import {Button} from "rsuite";
import {FaPlus, FaSave, FaTimesCircle} from "react-icons/fa";

export function BranchOffice() {

    const gridRef = useRef();
    const [showEdit, setShowEdit] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const handleCheckboxChange = (isOnRegister) => {
		if(isOnRegister){
			setIsChecked(false)
			setInputs({...inputs, IsOnRegister : false})
		}else{
			setIsChecked(true)
			setInputs({...inputs, IsOnRegister : true})
		}

	};
    let initBranchOff = { BranchOfficeId: 0, BranchOfficeName: '' }
    const companyId = useSelector(state => state.userSettings.companyId)

    const onSave = () => {

        let model = { BranchOfficeId: parseInt(inputs.BranchOfficeId),
            BranchOfficeName: inputs.BranchOfficeName, 
            IsOnRegister: isChecked,
            CompanyId: companyId};

        if (parseInt(inputs.BranchOfficeId) === 0) {
            branchOfficeService.create(model).then((result) => {
                gridRef.current.refreshDataGrid();
                
            });
        } else {
            branchOfficeService.update(model).then((result) => {
                gridRef.current.refreshDataGrid();
            });
        }
        setInputs({});
        setShowEdit(false);
    }
    const { inputs, handleInputChange, handleSubmit, setInputs } = useInputSingleForm(onSave, initBranchOff);

    const handleRowSelect = (gridItem) => {
        setShowEdit(true);
        setInputs({ BranchOfficeId: gridItem.branchOfficeId, BranchOfficeName: gridItem.branchOfficeName, IsOnRegister: gridItem.isOnRegister });
    }

    const handleOnCancel = () => {
        setInputs({});
        setShowEdit(false);
    }

    const handleOnNew = () => {
        setInputs(initBranchOff);
        setShowEdit(true);
    };

    useEffect(()=>{ setIsChecked(inputs.IsOnRegister)}, [inputs]) 

    return (
        <div>
            <div className="admin-heading">
                <h3>Lista de sucursales</h3>
                {
                    showEdit ?
                        <button className="btn-back" type="button" onClick={handleOnCancel}>
                            <FaTimesCircle />
                            <p>Cancelar</p>
                        </button>
                    : null
                }
            </div>
            <div className="admin-heading">
                <div></div>
                <Button type="button" className="btn-dark" onClick={handleOnNew}>
                    <p>Agregar sucursal</p>
                    <FaPlus />
                </Button>
            </div>
            <Row>
                <Col sm={12}>
                    {showEdit ?
                        <Form onSubmit={handleSubmit}>
                            <h4>{parseInt(inputs.BranchOfficeId) === 0 ? "Nuevo sucursal" : "Editar sucursal"}</h4>
                            <Row>
                                <Col md={4} sm={12}>
                                    <FormGroup className="form-dashboard-group">
                                        <Label for="BranchOfficeName">Nombre de sucursal</Label>
                                        <Input name="BranchOfficeName" type="text" onChange={handleInputChange} value={inputs.BranchOfficeName} required></Input>
                                    </FormGroup>
                                </Col>
                                <Col md={4} sm={12}>
									<FormGroup className="form-dashboard-group-checkbox">
										<div className="checkbox-checkmark">
										<label className="toggleButton" htmlFor='chkOrgullo'>
											{/* <Label for="DepartmentName">Es principal en registro</Label> */}
											<input type="checkbox" id="chkOrgullo" checked={isChecked} onChange={()=>handleCheckboxChange(inputs.IsOnRegister)}/>
											<div>
												<svg viewBox="0 0 44 44">
													<path d="M14,24 L21,31 L39.7428882,11.5937758 C35.2809627,6.53125861 30.0333333,4 24,4 C12.95,4 4,12.95 4,24 C4,35.05 12.95,44 24,44 C35.05,44 44,35.05 44,24 C44,19.3 42.5809627,15.1645919 39.7428882,11.5937758" transform="translate(-2.000000, -2.000000)"></path>
												</svg>
											</div>
											</label>
											<p>Se usa para el registro externo</p>
										</div>
									</FormGroup>
								</Col>
                            </Row>
                            <div className="flex-end">
                                <Button className="btn-dark" type="submit">
                                    <p>Guardar</p>
                                    <FaSave />
                                </Button>
                            </div>
                        </Form>
                    : null}
                </Col>
            </Row>
            <SearchBranchOffice handleRowSelect={handleRowSelect} ref={gridRef} />
        </div>
    );
}
