import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AsideCategoriesJobs } from "./asideCategoriesJobs";
import { TtlsJobBank } from "./ttlsJobBank";
import { Link, useParams } from "react-router-dom";
import { JobBankService } from "../../services/jobBank.service";
import "./jobBank.scss";
import {AiOutlineFileText} from "react-icons/ai";
import { FaChevronLeft} from "react-icons/fa";
import { postulanteService } from "../../services/postulantJob.service";
import Moment from "react-moment";
import Swal from "sweetalert2";

export function CandidatesNominated() {
  const history = useHistory();
  let { id } = useParams();
  const [job, setJob] = useState({});
  const [candidates, setCandidates] = useState([]);

  useEffect(() => {
    JobBankService.getJobById(id).then((result) => {
      setJob(result);
    });

    postulanteService.getPostulants(id).then((result) => {
      setCandidates(result);
    });
  }, []);

  const approvePostulant = (postulantId, companyName, fullName) => {
    Swal.fire({
      text: "¿Desea seleccionar a " + fullName + "?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        postulanteService
          .approvePostulant(postulantId, companyName)
          .then((result) => {
            Swal.fire({
              text: "Postulante aprobado",
              icon: "success",
              allowOutsideClick: false,
            });
            postulanteService.getPostulants(id).then((result) => {
              setCandidates(result);
            });
          });
      }
    });
  };

  const rejectPostulant = (postulantId, companyName, fullName) => {
    Swal.fire({
      text: "¿Desea rechazar a " + fullName + "?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        postulanteService
          .rejectPostulant(postulantId, companyName)
          .then((result) => {
            Swal.fire({
              text: "Postulante rechazado",
              icon: "success",
              allowOutsideClick: false,
            });
            postulanteService.getPostulants(id).then((result) => {
              setCandidates(result);
            });
          });
      }
    });
  };

  return (
    <section className="dashboard-container-page">
      <TtlsJobBank tituloInJobs="Bolsa de trabajo" />
      <section className="contain_gral_jobbank">
        <>
          <div className="sec_catalog_listjobs">
            <div className="wrapper_detailsjobs special">
              <div className="">
                <button onClick={() => history.goBack()} className="btn-back">
                  <FaChevronLeft />
                  <p>Regresar</p>
                </button>
              </div>

              <div className="box_items_jobs">
                <article className="item_card_jobs card_shadows">
                  <div className="card_info_jobs">
                    <h5 className="text fnt_medium name_vacant">{job.title}</h5>
                    <div className="name_business">
                      <ion-icon name="business-outline"></ion-icon>{" "}
                      {job.companyName}
                    </div>
                    <div className="city_jobs mb-3">
                      <ion-icon name="location"></ion-icon> {job.location}
                    </div>
                    <div className="publication_date">
                      Publicado{" "}
                      <Moment locale="es" format="DD [de] MMMM YYYY">
                        {job.publicationDate}
                      </Moment>
                    </div>
                  </div>
                  <div className="card_options">
                    <img
                      src={job.companyImage}
                      alt={"Logo empresa " + job.companyName}
                    />
                    {job.salary > 0 && (
                      <div className="box_salary mt-4">
                        <p className="text-muted m-0">Oferta salarial</p>
                        <div className="monto_salary text fnt_medium">
                          ${job.salary} MNX
                        </div>
                      </div>
                    )}
                  </div>
                </article>
                <div className="card_shadows mt-4 p-3">
                  <h5 className="text fnt_medium mb-4">Lista de candidatos</h5>
                  <div className="table-responsive">
                    <table className="wrapper_table table table-hover table-sm">
                      <thead className="thead-dark">
                        <tr>
                          <th>#</th>
                          <th>Nombre completo</th>
                          <th>Fecha de postulación</th>
                          <th>Adjuntos</th>
                          <th>Status</th>
                          <th>Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        {candidates?.map((candidate, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <th>{candidate.fullName}</th>
                            <td>
                              <Moment locale="es" format="DD/MMMM/YYYY">
                                {candidate.postulationDate}
                              </Moment>
                            </td>
                            <td>
                              <a
                                href={candidate.urlCurriculum}
                                target="_blank"
                                rel="noopener noreferrer"
                                download={
                                  candidate.urlCurriculum !== "" ? true : false
                                }
                              >
                                <AiOutlineFileText></AiOutlineFileText>
                              </a>
                            </td>
                            <td>{candidate.status}</td>
                            <td>
                              {candidate.status === "EnProceso" && (
                                <>
                                  <span
                                    className="icon_tables"
                                    title="Aprobar"
                                    onClick={() =>
                                      approvePostulant(
                                        candidate.postulantId,
                                        job.companyName,
                                        candidate.fullName
                                      )
                                    }
                                  >
                                    <i
                                      className="fas fa-check-circle"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  &nbsp;
                                  <span
                                    className="icon_tables_reject"
                                    title="Rechazar"
                                    onClick={() =>
                                      rejectPostulant(
                                        candidate.postulantId,
                                        job.companyName,
                                        candidate.fullName
                                      )
                                    }
                                  >
                                    <i
                                      className="fas fa-times-circle"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>

        {/* <ApplicantForm /> */}
      </section>
    </section>
  );
}
