import React, { useState } from 'react';
import { EditSurvey } from './editSurvey'
import {Col, Row} from "reactstrap";
import {FooterHome} from "../../components/footer/footerHome";
import { FaChevronLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export function DetailsSurvey(props) {
    return (
        <div className="dashboard-container-page">
            <div className="admin-subtitle">
                <div>
                    <h2 className='mb-2'>Tu Opinión</h2>
                    <h6>{props.match.params.id !== "0" ? "Editar encuesta" : "Nueva encuesta"}</h6>
                </div>
                <Link className="btn-back" to="/admin/AdminSurvey" >
                    <FaChevronLeft />
                    <p>Regresar</p>
                </Link>
            </div>
            <EditSurvey surveyId={props.match.params.id} />
            <Row>
                <Col sm={12}>
                    <FooterHome />
                </Col>
            </Row>
        </div>
    );
}   
