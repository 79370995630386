import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./prideMusic.scss";
import { Col, Row } from "reactstrap";
import bannerMusic from "../../assets/imgs/banner-music.png";
import {
  BsApple,
  BsFacebook,
  BsInstagram,
  BsLink,
  BsLinkedin,
  BsSoundwave,
  BsSpotify,
  BsTiktok,
  BsTwitter,
  BsWordpress,
  BsYoutube,
} from "react-icons/bs";
import {
  BiWorld,
} from "react-icons/bi";
import data from "../../assets/jsons/prideMusics.json";
import { FooterHome } from "../../components/footer/footerHome";

export function PrideMusic() {
  const roleName = useSelector((state) => state.userSettings.roleName);
  const [sectionOn, setSectionOn] = useState(true);
  const fSectionOn = () => setSectionOn(!sectionOn);

  return (
    <section className="dashboard-container-page">
      <Row>
        <Col sm={12}>
          <h2>Pride Music</h2>
        </Col>
        <Col sm={12}>
          <div className="drawer__bg__sections">
            <img
              src={bannerMusic}
              alt="Pride Music, Música por y para nuestra comunidad"
            />
          </div>
          {/* <div className="pride-music-header">
            <div
              className="pride-music-content"
              style={{ backgroundImage: `url(${bgHeader})` }}
            >
              <div className="pride-music-info">
                <h3>Música por y para nuestra comunidad</h3>
                <p>
                  ¿Conoces a algún artista o grupo LGBTQ+ que podríamos agregar
                  a esta sección? Notifícalo a Administrador PrideMx.
                </p>
              </div>
              <div className="pride-music-img">
                <img src={imgHeader} alt="" />
              </div>
            </div>
          </div> */}
        </Col>
        <Col sm={12}>
          <div className="cards-pride-music">
            {data.map((item, index) => {
              return (
                <div className="card-pride-music" key={item.id}>
                  <div className="card-pride-music-thumb">
                    <img src={item.thumbnail} alt="" />
                  </div>
                  <div className="card-pride-music-content">
                    <h4 className="text-center">{item.title}</h4>
                    <p className="text-center">{item.description}</p>
                    <ul>
                      {item.socialLink.instagram ? (
                        <li>
                          <a
                            href={item.socialLink.instagram}
                            target="_blank"
                            className="instagram"
                          >
                            <BsInstagram />
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                      {item.socialLink.facebook ? (
                        <li>
                          <a
                            href={item.socialLink.facebook}
                            target="_blank"
                            className="facebook"
                          >
                            <BsFacebook />
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                      {item.socialLink.linkedin ? (
                        <li>
                          <a
                            href={item.socialLink.linkedin}
                            target="_blank"
                            className="linkedin"
                          >
                            <BsLinkedin />
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                      {item.socialLink.twitter ? (
                        <li>
                          <a
                            href={item.socialLink.twitter}
                            target="_blank"
                            className="twitter"
                          >
                            <BsTwitter />
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                      {item.socialLink.youtube ? (
                        <li>
                          <a
                            href={item.socialLink.youtube}
                            target="_blank"
                            className="youtube"
                          >
                            <BsYoutube />
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                      {item.socialLink.web ? (
                        <li>
                          <a
                            href={item.socialLink.web}
                            target="_blank"
                            className="web"
                          >
                            <BsLink />
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                      {item.socialLink.apple ? (
                        <li>
                          <a
                            href={item.socialLink.apple}
                            target="_blank"
                            className="apple"
                          >
                            <BsApple />
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                      {item.socialLink.soundcloud ? (
                        <li>
                          <a
                            href={item.socialLink.soundcloud}
                            target="_blank"
                            className="souncloud"
                          >
                            <BsSoundwave />
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                      {item.socialLink.website ? (
                        <li>
                          <a
                            href={item.socialLink.website}
                            target="_blank"
                            className="website"
                          >
                            <BsLink />
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                      {item.socialLink.linkthree ? (
                        <li>
                          <a
                            href={item.socialLink}
                            target="_blank"
                            className="linkthree"
                          >
                            <BsLink />
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                      {item.socialLink.tiktok ? (
                        <li>
                          <a
                            href={item.socialLink.tiktok}
                            target="_blank"
                            className="tiktok"
                          >
                            <BsTiktok />
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>
                    <div className="card-pride-music-footer">
                      {item.links.spotify.link ? (
                        <a
                          href={`${item.links.spotify.link}`}
                          target="_blank"
                          className="btn-gradient-music"
                        >
                          <span>
                            <BsSpotify />
                          </span>
                          <p>Escuchar</p>
                        </a>
                      ) : (
                        <a
                          href={`${item.links.web.site}`}
                          target="_blank"
                          className="btn-gradient-music"
                        >
                          <span>
                            <BiWorld />
                          </span>
                          <p>Escuchar</p>
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </Col>
        <Col sm={12}>
          <FooterHome />
        </Col>
      </Row>
    </section>
  );
}
