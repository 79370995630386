import React from "react";
import { useSelector } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import "./blockScreen.scss";
export const BlockScreen = ({ color }) => {
  const blocking = useSelector((state) => state.blocking);
  return (
    <Modal
      isOpen={blocking}
      className="modal-blockscreen"
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {" "}
      <ModalBody>
        {" "}
        <div className="loader loader-circle"></div>{" "}
      </ModalBody>{" "}
    </Modal>
  );
};
