import React, { useState, useEffect } from "react";
import { HeadRewards } from "./headRewards";
import "./rewards.scss";
import { rewardsService } from "../../services/rewards.service";
import { Table, FormGroup, Label, Input, Row, Col } from "reactstrap";
import { FooterHome } from "../../components/footer/footerHome";
import Moment from "react-moment";
import moment from "moment";
import "moment/locale/es";

export function AccountStatus() {
  const [rewardsFilterByDate, setRewardsFilterByDate] = useState([]);
  const [reward, setReward] = useState([]);
  const [rewardsStatement, setRewardsStatement] = useState([]);

  useEffect(() => {
    rewardsService.rewardsFilterByDate().then((result) => {
      setRewardsFilterByDate(result);
      getRewardsStatement(result[0].startDate + "|" + result[0].endDate);
    });
  }, []);

  const getRewardsStatement = async (dateRange) => {
    rewardsService.rewardsStatement(dateRange).then((result) => {
      setReward(result.reward);
      setRewardsStatement(result.items);
    });
  };

  return (
    <section className="dashboard-container-page">
      <HeadRewards reward={reward} />
      <div className="container-redemption">
        <Row>
          <Col sm={12}>
            <h3>Estado de cuentas</h3>
          </Col>
          <Col sm={12}>
            <div className="container-redemption-header">
              <FormGroup>
                <Label for="exampleSelectMulti">Filtro de fechas</Label>
                <Input
                  type="select"
                  onChange={(event) => getRewardsStatement(event.target.value)}
                >
                  {rewardsFilterByDate.map((item) => (
                    <option value={item.startDate + "|" + item.endDate}>
                      {moment(item.startDate).format("MMMM YYYY")} -{" "}
                      {moment(item.endDate).format("MMMM YYYY")}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </div>
          </Col>
          <Col sm={12}>
            <div className="container-redemption-table">
              <Table hover>
                <thead>
                  <tr>
                    <th scope="col">Fecha de movimiento</th>
                    <th scope="col">Tipo de Movimiento</th>
                    <th scope="col">Concepto</th>
                    <th scope="col">Monto de movimiento</th>
                  </tr>
                </thead>
                <tbody>
                  {rewardsStatement.map((item) => (
                    <tr>
                      <th>
                        <Moment format="DD/MM/YYYY">{item.date}</Moment>
                      </th>
                      <td>{item.action}</td>
                      <td>{item.concept}</td>
                      <td style={{ textAlign: "center" }}>
                        <span className={`tag ${item.action.toLowerCase()}`}>
                          {item.reward}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </div>
      <div className="row">
        <div className="col-12">
          <FooterHome />
        </div>
      </div>
    </section>
  );
}
