import React, { useEffect, useState } from "react";

import "./cardUser.scss";
import { Link } from "react-router-dom";
import { backendService } from "../../services/backend.service";
import {FaUser} from "react-icons/fa";

export function CardUser() {
  const [profile, setProfileInfo] = useState({});

  async function getProfileInfo() {
    backendService.userProfile().then((data) => {
      setProfileInfo(data);
    });
  }

  useEffect(() => {
    backendService.userSettings().then((result) => {
      getProfileInfo();
    });
  }, []);

  return (
    <div className="card-user">
      <div className="card-user-avatar">
        <div className={`rotating ${profile.genderIdentityFlag}`}></div>
        <img alt="Usuario Kuimby" src={profile.urlProfileImage} />
      </div>
      <h3>
        &#128075; Hola, {profile.firstName}
        {/* {profile.middleName} {profile.lastName} */}
      </h3>
      <p className="text-center p_email">{profile.email}</p>

      <Link to="/profile" className="btn-dark">
        <p>Mi Perfil</p>
        <FaUser />
      </Link>
    </div>
    // <div className="card-user">
    //   <div className="card-user-avatar">
    //     <div className={`rotating ${profile.genderIdentityFlag}`}></div>
    //     <img alt="Usuario Kuimby" src={profile.urlProfileImage} />
    //   </div>
    //   <h3>
    //     {profile.firstName} {profile.middleName} {profile.lastName}
    //   </h3>
    //   <p className="text-center">{profile.email}</p>
    //   <Link to="/profile" className="btn-dark">
    //     <p>Mi Perfil</p>
    //     <div className="circle">
    //       <i className="icon-profile"></i>
    //     </div>
    //   </Link>
    // </div>
  );
}
