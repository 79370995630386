import React, { Fragment, useEffect, useState } from 'react'
import { messageService } from '../../services/message.service'
import Moment from 'react-moment'
import { DropDownDate, DropDownStatus } from './dropDownsMessage'
import { useSelector } from 'react-redux';
import { messagePrivilegeService } from '../../services/messagePrivilige.service'
import {Button} from "rsuite";
import {ReadMessage} from "./readMessage";
import {FaPlus} from "react-icons/fa";

export default function ArchivedMessages(props) {
    const roleName = useSelector(state => state.userSettings.roleName)
    const [userMessages, setUserMessages] = useState([])
    const [dateFilter, setDateFilter] = useState({ title: 'Descendente', value: 'desc' })
    const [statusFilter, setStatusFilter] = useState({ title: 'Todos', value: '' })
    const [isSender, setIsSender] = useState(false);
    const deleteMessage = (id) => messageService.deleteMessage(id).then(getMessages)
    const [mobileMsg, setMobileMsg] = useState(false);

    const changeStatusMobile = () => setMobileMsg(!mobileMsg)
    //
    const [message, setMessage] = useState()
    const [messageId, setMessageId] = useState()

    const readMessage = (id) => {
        setMessageId(id)
        setMobileMsg(!mobileMsg)
        messageService.getMessageById(id).then((data) => {
            setMessage(data.data);
        })
    }

    useEffect(() => {
        getMessages()
    }, [dateFilter, statusFilter,message,mobileMsg])

    useEffect(() => {
        getUserPermissions()
    }, [roleName])

    const getMessages = () => {
        messageService.getMessage(2, dateFilter.value, statusFilter.value)
            .then((data) => setUserMessages(data))
    }

    const getUserPermissions = () => {
        messagePrivilegeService.getUserPrivilege().then((data) => {
            if ((roleName === "Recursos Humanos") || data) {
                setIsSender(true)
                return true;
            }
            else {
                setIsSender(false)
                return false;
            }
        })
    }

    return (
        <>
            <section className="inbox-messages-header">
                <div className="inbox-messages-filters">
                    <DropDownStatus title={statusFilter.title} setFilter={setStatusFilter} />
                    <DropDownDate title={dateFilter.title} setFilter={setDateFilter} />
                </div>
                {isSender &&
                    <Button type="button" className="btn-dark" onClick={() => { props.setIsActive(5); props.setDraft({}) }}>
                        <p>Mensaje nuevo</p>
                        <FaPlus />
                    </Button>
                }
            </section>
            <section className="table-responsive">
                <article className="messages-table">
                    <ul className={`${!mobileMsg ? '' : 'isHide'}`}>
                    {userMessages.map((message, index) =>
                        <li key={index}>
                            <div className={`msg ${message.messageDetail && message.messageDetail.isRead ? "readed" : ""}`}>
                                <Button className="btn-message" onClick={() => readMessage(message.message.messageId)}>
                                    <div className="avatar-container"><img className="avatar" src={message.messageUserFrom.urlProfileImage} alt="" /></div>
                                    <span className="btn-link">
                                        <div className="user">
                                            <p>{message.messageUserFrom.name}</p>
                                            <span><Moment format="DD/MM/YYYY">{message.message.createDate}</Moment></span>
                                        </div>
                                        <span>{message.message.subject}</span>
                                    </span>
                                </Button>
                            </div>
                                {/*<span className="icon_tables" onClick={() => deleteMessage(message.message.messageId)}>
                                    <i className="far fa-trash-alt"></i>
                                  </span>*/}
                        </li>
                    )}
                    {
                        (userMessages.length === 0) ?
                            <li>
                                <span className="message-empty">
                                    <i className="icon-empty"></i>
                                    <p>No hay mensajes</p>
                                </span>
                            </li> : ''
                    }
                    </ul>
                    <div className={`messages-readed ${mobileMsg ? 'isShow' : ''}`}>
                        <ReadMessage messageId={messageId} archived={true} mobileMsg={mobileMsg} changeStatusMobile={changeStatusMobile} />
                    </div>
                </article>
            </section>
        </>
    )
}
