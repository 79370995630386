import React  from 'react';
import { EditRewardCategory } from './editRewardCategory'

export function DetailsRewardCategory(props) {

    return (
        <div className="dashboard-container-page">
            <div className="admin-title">
                <h2>Recompensas - Categorías</h2>
                <h6>{props.match.params.id != 0 ? "Editar categoría" : "Nueva categoría"}</h6>
            </div>
            <EditRewardCategory rewardCategoryId={props.match.params.id} />
        </div>
    );
}   
