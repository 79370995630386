import React, { useState, useEffect } from "react";
import { ItemMorePost } from "./itemMorePost";
import { companyPostViewService } from "../../services/compayPostView.service";

export function AsideOurCompany({
  getPost,
  postType,
  activeAsideSurvey,
  postCategoryId,
  setLastFiter,
}) {
    const [filter, setFilter] = useState([]);
    const [filterId, setFilterId] = useState(0);

    useEffect(() => {
    companyPostViewService
        .getDateFilters(postType, postCategoryId)
        .then((result) => {
          if (result) {
            setFilter([]);
            if (result && result.length > 0) {
              setFilter(result);
              let lastItem = result[0];
              let lastMonth = lastItem.months[0];
              lastMonth.postType = postCategoryId;
              setLastFiter(lastMonth);
              setFilterId(postCategoryId)
            }
          }
        });
    }, [postType, postCategoryId]);

    return (
        <div className={`blog-worldpride-aside-publications ${activeAsideSurvey}`}>
          <h3>Otras Publicaciones</h3>
          <div className="publications-list">
              {filter.map((item,i) => (
                <ItemMorePost filter={item} getPost={getPost} year={item.year} months={item.months} key={i+i+i} />
              ))}
          </div>
        </div>
    );
}
