import { callApi, callApiFileStorage } from "./service";
import swal from "sweetalert";

export const directoryInfoService = {
  getById,
  getAllByCompany,
  insertDirectory,
  getMyDirectory,
  updateDirectory,
  deleteDirectory,
  approveDirectory,
  rejectDirectory,
  setDirectoryScore,
  setDirectoryView,
  approveDirectoryGrace,
  getSettingsUser,
  updateDirectoryReward,
  getDirectoryLatest
};

async function getById(id) {
  return callApi(
    `/BackEnd/GetDirectoryInfoById?directoryInfoId=${id}`,
    "GET"
  ).then((result) => {
    if (result.responseCode === "00") {
      return result.data;
    } else {
      alert(result.responseMessage);

      return null;
    }
  });
}

async function getDirectoryLatest(companyId, statusId) {
  let endpoint = `/BackEnd/DirectoryLatest?companyId=${companyId}`;
  if (statusId) endpoint = endpoint + `&statusId=${statusId}`;
  return callApi(endpoint, "GET").then((result) => {
    if (result.responseCode === "00") {
      return result.data;
    } else {
      alert(result.responseMessage);
      return null;
    }
  });
}

async function getAllByCompany(companyId, statusId) {
  let endpoint = `/BackEnd/GetDirectoryInfoList?companyId=${companyId}`;
  if (statusId) endpoint = endpoint + `&statusId=${statusId}`;
  return callApi(endpoint, "GET").then((result) => {
    if (result.responseCode === "00") {
      return result.data;
    } else {
      alert(result.responseMessage);
      return null;
    }
  });
}

async function getMyDirectory() {
  return callApi(`/BackEnd/GetMyDirectoryInfoList`, "GET").then((result) => {
    if (result.responseCode == "00") {
      return result.data;
    } else {
      return null;
    }
  });
}

async function insertDirectory(params) {
  return callApiFileStorage(
    "/BackEnd/InsertDirectoryInfo",
    "POST",
    params,
    false
  ).then((result) => {
    if (result.responseCode === "00") {
      swal({ text: "El Servicio se añadió correctamente.", icon: "success" });
    } else {
      swal({ text: result.responseMessage, icon: "error" });
    }
    return result;
  });
}

async function updateDirectory(params) {
  return callApiFileStorage(
    "/BackEnd/DirectoryInfo",
    "PUT",
    params,
    false
  ).then((result) => {
    if (result.responseCode === "00") {
      swal({ text: "El Servicio se actualizó correctamente.", icon: "success" });
    } else {
      swal({ text: result.responseMessage, icon: "error" });
    }
    return result;
  });
}

async function deleteDirectory(DirectoryInfoId) {
  return callApi(
    `/BackEnd/DeleteDirectoryInfo/${DirectoryInfoId}`,
    "DELETE"
  ).then((result) => {
    if (result.responseCode === "00") {
      return result.data;
    } else {
      swal({ text: result.responseMessage })
      return null;
    }
  });
}

async function approveDirectory(DirectoryInfoId, email) {
  return callApi(
    `/BackOffice/ApproveDirectoryInfo?DirectoryInfoId=${DirectoryInfoId}&Email=${email}`,
    "POST"
  ).then((result) => {
    if (result.responseCode === "00") {
      return result.data;
    } else {
      swal({ text: result.responseMessage, icon: "error" })
      return null;
    }
  });
}

async function approveDirectoryGrace(DirectoryInfoId, email, PubliMax, DaysGrace) {
  return callApi(
    `/BackOffice/ApproveDirectoryInfo/Grace?DirectoryInfoId=${DirectoryInfoId}&Email=${email}&PubliMax=${PubliMax}&DaysGrace=${DaysGrace}`,
    "POST"
  ).then((result) => {
    if (result.responseCode === "00") {
      return result.data;
    } else {
      swal({ text: result.responseMessage, icon: "error" })
      return null;
    }
  });
}

async function rejectDirectory(DirectoryInfoId) {
  return callApi(
    `/BackOffice/RejectDirectoryInfo?DirectoryInfoId=${DirectoryInfoId}`,
    "POST"
  ).then((result) => {
    if (result.responseCode === "00") {
      return result.data;
    } else {
      swal({ text: result.responseMessage })
      return null;
    }
  });
}

async function setDirectoryScore(params) {
  return callApiFileStorage(
    "/BackEnd/DirectoryScore",
    "POST",
    params,
    false
  ).then((result) => {
    if (result.responseCode === "00") {
      swal({ text: "El Servicio se actualizó correctamente.", icon: "success" });
    } else {
      swal({ text: result.responseMessage, icon: "error" });
    }
    return result;
  });
}

async function setDirectoryView(id) {
  return callApiFileStorage(
    "/BackEnd/DirectoryView/" + id,
    "POST",
    {},
    false
  ).then((result) => {
    if (result.responseCode === "00") {
    } else {
      swal({ text: result.responseMessage, icon: "error" });
    }
    return result;
  });
}

async function getSettingsUser() {
  return callApi(
    `/BackOffice/DirectoryInfo/SettingsUser`,
    "get"
  ).then((result) => {
    if (result.responseCode === "00") {
      return result.data;
    } else {
      swal({ text: result.responseMessage, icon: "error" })
      return null;
    }
  });
}

async function updateDirectoryReward(directoryInfoId, enableReward) {
  return callApi(
    `/BackEnd/DirectoryInfo/reward/${directoryInfoId}/${enableReward}`,
    "POST",
    {},
    false
  ).then((result) => {
    if (result.responseCode === "00") {
    } else {
      swal({ text: result.responseMessage, icon: "error" });
    }
    return result;
  });
}
