import React, { useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Link } from "react-router-dom";
import "./jobBank.scss";
import { FormJob } from "./FormJob";
import { FormSection } from "./FormSection";
import {FooterHome} from "../../components/footer/footerHome";
import {Col, Row} from "reactstrap";

export function RegisterJobs(props) {
  const [datos, setDatos] = useState({
    title: "",
    categoria: [],
    ubicacion: "",
    location: "",
    titleCompany: "",
    imagen: null,
    infoSalarial: "",
    descripcion: "",
    phoneNumber: "",
    mail: "",
  });
  const [viewForm, setViewForm] = useState("1");
  const [job, setJob] = useState(null);

  const toggleView = (sec) => {
    if (viewForm !== sec) setViewForm(sec);
  };

  return (
    <div className="dashboard-container-page">
      <div className="job-bank-register">
        <div className="d-flex-between">
          <h2>Registro de empleo</h2>
          <Link to="/job-bank" className="btn-back"><i className="icon-chevron-left"></i><p>Ir a Bolsa de trabajo</p></Link>
        </div>
        <div className="border-pointed green">
          <p>Llena el siguiente formulario con los datos para poder continuar con el alta de la vacante.</p>
        </div>
        <div className="job-bank-register-form">
          {
            {
              1: (<FormJob
                    datos={datos}
                    setDatos={setDatos}
                    toggleView={() => toggleView("2")} //sig
                    setJob={setJob}
                />),

              2: (<FormSection
                    toggleView={() => toggleView("1")}
                    job={job}
                  />)
              }[viewForm]
          }
        </div>
      </div>
      <Row>
        <Col sm={12}>
          <FooterHome />
        </Col>
      </Row>
    </div>
  );
}
