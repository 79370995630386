import React, { useState, useEffect, useRef } from "react";
import { Header } from "./header/header";
import { backendService } from "../services/backend.service";
import { useDispatch } from "react-redux";
import { setUserSettings, setAccountSettings } from "../store/actions";
import { BlockScreen } from "./blockScreen/blockScreen";
import { InstallPWA } from "./pwa/installPWA";
import {Footer} from "./footer/footer";
import {messageService} from "../services/message.service";
export function LayoutFull(props) {
  const dispatch = useDispatch();
  const [activeMenu, setStateMenu] = useState("");
  const [profile, setProfileInfo] = useState();
  const [messages,setMessages] = useState([]);
  const MenuPurchaseBox = () => {
    setStateMenu(activeMenu === "" ? " is-show" : "");
  };

  const [activeBtn, setStateBtn] = useState("");
  const BtnSet = () => {
    setStateBtn(activeBtn === "" ? " visible_menu" : "");
  };

  const MyFun = () => {
    BtnSet();
    MenuPurchaseBox();
  };
  const getProfileInfo = () => {
    backendService.userProfile().then((data) => {
      setProfileInfo(data);
    });
  };

  useEffect(() => {
    backendService.userSettings().then((result) => {
      dispatch(setUserSettings(result));
      getProfileInfo();
    });

    messageService
    .getUnreadCount()
    .then((data) => setMessages(data));
  }, []);

  function getWidthDimensions() {
    const { innerWidth: width } = window;
    return width;
  }

  const [windowDimensions, setWindowDimensions] = useState(getWidthDimensions());

  useEffect(() => {
    const handleResize = () => setWindowDimensions(getWidthDimensions())
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
        <BlockScreen />
        <Header
            MyFun={() => MyFun()}
            activeBtn={activeBtn}
            history={props.history}
            userProfile={profile}
            windowDimensions={windowDimensions}
            messages={messages}
        />
        <main className="dashboard-wrapper">
          <div className="dashboard-container">{props.children}</div>
        </main>
        <Footer
            MyFun={() => MyFun()}
            activeBtn={activeBtn}
            history={props.history}
            userProfile={profile}
            windowDimensions={windowDimensions}
            messages={messages}
        />
        <InstallPWA />
    </>
  );
}

export function LayoutLess(props) {
  localStorage.removeItem("_rtpride");
  const dispatch = useDispatch();

  useEffect(() => {
    backendService
      .getCompanyCustomize(process.env.REACT_APP_COMPANY)
      .then((result) => {
        if (result) {
          const customizeValues = JSON.parse(result.customizeValues);
          dispatch(
            setUserSettings({ companyLogo: customizeValues.CompanyLogo })
          );
          dispatch(setAccountSettings(JSON.parse(result.accountSettings)));
        }
      });
  }, []);

  return (
    <section>
      <BlockScreen />
      {props.children}
      <InstallPWA />
    </section>
  );
}
