import React, { useEffect, useState } from "react";
import {Col, Row} from "reactstrap";
import { companyPostViewService } from "../../services/compayPostView.service";
import { backendService } from "./../../services/backend.service";
import "./ourCompany.scss";
import Moment from "react-moment";
import "moment/locale/es";
import {CommentsBox} from "./commentsBox";
import {CompanyPostAttachment} from "../../components/companyPost/companyPostAttachment";
import {FooterHome} from "../../components/footer/footerHome";
import {Link} from "react-router-dom";
import {Button} from "rsuite";

export function CompanyPostSecondary(props) {
  const [comments, setComments] = useState(0);
  const [postComment, setPostComment] = useState({});
  // const [activeTab, setActiveTab] = useState("1");
  const [commentBandera, setCommentBandera] = useState(0);
  const [postTotalLikes, setPostTotalLikes] = useState(0);
  const postCategoryId = props.match.params.id;
  //const [gallery, setGallery] = useState([]);
  //const [selectedGallery, setSelectedGallery] = useState({});
  const postType = 0;
  const [showCommentBox, setShowCommentBox] = useState("");

  // const toggle = (tab) => {
  //   if (activeTab !== tab) {
  //     setActiveTab(tab);
  //     setSectionActive(tab);
  //   }
  // };

  const handleCommentBox = () => setShowCommentBox(showCommentBox === "" ? "is-show" : "");

  const [activeAsideSurvey, setStateAsideSurvey] = useState("");
  // const AsidePurchaseBox = () => setStateAsideSurvey(activeAsideSurvey === "" ? " is-show" : "");
  const [posts, setPosts] = useState([]);
  // const [sectionActive, setSectionActive] = useState("1");
  const [userComment, setUserComment] = useState({});
  const [selectedPost, setSelectedPost] = useState({});
  const [currentFilter, setcurrentFilter] = useState("NaN-aN-aN");
  const [lastFiter, setLastFiter] = useState({});

  const getProfile = () => {
    backendService.userProfile().then((data) => {
      let userProfile = {
        name: data.firstName + " " + data.middleName + " " + data.lastName,
        genderIdentityFlag: data.genderIdentityFlag,
        urlProfileImage: data.urlProfileImage,
      };
      setUserComment(userProfile);
    });
  };

  const getPosts = async () => {
    clearVariable();
    companyPostViewService
        .getByFilters(currentFilter == "NaN-aN-aN" ? undefined : currentFilter, postCategoryId)
        .then((result) => {
          if (result && result.length > 0) {
            let item = {};
            if (props.match.params.id !== undefined && props.match.params.postId !== undefined) {
              getPost(props.match.params.postId);
            } else {
              item = result[0];
              handleSelectPost(item);
            }
            setPosts(result);
          }
        });
  };

  const getPost = (id) => {
    companyPostViewService.getById(id).then((result) => {
      if (result.postType === 0) {
        setComments(result.totalComments);
        setSelectedPost(result);
        postLikes(result, 0);
      } 
      // else if (result.postType === 1) {
      //   // toggle("2");
      //   result.iconPath = result.urlImage;
      //   handleSelectGallery(result);
      // }
    });
  };

  /*useEffect(() => {
    if (props.match.params.id !== undefined && props.match.params.postId !== undefined) {
      let newParam = { reportId: props.match.params.postId };
      setPostComment(newParam);
      getPost(props.match.params.postId);
    } 
    // else {
    //   clearVariable();
      // if ( currentFilter !== "NaN-aN-aN" && lastFiter.filterMonthDate !== undefined) {
      //   selectedPost.companyPostId = null;
      //   if (postCategoryId === lastFiter.postType) {
      //     getPosts();
      //   } 
      // } 
    
  }, [currentFilter, props]);*/

  // useEffect(() => {
  //   debugger
  //   if (props.match.params.postId === undefined) {
  //     // setSectionActive("1");
  //     getPosts();
  //   }
  // }, []);

  // useEffect(() => {
  //   if ( lastFiter.filterMonthDate !== undefined && postCategoryId === lastFiter.postType ) {
  //     getProfile();
  //     let datelastFilter = formatDate(new Date(lastFiter.filterMonthDate));
  //     if (currentFilter === datelastFilter) {
  //       getPosts();
  //     } else {
  //       getPostDateFilter(new Date(lastFiter.filterMonthDate));
  //     }
  //   }
  // }, [lastFiter]);

  useEffect(() => {
    getPost(props.match.params.postId);
    getProfile();
    // if (props.match.params.postId === undefined) getPosts();
  }, []);

  const handleSelectPost = (item) => {
    if (!item.isRead) {
      companyPostViewService
        .InsertRead({ CompanyPostId: item.companyPostId })
        .then((result) => {
          if (result) {
            debugger
            setSelectedPost(result);
            getProfile();
            postLikes(result, 0);
            if (posts.length > 0) {
              let items = posts.map((m) => {
                if (m.companyPostId === item.companyPostId) m.isRead = true;
                return m;
              });
              setPosts(items);
            }
          }
        });
    } else {
      getPost(item.companyPostId);
    }
  };

  // const handleOnUnRead = () => {
  //   var id =
  //     sectionActive === "1"
  //       ? selectedPost.companyPostId
  //       : selectedGallery.companyPostId;
  //   companyPostViewService
  //     .DeleteReadPost({ CompanyPostId: id })
  //     .then((result) => {
  //       if (result) {
  //         setSelectedPost(result);
  //         let items = posts.map((m) => {
  //           if (m.companyPostId == id) m.isRead = false;
  //           return m;
  //         });
  //         postLikes(result, 0);
  //         setPosts(items);
  //       }
  //     });
  // };

  const clearVariable = () => {
    setSelectedPost({});
    setPosts([]);
  };

  const postLikes = (post, order) => {
    var totalPost = post.like ? post.totalLikes * 5 - 4 : post.totalLikes * 5;
    setPostTotalLikes(totalPost);
  };

  const handleOnLike = () => {
    let  id =  selectedPost.companyPostId
    companyPostViewService.InsertLike({ CompanyPostId: id }).then((result) => {
      if (result) setSelectedPost(result);
      postLikes(result, 1);
    });
  };

  const handleOnUnLike = () => {
    var id = selectedPost.companyPostId;
    companyPostViewService
      .DeleteLikePost({ CompanyPostId: id })
      .then((result) => {
        if (result) setSelectedPost(result);
        postLikes(result, 0);
      });
  };

  const [activeView, setStateView] = useState("");
  const boxActiveView = () => {
    setStateView(activeView === "" ? " is-show" : "");
  };

  const getPostDateFilter = (filterDate) => {
    var dateFilter = formatDate(filterDate);
    setcurrentFilter(dateFilter);
  };

  const formatDate = (date) => {
    let datestring =
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2);
    return datestring;
  };

  // async function getGallery() {
  //   currentFilter !== "NaN-aN-aN" && companyPostService.getAllFront(1, currentFilter).then((result) => {
  //       if (result) {
  //         if (result && result.length > 0) {
  //           // setGallery([]);
  //           // setGallery(result);
  //           if (selectedGallery?.companyPostId && selectedGallery !== null && selectedGallery.companyPostId >= 0) {
  //             if (props.match.params.id !== undefined && props.match.params.postId !== undefined && currentFilter === "NaN-aN-aN") {
  //               getPost(props.match.params.postId);
  //             } else {
  //               const itemExist = result.filter(
  //                 (element) =>
  //                   element.companyPostId === selectedGallery.companyPostId
  //               );

  //               // if (itemExist.length === 0) {
  //               //   handleSelectGallery(result[0]);
  //               // } else {
  //               //   setCurrentGallery(result);
  //               // }
  //             }
  //           } else {
  //             if (
  //               props.match.params.id !== undefined &&
  //               props.match.params.postId !== undefined &&
  //               selectedPost.companyPostId !== props.match.params.postId &&
  //               currentFilter === "NaN-aN-aN"
  //             ) {
  //               getPost(props.match.params.postId);
  //             } 
  //             // else {
  //             //   // handleSelectGallery(result[0]);
  //             // }
  //           }
  //         }
  //       }
  //     });
  // }

  // useEffect(() => {
  //   setGallery([]);
  //   setSelectedGallery({});
  //   if (activeTab === "1" && currentFilter !== undefined && lastFiter.filterMonthDate !== undefined) {
  //     getPosts();
  //   } else if (activeTab === "2" && currentFilter !== undefined && lastFiter.filterMonthDate !== undefined) {
  //     getGallery();
  //   }
  // }, [currentFilter, activeTab]);

  useEffect(() => {
    if (currentFilter !== undefined && lastFiter.filterMonthDate !== undefined) getPosts();
  }, [currentFilter]);

  useEffect(() => {
    getPostDateFilter(new Date(lastFiter.filterMonthDate));
  }, [lastFiter]);

  // const setCurrentGallery = (dataItems) => {
  //   if (dataItems != null && dataItems.length > 0) {
  //     for (var i = 0; i < dataItems.length; i++) {
  //       var item = dataItems[i];
  //       if (selectedGallery.companyPostId === item.companyPostId) setCurrentGallerySelect(dataItems[i]);
  //     }
  //   }
  // };

  // const setCurrentGallerySelect = (item) => {
  //   setSectionActive("2");
  //   setSelectedGallery(item);
  // };

  // const handleSelectGallery = (item) => {
  //   if (!item.isRead) {
  //     companyPostViewService
  //       .InsertRead({ CompanyPostId: item.companyPostId })
  //       .then((result) => {
  //         selectedGallery.companyPostId = item.companyPostId;
  //         getGallery();
  //       });
  //   } else {
  //     setSelectedGallery(item);
  //     setSectionActive("2");
  //   }
  // };

  /*const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDots = () => setDropdownOpen((prevState) => !prevState);

  const goToGallery = () => {
    window.location.href = `/blog-company/gallery`;
  }*/

  //const [isOpen, setIsOpen] = useState(false);
  //const togglePosts = () => setIsOpen(!isOpen);

  return (
    <article className="dashboard-container-page">
      <Row className="blog-secondary-post-container">
        <Col sm={12}>
          <h2 className="d-flex-start">
            <Link to="/stories-pride" className="btn-back-post">
              <i className="icon-chevron-left"></i>
            </Link>
            {props.titleComponent}
          </h2>
        </Col>
        <Col sm={12}>
          {selectedPost.urlImage && (
            <div className="post-single-header">
              <div className="post-single-title">
                <p>{selectedPost.publishDate && (<Moment locale="es" format=" DD [de] MMMM [de] YYYY">{selectedPost.publishDate}</Moment>)}</p>
                <h3>{selectedPost.title}</h3>
              </div>
              <div className="post-single-thumb">
                <img src={selectedPost.urlImage} alt="" />
              </div>
            </div>
          )}
        </Col>
        <Col lg={9} md={12} sm={12}>
          <div className={`post-single-body`}>
            <div className="post-single-content">
              {
                  selectedPost.totalViews &&
                  <Row>
                    <Col lg={12}>
                      <div className="post-content">
                        <div className="read">
                          <p className="views_post">
                            <span className="number">{selectedPost.totalViews * 5}</span>
                            <span>vista(s)</span>
                          </p>
                        </div>
                        <div dangerouslySetInnerHTML={{__html: selectedPost.text,}} />
                      </div>
                    </Col>
                  </Row>
              }
              {/* <Row className="comments-container">
                <Col sm={12}>
                  <div className="comments">
                    <div className="comments-header">
                      <h5>Comentarios</h5>
                      <Dropdown isOpen={dropdownOpen} toggle={toggleDots}>
                        <DropdownToggle className="btn-dots"><BsThreeDotsVertical /></DropdownToggle>
                        <DropdownMenu>
                          {selectedPost.isRead ? (
                              <div className="btn-dots-link">
                                  <span onClick={handleOnUnRead}>
                                    <BsEyeSlash /> Marcar no leído
                                  </span>
                              </div>
                          ) : (
                              <div className="btn-dots-link">
                                <span onClick={handleOnUnRead}><BsEye/> No leído</span>
                              </div>
                          )}
                        </DropdownMenu>
                      </Dropdown> 
                    </div>
                    <div className="comments-content">
                      <CommentsBox
                          isShow={showCommentBox}
                          companyPostId={selectedPost.companyPostId}
                          handleCommentBox={handleCommentBox}
                          profileUser={userComment}
                          updateCountComment={setComments}
                          reportId={postComment?.reportId}
                          bandera={commentBandera}
                          setBandera={setCommentBandera}
                          postType={postType}
                      />
                      <CompanyPostAttachment CompanyPostId={selectedPost.companyPostId} IsDelete={false}/>
                    </div>
                    <div className="comments-footer">
                      <div className="likes-container">
                        {selectedPost.like ? (
                            <Button className="btn-like" onClick={handleOnUnLike}>
                              <p>No me gusta</p>
                            </Button>
                        ) : (
                            <Button className="btn-like" onClick={handleOnLike}>
                              <p>Me gusta</p>
                            </Button>
                        )}
                        <div className="likes-total">
                          {
                            (postTotalLikes) ?
                                <Button className="btn-like red">
                                  <i className="icon-heart-fill"></i>
                                  <p className="like_counter">{postTotalLikes}</p>
                                </Button>
                                :
                                <Button className="btn-like">
                                  <i className="icon-heart-fill"></i>
                                  <p className="like_counter">{postTotalLikes}</p>
                                </Button>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row> */}
            </div>
          </div>
        </Col>
        {/* <Col lg={3} md={12} sm={12}>
          <div className={`blog-secondary-aside`}>
            <h3>Otras Publicaciones</h3>
            <AsideBlogCompanySecondary
                getPost={getPostDateFilter}
                activeAsideSurvey={activeAsideSurvey}
                postCategoryId={postCategoryId}
                setLastFiter={setLastFiter}
                postType={postType}
            />
          </div>
        </Col> */}
      </Row>
      <Col sm={12}>
        <FooterHome />
      </Col>
    </article>
  );
}
