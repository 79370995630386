import React, { useRef, useState } from "react";
import { FormGroup, Label, Input, Row, Col, Form } from "reactstrap";
import { SelectUsers } from "../../components/Helpers/selectUsers";
import { AudienceUsers } from "../../components/Helpers/audienceUsers";
import { GeneralUpload } from "../../components/Helpers/generalUpload";
import { adminAudienceService } from "../../services/adminAudience.service";
import { storageService } from "../../services/storage.service";
import SearchAdminAudience from "./searchAdminAudience";
import moment from "moment";
import swal from "sweetalert";
import { Button } from "rsuite";
import { FooterHome } from "../../components/footer/footerHome";
import { companyEventTypeService } from "../../services/companyEventType.service";
import { useEffect } from "react";
import { EventTypes } from "../../enums/eventTypes";
import { event } from "react-ga";
import { GenderType } from "../../enums/genderType";
import "./audience.scss";
import {FaChevronLeft, FaPlus, FaSave} from "react-icons/fa";
import { Link } from "react-router-dom";

export function AdminAudience() {
  const [templateShow, setTemplateShow] = useState("1");
  const [images, setImages] = useState([]);
  const [localfiles, setLocalFiles] = useState([]);
  const [previewFile, setPreviewFile] = useState([]);
  const [userSelec, setUserSelec] = useState([]);
  const [isAudienceAddressed, setIsAudienceAddressed] = useState(false);
  const [createEventCalendar, setCreateEventCalendar] = useState(false);
  const gridRef = useRef();
  const horas = [1, 2, 3, 4, 5, 6];

  const [audience, setAudience] = useState({
    AudienceId: 0,
    Title: "",
    Reward: "",
    ConferenceUrl: "",
    StartDate: "",
    StartTime: "",
    Duracion: 0,
    PubDate: "",
    PostToGender: "",
  });

  const toggleTemplate = (sec) => {
    if (templateShow !== sec) setTemplateShow(sec);
  };

  async function saveAudience(item) {
    adminAudienceService
      .create(item, localfiles, createEventCalendar)
      .then((result) => {
        toggleTemplate("1");
        gridRef.current.refreshDataGrid();
        setUserSelec([]);
        setImages([]);
        setLocalFiles([]);
        setPreviewFile([]);
      });
  }

  async function updateAudience(item) {
    adminAudienceService.update(item, localfiles).then((result) => {
      toggleTemplate("1");
      gridRef.current.refreshDataGrid();
      setUserSelec([]);
      setImages([]);
      setLocalFiles([]);
      setPreviewFile([]);
    });
  }

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setAudience({ ...audience, [name]: checked });
    } else {
      setAudience({ ...audience, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isAudienceAddressed && userSelec.length <= 0) {
      return swal({ text: "Debes elegir al menos un usuario", icon: "error" });
    }

    if (audience.Duracion <= 0) {
      return swal({
        text: "Por favor indique la duración del evento",
        icon: "error",
      });
    }

    let conferenceDate = audience.StartDate + "T" + audience.StartTime;
    let EndDate = moment(conferenceDate)
      .add(audience.Duracion, "hours")
      .format("YYYY-MM-DDTHH:mm");

    const itemSave = {
      AuditoriumId: audience.AudienceId,
      Title: audience.Title,
      Reward: audience.Reward,
      ConferenceUrl: audience.ConferenceUrl,
      ConferenceDate: conferenceDate,
      EndDate: EndDate,
      StartDate: audience.PubDate + "T00:00",
      ListUser: userSelec,
      PostToGender: audience.PostToGender == "null" ? null : audience.PostToGender,
    };

    if (moment(EndDate).isBefore(audience.PubDate + "T00:00", "hours")) {
      await swal({
        text: "La fecha de publicación debe ser anterior al evento",
        icon: "error",
      });
      return;
    }

    if (localfiles.length === 0) {
      await swal({text: "La imagen de publicación es requerida", icon: "error"});
      return;
    }

    if (parseInt(audience.AudienceId) === 0) {
      saveAudience(itemSave);
    } else {
      updateAudience(itemSave);
    }
  };

  const handleImageUploadRemove = () => {
    setImages([]);
    setLocalFiles([]);
    setPreviewFile([]);
  };

  const handleOnChangeFile = (files) => {
    let convertingFile = URL.createObjectURL(files[0]);
    setPreviewFile(convertingFile);
    setLocalFiles(files);
  };

  const handleRowSelect = (gridItem) => {
    toggleTemplate("2");
    const duration = moment.duration(
      moment(gridItem.endDate).diff(gridItem.conferenceDate)
    );
    const horas = duration.asHours();
    setAudience({
      AudienceId: gridItem.auditoriumId,
      Title: gridItem.title,
      Reward: gridItem.reward,
      ConferenceUrl: gridItem.conferenceUrl,
      StartDate: gridItem.conferenceDate.split("T")[0],
      StartTime: gridItem.conferenceDate.split("T")[1],
      Duracion: horas,
      PubDate: gridItem.startDate.split("T")[0],
      PostToGender: gridItem.postToGender,
    });

    adminAudienceService
      .getAudiceImageById(gridItem.auditoriumId)
      .then((result) => {
        if (result.files.length > 0) {
          setImages(result);
          const file = b64toBlob(
            result.files[0].file,
            "application/png",
            result.files[0].fileName
          );
          setLocalFiles([file]);
        }
      });
  };

  const b64toBlob = (
    b64Data,
    contentType = "",
    filename = "",
    sliceSize = 512
  ) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return new File([blob], filename, { type: contentType });
  };

  const getCompanyEventType = () => {
    companyEventTypeService
      .getByCompanyAndEventType(process.env.REACT_APP_COMPANY, EventTypes.TALK)
      .then((result) => {
        if (result) setIsAudienceAddressed(result.isAudienceAddressed);
      });
  };

  const handleOnNew = () => {
    let audienceNew = {
      AudienceId: 0,
      Title: "",
      Reward: "",
      ConferenceUrl: "",
      StartDate: "",
      StartTime: "",
      Duracion: 0,
      PubDate: "",
      PostToGender: "",
    };

    setAudience(audienceNew);
    toggleTemplate("2");
    setUserSelec([]);
    setImages([]);
    setPreviewFile([]);
    setLocalFiles([]);
  };

  useEffect(() => {
    getCompanyEventType();
  }, []);

  return (
    <>
      <section className="dashboard-container-page">
        {
          {
            1: (
              <>
                <div className="admin-subtitle">
                  <div>
                    <h2>Mis Rutinas</h2>
                    <h6>Lista de publicaciones</h6>
                  </div>
                  <Link to="/admin" className="btn-back">
                    <FaChevronLeft />
                    <p>Regresar</p>
                  </Link>
                </div>
                <div className="admin-heading">
                  <div></div>
                  <Button type="button" className="btn-dark" onClick={handleOnNew}>
                    <p>Agregar publicación</p>
                    <FaPlus />
                  </Button>
                </div>
                <SearchAdminAudience
                  handleRowSelect={handleRowSelect}
                  ref={gridRef}
                />
              </>
            ),
            2: (
              <>
                <Form className="form-dashboard" onSubmit={handleSubmit}>
                  <div className="admin-heading">
                    <h4>{audience ? "Editar evento" : "Nuevo evento"} </h4>
                    <button
                      className="btn-back"
                      type="button"
                      onClick={() => toggleTemplate("1")}
                    >
                      <FaChevronLeft />
                      <p>Regresar</p>
                    </button>
                  </div>
                  <Row className="mb-4">
                    <Col className="mb-3" lg={6} md={12}>
                      <FormGroup className="form-dashboard-group mb-5">
                        <Label for="Title">Título de evento</Label>
                        <Input
                          required
                          type="text"
                          name="Title"
                          onChange={handleChange}
                          value={audience.Title}
                        />
                      </FormGroup>

                      <FormGroup className="form-dashboard-group mb-2">
                        <Label for="ConferenceUrl">URL del evento</Label>
                        <Input
                        className="form-control"
                          type="text"
                          name="ConferenceUrl"
                          onChange={handleChange}
                          value={audience.ConferenceUrl}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="mb-3" lg={6} md={12}>
                      {previewFile.length > 0 || images.files?.length > 0 ? (
                        images.files?.length > 0 ? (
                          <FormGroup className="form-dashboard-group-upload">
                            <div className="uploaded-file">
                              <img
                                src={`${
                                  images.files[0].file
                                    ? "data:application/octet-stream;base64,"
                                    : ""
                                }${images.files[0].file}`}
                                className="img-file"
                                alt=""
                              />
                              <Button
                                className="btn-remove-file"
                                onClick={handleImageUploadRemove}
                              >
                                <i className="icon-trash"></i>
                              </Button>
                            </div>
                          </FormGroup>
                        ) : (
                          <FormGroup className="form-dashboard-group-upload">
                            <div className="uploaded-file">
                              <img
                                src={previewFile}
                                className="img-file"
                                alt=""
                              />
                              <Button
                                className="btn-remove-file"
                                onClick={handleImageUploadRemove}
                              >
                                <i className="icon-trash"></i>
                              </Button>
                            </div>
                          </FormGroup>
                        )
                      ) : (
                        <GeneralUpload
                          accept="image/*"
                          isMultiple="false"
                          TitleLabel={"Imagen del evento"}
                          handleOnChangeFiles={handleOnChangeFile}
                        />
                      )}
                    </Col>
                  </Row>

                  <Row className="mb-4">
                    <Col className="mb-3" lg={3} md={6} sm={12}>
                      <div className="form-dashboard-group">
                        <Label for="StartDate">Fecha de Evento</Label>
                        <Input
                          required
                          type="date"
                          min={moment().format("YYYY-MM-DD")}
                          onChange={handleChange}
                          name="StartDate"
                          value={audience.StartDate}
                        />
                      </div>
                    </Col>
                    <Col className="mb-3" lg={3} md={6} sm={12}>
                      <div className="form-dashboard-group alt40">
                        <Label>Horario</Label>
                        <Input
                          required
                          type="time"
                          onChange={handleChange}
                          name="StartTime"
                          value={audience.StartTime}
                        />
                      </div>
                    </Col>
                    <Col className="mb-3" lg={3} md={6} sm={12}>
                      <div className="form-dashboard-group">
                        <Label>Horas</Label>
                        <select
                          className="form-select"
                          name="Duracion"
                          onChange={handleChange}
                          selectvalue={audience.Duracion}
                          value={audience.Duracion}
                          required={true}
                        >
                          <option value="0">{"Duración (Horas)"}</option>
                          {horas.map((item) => (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                    <Col className="mb-3" lg={3} md={6} sm={12}>
                      <FormGroup className="form-dashboard-group">
                        <Label for="PubDate">Inicio de Publicación</Label>
                        <Input
                          required
                          type="date"
                          onChange={handleChange}
                          name="PubDate"
                          value={audience.PubDate}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="mb-3" lg={3} md={6} sm={12}>
                      <FormGroup className="form-dashboard-group">
                        <Label for="Reward">Recompensa</Label>
                        <Input
                          required
                          type="text"
                          name="Reward"
                          onChange={handleChange}
                          value={audience.Reward}
                        />
                      </FormGroup>
                    </Col>
                    {parseInt(audience.AudienceId) === 0 && (
                      <Col lg={3} md={6} sm={12}>
                        <FormGroup check className="mt-3">
                          <Label for="CreateEventCalendar" check>Crear evento de calendario</Label>
                          <Input
                            type="checkbox"
                            name="CreateEventCalendar"
                            onChange={() =>
                              setCreateEventCalendar(!createEventCalendar)
                            }
                          />
                        </FormGroup>
                      </Col>
                    )}
                  </Row>

                  <Row>
                    <Col className="mb-4" sm={12}>
                      <Label>Evento dirigido a:</Label>
                      <FormGroup className="wrapper_label_radio_reto" check>
                        <div className="form-check box_label_radio_answ">
                          <input
                            className="form-check-input"
                            name="PostToGender"
                            id="PostToGenderMan"
                            type="radio"
                            value={GenderType.Hombre}
                            onChange={handleChange}
                            checked={audience.PostToGender == GenderType.Hombre}
                          />
                          <Label className="form-check-label label_radio_answ" for="PostToGenderMan">Hombres</Label>
                        </div>

                        <div className="form-check box_label_radio_answ">
                          <input
                            id="PostToGenderWoman"
                            className="form-check-input"
                            name="PostToGender"
                            type="radio"
                            value={GenderType.Mujer}
                            onChange={handleChange}
                            checked={audience.PostToGender == GenderType.Mujer}
                          />
                          <Label className="form-check-label label_radio_answ" for="PostToGenderWoman">Mujeres</Label>
                        </div>

                        <div className="form-check box_label_radio_answ">
                          <input
                            id="PostToGenderAll"
                            className="form-check-input"
                            name="PostToGender"
                            type="radio"
                            value={GenderType.null}
                            onChange={handleChange}
                            checked={String(audience.PostToGender) == GenderType.null}
                          />
                          <Label className="form-check-label label_radio_answ" for="PostToGenderAll">Todos</Label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  {isAudienceAddressed &&
                    (audience.AudienceId > 0 ? (
                      <AudienceUsers
                        targetId={audience.AudienceId}
                        eventType="Auditorio"
                        selectUser={setUserSelec}
                      />
                    ) : (
                      <SelectUsers
                        selectUser={setUserSelec}
                        title={"Evento dirigido a:"}
                      />
                    ))}
                  <div className="flex-end">
                    <Button className="btn-dark" type="submit">
                      <p>Guardar</p>
                      <FaSave />
                    </Button>
                  </div>
                </Form>
              </>
            ),
          }[templateShow]
        }
        <Row>
          <Col sm={12}>
            <FooterHome />
          </Col>
        </Row>
      </section>
    </>
  );
}
