import React, { Fragment, useEffect, useState } from 'react'
import { messageService } from '../../services/message.service'
import Moment from 'react-moment'
import {DropDownDate, DropDownStatus} from './dropDownsMessage'
import { useSelector } from 'react-redux';
import {Table} from "reactstrap";
import {IoAdd} from "react-icons/fa";
import {Button} from "rsuite";
import {Link} from "react-router-dom";
import {MessageAttachment} from "./messageAttachment";
import AnswerMessage from "./answerMessage";
import ParentMessage from "./readMessage";
import ComposeNewMessage from "./composeNewMessage";

export default function DraftMessages(props) {
    const roleName = useSelector(state => state.userSettings.roleName)
    const [userMessages, setUserMessages] = useState([])
    const [dateFilter, setDateFilter] = useState({ title: 'Descendente', value: 'desc' })
    const deleteMessage = (id) => messageService.deleteMessage(id).then(getMessages)

    const [isActive, setIsActive] = useState(1)
    const [draft, setDraft] = useState({})
    const [mobileMsg, setMobileMsg] = useState(false);

    const changeStatusMobile = () => setMobileMsg(!mobileMsg)

    const editMessage = (message) => {
        setDraft(message)
        setIsActive(5)
        setMobileMsg(!mobileMsg)
    }

    useEffect(() => {
        getMessages()
    }, [dateFilter,mobileMsg])

    const getMessages = () => {
        messageService.getMessage(3, dateFilter.value, '')
            .then((data) => setUserMessages(data))
    }

    return (
        <>
            <section className="inbox-messages-header">
                <div className="inbox-messages-filters">
                    <DropDownDate title={dateFilter.title} setFilter={setDateFilter} />
                </div>
            </section>
            <section className="table-responsive">
                <article className="messages-table">
                    <ul className={`${!mobileMsg ? '' : 'isHide'}`}>
                    {userMessages.map((message, index) =>
                        <li key={index}>
                            <div className={`msg ${message.messageDetail && message.messageDetail.isRead ? "readed" : ""}`}>
                                <Button className="btn-message" onClick={() => editMessage(message.message)}>
                                    <span className="btn-link">
                                        <div className="user">
                                            <p>{message.messageUserFrom.name}</p>
                                            <span><Moment format="DD/MM/YYYY">{message.message.createDate}</Moment></span>
                                        </div>
                                        <span>{message.message.subject}</span>
                                    </span>
                                </Button>
                            </div>
                                {/* <Button className="btn-icon-table delete" onClick={() => deleteMessage(message.message.messageId)}>
                                    <i className="icon-trash"></i>
                                </Button>*/}
                        </li>
                    )}
                    {
                        (userMessages.length === 0) ?
                            <li>
                            <span className="message-empty">
                                <i className="icon-empty"></i>
                                <p>No hay mensajes</p>
                            </span>
                            </li> : ''
                    }
                    </ul>
                    <div className={`messages-readed ${mobileMsg ? 'isShow' : ''}`}>
                        <div className="messages-readed-header">
                            <h2>Nuevo Mensaje</h2>
                            {
                                mobileMsg ?
                                    <Button className="btn-circle-message" onClick={changeStatusMobile}>
                                        <i className="icon-chevron-left"></i>
                                    </Button> : ''
                            }
                        </div>
                        <ComposeNewMessage setIsActive={setIsActive} draft={draft} setDraft={setDraft} mobileMsg={mobileMsg} changeStatusMobile={changeStatusMobile} />
                    </div>
                </article>
            </section>
        </>
    )
}
