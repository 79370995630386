import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Table } from "reactstrap";
import { companyEventTypeService } from '../../services/companyEventType.service';
import '../admin/admin.scss';
import { Button } from "rsuite";
import { Space } from 'antd';

const SearchCompanyEventType = forwardRef((props, ref) => {

    const [dataGrid, setDataGrid] = useState([]);
    const handleDelete = (event, data) => {
        companyEventTypeService.deletetCompanyEventType(data).then((result) => { getCompanyEventTypes(); });
        event.stopPropagation();
    };

    async function getCompanyEventTypes() {
        companyEventTypeService.getCompanyEventType()
            .then((result) => {
                if (result) {
                    setDataGrid(result);
                }
            });
    }

    useEffect(() => {
        getCompanyEventTypes();
    }, []);

    const refreshDataGrid = () => {
        getCompanyEventTypes();
    }

    useImperativeHandle(ref, () => ({
        refreshDataGrid: refreshDataGrid
    }));

    return (

        <div className="table-responsive">
            <Table className="admin-table" hover>
                <thead>
                    <tr>
                        <th>Tipo de evento</th>
                        <th>¿Genera recompensa?</th>
                        <th>¿Genera notificación?</th>
                        <th>¿Seleccionará la audiencia?</th>
                        <th>¿Se unirán nuevos registros?</th>
                        <th>Puntos</th>
                        <th>Estatus</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {dataGrid.map(item => (
                        <tr key={item.companyEventTypeId}>
                            <td>{item.eventTypeDesc}</td>
                            <td>{item.isRewarded ? 'Si' : 'No'}</td>
                            <td>{item.isNotification ? 'Si' : 'No'}</td>
                            <td>{item.isAudienceAddressed ? 'Si' : 'No'}</td>
                            <td>{item.onNewUserRegistry ? 'Si' : 'No'}</td>
                            <td>{item.reward}</td>
                            <td>{item.active ? 'Activo' : 'Inactivo'}</td>
                            {item.active ?
                                <td>
                                    <Space>
                                        <Button className="btn-icon-table" onClick={() => props.handleRowSelect(item)} style={{ marginRight: '.5rem' }}>
                                            <i className="icon-edit"></i>
                                        </Button>
                                        <Button className="btn-icon-table delete" onClick={(event) => handleDelete(event, item)}>
                                            <i className="icon-trash"></i>
                                        </Button>
                                    </Space>
                                </td>
                                : null}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>

    );
});

export default SearchCompanyEventType;
