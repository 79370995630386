import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import {Table} from "reactstrap";
import { branchOfficeService } from '../../../services/branchOffice.service';
import {Button} from "rsuite";
import { Space } from 'antd';
import {FaEdit, FaTrash} from "react-icons/fa";


const SearchBranchOffice = forwardRef((props, ref) => {

    const [dataGrid, setDataGrid] = useState([]);
    const handleDelete = (event, data) => {
        branchOfficeService.deleteBranchOffice(data).then((result) => { getAll(); });
        event.stopPropagation();
    };

    async function getAll() {
        branchOfficeService.getAll()
            .then((result) => {
                if (result) {
                    setDataGrid(result);
                }
            });
    }

    useEffect(() => {
        getAll();
    }, []);

    const refreshDataGrid = () => {
        getAll();
    }

    useImperativeHandle(ref, () => ({
        refreshDataGrid: refreshDataGrid
    }));

    return (
        <div className="table-responsive">
            <Table className="admin-table" hover>
                <thead>
                    <tr>
                        <th>Sucursal</th>
                        <th>Estatus</th>
                        <th>Se usa para el registro externo</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {dataGrid.map(item => (
                        <tr key={item.branchOfficeId} >
                            <td>{item.branchOfficeName}</td>
                            <td>{item.active ? 'Activo' : 'Inactivo'}</td>
                            <td>
								{item.isOnRegister ? 'Sí' : 'No'}
							</td>
                            {item.active ?
                                <td>
                                <Space>
                                    <Button className="btn-icon-table" onClick={() => props.handleRowSelect(item)}>
                                        <FaEdit />
                                    </Button>
                                    <Button className="btn-icon-table delete" onClick={(event) => handleDelete(event, item)}>
                                        <FaTrash />
                                    </Button>
                                </Space>
                                </td>
                                :
                                <td></td>
                            }
                        </tr>
                    ))}

                </tbody>

            </Table >
        </div>
    );
});

export default SearchBranchOffice;
