import React, { useState } from 'react';
import { SearchProductFilter } from './searchProductFilter';

import './rewardProductFilter.scss'
import { Link } from 'react-router-dom';
import {Col, Row} from "reactstrap";
import {FooterHome} from "../../components/footer/footerHome";
import {FaPlus} from "react-icons/fa";

export function AdminProductFilter() {
    const [refresh, setRefresh] = useState(0);

    return (
        <section className="dashboard-container-page">
            <div className="admin-subtitle">
                <div>
                    <h2>Administración de Cupones</h2>
                    <h6>Administrador</h6>
                </div>
                <Link className="btn-dark" to={"/admin/detail-product-filter/0"}>
                    <p>Agregar filtro</p>
                    <FaPlus />
                </Link>
            </div>
            <SearchProductFilter refresh={refresh} />
            <Row>
                <Col sm={12}>
                    <FooterHome />
                </Col>
            </Row>
        </section>
    );
}
