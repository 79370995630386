import React, { useEffect, useState, useRef } from 'react';
import useInputForm from '../../components/CustonHooks/FormHook'
import { Link, useParams } from 'react-router-dom';
import { Col, FormGroup, Row } from "reactstrap";
import { Button } from "rsuite";
import { useSurveyScale } from '../../services/surveyScale.service';
import { SearchSurveyScaleAnswer } from './searchSurveyScaleAnswer';
import { EditSurveyScaleAnswer } from './editSurveyScaleAnswer';
import {FaPlus, FaSave} from "react-icons/fa";

export function EditSurveyScale(props) {
    const [surveyScale, setSurveyScale] = useState({ SurveyScaleId: 0, Description: "", Active: true });
    const [refreshAnswer, setRefreshAnswer] = useState(0);
    const [showEditAnswer, setShowEditAnswer] = useState(false);
    const [selectedAnswer, setSelectedAnswer] = useState();
    const { surveyId } = useParams();

    const onSave = () => {
        if (inputs.SurveyScaleId && inputs.SurveyScaleId > 0)
            useSurveyScale.updateSurveyScale(inputs);
        else {
            useSurveyScale.createSurveyScale(inputs).then(data => {
                setSurveyScale({ SurveyScaleId: data.surveyScaleId, Description: data.description, Active: data.active });
            });
        }
    };

    const { handleSubmit, handleInputChange, inputs } = useInputForm(onSave, surveyScale);

    useEffect(() => {
        if (props.surveyScaleId !== "0") {
            useSurveyScale.getSurveyScaleById(props.surveyScaleId).then((result) => {
                setSurveyScale({ SurveyScaleId: result.surveyScaleId, Description: result.description, Active: result.active });
            });
        }

        setRefreshAnswer(prev => prev + 1);
        setSelectedAnswer(null);
        setShowEditAnswer(false);
    }, [props.survey]);

    const onSelectAnswer = (scaleAnswer) => {
        setShowEditAnswer(true);
        setSelectedAnswer({ SurveyScaleAnswerId: scaleAnswer.surveyScaleAnswerId, SurveyScaleId: scaleAnswer.surveyScaleId, Description: scaleAnswer.description, ScaleValue: scaleAnswer.scaleValue, AnswerOrder: scaleAnswer.answerOrder, Active: scaleAnswer.active });
    }
    const handleOnNewScaleAnswer = () => {
        setSelectedAnswer(null);
        setShowEditAnswer(true);
    };
    const handleOnCancelEditQuestion = () => {
        setSelectedAnswer(null);
        setShowEditAnswer(false);
    }
    const handleOnSaveEditQuestion = () => {
        setRefreshAnswer(prev => prev + 1);
        setShowEditAnswer(false);
    }

    return (
        <div className="dashboard-container-page">
            <form onSubmit={handleSubmit} className="form-dashboard">
                <input type="hidden" name="SurveyScaleId" onChange={handleInputChange} value={inputs.SurveyScaleId} />
                <div className="d-flex-between">
                    <h3>{props.surveyScaleId != 0 ? "Editar encuesta" : "Nueva encuesta"}</h3>
                    <Link className="btn-back" to={`/admin/AdminSurveyScale/${surveyId}`} ><i className="icon-chevron-left"></i><p>Regresar</p></Link>
                </div>
                <Row>
                    <Col lg={4} md={6} sm={12}>
                        <FormGroup className="form-dashboard-group">
                            <label htmlFor="">Título</label>
                            <input type="text" name="Description" onChange={handleInputChange} value={inputs.Description} required />
                        </FormGroup>
                    </Col>
                </Row>
                <div className="flex-end">
                    <Button className="btn-dark" type="submit">
                        <p>Guardar</p>
                        <FaSave />
                    </Button>
                </div>
            </form>
            {surveyScale.SurveyScaleId != 0 &&
                <Row className="form-dashboard" style={{ marginTop: '1rem' }}>
                    <Col md={6} sm={12}>
                        <div className="d-flex-between">
                            <h4>Lista de respuestas</h4>
                            {
                                (!showEditAnswer) ?
                                    <Button className="btn-dark" onClick={handleOnNewScaleAnswer}>
                                        <p>Agregar respuesta</p>
                                        <FaPlus />
                                    </Button>
                                    : null
                            }
                        </div>
                        {
                            showEditAnswer ?
                                <EditSurveyScaleAnswer surveyScaleId={surveyScale.SurveyScaleId} currentAnswer={selectedAnswer} onCancelEdit={handleOnCancelEditQuestion} onSave={handleOnSaveEditQuestion} />
                                : null
                        }
                        <SearchSurveyScaleAnswer surveyScale={{ surveyScaleId: props.surveyScaleId }} onSelectAnswer={onSelectAnswer} refresh={refreshAnswer} />
                    </Col>
                </Row>}

        </div>
    );
}
