import React, { useEffect, useState, useRef } from 'react';
import useInputForm from '../../components/CustonHooks/FormHook'
import { SelectUsers } from "../../components/Helpers/selectUsers";
import { AudienceUsers } from "../../components/Helpers/audienceUsers";
import { useSurvey } from '../../services/survey.service'
import { SearchQuestion } from './searchQuestion';
import { EditQuestion } from './editQuestion';
import { EditQuestionAnswer } from './editQuestionAnswer';
import { Link } from 'react-router-dom';
import moment from 'moment';
import swal from "sweetalert";
import { Col, FormGroup, Row } from "reactstrap";
import { Button } from "rsuite";
import {FaChevronLeft, FaChevronRight, FaPlus, FaSave} from "react-icons/fa";
import {Space} from "antd";
import { useParams } from 'react-router-dom/cjs/react-router-dom';

export function EditSurvey(props) {
    const [userSelec, setUserSelec] = useState([]);
    const [survey, setSurvey] = useState({ SurveyId: 0, Title: "", StartDate: "", EndDate: "", Reward: 0, IsFullAnswered: false, Active: true });
    const [refreshQuestions, setRefreshQuestions] = useState(0);
    const [showEditQuestion, setShowEditQuestion] = useState(false);
    const [selectedQuestion, setSelectedQuestion] = useState();
    const [surveyQuestions, setSurveyQuestions] = useState([]);
    const minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
    const maxDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));

    const params = useParams();

    const onSave = () => {
        if (userSelec.length > 0) {
            inputs.Reward = parseInt(inputs.Reward);
            inputs.ListUser = userSelec;
            if (inputs.SurveyId && inputs.SurveyId > 0)
                useSurvey.updateSurvey(inputs);
            else
                useSurvey.createSurvey(inputs);
        } else
            swal({ text: "Debes elegir al menos un usuario", icon: "error" });
    };

    const { handleSubmit, handleInputChange, inputs } = useInputForm(onSave, survey);

    useEffect(() => {
        if (parseInt(params.id) !== 0) {
            useSurvey.getSurveysById(props.surveyId).then((result) => {
                setSurvey({ 
                    SurveyId: result.surveyId, 
                    Title: result.title, 
                    StartDate: result.startDate, 
                    EndDate: result.endDate, 
                    Reward: result.reward, 
                    IsFullAnswered: result.isFullAnswered,
                     Active: result.active, 
                     IsAlreadyStarted: result.isAlreadyStarted, 
                     Estatus: result.estatus 
                });
                if (result.isAlreadyStarted && result.estatus == "Activa") {
                    swal({ text: "Algunos usuarios ya han comenzado la encuesta, por lo que ya no es posible añadir o eliminar preguntas", icon: "warning" });
                }
            });
        }

        setRefreshQuestions(prev => prev + 1);
        setSelectedQuestion(null);
        setShowEditQuestion(false);
    }, [props.survey]);

    const handleOnSelectQuestion = (question) => {
        setShowEditQuestion(true);
        setSelectedQuestion({ SurveyQuestionId: question.surveyQuestionId, SurveyId: question.surveyId, SurveyAnswerTypeId: question.surveyAnswerTypeId, Question: question.question, SurveyScaleId: question.surveyScaleId });
    }
    const handleOnNewQuestion = () => {
        setSelectedQuestion(null);
        setShowEditQuestion(true);
    };
    const handleOnCancelEditQuestion = () => {
        setSelectedQuestion(null);
        setShowEditQuestion(false);
    }
    const handleOnSaveEditQuestion = () => {
        setRefreshQuestions(prev => prev + 1);
        setShowEditQuestion(false);
    }

    return (
        <div className="dashboard-container-page">
            <form onSubmit={handleSubmit} className="form-dashboard">
                <input type="hidden" name="SurveyId" onChange={handleInputChange} value={inputs.SurveyId} />
                <Row>
                    <Col lg={4} md={6} sm={12}>
                        <FormGroup className="form-dashboard-group">
                            <label htmlFor="">Título de encuesta</label>
                            <input type="text" name="Title" onChange={handleInputChange} value={inputs.Title} required />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} md={6} sm={12}>
                        <FormGroup className="form-dashboard-group">
                            <label htmlFor="StartDate">Desde</label>
                            <input type="date" id="StartDate" name="StartDate" onChange={handleInputChange} value={moment(inputs.StartDate).format('YYYY-MM-DD')} min={moment(minDate).format('YYYY-MM-DD')} max={moment(maxDate).format('YYYY-MM-DD')} required />
                        </FormGroup>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                        <FormGroup className="form-dashboard-group">
                            <label htmlFor="EndDate">Hasta</label>
                            <input type="date" id="EndDate" name="EndDate" onChange={handleInputChange} value={moment(inputs.EndDate).format('YYYY-MM-DD')} min={moment(inputs.StartDate).format('YYYY-MM-DD')} max={moment(maxDate).format('YYYY-MM-DD')} required />
                        </FormGroup>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                        <FormGroup className="form-dashboard-group">
                            <label htmlFor="Reward">Recompensa</label>
                            <input type="text" id="Reward" name="Reward" onChange={handleInputChange} value={inputs.Reward} placeholder="0" required />
                        </FormGroup>
                    </Col>
                    <Col xs={12} style={{background: 'rgba(0,0,0,.1)', padding: '1rem', borderRadius: '.5rem'}} className="mb-3">
                        {props.surveyId > 0 ?
                            <AudienceUsers targetId={props.surveyId} eventType="Encuesta" selectUser={setUserSelec} />
                            :
                            <SelectUsers selectUser={setUserSelec} title={"Encuesta dirigida a:"} />
                        }
                    </Col>
                </Row>
                <div className="form-dashboard-footer">
                    <Button className="btn-dark" type="submit">
                        <p>Guardar</p>
                        <FaSave />
                    </Button>
                    {survey.IsFullAnswered ?
                        <Link className="rs-btn btn-dark" to={"/admin/survey-results/" + props.surveyId}>
                            Ver Resultados
                            <FaChevronRight />
                        </Link>
                        : null
                    }
                </div>
            </form>
            {parseInt(params.id) !== 0 ?
                <Row className="form-dashboard" style={{ marginTop: '1rem' }}>
                    <Col lg={6} md={12} sm={12}>
                        <div className="admin-heading">
                            <h4>Lista de preguntas</h4>
                            {
                                (!showEditQuestion) ?
                                    <Button 
                                        disabled={survey.IsAlreadyStarted && survey.Estatus == "Activa"} 
                                        className="btn-dark" 
                                        onClick={handleOnNewQuestion}
                                    >
                                        <p>Agregar pregunta</p>
                                        <FaPlus />
                                    </Button>
                                    : null
                            }
                        </div>
                        {
                            showEditQuestion ?
                                <EditQuestion 
                                    surveyId={props.surveyId} 
                                    isSurveyAlreadyStarted={survey.IsAlreadyStarted && survey.Estatus == "Activa"} 
                                    currentQuestion={selectedQuestion} 
                                    onCancelEdit={handleOnCancelEditQuestion} 
                                    onSave={handleOnSaveEditQuestion} 
                                />
                                : null
                        }
                        <SearchQuestion 
                            setSurveyQuestions={setSurveyQuestions} 
                            survey={{ surveyId: props.surveyId }} 
                            isSurveyAlreadyStarted={survey.IsAlreadyStarted && survey.Estatus == "Activa"} 
                            onSelectQuestion={handleOnSelectQuestion} 
                            refresh={refreshQuestions} 
                        />
                    </Col>
                    <Col lg={6} md={12} sm={12}>
                        <h4>Respuestas</h4>
                        {showEditQuestion ?
                            <EditQuestionAnswer 
                                surveyQuestions={surveyQuestions} 
                                isSurveyAlreadyStarted={survey.IsAlreadyStarted && survey.Estatus == "Activa"} 
                                surveyId={props.surveyId} currentQuestion={selectedQuestion} 
                                onCancelEdit={handleOnCancelEditQuestion} 
                                onSave={handleOnSaveEditQuestion} 
                            />
                            :
                            <div className="box_empty mt-5" style={{color:'black',background: 'rgba(0,0,0,.05)', padding: '1rem', borderRadius: '.5rem'}}>
                                <span><i className="fas fa-exclamation-triangle text-danger"></i></span>
                                <h6 className="text fnt_medium">Selecciona una pregunta</h6>
                            </div>
                        }
                    </Col>
                </Row>
                : null}

        </div>
    );
}   
