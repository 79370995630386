import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { courseService } from '../../services/course.service';
import {Table} from "reactstrap";
import {Button} from "rsuite";
import { Space } from 'antd';
import { FaEdit, FaTrash } from 'react-icons/fa';

const SearchCourse = forwardRef((props, ref) => {
    const [dataGrid, setDataGrid] = useState([]);

    const handleDelete = (event, data) => {
        courseService.deleteCourse(data).then((result) => { getCourse(); });
        event.stopPropagation();
    };

    async function getCourse() {
        courseService.getCourse().then((result) => {if (result) setDataGrid(result);});
    }

    useEffect(() => {
        getCourse();
    }, []);

    const refreshDataGrid = () => getCourse();

    useImperativeHandle(ref, () => ({
        refreshDataGrid: refreshDataGrid
    }));

    return (
        <article className="table-responsive">
            <Table className="admin-table" hover>
                <thead>
                    <tr>
                        <th>Tipo de capacitación</th>
                        <th>Nombre</th>
                        <th>Descripción</th>
                        <th>Estatus</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {dataGrid.map(item => (
                        <tr key={item.courseId} >
                            <td>{item.courseMediaTypeName}</td>
                            <td>{item.name}</td>
                            <td>{item.description}</td>
                            <td>{item.active ? 'Activo' : 'Inactivo'}</td>
                            {item.active ?
                                <td>
                                    <Space>
                                        <Button className="btn-icon-table edit" onClick={() => props.handleRowSelect(item)}>
                                            <FaEdit />
                                        </Button>
                                        <Button type="button" className="btn-icon-table delete" onClick={(event) => handleDelete(event, item)}>
                                            <FaTrash />
                                        </Button>
                                    </Space>
                                </td>
                                : null
                            }
                        </tr>
                    ))}

                </tbody>
            </Table>
        </article>
    );

});

export default SearchCourse;
