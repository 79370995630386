import React, { useEffect, useState } from "react";
import { ReactDOM } from "react";
import swal from "sweetalert";
import { categoryServiceService } from "../../services/categoryService.service";
import { useForm, useFormState } from "react-hook-form";
import "./registerServices.scss";
import { ValidacionEmail } from "../../utils/ValidacionMail";
import { ValidateName } from "../../utils/ValidateName";
import {Button, Col, FormGroup, Label, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import { BsInfoCircle, BsUpload} from "react-icons/bs";
import {Input} from "rsuite";
import { FaTimes} from "react-icons/fa";

export function FormPersonal(props) {
  let {
    categoryServiceId,
    contactImage,
    directoryContacts,
    fullName,
    location,
    subCategoryServiceId,
    setPercentage,
    calcPercentage
  } = props.data;

  const activeTab3 = props.activeTab3;

  const [datos, setDatos] = useState({
    nombre: "",
    descripcion: "",
    correo: "",
    contacto1: null,
    contacto1DirectoryContactId: null,
    contacto2: null,
    contacto2DirectoryContactId: null,
    categoria: "",
    subcategoria: "",
    location: "",
    imagen: "",
  });
  const [emailCorrecto, setErrorMail] = useState(false);
  const [nombreCorrecto, setErrorNombre] = useState(false);
  const [telefonoCorrecto, setErrorTel] = useState(false);
  const [band, setBand] = useState(false);
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [subCategoryFilter, setSubCategoryFilter] = useState([]);
  const [contactArray, setContactArray] = useState(null);
  const { register, handleSubmit, reset } = useForm({});
  const [countChar, setCountChar] = useState(0);


  useEffect(() => {
    categoryServiceService.getCategoryCatalog().then((categories) => {
      if (categories != null) {
        setCategoryFilter(categories);
      }
    });
  }, []);

  const handleInputChange = (event) => {
      switch (event.target.name) {
        case "correo":
          let error = false;
          if (event.target.value === "") {
            setErrorMail(error);
          } else {
            error = ValidacionEmail(event.target.value);
            setErrorMail(error);
          }
          break;
        case "nombre":
          let errorNombre = false;
          if (event.target.value === "") {
            setErrorNombre(errorNombre);
          } else {
            errorNombre = ValidateName(event.target.value);
            setErrorNombre(errorNombre);
          }
          break;
        case "contacto1":
          let errorContacto = event.target.value === "" ? false : true;
          event.target.value !== null
              ? setDatos({...datos, contacto1: event.target.value})
              : setErrorTel(errorContacto);
          break;
        case "contacto2":
          setDatos({...datos, contacto2: event.target.value});
          break;
        case "categoria":
          if (event.target.value === "0") {
            setSubCategoryFilter([]);
            setDatos({...datos, subcategoria: 0});
          } else {
            setDatos({
              ...datos,
              categoria: event.target.value,
            });
            categoryServiceService
                .getSubCategoriesCatalogByCatId(
                    event.target.value,
                    process.env.REACT_APP_COMPANY
                )
                .then((subs) => {
                  setSubCategoryFilter(subs.sort((a, b) => a[1] - b[1]));
                  setDatos({
                    ...datos,
                    categoria: subs[0].categoryServiceId,
                    subcategoria: subs[0].subCategoryServiceId,
                  });
                });
          }
          break;
        case "descripcion":
          setCountChar(event.target.value.length);
        default: break;
      }
      if(event.target.name !== "imagen") setDatos({...datos, [event.target.name]: event.target.value,})
  }

  useEffect(() => {
    let Data;
    if (props.register.personal.hasOwnProperty("nombre")) {
      let reg = props.register.personal;
      Data = {
        nombre: reg.nombre,
        correo: reg.correo,
        contacto1: reg.contacto1,
        contacto2: reg.contacto2,
        categoria: reg.categoria,
        subcategoria: reg.subcategoria,
        location: reg.location,
        imagen: reg.imagen,
        descripcion: reg.descripcion,

      };
    } else {
      Data = {
        nombre: fullName,
        correo: "",
        contacto1:
          directoryContacts !== undefined && directoryContacts.length > 0
            ? directoryContacts[0].contactValue
            : null,
        contacto1DirectoryContactId:
          directoryContacts !== undefined && directoryContacts.length > 0
            ? directoryContacts[0].directoryContactId
            : null,
        contacto2:
          directoryContacts !== undefined && directoryContacts.length > 1
            ? directoryContacts.filter(x => x.contactTypeId == 1).length > 1
              ? directoryContacts.filter(x => x.contactTypeId == 1)[1].contactValue
              : null
            : null,
        contacto1optDirectoryContactId:
          directoryContacts !== undefined && directoryContacts.length > 1
            ? directoryContacts.filter(x => x.contactTypeId == 1).length > 1
              ? directoryContacts.filter(x => x.contactTypeId == 1)[1].directoryContactId
              : null
            : null,
        contacto2DirectoryContactId:
          directoryContacts !== undefined && directoryContacts.length > 1
            ? directoryContacts.filter(x => x.contactTypeId == 2).length > 0
              ? directoryContacts.filter(x => x.contactTypeId == 2)[0].directoryContactId
              : null
            : null,
        categoria: categoryServiceId,
        subcategoria: subCategoryServiceId,
        location: location,
        imagen: contactImage,
      };
    }

    if (props.data && props.data?.categoryServiceId !== null && props.data?.categoryServiceId != undefined && props.data?.categoryServiceId !== 0) 
    {
      categoryServiceService
        .getSubCategoriesCatalogByCatId(
          props.data.categoryServiceId,
          process.env.REACT_APP_COMPANY
        )
        .then((subs) => {
          setSubCategoryFilter(subs);
          setDatos({
            ...datos,
            categoria: props.data.categoryServiceId,
            subcategoria: props.data.subCategoryServiceId,
            nombre: props.data.fullName,
            descripcion: props.data.directoryDescription,
            location: props.data.location,
            //Concatacto1
            contacto1: props.data.directoryContacts[0].contactValue,
            contacto1DirectoryContactId:
              props.data.directoryContacts[0].directoryContactId,
            //Correo
            correo: props.data.directoryContacts.filter(x => x.contactTypeId == 2)[0].contactValue,
            contacto2DirectoryContactId:
              props.data.directoryContacts.filter(x => x.contactTypeId == 2)[0].directoryContactId,
            //Concato2
            contacto2:
            props.data.directoryContacts.filter(x => x.contactTypeId == 1).length > 1 ?
            props.data.directoryContacts.filter(x => x.contactTypeId == 1)[1].contactValue : null,
            contacto1optDirectoryContactId:
              props.data.directoryContacts.filter(x => x.contactTypeId == 1).length > 1 ?
                props.data.directoryContacts.filter(x => x.contactTypeId == 1)[1].directoryContactId : null,
            imagen: props.data.contactImage,
          });
          setErrorMail(true);
          setErrorNombre(true);
          setErrorTel(true);
        });
      }
    setDatos(Data);

    setErrorMail(Data.correo !== "" ? true : false);
    setErrorNombre(Data.nombre !== "" ? true : false);
    setErrorTel(Data.contacto1 !== "" ? true : false);
  }, [props.data]);

  function updateImage(e) {
    var file = e.target.files[0];
    props.setFile(file);
    var reader = new FileReader();
    var url = reader.readAsDataURL(file);

    reader.onloadend = function () {
      setDatos({
        ...datos,
        imagen: [reader.result],
      });
    };
  }

  function deleteImg() {
    setDatos({ ...datos, imagen: "" });
    props.setFile(null);
    setBand(false);
  }

  function enviadatos(e) {
    if (emailCorrecto === false) {
      swal({ text: "Correo incorrecto, por favor revise.", icon: "error" });
    } else {
      if (nombreCorrecto === false) {
        swal({ text: "Nombre incorrecto, por favor revise.", icon: "error" });
      } else {
        if (telefonoCorrecto === false) {
          swal({
            text: "Teléfono incorrecto, por favor revise.",
            icon: "error",
          });
        } else {
          props.Datepersonal(datos, "personal");
          props.toggleView();
        }
      }
    }
  }

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <form className="form-register-services" onSubmit={handleSubmit(enviadatos)}>
        <div className="title"><h3>Información personal</h3></div>
        <div className="group">
          <FormGroup className="form-dashboard-group mr">
            <Label for="">Nombre</Label>
            <input defaultValue={datos["nombre"]} type="text" id={datos["nombre"]} name="nombre" required={true} maxLength={500} onChange={handleInputChange}/>
          </FormGroup>
          <FormGroup className="form-dashboard-group">
            <Label for="">Correo electrónico</Label>
            <input type="email" value={datos["correo"]} id={datos["correo"]} required={true} name="correo" errormessage="Por favor, especifique una dirección de correo válida." onChange={handleInputChange}/>
          </FormGroup>
        </div>
        <div className="group-columns">
          <div className="columns mr">
            <FormGroup className="form-dashboard-group">
              <Label for="">Teléfono de contacto</Label>
              <input type="number" id="1" required={true} defaultValue={datos.contacto1 !== null ? datos.contacto1 : null} name="contacto1" onChange={handleInputChange}/>
            </FormGroup>
            <FormGroup className="form-dashboard-group">
              <Label for="">Teléfono de contacto 2{" "} (Opcional)</Label>
              <input type="number" id="2" name="contacto2" defaultValue={datos.contacto2 !== null ? datos.contacto2 : null} onChange={handleInputChange}/>
            </FormGroup>
            <FormGroup className="form-dashboard-group">
              <Label for="">Ubicación</Label>
              <input defaultValue={datos["location"]} type="text" id={datos["location"]} name="location" required={true} onChange={handleInputChange}/>
            </FormGroup>
          </div>
          <div className="columns">
            <FormGroup className="form-dashboard-group">
              <Label>Descripción corta del anuncio  </Label>&nbsp;&nbsp;&nbsp; <small> <i>( {countChar + "/" + 255} )</i> </small>
              <textarea defaultValue={datos["descripcion"]} id={datos["descripcion"]} rows="5" required={true} name="descripcion" maxLength={255} onChange={handleInputChange}></textarea>
            </FormGroup>
          </div>
        </div>
        <div style={{paddingTop: '1rem'}}><h3>Información profesional</h3></div>
        <FormGroup className="form-dashboard-group">
            <Label for="exampleFormControlSelect1">Categoría</Label>
            <select id="exampleFormControlSelect1" name="categoria" value={datos["categoria"] || 0} required={true} onChange={handleInputChange}>
              <option value={0}></option>
              {categoryFilter &&
                categoryFilter.map((categoryItem, index) => (
                  <option key={index} value={categoryItem.categoryServiceId}>
                    {categoryItem.categoryName}
                  </option>
                ))}
            </select>
          </FormGroup>
        <div>
          <FormGroup className="form-dashboard-group">
            <Label for="exampleFormControlSelect1">Subcategoría</Label>
            <select id="exampleFormControlSelect1" name="subcategoria" value={datos["subcategoria"] || 0} onChange={handleInputChange} required={true}>
              <option value={0}></option>
              {subCategoryFilter &&
                subCategoryFilter.map((subCategoryItem, index) => (
                  <option key={index} value={subCategoryItem.subCategoryServiceId}>
                    {subCategoryItem.subCategoryName}
                  </option>
                ))}
            </select>
          </FormGroup>
        </div>
        <div>
          <FormGroup className="form-dashboard-group-file">
            {(datos.imagen) ?
                <>
                  <p>Previsualización</p>
                  <div className="info-uploaded">
                    <button className="btn-close-file" type="button" onClick={deleteImg}><FaTimes /></button>
                    <img alt="Imagen destacada en directorio de Romeh" src={datos.imagen} onLoad={(e) => {
                      if (!props.loadPicture) return;
                      props.setLoadPicture(false);
                      let value = e.target.src;
                      let name = value
                          .substring(value.lastIndexOf("/") + 1)
                          .replace("%", " ");
                      fetch(value)
                          .then((res) => res.blob())
                          .then((blob) => {
                            const file = new File([blob], name, blob);
                            props.setFile(file);
                          });
                    }} onClick={toggle} />
                  </div>
                </>
              :
                <>
                  <p className="text-start">Imagen destacada </p>
                  <div className="info-upload">
                    <input type="file" id="file-upload" placeholder="Imagen destacada" accept="image/*" name="imagen" onChange={(e) => updateImage(e)} />
                    <Label className="btn-upload-file" for="file-upload">
                      <i className="icon-upload-file"></i> Subir archivo
                    </Label>
                    <div className="extra">
                      <span><BsInfoCircle /></span>
                      <p>El tamaño recomendado de la imagen es de <b>1280 x 720</b> pixeles.</p>
                    </div>
                  </div>
                </>
            }
          </FormGroup>
          <Modal isOpen={modal} toggle={toggle} style={{paddingTop: '8rem'}}>
            <ModalBody style={{padding: 0, position: 'relative' }}>
              <Button className="btn-outline-dark close" onClick={toggle}><FaTimes /></Button>
              <div className="modal-img">
                <img alt="Imagen destacada en directorio de Romeh" src={datos.imagen} onLoad={(e) => {
                  if (!props.loadPicture) return;
                  props.setLoadPicture(false);
                  let value = datos.imagen;
                  let name = value
                      .substring(value.lastIndexOf("/") + 1)
                      .replace("%", " ");
                  fetch(value)
                      .then((res) => res.blob())
                      .then((blob) => {
                        const file = new File([blob], name, blob);
                        props.setFile(file);
                      });
                }}/>
              </div>
            </ModalBody>
          </Modal>
        </div>
        <div className="flex-end">
            <Button type="submit" className="btn-gradients-green">
              <p>Siguiente</p>
              <div className="circle"><i className="icon-chevron-right"></i></div>
            </Button>
          </div>
    </form>
  );
}
