import React, { useRef, useState } from "react";
import {TabContent, TabPane, Nav, NavItem, NavLink, Row, Col} from "reactstrap";
import classnames from "classnames";
import { EditorPost } from "./editor";

import { Form, FormGroup, Label, Input } from "reactstrap";
import useInputSingleForm from "../../components/CustonHooks/inputFormHook";
import { GeneralUpload } from "../../components/Helpers/generalUpload";
import SearchCompanyPost from "./searchCompanyPost";
import { companyPostService } from "../../services/companyPost.service";
import { SelectUsers } from "../../components/Helpers/selectUsers";
import { AudienceUsers } from "../../components/Helpers/audienceUsers";
import { CompanyPostAttachment } from "../../components/companyPost/companyPostAttachment";
import { storageService } from "../../services/storage.service";

import "./ourCompanyAdmin.scss";

import { AdminGallery } from "./galleries/adminGallery";
import swal from "sweetalert";
import {Button, Checkbox} from "rsuite";
import {FooterHome} from "../../components/footer/footerHome";
import {FaChevronLeft, FaPlus, FaSave, FaTimesCircle, FaTrash} from "react-icons/fa";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export function CompanyPost() {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const [isChecked, setIsChecked] = useState(false);
  let companyInit = {
    CompanyPostId: 0,
    CompanyPostCategoryId: 1,
    Title: "",
    Subtitle: "",
    Text: "",
    DepartmentId: 0,
    JobRoleId: 0,
  };
  const [localfiles, setLocalFiles] = useState([]);
  const [localAttachments, setLocalAttachments] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [userSelec, setUserSelec] = useState([]);
  const [images, setImages] = useState([]);
  const [preview1, setPreview1] = useState([]);
  const [preview2, setPreview2] = useState([]);
  const gridRef = useRef();

  const onSave = () => {
    let model = {
      CompanyPostId: parseInt(inputs.CompanyPostId),
      CompanyPostCategoryId: isChecked ? 2 : 1,
      Title: inputs.Title,
      Subtitle: inputs.Subtitle,
      Text: inputs.Text,
      ListUser: userSelec,
    };

    if (userSelec.length > 0) {
      if (parseInt(inputs.CompanyPostId) === 0) {
        companyPostService
          .create(model, localfiles, localAttachments)
          .then((result) => {
            gridRef.current.refreshDataGrid();
          });
      } else {
        companyPostService
          .update(model, localfiles, localAttachments)
          .then((result) => {
            gridRef.current.refreshDataGrid();
          });
      }
      setUserSelec([]);
      setInputs({});
      setShowEdit(false);
      setLocalFiles([]);
      setLocalAttachments([]);
    } else {
      swal({ text: "Debes elegir al menos un usuario", icon: "error" });
    }
  };
  const { inputs, handleInputChange, handleSubmit, setInputs } =
    useInputSingleForm(onSave, {});

  const handleOnCancel = () => {
    setInputs({});
    setShowEdit(false);
    setUserSelec([]);
    setLocalFiles([]);
    setLocalAttachments([]);
  };

  const handleOnChangeFile = (files) => {
    let convertingFile = URL.createObjectURL(files[0]);
    setPreview1(convertingFile)
    setLocalFiles(files)
  };

  const handleOnChangeAttach = (attachments) => {
    let convertingFile = URL.createObjectURL(attachments[0]);
    setPreview2(convertingFile)
    setLocalAttachments(attachments)
  };

  const handleOnNew = () => {
    setInputs(companyInit);
    setShowEdit(true);
    setUserSelec([]);
    setImages([]);
    setLocalFiles([]);
    setLocalAttachments([]);
  };

  const handleRowSelect = (gridItem) => {
    setShowEdit(true);
    setInputs({
      CompanyPostId: gridItem.companyPostId,
      Title: gridItem.title,
      Subtitle: gridItem.subtitle,
      Text: gridItem.text,
      CompanyPostCategoryId: gridItem.companyPostCategoryId === 1 ? false : true,
    });

    setIsChecked(gridItem.companyPostCategoryId === 1 ? false : true);
    companyPostService
      .getCompanyPostImagesById(gridItem.companyPostId)
      .then((result) => {
        setImages(result.files);
      });
  };

  const handleDeleteImage = (e, value) => {
    if(value) {
      storageService
          .deleteStorage({
            EntityId: images[0].entityId,
            EntityTypeId: images[0].entityTypeId,
            Sequence: images[0].sequence,
          })
          .then((result) => {
             setLocalFiles([]);
          });
    } else {
      storageService
          .deleteStorage({
            EntityId: images[0].entityId,
            EntityTypeId : images[0].entityTypeId,
            Sequence: images[0].sequence,
          })
          .then((result) => {
            setLocalAttachments([]);
          });
    }
    e.stopPropagation();
  };

  const handleCheckboxChange = (e) => setIsChecked(e.target.checked);


  return (
    <section className="dashboard-container-page">
      <div className="admin-subtitle">
        <div>
          <h2 >Preguntas frecuentes</h2>
          <h6>Administrador</h6>
        </div>
        <Link to="/admin" className="btn-back">
          <FaChevronLeft />
          <p>Regresar</p>
        </Link>
      </div>

      <Nav className="admin-tabs" tabs>
        <NavItem>
          <NavLink className={classnames({ active: activeTab === "1" })} onClick={() => {toggle("1");}}>Artículos</NavLink>
        </NavItem>
        <NavItem>
            <NavLink className={classnames({ active: activeTab === "2" })} onClick={() => {toggle("2");}}>Galerías</NavLink>
        </NavItem>
      </Nav>

      <TabContent className="admin-tabs-content" activeTab={activeTab}>
        <TabPane tabId="1">
          <div className="card-tabs">
            {/* ==== LISTA DE ARTICULOS ==== */}
            {!showEdit ? (
                <>
                  <div className="admin-heading">
                    <h3>Lista de artículos</h3>
                    <Button type="button" className="btn-dark" onClick={handleOnNew}>
                      <p>Agregar entrada</p>
                      <FaPlus />
                    </Button>
                  </div>
                  <SearchCompanyPost handleRowSelect={handleRowSelect} ref={gridRef}/>
                </>
            ) : null}
            {/* ==== LISTA DE NUEVO/EDITAR ==== */}
            <div className="wrapper_editor_textual">
              {showEdit ? (
                <Form onSubmit={handleSubmit}>
                    <div className="admin-heading" style={{marginBottom: '1rem'}}>
                      <h4>Información de la entrada</h4>
                      <Button className="btn-back" type="button" onClick={handleOnCancel}>
                        <FaTimesCircle />
                        <p>Cancelar</p>
                      </Button>
                    </div>
                    <Row>
                      <Col md={6} sm={12}>
                        <FormGroup className="form-dashboard-group">
                          <Label for="Title">Título de entrada</Label>
                          <Input name="Title" onChange={handleInputChange} value={inputs.Title} required></Input>
                        </FormGroup>
                        <FormGroup className="form-dashboard-group-checkbox">
                          <div className="checkbox-checkmark">
                            <label className="toggleButton">
                              <input type="checkbox" id="chkOrgullo" checked={isChecked} onChange={handleCheckboxChange} />
                                <div>
                                  <svg viewBox="0 0 44 44">
                                    <path d="M14,24 L21,31 L39.7428882,11.5937758 C35.2809627,6.53125861 30.0333333,4 24,4 C12.95,4 4,12.95 4,24 C4,35.05 12.95,44 24,44 C35.05,44 44,35.05 44,24 C44,19.3 42.5809627,15.1645919 39.7428882,11.5937758" transform="translate(-2.000000, -2.000000)"></path>
                                  </svg>
                                </div>
                            </label>
                            <p>Es Consulta</p>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={6} sm={12}>
                          {
                            (preview1 && preview1.length > 0 || images && images.length > 0) ?
                                (images.length > 0) ?
                                  <FormGroup className="form-dashboard-group-upload">
                                    <div className="uploaded-file">
                                      <img src={`${(images[0].file) ? 'data:application/octet-stream;base64,' : '' }${images[0].file}`} alt="" className="img-file" />
                                      <Button className="btn-remove-file" onClick={(event) => handleDeleteImage(event,true)}>
                                        <FaTrash />
                                      </Button>
                                    </div>
                                  </FormGroup>
                                  :
                                  <FormGroup className="form-dashboard-group-upload">
                                    <div className="uploaded-file">
                                      <img src={preview1} alt="" className="img-file" />
                                      <Button className="btn-remove-file" onClick={(event) => handleDeleteImage(event,false)}>
                                        <i className="icon-trash"></i>
                                      </Button>
                                    </div>
                                  </FormGroup>
                             :
                                <GeneralUpload TitleLabel={"Imagen de la entrada"} handleOnChangeFiles={handleOnChangeFile}/>
                          }
                      </Col>
                    </Row>
                    <Row>
                      <Col md={5} sm={12}>
                        {
                          (preview2 && preview2.length > 0 || images && images.length > 0) ?
                              (images.length > 0) ?
                                  <FormGroup className="form-dashboard-group-upload">
                                    <div className="uploaded-file">
                                      <img src={`${(images[0].file) ? 'data:application/octet-stream;base64,' : '' }${images[0].file}`} alt="" className="img-file" />
                                      <Button className="btn-remove-file" onClick={(event) => handleDeleteImage(event,false)}>
                                        <i className="icon-trash"></i>
                                      </Button>
                                    </div>
                                  </FormGroup>
                                  :
                                  <FormGroup className="form-dashboard-group-upload">
                                    <div className="uploaded-file">
                                      <img src={preview2} alt="" className="img-file" />
                                      <Button className="btn-remove-file" onClick={(event) => handleDeleteImage(event,false)}>
                                        <FaTrash />
                                      </Button>
                                    </div>
                                  </FormGroup>
                              :
                              <GeneralUpload TitleLabel={"Archivos adjuntos"} IsMultipe={true} handleOnChangeFiles={handleOnChangeAttach}/>
                        }
                      </Col>
                      <Col md={7} sm={12}>
                        {
                          (inputs.CompanyPostId !== null && inputs.CompanyPostId > 0) ? (
                          <CompanyPostAttachment CompanyPostId={inputs.CompanyPostId} IsDelete={true}/>
                        ) : null}
                      </Col>
                    </Row>
                    <Row>
                      <EditorPost content={inputs.Text} name="Text" handleInputChange={handleInputChange}/>
                    </Row>
                    <Row style={{marginTop: '1rem'}}>
                      <Col sm={12}>
                        {
                          (inputs.CompanyPostId > 0) ?
                            <AudienceUsers targetId={inputs.CompanyPostId} eventType="Articulo" selectUser={setUserSelec}/>
                         :
                            <SelectUsers selectUser={setUserSelec} title={"Entrada dirigida a:"}/>
                        }
                      </Col>
                    </Row>
                  <div className="flex-end">
                    <Button className="btn-dark" type="submit">
                      <p>Guardar</p>
                      <FaSave />
                    </Button>
                  </div>
                </Form>
              ) : null}
            </div>
          </div>
        </TabPane>
        <TabPane tabId="2">
          <div className="card-tabs"><AdminGallery /></div>
        </TabPane>
      </TabContent>
      <Row>
        <Col sm={12}>
          <FooterHome />
        </Col>
      </Row>
    </section>
  );
}
