import React, { useState } from 'react';
import IcoUser from '../../assets/imgs/iconos/ico_user.png'
import { userService } from '../../services/user.service';
import { useForm } from 'react-hook-form';
import swal from 'sweetalert';
import { Button, Col, Form, Input } from "reactstrap";
import { IoMail } from "react-icons/io5";

export function RecoverPassword({ toggleLogeo }) {
    const { register, handleSubmit } = useForm();
    const [account, setAccount] = useState("");
    const [isRecovery, setisRecovery] = useState(false);
    const [isGranted, setisGranted] = useState(false);
    const [additionalEmailVal, setAdditionalEmailVal] = useState("");
    const [isCorporateUser, setIsCorporateUser] = useState(false);

    const validateAccount = () => {
        if (account) {
            userService.validateEmailAccount({ email: account }).then((result) => {
                if (result != undefined) {
                    setIsCorporateUser(result.isCorporateUser)
                    if (result.userId != undefined)
                        setisGranted(true);

                    if (isCorporateUser)
                        setisRecovery(true);
                    else {
                        if (result.additionalEmail) {
                            setAdditionalEmailVal(result.additionalEmail);
                            setisRecovery(true);
                        }
                        else {
                            setisRecovery(false);
                        }
                    }
                }
                else {
                    swal("El usuario no está registrado.");
                }
            })
        }
        else {
            swal("Capture una dirección de correo");
        }
    }

    const handleChange = (event) => {
        setAccount({ email: event.target.value });
    }

    const onSubmit = (data, e) => {
        data.CompanyId = process.env.REACT_APP_COMPANY;
        data.email = account;
        if (isRecovery) {
            data.IsRegistered = true;
            data.AdditionalEmail = additionalEmailVal;
        }
        else {
            data.IsRegistered = false;
        }
        if (!isCorporateUser) {
            if (data.AdditionalEmail !== undefined && data.AdditionalEmail !== "") {
                userService.forgotPassword(data)
            }
        }
        else {
            userService.forgotPassword(data)
        }
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)} className="form-login" row>
            <input type="hidden" name="AdditionalEmail"  {...register("AdditionalEmail")} />
            <input type="hidden" name="Birthday" {...register("Birthday")} />
            <input type="hidden" name="IsRegistered" {...register("IsRegistered")} />

            <Col lg={12}>
                <h2 className="login-header-title">RECUPERAR CONTRASEÑA</h2>
                <p className="login-header-subtitle">Ingresa tu correo electrónico y te enviaremos las instrucciones sobre cómo restablecerla.</p>
            </Col>
            <Col lg={12}>
                <label form="email" className="form-login-input">
                    <span className="form-login-icon"><IoMail /></span>
                    <Input onChange={(e) => setAccount(e.target.value)} id="email" placeholder="Correo electrónico" type="email" name="Email" />
                </label>
            </Col>
            <Col lg={12}>
                <div className="form-login-recover">
                    <button type="button" onClick={() => toggleLogeo(true)}>Iniciar sesión</button>
                </div>
                {!isGranted ?
                    <div className="form-login-submit">
                        <Button className="btn-login" type="submit" id="btnValidate" onClick={validateAccount}>CONSULTAR</Button>
                    </div>
                    : null}
            </Col>
            <Col lg={12}>
                {!(isRecovery) && isGranted && !isCorporateUser ?
                    <div className="form-group mt-4">
                        <span>Es necesario efectuar una comprobación de identidad. Captura los siguientes datos: </span>
                        <br />
                        <label for="Birthday">Fecha de nacimiento</label>
                        <input className="form-control" type="date" id="Birthday" name="Birthday"  {...register("Birthday")} />
                        <label for="AdditionalEmail">Correo Electrónico para recupear contraseña</label>
                        <input type="email" className="form-control" id="AdditionalEmail" name="AdditionalEmail" aria-describedby="emailHelp" placeholder="correo electrónico" {...register("AdditionalEmail")} />
                    </div>
                    : null}
                {isGranted ?
                    <div className="form-login-submit">
                        <button type="submit" className="btn-login">Recuperar contraseña</button>
                    </div>
                    : null}
            </Col>
        </Form>
    )
}
