import React, { useEffect, useState } from "react";
import "./audience.scss";
import "moment/locale/es";
import { BoxListAudience } from "./boxListAudience";
import { audienceService } from "../../services/audience.service";
import { AdvertisingSection } from "../../components/advertisingSection/advertisingSection";

import bgPridetalks from "../../assets/imgs/dashboard/bg_pridetalks.svg";
import { SideAdvertising } from "../../components/sideAdvertising/sideAdvertising";
import moment from "moment";
import { Col, Row } from "reactstrap";
// import { metaPixelService } from "../../services/metaPixel.service";
import { Button } from "rsuite";
import { useCompanyHoliday } from "../../services/companyHoliday.service";
import { FooterHome } from "../../components/footer/footerHome";

export function Audience(props) {
  const [activeAsideSurvey, setStateAsideSurvey] = useState("");
  const [listAudienceP, setListAudienceP] = useState([]);
  const [listAudienceO, setListAudienceO] = useState([]);
  const [disabledHasMore, setDisabledHasMore] = useState(false);
  const itemsPerPage = 4;

  const AsidePurchaseBox = () => {
    setStateAsideSurvey(activeAsideSurvey === "" ? " is-show" : "");
  };

  async function getPending() {
    audienceService.getPending().then((result) => {
      if (result) setListAudienceP(result);
    });
  }

  async function getComplete() {
    audienceService.updateComplete(0, 0, itemsPerPage).then((result) => {
      if (result) setListAudienceO(result);
    });
  }

  async function updateComplete(date, id) {
    audienceService.updateComplete(date, id, itemsPerPage).then((result) => {
      if (result && result.length > 0) {
        setListAudienceO([...listAudienceO, ...result]);
      } else {
        setDisabledHasMore(true)
      }
    });
  }

  const [sliderItems, setSliderItems] = useState([]);
  const [banner, setBanner] = useState([]);
  const [defaultBanner, setDefaultBanner] = useState(true);
  const [holyday, setHolyday] = useState({ type: 0, src: "" })

  useEffect(() => {
    getPending();
    getComplete();
    getCompanyHolidays();
    getCompanyHolidaysSide();
  }, []);

  async function getCompanyHolidays() {
    if (4) {
      useCompanyHoliday.getCompanyHolidayFront(4).then((result) => {
        if (result) {
          let temp = [];
          result.map((ch) => {
            temp.push({
              key: ch.companyHolidayId.toString(),
              file: ch.imagePath,
            });
            setDefaultBanner(false)
          });
          const bannerItem = temp.sort(() => Math.random() - Math.random())
            .find(() => true);
          setBanner(bannerItem)
        }
      });
    }
  }

  async function getCompanyHolidaysSide() {
    useCompanyHoliday.getCompanyHolidayFront(3).then((result) => {
      if (result) {
        let temp = [];
        result.map((ch) => {
          temp.push({
            key: ch.companyHolidayId.toString(),
            file: ch.imagePath,
            description: ch.description,
            isVideo: ch.isVideo,
            link: ch.iframe
          });
        });
        setSliderItems(temp);
      }
    });
  }

  return (
    <section className="dashboard-container-page">
      <article className="audience-container">
        <Row>
          <Col sm={12}>
            <div className="d-flex-between">
              <h2>Mis rutinas</h2>
              {(sliderItems && sliderItems.length > 0) ?
                <Button className="dots" onClick={AsidePurchaseBox}><i className="icon-dots-v"></i></Button> : ''
              }
            </div>
          </Col>
          {
            (banner) ?
              <Col sm={12}>
                <AdvertisingSection HolType={4} />
              </Col>
              : ''
          }
          {
            (sliderItems && sliderItems.length > 0) ?
              <>
                <Col lg={10} md={12} sm={12}>
                  <Row>
                    <Col sm={12}>
                      {
                        (listAudienceP.length !== 0) ?
                          listAudienceP.slice(0, 1).map((card, key) => (
                            <article className="audience-header" style={{ backgroundImage: `url(${bgPridetalks})` }} key={key}>
                              <div className="audience-header-info">
                                <h4>¡Disfruta de las transmisiones!</h4>
                                <div className="content">
                                  <h3>{card.data.title}</h3>
                                  <p>{moment(card.data.conferenceDate).format("LLLL")}</p>
                                  <div className="flex-start">
                                    <a href={`${card.data.conferenceUrl}`} className="btn-gradient-play" target="_blank"
                                      // onClick={() => {
                                      //   const data = {
                                      //     content_name: card.data.title,
                                      //     content_type: "product",
                                      //     contents: [
                                      //       {
                                      //         id: card.data.auditoriumId,
                                      //         quantity: 1,
                                      //         link: card.data.conferenceUrl,
                                      //       },
                                      //     ],
                                      //   };
                                      //   metaPixelService.trackEvent(
                                      //     "track",
                                      //     "ViewContent",
                                      //     data
                                      //   );
                                      // }}
                                    >
                                      <p>Ver video</p>
                                      <i className="icon-play"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="audience-header-thumb">
                                <div className="audience-card">
                                  <div className="img"><img src={card.files.length > 0 && card.files[0].path} alt={card.data.title} /></div>
                                </div>
                              </div>
                            </article>
                          ))
                          :
                          listAudienceO.slice(0, 1).map((card, key) => (
                            <article className="audience-header" style={{ backgroundImage: `url(${bgPridetalks})` }} key={card.eventId}>
                              <div className="audience-header-info">
                                <h4>¡Disfruta de las transmisiones!</h4>
                                <div className="content">
                                  <h3>{card.data.title}</h3>
                                  <p>{moment(card.data.conferenceDate).format("LLLL")}</p>
                                  <div className="flex-start">
                                    <a href={`/${card.data.conferenceUrl}`} className="btn-gradient-play" target="_blank"
                                      // onClick={() => {
                                      //   const data = {
                                      //     content_name: card.data.title,
                                      //     content_type: "product",
                                      //     contents: [
                                      //       {
                                      //         id: card.data.auditoriumId,
                                      //         quantity: 1,
                                      //         link: card.data.conferenceUrl,
                                      //       },
                                      //     ],
                                      //   };
                                      //   metaPixelService.trackEvent(
                                      //     "track",
                                      //     "ViewContent",
                                      //     data
                                      //   );
                                      // }}
                                    >
                                      <p>Ver video</p>
                                      <i className="icon-play"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="audience-header-thumb">
                                <div className="audience-card">
                                  <div className="img"><img src={card.files.length > 0 && card.files[0].path} alt={card.data.title} /></div>
                                </div>
                              </div>
                            </article>
                          ))
                      }
                    </Col>
                    <Col sm={12}>
                      <h4>{props.Title}</h4>
                    </Col>
                    <Col sm={12}>
                      {listAudienceP.length > 0 || listAudienceO.length > 0 ? (
                        <BoxListAudience Title="Disfruta de transmisiones" Items={listAudienceP} OldItems={listAudienceO} updateComplete={updateComplete} disabledHasMore={disabledHasMore} />
                      ) : null}
                    </Col>
                  </Row>
                </Col>
                <Col lg={2} md={12} sm={12}>
                  <aside className={`aside-lateral ${activeAsideSurvey}`}>
                    <Button className="btn-close" onClick={AsidePurchaseBox}><i className="icon-close"></i></Button>
                    <SideAdvertising sliderItems={sliderItems} />
                  </aside>
                </Col>
              </>
              :
              <>
                <Col sm={12}>
                  <Row>
                    <Col sm={12}>
                      {
                        listAudienceO.slice(0, 1).map((card, key) => (
                          <article className="audience-header" style={{ backgroundImage: `url(${bgPridetalks})` }} key={card.eventId}>
                            <div className="audience-header-info">
                              <h4>¡Disfruta de las transmisiones!</h4>
                              <div className="content">
                                <h3>{card.data.title}</h3>
                                <p>{moment(card.data.conferenceDate).format("LLLL")}</p>
                                <div className="flex-start">
                                  <a href={card.data.conferenceUrl} className="btn-gradient-play" target="_blank"
                                    // onClick={() => {
                                    //   const data = {
                                    //     content_name: card.data.title,
                                    //     content_type: "product",
                                    //     contents: [
                                    //       {
                                    //         id: card.data.auditoriumId,
                                    //         quantity: 1,
                                    //         link: card.data.conferenceUrl,
                                    //       },
                                    //     ],
                                    //   };
                                    //   metaPixelService.trackEvent(
                                    //     "track",
                                    //     "ViewContent",
                                    //     data
                                    //   );
                                    // }}
                                  >
                                    <p>Ver video</p>
                                    <i className="icon-play"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="audience-header-thumb">
                              <div className="audience-card">
                                <div className="img"><img src={card.files.length > 0 && card.files[0].path} alt={card.data.title} /></div>
                              </div>
                            </div>
                          </article>
                        ))
                      }
                    </Col>
                    <Col sm={12}>
                      <h4>{props.Title}</h4>
                    </Col>
                    <Col sm={12}>
                      {listAudienceP.length > 0 || listAudienceO.length > 0 ? (
                        <BoxListAudience Title="Disfruta de transmisiones" Items={listAudienceP} OldItems={listAudienceO} updateComplete={updateComplete} disabledHasMore={disabledHasMore} />
                      ) : null}
                    </Col>
                  </Row>
                </Col>
              </>
          }
          <Col sm={12}>
            <FooterHome />
          </Col>
        </Row>
      </article>
    </section>
  );
}
