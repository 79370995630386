import React, {useState} from "react";
import { Link, useParams } from "react-router-dom";
import "./register.scss";
import LogoCompany from "../../assets/imgs/RodrigoRomeh.png";
import { userService } from "../../services/user.service";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Legals } from "../../components/legals/legals";
import { newAccountService } from "../../services/newAccountService.service";
import "dayjs/locale/es";
import { Col, Form, Row, Input, Select, DatePicker } from "antd";
import { BoxSocialNetworks } from "../../components/boxSocialNetworks";
import {Aside} from "./aside";
import {ImgCache} from "../../components/CustonHooks/imgCache";
import { Button } from 'rsuite';
import locale from 'antd/es/date-picker/locale/es_ES';
import moment from "moment";
import dayjs from "dayjs";
import {FaEnvelope, FaKey, FaPhone, FaUser} from "react-icons/fa";

export const Register = () => {
  const companyLogo = useSelector(state => state.userSettings.companyLogo);
  const accountSettings = useSelector((state) => state.accountSettings);
  const { referralToken } = useParams();
  const [maritalStatusCatalog, setMaritalStatus] = useState([]);
  let [nacionalityCatalog, setNacionalityCatalog] = useState([]);
  const [newUser, setNewUser] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    NationalityTypeId: '',
    birthday: '',
    gender: '',
    mobile: 0,
    mobileConfirm: 0,
    PostalCode: 0,
    email: '',
    password: '',
    confirmPassword: ''
  });

  const getMaritalStatus = () => {
    newAccountService
      .getMaritalStatusCatalog()
      .then((cat) => {
        setMaritalStatus(cat);
      })
      .catch((err) => alert(err));
  };

  const getNacionality = () => {
    newAccountService
      .getNacionalityCatalog()
      .then((nac) => {
        setNacionalityCatalog(nac);
      })
      .catch((err) => console.log(err));
  };

    const getMinDate = () => {
        const substractDate = moment().subtract(18,'years')
        const minDate = substractDate.format('DD/MMMM/YYYY')
        const dateReceived = moment(minDate, 'DD/MMMM/YYYY');
        const dateConverted = dateReceived.format('YYYY-MM-DD');
        setNewUser({...newUser, birthday: dateConverted})
    }

    useEffect(() => {
        getMaritalStatus();
        getNacionality();
        getMinDate();
    }, []);

    const onSubmit = (data) => {
        if (accountSettings.activateRegistration) registerUser(newUser);
    };

  const registerUser = (data) => {
    data.CompanyId = process.env.REACT_APP_COMPANY;
    data.RFC = data.iniciales + data.fechanac;
    data.NationalityTypeId = data.NationalityTypeId === "" ? "1" : data.NationalityTypeId;
    data.CountryStateTypeId = data.CountryStateTypeId === "" ? "1" : data.CountryStateTypeId;
    data.MaritalStatusTypeId = data.MaritalStatusTypeId === "" ? "1" : data.MaritalStatusTypeId;
    data.IsCorporateUser = true;
    data.gender = data.gender;
    data.ReferralToken = referralToken;
    userService.register(data);
  };


    const handleInputChange = (e) => setNewUser({...newUser, [e.target.name]: e.target.value});

    const handleSelectNationality = (value) => setNewUser({...newUser, NationalityTypeId: value});

    const handleSelectSex = (value) => setNewUser({...newUser, gender: value});

    const handleDate = (date) => {
        const dateReceived = moment(date, 'DD/MMMM/YYYY');
        const dateConverted = dateReceived.format('YYYY-MM-DD');
        setNewUser({...newUser, birthday: dateConverted})
    }

    const handleConfirmPhone = (e) => {
        if(e.target.value === newUser.mobile) setNewUser({...newUser, mobileConfirm: e.target.value})
    }

    const handleConfirmPassword = (e) => {
        if(e.target.value === newUser.password)
            setNewUser({...newUser, confirmPassword: e.target.value});
    }

  return (
    <section className="register-wrapper">
      <article className="register-container">
        <Aside message="¡Quiero unirme!" />
        <div className="register-container-form">
          <div className="register-container-form-header">
            <ImgCache src={companyLogo ? companyLogo : LogoCompany} className="logo" alt="Kuimby Social" />
          </div>
          <div className="form-register">
            <div className="form-register-title">
              <h2>Registrarse</h2>
            </div>
            <Form
              onFinish={onSubmit}
              autoComplete="off"
              className="form-register"
              layout="vertical"
              fields={[
                  {
                      "name": ['birthday'],
                      "value": dayjs(newUser.birthday)
                  },
              ]}
            >
              <h5>Datos de usuario</h5>
              <Row gutter={16}>
                <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                  <Form.Item
                      label="Nombre(s)"
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: '*Campo requerido'
                        }
                      ]}
                      onChange={handleInputChange}
                  >
                    <Input type="text" name="firstName" size="large"
                           prefix={<FaUser />}
                    />
                  </Form.Item>
                </Col>
                  <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                  <Form.Item
                      label="Apellido Paterno"
                      name="middleName"
                      rules={[
                        {
                          required: true,
                          message: '*Campo requerido'
                        }
                      ]}
                      onChange={handleInputChange}
                  >
                    <Input prefix={<FaUser />} type="text" name="middleName" size="large" />
                  </Form.Item>
                </Col>
                  <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                  <Form.Item
                      label="Apellido Materno"
                      name="lastName"
                      rules={[
                        {
                          required: true,
                          message: '*Campo requerido'
                        }
                      ]}
                      onChange={handleInputChange}
                  >
                    <Input prefix={<FaUser />} type="text" name="lastName" size="large" />
                  </Form.Item>
                </Col>
                <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                  <Form.Item
                      label="Nacionalidad"
                      name="NationalityTypeId"
                      rules={[
                          {
                              required: true,
                              message: '*Campo requerido'
                          }
                      ]}
                  >
                    <Select
                      name="NationalityTypeId"
                      rules={[
                        {
                          required: true,
                          message: '*Campo requerido'
                        }
                      ]}
                      size="large"
                      onChange={handleSelectNationality}
                    >
                      {nacionalityCatalog.map((x) => (
                        <Select.Option key={x.nationalityTypeId} value={x.nationalityTypeId}>{x.description}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                <Form.Item
                    label="Fecha de Nacimiento"
                    name="birthday"
                    rules={[
                      {
                        required: true,
                        message: '*Campo requerido'
                      }
                    ]}
                >
                    <DatePicker
                        style={{width: '100%'}}
                        locale={locale}
                        disabledDate={(current) => moment().subtract(18,'years')  < current}
                        format="DD/MMMM/YYYY"
                        allowClear={false}
                        onChange={(_, dateString) => handleDate(dateString)}
                        size="large"
                        name="birthday"
                        picket="date"
                        showToday={false}
                    />
                  </Form.Item>
                  <p className="border-pointed register">Queremos felicitarte cada año.</p>
                </Col>
                <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                  <Form.Item
                      label="Género"
                      name="gender"
                      rules={[
                        {
                          required: true,
                          message: '*Campo requerido'
                        }
                      ]}
                  >
                    <Select
                        name="gender"
                        size="large"
                        onChange={handleSelectSex}
                    >
                      <Select.Option value="M">Masculino</Select.Option>
                      <Select.Option value="F">Femenino</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xl={9} lg={12} md={9} sm={12} xs={24}>
                  <Form.Item
                      label="Teléfono celular"
                      name="mobile"
                      rules={[
                        {
                          required: true,
                          message: '*Campo requerido'
                        }
                      ]}
                      onChange={handleInputChange}
                  >
                    <Input
                        type="text"
                        maxLength={10}
                        minLength={10}
                        onKeyPress={(e) => {
                            e = e || window.e;
                            const charCode = e.keyCode || e.which;
                            const charStr = String.fromCharCode(charCode);
                            if (isNaN(charStr)) e.preventDefault();
                        }}
                        onKeyDown={(e) => {}}
                        size="large"
                        name="mobile"
                        prefix={<FaPhone />}
                    />
                  </Form.Item>
                </Col>
                <Col xl={9} lg={12} md={9} sm={12} xs={24}>
                  <Form.Item
                      label="Confirmar teléfono celular"
                      name="mobileConfirm"
                      rules={[
                        {
                          required: true,
                          message: '*Campo requerido'
                        },
                          ({ getFieldValue }) => ({
                              validator(_, value) {
                                  if (!value || getFieldValue('mobile') === value) {
                                      return Promise.resolve();
                                  }
                                  return Promise.reject(new Error('¡Los números no coinciden!'));
                              },
                          }),
                      ]}
                      onChange={handleConfirmPhone}
                  >
                    <Input
                        size="large"
                        type="text"
                        disabled={(newUser.mobile === 0)}
                        maxLength={10}
                        minLength={10}
                        onKeyPress={(e) => {
                            e = e || window.e;
                            const charCode = e.keyCode || e.which;
                            const charStr = String.fromCharCode(charCode);
                            if (isNaN(charStr)) e.preventDefault();
                        }}
                        name="mobileConfirm"
                        prefix={<FaPhone />}
                    />
                  </Form.Item>
                </Col>
                <Col xl={6} lg={9} md={6} sm={8} xs={12}>
                  <Form.Item
                      label="Código postal"
                      name="PostalCode"
                      onChange={handleInputChange}
                      rules={[
                          {
                              required: true,
                              message: '*Campo requerido'
                          },
                          {
                              min: 5,
                              message: 'Se necesitan 5 dígitos'
                          }
                      ]}
                  >
                    <Input type="text"
                           minLength={5}
                           maxLength={5}
                           name="PostalCode"
                           size="large"
                           onKeyPress={(e) => {
                               e = e || window.e;
                               const charCode = e.keyCode || e.which;
                               const charStr = String.fromCharCode(charCode);
                               if (isNaN(charStr)) e.preventDefault();
                           }}
                           prefix={<FaUser />}
                    />
                  </Form.Item>
                    <div>
                        <p className="border-pointed register">
                            Buscamos llevar esta comunidad a cada rincón.
                        </p>
                    </div>
                </Col>
                <Col xs={24}>
                  <h5 className="mt-5 mb-1">Datos de la cuenta</h5>
                </Col>
                <Col xs={24}>
                  <Form.Item
                      label="Correo electrónico"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: '*Campo requerido'
                        },
                        {
                          email: true,
                          message: '*Formato de correo inválido'
                        }
                      ]}
                      onChange={handleInputChange}
                  >
                    <Input prefix={<FaEnvelope />} type="email" name="email" size="large" />
                  </Form.Item>
                  <p className="border-pointed register">¡No olvides verificar tu cuenta! Revisa bandeja de entrada o spam de tu correo electrónico registrado.</p>
                </Col>
                <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                  <Form.Item
                      label="Contraseña"
                      name="password"
                      rules={[
                         {
                              required: true,
                              message: ''
                         },
                         {
                              min: 8,
                              message: <small>Mínimo 8 carácteres</small>
                         },
                          {
                              pattern: new RegExp(/.*[A-Z].*/),
                              message: <small>Se necesita al menos 1 letra mayúscula</small>
                          },
                          {
                              pattern: new RegExp(/.*[a-z].*/),
                              message: <small>Se necesita al menos 1 letra minúsucla</small>
                          },
                          {
                              pattern: new RegExp(/.*[0-9].*/),
                              message: <small>Se necesita al menos 1 número</small>
                          },
                          {
                              pattern: new RegExp(/(.*[!@#$%\^&*(){}[\]<>?/|-]+|.*[!@#$%\^&*(){}[\]<>?/|\-]+)/),
                              message: <small>Se necesita al menos 1 carácter especial</small>
                          },
                      ]}
                      onChange={handleInputChange}
                      hasFeedback
                  >
                    <Input.Password prefix={<FaKey />} name="password" size="large" minLength={8} />
                  </Form.Item>
                </Col>
                <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                  <Form.Item
                      label="Confirmar contraseña"
                      name="confirmPassword"
                      rules={[
                          {
                              required: true,
                              message: ''
                          },
                          ({ getFieldValue }) => ({
                              validator(_, value) {
                                  if (!value || getFieldValue('password') === value) {
                                      return Promise.resolve();
                                  }
                                  return Promise.reject(new Error('¡Las contraseñas no coinciden!'));
                              },
                          }),
                      ]}
                      onChange={handleConfirmPassword}
                  >
                    <Input.Password
                        disabled={(newUser.password.length === 0)}
                        name="confirmPassword"
                        size="large"
                        prefix={<FaKey />}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <div className="form-register-submit">
                <Button type="submit" className="btn-outline-login">Registrarse</Button>
                <Link to="/login" type="button" className="btn-outline-dark-login">Regresar</Link>
              </div>
            </Form>
          </div>
          <div className="register-container-form-social-media">
            <BoxSocialNetworks />
            <Legals />
          </div>
        </div>
      </article>
    </section>
  );
}
