import React, { useRef, useState } from "react";
import { Col, Form, FormGroup, Input, Label, Modal, ModalBody, Row } from "reactstrap";
import useInputSingleForm from "../../components/CustonHooks/inputFormHook";
import { SelectUsers } from "../../components/Helpers/selectUsers";
import { AudienceUsers } from "../../components/Helpers/audienceUsers";
import { SelectCustom } from "../../components/Helpers/SelectCustom";
import SearchCourse from "../../components/courseAdmin/searchCourse";
import { courseService } from "../../services/course.service";
import { GeneralUpload } from "../../components/Helpers/generalUpload";
import { CourseAttachment } from "../../components/courseAdmin/courseAttachment";
import ImgInfog from "../../assets/imgs/imgInfografia.jpg";
import videoYT from "../../assets/imgs/video-yt.jpg";
import pdf from "../../assets/imgs/pdf.png";
import swal from "sweetalert";
import moment from 'moment';
import '../admin/admin.scss';
import { Button } from "rsuite";
import {FaDownload, FaPlus, FaChevronLeft, FaSave, FaTimes} from "react-icons/fa";
import { FooterHome } from "../../components/footer/footerHome";
import { companyEventTypeService } from '../../services/companyEventType.service';
import { useEffect } from "react";
import { EventTypes } from "../../enums/eventTypes";
import { useHistory } from "react-router-dom";

export function AdminCourse() {
    const gridRef = useRef();
    const [showEdit, setShowEdit] = useState(false);
    const [userSelec, setUserSelec] = useState([]);
    const [localAttachments, setLocalAttachments] = useState({ file: {}, url: "" });
    const [createCalendarEvent, setCreateCalendarEvent] = useState(false);
    const [isAudienceAddressed, setIsAudienceAddressed] = useState(false);
    const [isPdf, setIsPdf] = useState(false);
    const minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
    const maxDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
    const history = useHistory()

    let initTraining = {
        CourseId: 0,
        CourseMediaTypeId: 0,
        Name: "",
        Description: "",
        StartDate: "",
        EndDate: "",
        Reward: 0,
        UrlMedia: "",
        EventId: 0,
        DepartmentId: 0,
        JobRoleId: 0
    };

    const onSave = () => {
        let model = {
            CourseId: inputs.CourseId,
            CourseMediaTypeId: parseInt(inputs.CourseMediaTypeId),
            Name: inputs.Name,
            Description: inputs.Description,
            StartDate: new Date(inputs.StartDate),
            EndDate: new Date(inputs.EndDate),
            Reward: parseInt(inputs.Reward),
            UrlMedia: inputs.UrlMedia,
            EventId: inputs.EventId,
            DepartmentId: parseInt(inputs.DepartmentId),
            RoleId: parseInt(inputs.JobRoleId),
            ListUser: userSelec,
        };
        let isValid = true;
        let errorMessage = "";
        let isNew = parseInt(inputs.CourseId) == 0;

        if (isNew) {
            if (isAudienceAddressed && userSelec.length <= 0) {
                isValid = false;
                errorMessage = "Debes elegir al menos un usuario";
            }

            if (inputs.CourseMediaTypeId == 1) {
                if (localAttachments.file.length == 0) {
                    isValid = false;
                    errorMessage = "Debes elegir al menos un archivo";
                }
            }
        } else {
            if (isAudienceAddressed && userSelec.length <= 0) {
                isValid = false;
                errorMessage = "Debes elegir al menos un usuario";
            }

            if (inputs.CourseMediaTypeId == 1) {
                if (localAttachments.file.length == 0) {
                    isValid = false;
                    errorMessage = "Debes elegir al menos un archivo";
                }
            }
        }

        if (isValid && isNew) {
            courseService
                .createCourse(model, inputs.CourseMediaTypeId == 3 ? null : localAttachments.file, createCalendarEvent)
                .then((result) => {
                    setShowEdit(false);
                    gridRef.current.refreshDataGrid();
                    setLocalAttachments({ file: {}, url: "" });
                });
        } else if (isValid && !isNew) {
            courseService
                .updateCourse(model, inputs.CourseMediaTypeId == 3 ? null : localAttachments.file)
                .then((result) => {
                    setShowEdit(false);
                    gridRef.current.refreshDataGrid();
                    setLocalAttachments({ file: {}, url: "" });
                });
        } else {
            swal({ text: errorMessage, icon: "error" });
        }
    };

    const {
        inputs,
        handleInputChange,
        handleSubmit,
        setInputs,
    } = useInputSingleForm(onSave, initTraining);

    const handleOnNewCourse = () => {
        setInputs(initTraining);
        setShowEdit(true);
    };

    const handleOnCancel = () => {
        if(showEdit === false) {
            history.push('/admin')
            return;
        }
        setInputs({});
        setShowEdit(false);
        setLocalAttachments({ file: {}, url: "" });
    };

    const formatDate = (date) => {
        return date.getFullYear() +
            "-" +
            ("0" + (date.getMonth() + 1)).slice(-2) +
            "-" +
            ("0" + date.getDate()).slice(-2);
    };

    const handleRowSelect = async (gridItem) => {
        setInputs({
            CourseId: gridItem.courseId,
            CourseMediaTypeId: gridItem.courseMediaTypeId,
            Name: gridItem.name,
            Description: gridItem.description == null ? "" : gridItem.description,
            StartDate: formatDate(new Date(gridItem.startDate)),
            EndDate: formatDate(new Date(gridItem.endDate)),
            Reward: gridItem.reward,
            UrlMedia: gridItem.urlMedia,
            EventId: gridItem.eventId
        });

        if (gridItem.courseId) {
            await getAttachments(gridItem.courseId);
        }

        if (gridItem.courseMediaTypeId == 1) {
            setIsPdf(true);
        }

        setShowEdit(true);
    };

    async function getAttachments(courseId) {
        if (courseId != null && courseId > 0) {
            courseService.getCourseFilesById(courseId).then((result) => {
                if (result != null && result.files.length > 0) {
                    const file = b64toBlob(result.files[0].file, 'application/pdf', result.files[0].fileName);
                    let url = URL.createObjectURL(file);
                    setLocalAttachments({ file: [file], url });
                }
            });
        }
    }

    const b64toBlob = (b64Data, contentType = '', filename = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return new File([blob], filename, { type: contentType });
    }

    const handleOnChangeAttach = (attachments) => {
        const imagesExt = ['image/png', 'image/svg', 'image/jpg', 'image/jpeg', 'image/webp'];
        const filesExt = ['application/pdf'];
        if (imagesExt.includes(attachments[0].type)) {
            let convertedToURL = URL.createObjectURL(attachments[0]);
            setLocalAttachments({ file: attachments, url: convertedToURL });
            return;
        }

        if (filesExt.includes(attachments[0].type)) {
            let convertedToURL = URL.createObjectURL(attachments[0]);
            setLocalAttachments({ file: attachments, url: convertedToURL });
            setIsPdf(true)
            return;
        }

        setLocalAttachments({ file: {}, url: '' });
    }

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const [iframeData, setIframeData] = useState(null);

    const handleIframe = (e) => {
        const range = document.createRange();
        const fragment = range.createContextualFragment(e.target.value);
        setIframeData(fragment.firstChild.src)
    }

    const handleImageUploadRemove = () => setLocalAttachments({ file: {}, url: "" });

    const getCompanyEventType = () => {
        companyEventTypeService.getByCompanyAndEventType(process.env.REACT_APP_COMPANY, EventTypes.COURSE)
            .then((result) => {
                if (result) {
                    setIsAudienceAddressed(result.isAudienceAddressed);
                }
            });
    };

    useEffect(() => {
        getCompanyEventType();
    }, []);

    return (
        <>
            <section className="dashboard-container-page">
                <article className="admin-subtitle">
                    <div>
                        <h2>Escuela Romeh</h2>
                        <h6>Listado</h6>
                    </div>
                    <Button className="btn-back" onClick={handleOnCancel}>
                        <FaChevronLeft />
                        <p>Regresar</p>
                    </Button>
                </article>
                {!showEdit ? (
                    <>
                        <div className="admin-heading">
                            <div></div>
                            <Button type="button" className="btn-dark" onClick={handleOnNewCourse}>
                                <p>Agregar cartelera</p>
                                <FaPlus />
                            </Button>
                        </div>
                        <SearchCourse handleRowSelect={handleRowSelect} ref={gridRef} />
                    </>
                ) : null}
                {showEdit ? (
                    <Form className="form-dashboard" onSubmit={handleSubmit}>
                        <Row>
                            <Col lg={4} md={6} sm={12}>
                                <FormGroup className="form-dashboard-group">
                                    <Label for="CourseMediaTypeId">Tipo de taller</Label>
                                    <SelectCustom
                                        selectName="CourseMediaTypeId"
                                        handleOnChange={handleInputChange}
                                        selectValue={inputs.CourseMediaTypeId}
                                        method="CourseMediaType"
                                        textField="name"
                                        valueField="courseMediaTypeId"
                                        required
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={4} md={6} sm={12}>
                                <FormGroup className="form-dashboard-group">
                                    <Label for="Name">Nombre</Label>
                                    <Input name="Name" onChange={handleInputChange} value={inputs.Name} required></Input>
                                </FormGroup>
                            </Col>
                            <Col lg={4} md={6} sm={12}>
                                <FormGroup className="form-dashboard-group">
                                    <Label for="Description">Descripción</Label>
                                    <Input name="Description" onChange={handleInputChange} value={inputs.Description} required />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4} md={6} sm={12}>
                                <FormGroup className="form-dashboard-group">
                                    <Label for="StartDate">Desde</Label>
                                    <Input type="date" name="StartDate" onChange={handleInputChange} value={inputs.StartDate}
                                        min={moment(minDate).format('YYYY-MM-DD')}
                                        max={moment(maxDate).format('YYYY-MM-DD')}
                                        required
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={4} md={6} sm={12}>
                                <FormGroup className="form-dashboard-group">
                                    <Label for="EndDate">Hasta</Label>
                                    <Input type="date" name="EndDate" onChange={handleInputChange} value={inputs.EndDate}
                                        min={moment(inputs.StartDate).format('YYYY-MM-DD')}
                                        max={moment(maxDate).format('YYYY-MM-DD')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={4} md={6} sm={12}>
                                <FormGroup className="form-dashboard-group">
                                    <Label for="Reward">Reward</Label>
                                    <Input name="Reward" onChange={handleInputChange} value={inputs.Reward} required />
                                </FormGroup>
                            </Col>
                            {parseInt(inputs.CourseId) == 0 && <Col lg={4} md={6} sm={12}>
                                <FormGroup check>
                                    <Label for="CreateCalendarEvent" check>Crear evento de calendario</Label>
                                    <Input type="checkbox" name="CreateCalendarEvent" onChange={() => setCreateCalendarEvent(!createCalendarEvent)} />
                                </FormGroup>
                            </Col>}
                            <br />
                        </Row>
                        <Row>
                            <Col sm={12}>
                                {
                                    {
                                        1: (
                                            <Row>
                                                <Col md={6} sm={12} style={{ padding: '0' }}>
                                                    {
                                                        (localAttachments.url) ?
                                                            (isPdf) ?
                                                                <div className="form-dashboard-group-pdf">
                                                                    <div className="uploaded-file">
                                                                        <img src={pdf} alt="" className="img-file" />
                                                                        <Button className="btn-remove-file" onClick={handleImageUploadRemove}>
                                                                            <i className="icon-close"></i>
                                                                        </Button>
                                                                    </div>
                                                                    <a className="btn-dark" href={localAttachments.url} target="_self" download>
                                                                        <p>Descargar</p>
                                                                        <FaDownload />
                                                                    </a>
                                                                </div>
                                                                :
                                                                <div className="form-dashboard-group-upload" style={{ margin: '0' }}>
                                                                    <div className="uploaded-file">
                                                                        <img src={localAttachments.url} alt="" className="img-file" />
                                                                        <Button className="btn-remove-file" onClick={handleImageUploadRemove}>
                                                                            <i className="icon-close"></i>
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            :
                                                            <GeneralUpload accept="image/*,application/pdf" TitleLabel={"Media"} IsMultipe={false} handleOnChangeFiles={handleOnChangeAttach} />
                                                    }
                                                </Col>
                                            </Row>
                                        ),
                                        2: (
                                            <Row>
                                                <Col md={6} sm={12}>
                                                    <FormGroup className="form-dashboard-group-file">
                                                        <Label for="UrlMedia">Url Media</Label>
                                                        <Input name="UrlMedia" onChange={handleInputChange} value={inputs.UrlMedia} />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6} sm={12}>
                                                    {
                                                        (localAttachments.url) ?
                                                            <FormGroup className="form-dashboard-group-upload" style={{ margin: '0' }}>
                                                                <div className="uploaded-file">
                                                                    <img src={localAttachments.url} alt="" className="img-file" />
                                                                    <Button className="btn-remove-file" onClick={handleImageUploadRemove}>
                                                                        <i className="icon-close"></i>
                                                                    </Button>
                                                                </div>
                                                            </FormGroup>
                                                            :
                                                            <GeneralUpload accept="image/*" TitleLabel={"Media"} IsMultipe={false} handleOnChangeFiles={handleOnChangeAttach} />
                                                    }
                                                </Col>
                                            </Row>
                                        ),
                                        3: (
                                            <Row>
                                                <Col xl={8} lg={7} md={12} sm={12} className="admin-iframe">
                                                    <div className="form-dashboard-group">
                                                        <Label for="iframe" style={{ top: '-1.7rem' }}>Iframe para video <i>(Youtube)</i></Label>
                                                        <textarea name="UrlMedia" id="iframe" rows="3" onChange={(e) => { handleInputChange(e); handleIframe(e) }} value={(inputs.UrlMedia) ? inputs.UrlMedia : ''}></textarea>
                                                    </div>
                                                    <div className="admin-iframe-footer">
                                                        <Button className="btn-outline-dark" onClick={toggle}>
                                                            <p>¿Cómo crear un iframe en youtube?</p>
                                                            <div className="circle"><i className="icon-chevron-right"></i></div>
                                                        </Button>
                                                    </div>
                                                </Col>
                                                <Col xl={4} lg={5} md={12} sm={12} className="admin-iframe">
                                                    {
                                                        (iframeData) ?
                                                            <iframe src={iframeData} title="YouTube video player"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                            >
                                                            </iframe>
                                                            :
                                                            <img className="yt" src={videoYT} alt="Video Youtube" />
                                                    }
                                                </Col>
                                            </Row>
                                        ),
                                    }[inputs.CourseMediaTypeId]
                                }
                            </Col>
                            {/* <Col md={4} sm={12}>
                                {(inputs.CourseMediaTypeId == 1 || inputs.CourseMediaTypeId == 2)&&
                                    inputs.CourseId != null &&
                                    inputs.CourseId > 0 ? (
                                        <CourseAttachment
                                            CourseId={inputs.CourseId}
                                            IsDelete={true}
                                            setCountStorageFiles={setCountStorageFiles}
                                        />
                                    ) : null}
                            </Col> */}
                            <Col sm={12}>
                                {isAudienceAddressed &&
                                    (inputs.CourseId > 0 ? (
                                        <>
                                            <AudienceUsers targetId={inputs.CourseId} eventType="Curso" selectUser={setUserSelec} />
                                        </>
                                    ) : (
                                        <>
                                            <SelectUsers selectUser={setUserSelec} title={"Taller dirigido a:"} />
                                        </>
                                    ))
                                   }
                            </Col>
                            <Col sm={12}>
                                <div className="flex-end">
                                    <Button className="btn-dark" type="submit">
                                        <p>Guardar</p>
                                        <FaSave />
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                ) : null}
                <Row>
                    <Col sm={12}>
                        <FooterHome />
                    </Col>
                </Row>
            </section>
            <Modal isOpen={modal} toggle={toggle} centered={true}>
                <ModalBody style={{ width: '100%', borderRadius: '1rem' }}>
                    <Button className="btn-close-modal" onClick={toggle} >
                        <FaTimes />
                    </Button>
                    <img src={ImgInfog} width="100%" style={{ width: '100%', borderRadius: '1rem', objectFit: 'contain' }} alt="Infografía Kuimby" />
                </ModalBody>
            </Modal>
        </>
    );
}
