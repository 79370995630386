import React, { useState, useEffect } from 'react';
import Slider from "react-slick"

import './sliderDiary.scss'

import { useFrontDiary } from '../../services/diary.service';
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useDiary } from '../../services/diary.service'

export function DiarySlider() {
    const [sliderItems, setSliderItems] = useState([]);

    useEffect(() => {
        getEvents();
    }, []);

    async function getEvents() {
        useFrontDiary.getCompanyHolidayFront(1).then((result) => {
            if (result) {
                var temp = [];
                result.map((ch) => {
                    temp.push({
                        key: ch.companyHolidayId.toString(),
                        file: ch.imagePath,
                        description: ch.description,
                        link: ch.iframe ? ch.iframe : "#"
                    });
                });
                setSliderItems(temp);
            }
        });
    }

    function goToPage(link, eventTargetId) {
        window.open(link, '_blank');
        obtainReward(eventTargetId);
    }

    const obtainReward = (eventTargetId) => {
        useDiary.obtainCompanyDiaryReward(eventTargetId);
    }


    const settings = {
        dots: false,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3500,
        adaptiveHeight: true
    };
    return (
        <div className="diary-slider">
            {sliderItems && sliderItems.length > 0 ?
                <Splide
                    options={{
                        arrows: true,
                        autoplay: true,
                        rewind: true,
                        perMove: 1,
                        perPage: 1,
                        rewindByDrag: true,
                        speed: 1000,
                        type: 'fade',
                        drag: true,
                        pagination: true
                    }}
                >
                    {
                        sliderItems.map(item =>
                            <SplideSlide key={item.key}>
                                {
                                    item.link && item.eventTargetId ?
                                        <a onClick={() => goToPage(item.link, item.eventTargetId)}>
                                            <img className="item" src={item.file} alt={item.description} />
                                        </a>
                                        :
                                        <a href={item.link} target='_blank'>
                                            <img className="item" src={item.file} alt={item.description} />
                                        </a>
                                }
                            </SplideSlide>)
                    }
                </Splide>
                : <img className="item" src="/static/media/R_C.796d6968.png" alt="Romeh" style={{ width: "50%", marginLeft: "auto", marginRight: "auto", display: "block" }} />}
        </div>
    )
}
