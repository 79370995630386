import React, { useEffect, useState } from "react";
import {Col, Row, DropdownToggle, DropdownMenu, Dropdown} from "reactstrap";
import { companyPostViewService } from "../../services/compayPostView.service";
import { backendService } from "./../../services/backend.service";
import { CompanyPostAttachment } from "../../components/companyPost/companyPostAttachment";
import { AsideOurCompany } from "./asideOurCompany";
import "./ourCompany.scss";
import Moment from "react-moment";
import "moment/locale/es";
import { CommentsBox } from "./commentsBox";
import { IoArrowBackOutline } from "react-icons/fa";
import { BsEye, BsEyeSlash, BsThreeDotsVertical,} from "react-icons/fa";
import bgGallery from '../../assets/imgs/icon-images.png';
import {Link} from "react-router-dom";
import {Button} from "rsuite";
import {FooterHome} from "../../components/footer/footerHome";

export function CompanyPostPrimary(props) {
  const [comments, setComments] = useState(0);
  const [postComment, setPostComment] = useState({});
  const [commentBandera, setCommentBandera] = useState(0);
  const [postTotalLikes, setPostTotalLikes] = useState(0);
  const postCategoryId = 1;
  const postType = 1;
  const [showCommentBox, setShowCommentBox] = useState("");

  const handleCommentBox = () => setShowCommentBox(showCommentBox === "" ? "is-show" : "");

  const [activeAsideSurvey, setStateAsideSurvey] = useState("");
  const AsidePurchaseBox = () => setStateAsideSurvey(activeAsideSurvey === "" ? " is-show" : "");
  const [posts, setPosts] = useState([]);
  const [sectionActive, setSectionActive] = useState("1");
  const [userComment, setUserComment] = useState({});
  const [selectedPost, setSelectedPost] = useState({});
  const [currentFilter, setcurrentFilter] = useState("NaN-aN-aN");
  const [lastFiter, setLastFiter] = useState({});

  const getProfile = () => {
    backendService.userProfile().then((data) => {
      let userProfile = {
        name: data.firstName + " " + data.middleName + " " + data.lastName,
        genderIdentityFlag: data.genderIdentityFlag,
        urlProfileImage: data.urlProfileImage,
      };
      setUserComment(userProfile);
    });
  };

  const getPosts = async () => {
    clearVariable();
    companyPostViewService
      .getByFilters(currentFilter == "NaN-aN-aN" ? undefined : currentFilter, postCategoryId)
      .then((result) => {
        if (result && result.length > 0) {
          let item = {};
          if (props.match.params.id !== undefined && props.match.params.postId !== undefined) {
            getPost(props.match.params.postId);
          } else {
            item = result[0];
            handleSelectPost(item);
          }
          setPosts(result);
        }
      });
  };

  const getPost = (id) => {
    companyPostViewService.getById(id).then((result) => {
      if (result.postType === 0) {
        setComments(result.totalComments);
        setSelectedPost(result);
        postLikes(result, 0);
      } else if (result.postType === 1) {
        result.iconPath = result.urlImage;
      }
    });
  };

  // useEffect(() => {
  //   if (props.match.params.id !== undefined && props.match.params.postId !== undefined) {
  //     let newParam = { reportId: props.match.params.reportId };
  //     setPostComment(newParam);
  //   } else {
  //     clearVariable();
  //     if (currentFilter !== "NaN-aN-aN" && lastFiter.filterMonthDate !== undefined) {
  //       selectedPost.companyPostId = null;
  //       if (postCategoryId === lastFiter.postType) getPosts();
  //     }
  //   }
  // }, [currentFilter, props]);

  useEffect(() => {
    getPost(props.match.params.postId);
    getProfile();
    // if (props.match.params.postId === undefined) getPosts();
  }, []);

  // useEffect(() => {
  //   if (lastFiter.filterMonthDate !== undefined && postCategoryId === lastFiter.postType) {
  //     getProfile();
  //     let datelastFilter = formatDate(new Date(lastFiter.filterMonthDate));
  //     if (currentFilter === datelastFilter) {
  //       getPosts();
  //     } else {
  //       getPostDateFilter(new Date(lastFiter.filterMonthDate));
  //     }
  //   }
  // }, [lastFiter]);

  const handleSelectPost = (item) => {
    if (!item.isRead) {
      companyPostViewService
        .InsertRead({ CompanyPostId: item.companyPostId })
        .then((result) => {
          if (result) {
            setSelectedPost(result);
            postLikes(result, 0);
            if (posts.length > 0) {
              let items = posts.map((m) => {
                if (m.companyPostId === item.companyPostId) m.isRead = true;
                return m;
              });
              setPosts(items);
            }
          }
        });
    } else {
      getPost(item.companyPostId);
    }
  };

  const handleOnUnRead = () => {
    let id = sectionActive === "1" ? selectedPost.companyPostId : '';
    companyPostViewService
      .DeleteReadPost({ CompanyPostId: id })
      .then((result) => {
        if (result) {
          setSelectedPost(result);
          let items = posts.map((m) => {
            if (m.companyPostId === id) m.isRead = false;
            return m;
          });
          postLikes(result, 0);
          setPosts(items);
        }
      });
  };

  const clearVariable = () => {
    setSelectedPost({});
    setPosts([]);
  };

  const postLikes = (post, order) => {
    const totalPost = post.like ? post.totalLikes * 5 - 4 : post.totalLikes * 5;
    setPostTotalLikes(totalPost);
  };

  const handleOnLike = () => {
    const id = sectionActive === "1" ? selectedPost.companyPostId : '';
    companyPostViewService.InsertLike({ CompanyPostId: id }).then((result) => {
      if (result) setSelectedPost(result);
      postLikes(result, 1);
    });
  };

  const handleOnUnLike = () => {
    const id = selectedPost.companyPostId;
    companyPostViewService
        .DeleteLikePost({ CompanyPostId: id })
        .then((result) => {
          if (result) setSelectedPost(result);
          postLikes(result, 0);
        });
  };

  const getPostDateFilter = (filterDate) => {
    const dateFilter = formatDate(filterDate);
    setcurrentFilter(dateFilter);
  };

  const formatDate = (date) => {
    let datestring =
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2);
    return datestring;
  };

  useEffect(() => {
    if (currentFilter !== undefined && lastFiter.filterMonthDate !== undefined) getPosts();
  }, [currentFilter]);

  useEffect(() => {
    getPostDateFilter(new Date(lastFiter.filterMonthDate));
  }, [lastFiter]);

  //const [dropdownOpen, setDropdownOpen] = useState(false);
  //const toggleDots = () => setDropdownOpen((prevState) => !prevState);

  const goToGallery = () => window.location.href = `/blog-company/gallery`;

  return (
    <article className="dashboard-container-page">
      <Row>
        <Col sm={12}>
          <h2 className="d-flex-start">
            <Link to="/blog-company" className="btn-back-post">
              <i className="icon-chevron-left"></i>
            </Link>
            {props.titleComponent}
          </h2>
        </Col>  
        <Col lg={9} md={12} sm={12}>
          <div className={`blog-worldpride-content`}>
              <div className="blog-worldpride-content-post">
                <Row>
                  <Col sm={12} md={6} lg={6}>
                    <div className="post-title">
                      <p> 
                        {selectedPost.publishDate && (<Moment locale="es" format=" DD [de] MMMM [de] YYYY">{selectedPost.publishDate}</Moment>)}
                      </p>
                      <h3>{selectedPost.title}</h3>
                    </div>
                  </Col>
                  <Col sm={12} md={6} lg={6}>
                    {selectedPost.urlImage && (
                      <div className="post-thumb">
                        <div className="rect-gray"></div>
                        <img src={selectedPost.urlImage} alt="" />
                      </div>
                    )}
                  </Col>
                  {
                    selectedPost &&
                        <Col sm={12}>
                          <div className="post-content">
                            <div className="read">
                              <p className="views_post">
                                <span className="number">{selectedPost.totalViews * 5}</span>
                                <span>vista(s)</span>
                              </p>
                            </div>
                          </div>
                        </Col>
                  }
                  <Col sm={12}>
                    <div dangerouslySetInnerHTML={{__html: selectedPost.text}} />
                  </Col>
                </Row>
                {/* <Row className="comments-container">
                  <Col sm={12}>
                    <div className="comments">
                      <div className="comments-header">
                        <h5>Comentarios</h5>
                        {/* <Dropdown isOpen={dropdownOpen} toggle={toggleDots}>
                          <DropdownToggle className="btn-dots"><BsThreeDotsVertical /></DropdownToggle>
                          <DropdownMenu>
                            {selectedPost.isRead ? (
                                <div className="btn-dots-link">
                                  <span onClick={handleOnUnRead}>
                                    <BsEyeSlash /> Marcar no leído
                                  </span>
                                </div>
                            ) : (
                                <div className="btn-dots-link">
                                  <span onClick={handleOnUnRead}><BsEye/> No leído</span>
                                </div>
                            )}
                          </DropdownMenu>
                        </Dropdown> 
                      </div>
                      <div className="comments-content">
                          <CommentsBox
                              isShow={showCommentBox}
                              companyPostId={selectedPost.companyPostId}
                              handleCommentBox={handleCommentBox}
                              profileUser={userComment}
                              updateCountComment={setComments}
                              reportId={postComment?.reportId}
                              bandera={commentBandera}
                              setBandera={setCommentBandera}
                              postType={postType}
                          />
                        <CompanyPostAttachment CompanyPostId={selectedPost.companyPostId} IsDelete={false}/>
                      </div>
                      <div className="comments-footer">
                        <div className="likes-container">
                          {selectedPost.like ? (
                              <Button className="btn-like" onClick={handleOnUnLike}>
                                <p>No me gusta</p>
                              </Button>
                          ) : (
                              <Button className="btn-like" onClick={handleOnLike}>
                                <p>Me gusta</p>
                              </Button>
                          )}
                          <div className="likes-total">
                            {
                              (postTotalLikes) ?
                                  <Button className="btn-like red">
                                    <i className="icon-heart-fill"></i>
                                    <p className="like_counter">{postTotalLikes}</p>
                                  </Button>
                                  :
                                  <Button className="btn-like">
                                    <i className="icon-heart-fill"></i>
                                    <p className="like_counter">{postTotalLikes}</p>
                                  </Button>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row> */}
              </div>
          </div>
        </Col>
        <Col lg={3} md={12} sm={12}>
          <div className="blog-worldpride-aside">
            <Link to={`/blog-company/gallery`} className="blog-worldpride-aside-card">
              <div className="bg-gradient-pride"></div>
              <img src={bgGallery} alt="" />
              <h3>Galería de Preguntas frecuentes </h3>
              <div className="flex-end">
                <Button onClick={goToGallery} className="btn-outline-white">Galería <span></span></Button>
              </div>
            </Link>
          </div>
          {/* <div className="space-col-top">
              <AsideOurCompany
                  getPost={getPostDateFilter}
                  postType={0}
                  activeAsideSurvey={activeAsideSurvey}
                  postCategoryId={postCategoryId}
                  onCloseClick={() => setStateAsideSurvey("")}
                  setLastFiter={setLastFiter}
              />
          </div> */}
        </Col>
      </Row>
      <Col sm={12}>
        <FooterHome />
      </Col>
    </article>
  );
}
