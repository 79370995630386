import React, { Fragment, useState } from "react";
import { attendeeService } from "../../services/attendee.service";
import { SelectUsers } from "./selectUsers";
import {Col, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from "reactstrap";

import "rsuite/dist/styles/rsuite-default.css";
import { useEffect } from "react";
import {Button} from "rsuite";
import {FaChevronRight, FaPlus} from "react-icons/fa";

export function AudienceUsers({ targetId, eventType, selectUser }) {
  const [users, setUsers] = useState([]);
  const [showSelect, setShowSelect] = useState(false);
  const [show, setShow] = useState(false);
  const [userSelec, setUserSelec] = useState([]);
  const [usersRemove, setUsersRemove] = useState([]);

  useEffect(() => {
    setShow(false);
    getUsers();
  }, [targetId, eventType]);

  const getUsers = () => {
    attendeeService.getAttendeeUsers(targetId, eventType).then((result) => {
      if (result) {
        setUsers(result);
        selectUser(result);
      } else {
        setUsers([]);
        selectUser([]);
      }
    });
  };

  const addUsers = () => {
    setUsers([...users, ...userSelec]);
    selectUser([...users, ...userSelec]);
    closeModal();
  };

  const removeUsers = () => {
    setUsers(users.filter((item) => !usersRemove.includes(item.id)));
    selectUser(users.filter((item) => !usersRemove.includes(item.id)));
    setUsersRemove([]);
  };

  const closeModal = () => {
    setShowSelect(false);
    setUserSelec([]);
  };

  const handleSelectUser = (event, data) => {
    data.check = event.target.checked;
    if (data.check) setUsersRemove([...usersRemove, data.id]);
    else setUsersRemove(usersRemove.filter((item) => item != data.id));
  };

  const handleSelectUserAll = (event) => {
    let localUsers = [];
    users.map((data) => {
      if (event.target.checked && !data.check) {
        usersRemove.push(data.id);
        localUsers = usersRemove;
      }
      return data.check = event.target.checked;
    });

    setUsersRemove(localUsers.length === 0 ? [] : [...localUsers]);
  };

  return (
    <Row>
      <Col sm={12} className="mb-3">
        <div>
          <Button type="button" className="rs-btn btn-dark" onClick={() => setShow(!show)}>
            {show ? "Ocultar audiencia" : "Ver audiencia"}
            <FaChevronRight />
          </Button>
        </div>
      </Col>
      {show && (
        <>
          <Col sm={12} style={{background: 'rgba(0,0,0,.1)', borderRadius: '.5rem', paddingBottom: '1rem'}}>
            <Row>
              <Col sm={12}>
                <FormGroup className="form-dashboard-group">
                  <SelectUsers
                      selectUser={setUserSelec}
                      title="Agregar usuarios:"
                      usersFilter={users}
                      filter={true}
                  />
                </FormGroup>
              </Col>
              <Col sm={12}>
                <div className="flex-end">
                  <Button className="btn-dark" onClick={addUsers}>
                    <p>Agregar</p>
                    <FaPlus />
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>

          <Col sm={12} className="mt-5">
            <div className="d-flex-between">
              <h4>Audiencia</h4>
              {usersRemove.length > 0 && (
                  <Button type="button" className="btn-icon-table delete" onClick={removeUsers}>
                    <i className="icon-trash"></i>
                    <p>usuarios</p>
                  </Button>
              )}
            </div>
          </Col>

          <div className="table-responsive">
            <Table className="admin-table" hover>
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Grupo</th>
                  <th>
                    {users.length > 0 ? (
                        <label className="toggler-wrapper dark" htmlFor="checkboxAllAtenndee">
                          <input type="checkbox" id="checkboxAllAtenndee" onClick={handleSelectUserAll}/>
                          <div className="toggler-slider">
                            <div className="toggler-knob"></div>
                          </div>
                          <p>Seleccionar</p>
                        </label>
                    ) : (
                        "Seleccionar"
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                {users && (
                    <>
                      {users.map((user, index) => (
                          <tr key={index}>
                            <td>{user.name}</td>
                            <td>{user.department}</td>
                            <td>
                              <label className="toggler-wrapper dark" htmlFor={`check-a-${user.id}`}>
                                <input type="checkbox" id={`check-a-${user.id}`} onClick={(event) => handleSelectUser(event, user)} checked={user.check}/>
                                <div className="toggler-slider">
                                  <div className="toggler-knob"></div>
                                </div>
                              </label>
                            </td>
                          </tr>
                      ))}
                      {
                        users.length === 0 ?
                            <tr>
                              <td colSpan="3" className="text-center"><p>No hay registros</p></td>
                            </tr>
                            : null
                      }
                    </>
                )}
              </tbody>
            </Table>
          </div>
        </>
      )}
    </Row>
  );
}
