import React, { useEffect, useState } from 'react';

import { useCaringCommunity } from '../../services/caringCommunity.service';
import {Modal, ModalBody, Table} from "reactstrap";
import {Button} from "rsuite";
import {FaPlus, FaTimes} from "react-icons/fa";

export function ListCommunity(props){
    const [CommunitiesData, setCommunitiesData] = useState([]);

    const handleDelete = (data) => {
        useCaringCommunity.deleteCommunity(data.caringCommunityId).then(() => {
            getCommunities();
        });
    };

    useEffect(() => {
        getCommunities();
    }, [props.refresh])

    async function getCommunities() {
        useCaringCommunity.getCommunities(process.env.REACT_APP_COMPANY).then((result) => {
            setCommunitiesData(result);
        });
    }

    async function onReactivateCommunity(data) {
        data.active = true;
        let file = await getFileImage(data.image);

        let formData = new FormData();
        formData.append("CaringCommunityId", data.caringCommunityId);
        formData.append("Name", data.name);
        formData.append("Description", data.description);
        formData.append("CompanyId", data.companyId);
        formData.append("Ligue1", data.ligue1);
        formData.append("Ligue2", data.ligue2);
        formData.append("Image", file);
        formData.append("Active", true);
        formData.append("createDate", data.createDate);

        useCaringCommunity.updateCommunity(formData).then(() => {
            getCommunities();
        });
    }

    const getFileImage = async (url) => {
        let name = url.substring(url.lastIndexOf('/') + 1).replace("%", " ");
        let file = await fetch(url)
            .then(res => res.blob())
            .then(blob => {
            const file = new File([blob], name, blob)
             return file;
            })
        return file;
    }

    const [modal, setModal] = useState(false);
    const toggle = (img) => {
        setModal(!modal);
        setImageModal(img)
    }

    const [imgModal, setImageModal] = useState('');

    return (
        <div>
            <div className="admin-subtitle">
                <h3>Lista de comunidades solidarias</h3>
                <Button type="button" className="btn-dark" onClick={props.onNewClicked}>
                    <p>Agregar comunidad</p>
                    <FaPlus />
                </Button>
            </div>
            <div className="table-responsive">
                <Table className="admin-table" hover>
                    <thead>
                        <tr>
                            <th>Imagen</th>
                            <th>Título</th>
                            <th>Fecha de creación</th>
                            <th>Estatus</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {CommunitiesData.map(community => (
                            <tr key={community.caringCommunityId} >
                                <td>
                                    <div className="d-flex-center">
                                        <a href="#" onClick={() => toggle(community.image)} className="img-table">
                                            <img src={community.image} style={{ width: '60px' }} alt="Imagen de comunidad solidaria"/>
                                        </a>
                                    </div>
                                </td>
                                <td>{community.name}</td>
                                <td>{community.createDate}</td>
                                <td>
                                    <div className="d-flex-center">
                                        <div className="badge green">
                                            {community.active ? "Activo" : "Inactivo"}
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex-center">
                                        {community.active &&
                                            <>
                                                <Button type="button" className="btn-icon-table" onClick={() => props.onSelectCommunity(community)} >
                                                    <i className="icon-edit"></i>
                                                </Button>
                                                <Button type="button" className="btn-icon-table delete" onClick={() => handleDelete(community)}>
                                                    <i className="icon-trash"></i>
                                                </Button>
                                            </>
                                        }
                                        {!community.active &&
                                            <Button className="btn-icon-table" onClick={() => onReactivateCommunity(community)}>
                                                <i className="fas fa-check-circle"></i>
                                            </Button>
                                        }
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                <Modal isOpen={modal} toggle={toggle} centered={true} className="modal-images">
                    <ModalBody>
                        <Button className="btn-close-modal" onClick={toggle}>
                            <FaTimes />
                        </Button>
                        <img src={imgModal} width="100%" style={{maxHeight: '300px', objectFit: 'contain'}} alt="" />
                    </ModalBody>
                </Modal>

            </div>
        </div>
    )
}
