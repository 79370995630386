import React, { useEffect, useState } from 'react'
import { messageService } from '../../services/message.service'
import Moment from 'react-moment'
import { DropDownDate, DropDownStatus } from './dropDownsMessage'
import { useSelector } from 'react-redux';
import { messagePrivilegeService } from '../../services/messagePrivilige.service'
import {Button} from "rsuite";
import {ReadMessage} from "./readMessage";
import {FaPlus} from "react-icons/fa";


export default function InboxMessages(props) {
    const roleName = useSelector(state => state.userSettings.roleName)
    const [userMessages, setUserMessages] = useState([])
    const [dateFilter, setDateFilter] = useState({ title: 'Descendente', value: 'desc' })
    const [statusFilter, setStatusFilter] = useState({ title: 'Todos', value: '' })
    const [isSender, setIsSender] = useState(false);
    const [idReadedMessage, setIdReadedMessage] = useState(0);
    const [mobileMsg, setMobileMsg] = useState(false);

    const changeStatusMobile = () => setMobileMsg(!mobileMsg)

    const readMessage = (message) => {
        var msgIndex = userMessages.findIndex((obj => obj.message.messageId == message.messageId));
        if(!userMessages[msgIndex].messageDetail.isRead)
        {
            message.isRead = true;
            userMessages[msgIndex].messageDetail.isRead = true;
            messageService.updateMessage(message).finally(() => 
            {
             var msgIndex = userMessages.findIndex((obj => obj.message.messageId == message.messageId));
             userMessages[msgIndex].messageDetail.isRead = message.isRead;
             setUserMessages(userMessages);
             
         })
        }
        setMobileMsg(!mobileMsg)
        setIdReadedMessage(message.messageId)
    }

    useEffect(() => {
        getMessages()
    }, [dateFilter, statusFilter])

    useEffect(() => {
        getUserPermissions()
    }, [roleName])

    useEffect(() => {
        if (props.messageId   && userMessages.length > 0) {
            var message =  userMessages.find(item => item.message.messageId == props.messageId);
            if(message) readMessage(message.message)
            }
    }, [userMessages.length])

    async function getMessages() {
        await messageService.getMessage(0, dateFilter.value, statusFilter.value)
            .then((data) => {
                setUserMessages(data)
                }
            )
    }

    const getUserPermissions = () => {
        messagePrivilegeService.getUserPrivilege().then((data) => {
            if ((roleName === "Recursos Humanos") || data) {
                setIsSender(true)
                return true;
            } else {
                setIsSender(false)
                return false;
            }
        })
    }

    /*const deleteMessage = (id) => messageService.deleteMessage(id).then(getMessages)*/

    return (
        <>
            <section className="inbox-messages-header">
                <div className="inbox-messages-filters">
                    <DropDownStatus title={statusFilter.title} setFilter={setStatusFilter} />
                    <DropDownDate title={dateFilter.title} setFilter={setDateFilter} />
                </div>
                {isSender &&
                    <Button type="button" className="btn-dark" onClick={() => { props.setIsActive(5); props.setDraft({}) }}>
                        <FaPlus />
                        <p>Mensaje</p>
                    </Button>
                }
            </section>
            <section className="table-responsive">
                <article className={`messages-table`}>
                    <ul className={`${!mobileMsg ? '' : 'isHide'}`}>
                    {userMessages.map((message, index) =>
                        <li key={index}>
                            <div className={`msg ${message.messageDetail.isRead ? "readed" : ""}`} >
                                <Button className="btn-message" onClick={() => readMessage(message.message)}>
                                    <div className="avatar-container">
                                        <span className="avatar"><img src={message.messageUserFrom.urlProfileImage} alt="" /></span>
                                    </div>
                                    <span className="btn-link">
                                        <div className="user">
                                            <p>{message.messageUserFrom.name}</p>
                                            <span><Moment format="DD/MM/YYYY">{message.message.createDate}</Moment></span>
                                        </div>
                                        <span>{message.message.subject}</span>
                                    </span>
                                </Button>
                            </div>
                        </li>
                    )}
                    {
                        (userMessages.length === 0) ?
                            <li>
                            <span className="message-empty">
                                <i className="icon-empty"></i>
                                <p>No hay mensajes</p>
                            </span>
                            </li> : ''
                    }
                    </ul>
                    <div className={`messages-readed ${mobileMsg ? 'isShow' : ''}`}>
                        <ReadMessage messageId={idReadedMessage} archived={true} mobileMsg={mobileMsg} changeStatusMobile={changeStatusMobile} />
                    </div>
                </article>
            </section>
        </>
    )
}
