import React, { useState, useEffect } from "react";
import "moment/locale/es";
import { Button } from "reactstrap";
import { SliderHome } from "../../components/sliderHome/sliderHome";
import { BoxSchedule } from "../../components/boxSchedule/boxSchedule";
import { companyPostService } from "../../services/companyPost.service";
import { courseViewService } from "../../services/courseView.service";
import { backendService } from "../../services/backend.service";
import { CarouselMainHome } from "../../components/carouselMainHome/carouselMainHome";
import { CarouselMainHomeTwo } from "../../components/carouselMainHomeTwo/carouselMainHomeTwo";
import { CardUser } from "../../components/cardUser/cardUser";
import { FooterHome } from "../../components/footer/footerHome";
import "@splidejs/react-splide/dist/css/splide-core.min.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { BsThreeDotsVertical } from "react-icons/bs";
import { ItemSegmentHome } from "../../components/itemSegmentHome/itemSegmentHome";
import gym from "../../assets/imgs/dashboard/gym.png";
import gymFuncional from "../../assets/imgs/dashboard/gymhome.png";
import dieta from "../../assets/imgs/dashboard/dieta.png";
import medidas from "../../assets/imgs/dashboard/medidas.png";
import moment from "moment/moment";

export function Home() {
  const [lastPost, setLastPost] = useState({});
  const [directoryItems, setDirectoryItems] = useState([]);
  const [auditoriumItems, setAuditoriumItems] = useState([]);
  const [ourWorld, setOurWorld] = useState([]);
  const [lastVideo, setLastVideo] = useState([]);
  const [lastPDF, setLastPDF] = useState([]);
  const [localAttachments, setLocalAttachments] = useState({ file: {}, url: "", name: "" });
  const [myModal, setMyModal] = useState("inShow");
  const week = moment().subtract(process.env.REACT_APP_WEEK, 'weeks').isoWeek();
  const [statusDotsHeader, setStatusDotsHeader] = useState(false);

  useEffect(() => {
    backendService.widgets().then((data) => {
      setWidgets(data);
      if (data && data.auditoriumWidget && data.auditoriumWidget.length > 0) {
        let auditoriumItems = data.auditoriumWidget.map(({ data }) => data);
        let listSorted = auditoriumItems.sort(function (a, b) {
          return (
            Math.abs(Date.now() - new Date(a.conferenceDate)) -
            Math.abs(Date.now() - new Date(b.conferenceDate))
          );
        });

        setAuditoriumItems(listSorted);
      }
      if (data.surveyWidget != null) {
        let date = new Date();
        date.setDate(date.getDate() + data.surveyWidget.expirationDays);
        data.surveyWidget.expirationDate = date;
      }


      if (data.directoryInfoWidget?.length > 0) setDirectoryItems(data.directoryInfoWidget);
    });
    getLatestPost();
    getOrWorld();
    getLatestCourse();
    setTimeout(() => setDoodle(), 8000);
  }, []);

  const [widgets, setWidgets] = useState({
    surveyWidget: null,
    auditoriumWidget: {}
  });

  async function getLatestPost() {
    companyPostService.getLatest(2).then((result) => {
      if (result && result.length > 0) setLastPost(result[0]);
    });
  }

  async function getOrWorld() {
    companyPostService.getLatest(1).then((result) => {
      setOurWorld(result);
    });
  }

  async function getLatestCourse() {
    courseViewService.getLatestCourse().then((result) => {
      let listCourse = JSON.parse(result);
      const filteredVideos = listCourse?.filter(course => course.CourseMediaTypeId === 3) ?? [];
      const filteredPDF = listCourse?.filter(course => course.FileName.toLowerCase().endsWith('.pdf')) ?? [];
      const pdfURL = filteredPDF[0]?.Path || null;
      const pdfName = filteredPDF[0]?.Name || null;

      setLastPDF(filteredPDF.at(0)??{});
      setLastVideo(filteredVideos); 
      getAttachments(pdfURL, pdfName);
    });
  }

  async function getAttachments(pdfURL, pdfName) {
    fetch(pdfURL)
      .then(response => response.blob())
      .then(file => {
        let url = URL.createObjectURL(file);
        setLocalAttachments({ file: [file], url, pdfName });
      })
      .catch(error => {
        console.error('Error al obtener el archivo PDF:', error);
        setLocalAttachments(null);
      });
  }

  async function setDoodle() {
    setMyModal(myModal === "");
  }

  function getWidthDimensions() {
    const { innerWidth: width } = window;

    return width;
  }

  const [overlay, setOverlay] = useState(false);
  const toggleOverlay = () => setOverlay(!overlay);

  const [windowDimensions, setWindowDimensions] = useState(
    getWidthDimensions()
  );

  const handleOverlay = (width) => {
    if (width >= 1280) setOverlay(false);
  };

  useEffect(() => {
    const handleResize = () => setWindowDimensions(getWidthDimensions());
    handleOverlay(getWidthDimensions());
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleOverlay]);

  const lastGym = auditoriumItems.find(item =>
    item.title.toLowerCase().includes('gym') || item.title.toLowerCase().includes('gimnasio')
  );
  const lastFuncional = auditoriumItems.find(data =>
    data.title.toLowerCase().includes('funcional')
  );

  return (
    <>
      {windowDimensions <= 1180 ? (
        <Button className="btn-dots-v" onClick={toggleOverlay}>
          <BsThreeDotsVertical />
        </Button>
      ) : (
        ""
      )}
      <section className="dashboard-side-left">
        <CardUser />
      </section>
      <section className={`dashboard-side-center  ${overlay ? "viewing" : ""}`}>
        {overlay ? (
          <>
            <section
              className={`dashboard-side-left ${overlay ? "show" : "hide"}`}
            >
              <CardUser />
            </section>          
          </>
        ) : (
          <>
            <SliderHome />

            <BoxSchedule />

            <div className="dashboard_segment_home">
              <div>
                <div className="drawer_ttls_segment">
                  <h3 className="ttls_in_muro">
                    <Link to="/audience">Mis rutinas</Link>
                  </h3>
                  {/* <p className="label_ttls_segment">
                    <span>Semana {week}</span>
                  </p> */}
                </div>
                <div className="drawer_content_segment">
                {lastGym &&
                 (
                  <ItemSegmentHome
                    isPDF={false}
                    urlDirect={lastGym.conferenceUrl}
                    iconoLink={gym}
                    iconoLinkName="Gimnasio"
                    alert={false}
                  /> 
                  )} 
                  {lastFuncional &&
                 (
                  <ItemSegmentHome
                    isPDF={false}
                    urlDirect={lastFuncional.conferenceUrl}
                    iconoLink={gymFuncional}
                    iconoLinkName="Funcional"
                    alert={false}
                  />
                 )}
                </div>
              </div>
              <div>
                <div className="drawer_ttls_segment">
                  <h3 className="ttls_in_muro">
                    <Link to="/">Alimentación</Link>
                  </h3>
                  {/* <p className="label_ttls_segment">
                    <span>Semana 10</span>
                  </p> */}
                </div>
                <div className="drawer_content_segment">
                  {localAttachments.pdfName &&
                  <ItemSegmentHome
                    isPDF={true}
                    urlDirect={localAttachments.url}
                    pdfName={localAttachments.pdfName}
                    iconoLink={dieta}
                    iconoLinkName="Plan alimenticio"
                    alert={false}
                  />}
                  
                  {widgets.surveyWidget &&
                  <ItemSegmentHome
                    isPDF={false}
                    urlDirect="/surveys"
                    iconoLink={medidas}
                    iconoLinkName="Medidas clínicas"
                    alert={true}
                  />}
                </div>
              </div>
            </div>

            <CarouselMainHome ourWorld={ourWorld} />

            <CarouselMainHomeTwo lastVideo={lastVideo} />

            <FooterHome />
          </>
        )}
      </section>
    </>
  );
}
