import React, { useEffect, useState } from 'react';
import useInputForm from '../../components/CustonHooks/FormHook'
import { useQuestion } from '../../services/surveyQuestion.service'
import { SelectCustom } from '../../components/Helpers/SelectCustom'
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { Button } from "rsuite";
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { useSurveyScale } from '../../services/surveyScale.service';
import {FaSave, FaTimesCircle} from "react-icons/fa";

export function EditSurveyScaleAnswer(props) {
    const onSave = () => {
        if (inputs.SurveyScaleAnswerId && inputs.SurveyScaleAnswerId > 0)
            useSurveyScale.updateSurveyScaleAnswer({ SurveyScaleAnswerId: parseInt(inputs.SurveyScaleAnswerId), SurveyScaleId: parseInt(inputs.SurveyScaleId), Description: inputs.Description, ScaleValue: parseInt(inputs.ScaleValue), AnswerOrder: parseInt(inputs.AnswerOrder) }).then((result) => {
                if (props.onSave)
                    props.onSave();
            });
        else
            useSurveyScale.createSurveyScaleAnswer({ SurveyScaleId: parseInt(inputs.SurveyScaleId), Description: inputs.Description, ScaleValue: parseInt(inputs.ScaleValue), AnswerOrder: parseInt(inputs.AnswerOrder) }).then((result) => {
                if (props.onSave)
                    props.onSave();
            });
    };
    const handleOnCancel = () => {
        if (props.onCancelEdit)
            props.onCancelEdit();
    };
    const [answer, setAnswer] = useState({ SurveyScaleAnswerId: "", SurveyScaleId: "", Description: "", ScaleValue: "", AnswerOrder: "" });
    const { handleSubmit, handleInputChange, inputs } = useInputForm(onSave, answer);

    useEffect(() => {
        let newP = {};
        if (props.currentAnswer) {
            newP = { SurveyScaleAnswerId: props.currentAnswer.SurveyScaleAnswerId.toString(), SurveyScaleId: props.currentAnswer.SurveyScaleId.toString(), Description: props.currentAnswer.Description, ScaleValue: props.currentAnswer.ScaleValue.toString(), AnswerOrder: props.currentAnswer.AnswerOrder.toString() };
            setAnswer(newP);
        }
        else
            setAnswer({ SurveyScaleAnswerId: "", SurveyScaleId: props.surveyScaleId, Description: "", ScaleValue: "", AnswerOrder: "" });
    }, [props.currentQuestion]);

    return (
        <>
            <form onSubmit={handleSubmit} className="form-dashboard">
                <Row>
                    <Col sm={12}>
                        <div className="flex-end">
                            <Button className="btn-back" type="button" onClick={handleOnCancel}>
                                <FaTimesCircle />
                                <p>Cancelar</p>
                            </Button>
                        </div>
                    </Col>
                    <input type="hidden" name="SurveyScaleAnswerId" onChange={handleInputChange} value={inputs.SurveyScaleAnswerId} />
                    <input type="hidden" name="SurveyScaleId" onChange={handleInputChange} value={inputs.SurveyScaleId} />
                    <Col sm={12}>
                        <FormGroup className="form-dashboard-group">
                            <Label for="">Respuesta</Label>
                            <Input type="text" name="Description" onChange={handleInputChange} value={inputs.Description} required />
                        </FormGroup>
                    </Col>
                    <Col sm={12}>
                        <FormGroup className="form-dashboard-group">
                            <Label for="">Orden</Label>
                            <Input type="number" name="AnswerOrder" onChange={handleInputChange} value={inputs.AnswerOrder} required />
                        </FormGroup>
                    </Col>
                    <Col sm={12}>
                        <FormGroup className="form-dashboard-group">
                            <Label for="">Valor</Label>
                            <Input type="number" name="ScaleValue" onChange={handleInputChange} value={inputs.ScaleValue} required />
                        </FormGroup>
                    </Col>
                </Row>
                <div className="flex-end">
                    <Button className="btn-dark" type="submit">
                        <p>Guardar</p>
                        <FaSave />
                    </Button>
                </div>
            </form>
        </>

    );
}   
