import React, { useEffect, useState } from 'react'
import InboxMessages from './inboxMessages'
import SentMessages from './sentMessages'
import ArchivedMessages from './archivedMessages'
import ComposeNewMessage from './composeNewMessage'
import ConfigurePrivileges from './configurePrivileges'
import DraftMessages from './draftMessages'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'

import './messages.scss'
import {Col, Row, Tooltip} from "reactstrap";
import {Button} from "rsuite";
import {FooterHome} from "../../components/footer/footerHome";

export function Messages(props) {
    let { id } = useParams()
    const roleName = useSelector(state => state.userSettings.roleName)
    const [isActive, setIsActive] = useState(1)
    const [draft, setDraft] = useState({})

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [tooltipOpen2, setTooltipOpen2] = useState(false);
    const [tooltipOpen3, setTooltipOpen3] = useState(false);
    const [tooltipOpen4, setTooltipOpen4] = useState(false);
    const [tooltipOpen5, setTooltipOpen5] = useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen);
    const toggle2 = () => setTooltipOpen2(!tooltipOpen2);
    const toggle3 = () => setTooltipOpen3(!tooltipOpen3);
    const toggle4 = () => setTooltipOpen4(!tooltipOpen4);
    const toggle5 = () => setTooltipOpen5(!tooltipOpen5);

    return (
        <section className="dashboard-container-page">
            <Row>
                <Col sm={12}><h2>Mensajes</h2></Col>
                <Col sm={12} className="tabs-messages-container">
                    <article className="tabs-links">
                        <Button id={'Tooltip-'+1} className={"btn-link " + (isActive === 1 ? "active" : "")} onClick={() => setIsActive(1)}><i className="icon-msgs envelope"></i></Button>
                        <Tooltip
                            placement="bottom"
                            isOpen={tooltipOpen}
                            target={'Tooltip-'+1}
                            toggle={toggle}
                        >
                            Bandeja de entrada
                        </Tooltip>
                        <Button id={'Tooltip-'+2} className={"btn-link " + (isActive === 2 ? "active" : "")} onClick={() => setIsActive(2)}><i className="icon-sent-msg envelope"></i></Button>
                        <Tooltip
                            placement="bottom"
                            isOpen={tooltipOpen2}
                            target={'Tooltip-'+2}
                            toggle={toggle2}
                        >
                            Enviados
                        </Tooltip>
                        <Button id={'Tooltip-'+3} className={"btn-link " + (isActive === 3 ? "active" : "")} onClick={() => setIsActive(3)}><i className="icon-archive draft"></i></Button>
                        <Tooltip
                            placement="bottom"
                            isOpen={tooltipOpen3}
                            target={'Tooltip-'+3}
                            toggle={toggle3}
                        >
                            Archivados
                        </Tooltip>
                        {roleName === "Recursos Humanos" &&
                            <>
                                <Button id={'Tooltip-'+4} className={"btn-link " + (isActive === 4 ? "active" : "")} onClick={() => setIsActive(4)}><i className="icon-writting-msg"></i></Button>
                                <Tooltip
                                    placement="bottom"
                                    isOpen={tooltipOpen4}
                                    target={'Tooltip-'+4}
                                    toggle={toggle4}
                                >
                                    Borradores
                                </Tooltip>
                                <Button id={'Tooltip-' + 5} className={"btn-link mr " + (isActive === 6 ? "active" : "")}  onClick={() => setIsActive(6)}><i className="icon-settings-msg"></i></Button>
                                <Tooltip
                                    placement="bottom"
                                    isOpen={tooltipOpen5}
                                    target={'Tooltip-'+5}
                                    toggle={toggle5}
                                >
                                    Configuración
                                </Tooltip>
                            </>
                        }
                    </article>
                    <article className="content_messages">
                        {isActive === 1 && <InboxMessages setIsActive={setIsActive} setDraft={setDraft} messageId={id} />}
                        {isActive === 2 && <SentMessages setIsActive={setIsActive} setDraft={setDraft} />}
                        {isActive === 3 && <ArchivedMessages setIsActive={setIsActive} setDraft={setDraft} />}
                        {isActive === 4 && <DraftMessages setIsActive={setIsActive} setDraft={setDraft} />}
                        {isActive === 5 && <ComposeNewMessage setIsActive={setIsActive} draft={draft} setDraft={setDraft} />}
                        {isActive === 6 && <ConfigurePrivileges />}
                    </article>
                </Col>
                <Col sm={12}><FooterHome /></Col>
            </Row>
        </section>
    )
}
