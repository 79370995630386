import React, { useEffect, useState } from 'react';
import useInputForm from '../../components/CustonHooks/FormHook'
import { rewardProductService } from '../../services/control/rewardProduct.service';

import { Link } from 'react-router-dom';
import {Col, FormGroup, Label, Row} from "reactstrap";
import {FaSave, FaTimesCircle} from "react-icons/fa";

export function EditReward(props) {

    const onSave = () => {
        // debugger
        inputs.points = parseInt(inputs.points);
        inputs.posX = parseInt(inputs.posX);
        inputs.posY = parseInt(inputs.posY);
        inputs.lowStock = parseInt(inputs.lowStock);
        if (inputs.rewardProductId && inputs.rewardProductId > 0)
            rewardProductService.edit(inputs);
        else
            rewardProductService.create(inputs);
    };

    const [reward, setReward] = useState({
        rewardProductId: 0,
        title: "",
        titleAdmin: "",
        description: "",
        points: 0,
        lowStock: "",
        popUpText: "",
        urlImage: "",
        urlPdf: "",
        isBarCode: false,
        isVisible: false,
        posX: 0,
        posY: 0,
        fontSize: 0,
        restriction: "",
        twoValidation: false,
        rewardProviderId: 0,
        rewardCategoryId: 0,
        active: true,
        couponUrl: "",
        filePDF: null,
        fileImage: null
    });

    useEffect(() => {
        rewardProductService.getCatalogs()
            .then((result) => {
                setCatalogs(result);
                if (props.rewardProductId != 0) {

                    rewardProductService.getById(props.rewardProductId).then((result) => {
                        // debugger
                        setReward({
                            rewardProductId: result.rewardProductId,
                            title: result.title,
                            titleAdmin: result.titleAdmin,
                            description: result.description,
                            points: result.points,
                            lowStock: result.lowStock,
                            popUpText: result.popUpText,
                            urlImage: result.urlImage,
                            urlPdf: result.urlPdf,
                            isBarCode: result.isBarCode,
                            isVisible: result.isVisible,
                            posX: result.posX,
                            posY: result.posY,
                            fontSize: result.fontSize,
                            restriction: result.restriction,
                            twoValidation: result.twoValidation,
                            rewardProviderId: result.rewardProviderId,
                            rewardCategoryId: result.rewardCategoryId,
                            active: result.active,
                            couponUrl: result.couponUrl,
                            fileImage: null,
                            filePDF: null
                        });
                    });
                }

            });

    }, [props.reward]);
    const { handleSubmit, handleInputChange, handleInputFileChange, inputs, setInputs } = useInputForm(onSave, reward);
    const [catalogs, setCatalogs] = useState({ rewardProvider: [], rewardCategory: [] });



    return (
        <div className="dashboard-container-page">
            <form onSubmit={handleSubmit} className="form-dashboard">
                <input type="hidden" name="urlImage" onChange={handleInputChange} value={inputs.urlImage} />
                <input type="hidden" name="urlPdf" onChange={handleInputChange} value={inputs.urlPdf} />
                    <h3>{props.rewardProductId != 0 ? "Editar recompensa" : "Nueva recompensa"}</h3>
                    <Row>
                        <Col lg={4} md={6} sm={12}>
                            <FormGroup className="form-group">
                                <Label for="rewardCategory">Categoría</Label>
                                <select className="form-control" name="rewardCategoryId" onChange={handleInputChange} selectvalue={inputs.rewardCategoryId} value={inputs.rewardCategoryId}>
                                    <option value="">Selecciona una opción</option>
                                    {catalogs.rewardCategory.map(item => (
                                        <option key={item.value} value={item.value}>
                                            {item.label}
                                        </option>
                                    ))}
                                </select>
                            </FormGroup>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                            <FormGroup className="form-dashboard-group">
                                <Label for="rewardCategory">Proveedor</Label>
                                <select className="form-control" name="rewardProviderId" onChange={handleInputChange} selectvalue={inputs.rewardProviderId} value={inputs.rewardProviderId}>
                                    <option value="">Selecciona una opción</option>
                                    {catalogs.rewardProvider.map(item => (
                                        <option key={item.value} value={item.value}>
                                            {item.label}
                                        </option>
                                    ))}
                                </select>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={4} md={6} sm={12}>
                            <input type="hidden" name="rewardProductId" onChange={handleInputChange} value={inputs.rewardProductId} />
                            <FormGroup className="form-dashboard-group">
                                <Label for="title">Nombre</Label>
                                <input type="text" name="title" className="form-control" onChange={handleInputChange} value={inputs.title} required />
                            </FormGroup>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                            <FormGroup className="form-dashboard-group">
                                <Label for="fileImage">Imagen sitio</Label>
                                <input type="file" name="fileImage" className="form-control" accept='.png, .jpg, .jpeg'  onChange={handleInputFileChange} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4} md={6} sm={12}>
                            <FormGroup className="form-dashboard-group">
                                <Label for="titleAdmin">Titulo Administrador</Label>
                                <input type="text" name="titleAdmin" className="form-control" onChange={handleInputChange} value={inputs.titleAdmin} required />
                            </FormGroup>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                            <div className="form-dashboard-group">
                                <Label for="filePdf">Cupón PDF</Label>
                                <input type="File" name="filePDF" className="form-control" accept='.PDF' onChange={handleInputFileChange} />
                            </div>
                        </Col>
                    </Row>
                    <Row className="row">
                        <Col lg={4} md={6} sm={12}>
                            <FormGroup className="form-dashboard-group">
                                <Label for="description">Descripcion</Label>
                                <input type="text" name="description" className="form-control" onChange={handleInputChange} value={inputs.description} required />
                            </FormGroup>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                            <FormGroup className="d-flex-start">
                                <label className="toggler-wrapper dark" htmlFor="isBarCode">
                                    <input
                                        type="checkbox"
                                        name="isBarCode"
                                        id="isBarCode"
                                        onChange={handleInputChange}
                                        checked={inputs.isBarCode} />
                                    <div className="toggler-slider">
                                        <div className="toggler-knob"></div>
                                    </div>
                                </label>
                                <p style={{marginLeft: '.5rem'}}>Código de barras</p>
                            </FormGroup>
                           <FormGroup className="d-flex-start">
                               <label className="toggler-wrapper dark" htmlFor="isVisible">
                                   <input
                                       type="checkbox"
                                       name="isVisible"
                                       id="isVisible"
                                       className="custom-control-input"
                                       onChange={handleInputChange}
                                       checked={inputs.isVisible} />
                                   <div className="toggler-slider">
                                       <div className="toggler-knob"></div>
                                   </div>
                               </label>
                               <p style={{marginLeft: '.5rem'}}>Mostrar folio</p>
                           </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4} md={6} sm={12}>
                            <FormGroup className="form-dashboard-group">
                                <Label for="PopUpText">Texto del aviso</Label>
                                <input type="textarea" name="popUpText" className="form-control" onChange={handleInputChange} value={inputs.popUpText} required />
                            </FormGroup>
                        </Col>
                        <Col md={3} sm={12}>
                            <FormGroup className="form-dashboard-group">
                                <Label for="posX">Posición X</Label>
                                <input type="number" name="posX" className="form-control" onChange={handleInputChange} min="0" value={inputs.posX} required />
                            </FormGroup>
                        </Col>
                        <Col md={3} sm={12}>
                            <FormGroup className="form-dashboard-group">
                                <Label for="posY">Posición Y</Label>
                                <input type="number" name="posY" className="form-control" onChange={handleInputChange} min="0" value={inputs.posY} required  />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4} md={6} sm={12}>
                            <FormGroup className="form-dashboard-group">
                                <Label for="lowStock">Mínimo de unidades</Label>
                                <input type="number" name="lowStock" className="form-control" min="0" onChange={handleInputChange} value={inputs.lowStock} required />
                            </FormGroup>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                            <FormGroup className="form-dashboard-group">
                                <Label for="fontSize">Tamaño de la fuente</Label>
                                <input type="number" name="fontSize" className="form-control"  min="0" onChange={handleInputChange} value={inputs.fontSize} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr />
                    <div className="d-flex-between">
                        <Link className="btn-back" to="/operator/controlreward/1" >
                            <FaTimesCircle />
                            <p>Cancelar</p>
                        </Link>
                        <button className="btn-dark" type="submit">
                            <p>Guardar</p>
                            <FaSave />
                        </button>
                    </div>
            </form>
        </div>
    );
}   
