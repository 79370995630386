import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { rewardProductService } from '../../services/control/rewardProduct.service';
import {Table} from "reactstrap";
import {Button} from "rsuite";
import {FaEye} from "react-icons/fa";

export function SearchReward(props) {
    const [rewardsData, setRewardsData] = useState({ rewards: [] });
    const handleDelete = (data) => {
        rewardProductService.deleteRewardProduct(data).then((result) => { getRewardProducts(); });
    };

    useEffect(() => {
        getRewardProducts();
    }, [props.refresh]);

    async function getRewardProducts() {
        rewardProductService.get().then((result) => {
            if (result) {
                setRewardsData({ rewards: result });
            }
        });
    }


    const getPreview = async (id) => {
        rewardProductService.getTestCoupon(id);
    }

    return (
        <div className="table-responsive">
            <Table className="admin-table" hover>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Producto</th>
                        <th>Mostrar folio</th>
                        <th>Código de barras</th>
                        <th>Puntos</th>
                        <th>Activo</th>
                        <th>Folios disponibles</th>
                    </tr>
                </thead>
                <tbody>
                    {rewardsData.rewards.map(reward => (
                        <tr key={reward.rewardProductId}>
                            <td>
                                <Link className="btn-link d-flex-center" to={"/operator/details-reward/" + reward.rewardProductId}>{reward.rewardProductId}</Link>
                            </td>
                            <td>
                                <Link className="btn-link d-flex-center" to={"/operator/details-reward/" + reward.rewardProductId}>{reward.title}</Link>
                            </td>
                            <td>
                                <Link className="btn-link d-flex-center" to={"/operator/details-reward/" + reward.rewardProductId}>{reward.isVisible ? "Si" : "No"}</Link>
                            </td>
                            <td>
                                <Link className="btn-link d-flex-center" to={"/operator/details-reward/" + reward.rewardProductId}>{reward.isBarCode ? "Si" : "No"}</Link>
                            </td>
                            <td>{reward.points}</td>
                            <td>{reward.active ? "Activo" : "Inactivo"}</td>
                            <td>{reward.stock}</td>
                            <td>
                                <Button className="btn-icon-table" onClick={() => handleDelete(reward.rewardProductId)}>
                                    <i className="icon-trash"></i>
                                </Button>
                            </td>
                            <td>
                                <Link className="btn-link d-flex-center" to={"/operator/load-coupons/" + reward.rewardProductId}>Cargar</Link>
                            </td>
                            <td>
                                {
                                    reward.urlPdf != null ?
                                        <Button type="button" className="btn-dark" onClick={() => getPreview(reward.rewardProductId)}>
                                            <p>Visualizar</p>
                                            <FaEye />
                                        </Button>
                                        : null
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>

    );
}
