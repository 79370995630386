import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import "./itemSegmentHome.scss";

export function ItemSegmentHome(props) {
  return (
    <>
      {props.isPDF ? (
        <a
          className="item_segment_home"
          href={props.urlDirect}
          download={props.pdfName + ".pdf"}
          target="_blank"
          rel="noreferrer"
        >
          <div className="bg_img_segment">
            <img
              className="trans"
              src={props.iconoLink}
              alt={props.iconoLinkName}
            />
          </div>
          <p>{props.iconoLinkName}</p>
        </a>
      ) : (
        <a target="_self" href={props.urlDirect} className="item_segment_home">
          {props.alert ? <span className="dot_alert"></span> : ""}
          <div className="bg_img_segment">
            <img
              className="trans"
              src={props.iconoLink}
              alt={props.iconoLinkName}
            />
          </div>
          <p>{props.iconoLinkName}</p>
        </a>
      )}
    </>
  );
}
