import React, { useEffect, useState } from "react";
import Select from 'react-select';
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { JobBankService } from "../../services/jobBank.service";
import useInputForm from "../../components/CustonHooks/FormHook";
import CreatableSelect from 'react-select/creatable';
import { ValidacionEmail } from "../../utils/ValidacionMail"
import Swal from "sweetalert2";
import {Col, FormGroup, Input, InputGroup, InputGroupText, Modal, ModalBody, Row} from "reactstrap";
import {Button} from "rsuite";
import {GeneralUpload} from "../../components/Helpers/generalUpload";
import {FaTimes} from "react-icons/fa";

export function FormJob({datos, setDatos, toggleView, setJob}) {
      const [editorState, setEditorState] = useState();
      const [selectedOption, setSelectedOption] = useState([]);
      const [options, setOptions] = useState([]);
      const [image, setImage] = ( useState(null));

      useEffect(() => {
        JobBankService.getCategories().then((result) => {
          if(!result) return;
          setOptions(result.map(x => ({ value: x.jobCategoryId, label: x.name })));
        });
      }, []);

      function updateImage(e) {
          const file = e.target.files[0];
          inputs.imagen= file;
          setImage(e.target.files[0]);
      }

    const handleOnChangeFile = (files) => setImage(URL.createObjectURL(files[0]));
    
      const onSave = () => {
        inputs.descripcion = editorState;
        inputs.categoria = selectedOption;
        let error = ValidacionEmail(inputs.mail);
        if (error === false) {
            Swal.fire({ text: "Correo incorrecto, por favor revise.", icon: "error" });
            return;
        }
        if(inputs.categoria.length === 0){
            Swal.fire({ text: "Seleccion al menos una categoría", icon: "error" });
            return;
        }
        if(inputs.descripcion === ""){
            Swal.fire({ text: "Ingrese una descripción", icon: "error" });
            return;
        }
        insertJob()
      };
    
      async function insertJob() {
        
        //Agarrar datos del input y ponerlo en este formData

        let formData = new FormData();
        formData.append("CompanyId", process.env.REACT_APP_COMPANY);
        formData.append("Title", inputs.title);

        //Intentar agarrar lo que pone el editor y ponerlo un string

        formData.append("Description", inputs.descripcion);
        formData.append("Currency", "MXN");
        formData.append("Salary", inputs.infoSalarial);
        formData.append("Location", inputs.location);
        formData.append("Direction", inputs.ubicacion);
        formData.append("CompanyName", inputs.titleCompany);

        //En CompanyImage debería ir el logo de la empresa

        formData.append("CompanyImage", inputs.imagen);
        formData.append("PhoneNumber", inputs.phoneNumber);
        formData.append("Email", inputs.mail);
    
        selectedOption.forEach((element, index) => {
          formData.append(`JobCategory[${index}].JobCategoryId`, element?.__isNew__ ? 0 : element.value);
          formData.append(`JobCategory[${index}].Name`, element.label);
        });

        JobBankService.insertJob(formData).then((result) => {
        setJob(result);
        Swal.fire({text: "Se ha registrado la oferta de trabajo", icon: "success", showCloseButton: true, allowOutsideClick: false, allowEscapeKey: false})
          .then((result) => {
            toggleView();
          });
        //clean inputs

        setDatos({
          title: "",
          categoria: [],
          ubicacion: "",
          location: "",
          titleCompany: "",
          imagen: null,
          infoSalarial: "",
          descripcion: "",
          phoneNumber: "",
          mail: "",
        });
        setEditorState(EditorState.createEmpty());
        setSelectedOption([]);
        });
      }
    
      const { inputs, handleInputChange, handleSubmit, setInputs } = useInputForm(
        onSave,
        datos
      );
    
      const onEditorStateChange = (editorState) => {
        setEditorState(draftToHtml(convertToRaw(editorState.getCurrentContent())));
      };
    
      const getFileImage = async (url) => {
        let fileName = url.substring(0, url.lastIndexOf("."));
        let file = await fetch(url)
          .then((res) => res.blob())
          .then((blob) => {
            const file = new File([blob], fileName, blob);
            return file;
          });
        return file;
      };

      const handleImageUploadRemove = () => setImage(null)

      const [modal, setModal] = useState(false);
      const toggle = (img) => {
        setImageModal(image)
        setModal(!modal);
      }

      const [imgModal, setImageModal] = useState('');

      return (
          
        <form onSubmit={handleSubmit}>
          <article className="steps">
            <div className="item active">
              <span>1</span>
            </div>
            <div className="lines"></div>
            <div className="item">
              <span>2</span>
            </div>
          </article>
          <article className="content">
            <Row>
              <Col sm={12}>
                <h4>Información de la vacante</h4>
              </Col>
              <Col md={6} sm={12}>
                <div className="form-dashboard-group">
                  <label>Título de la vacante</label>
                  <Input type="text" name="title" required={true} onChange={handleInputChange}/>
                </div>
              </Col>
              <Col md={6} sm={12}>
                <div className="form-dashboard-group">
                  <label for="categoria">Categoría</label>
                  <CreatableSelect
                    closeMenuOnSelect={false}
                    value={selectedOption}
                    required={true}
                    onChange={setSelectedOption}
                    isMulti
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={options}
                    id="categoria"
                  />
                </div>
              </Col>
              <Col md={6} sm={12}>
                <div className="form-dashboard-group">
                  <label for="ubicacion">Dirección</label>
                  <Input type="text" name="ubicacion" errormessage="" onChange={handleInputChange} required={true}/>
                </div>
              </Col>
              <Col md={6} sm={12}>
                <div className="form-dashboard-group">
                  <label for="location">Localidad</label>
                  <Input type="text" name="location" errormessage="" onChange={handleInputChange} required={true}/>
                </div>
              </Col>
              <Col md={6} sm={12}>
                <div className="form-dashboard-group">
                  <label for="titleCompany">Nombre de la empresa</label>
                  <Input type="text" name="titleCompany" onChange={handleInputChange} required={true}/>
                </div>
              </Col>
              <Col md={6} sm={12}>
                <div className="form-dashboard-group">
                  <label for="mail">Correo</label>
                  <Input type="text" name="mail" onChange={handleInputChange} required={true}/>
                </div>
              </Col>
              <Col md={6} sm={12}>
                <div className="form-dashboard-group">
                  <label for="">Número de contacto</label>
                  <Input name="phoneNumber" maxLength={10} minLength={10}
                    onKeyPress={(e) => {
                      // Solo se admiten números
                      e = e || window.e;
                        const charCode = e.keyCode || e.which;
                        const charStr = String.fromCharCode(charCode);
                        if(isNaN(charStr))
                      e.preventDefault();
                    }}
                    onChange={handleInputChange}
                    required={true}
                  />
                </div>
              </Col>
              <Col md={6} sm={12} style={{marginTop: '1rem'}}>
                    {
                        (image !== null) ?
                            <div className="form-dashboard-group-upload">
                              <div className="uploaded-file">
                                  <img src={image} alt="" className="img-file" onClick={() => toggle(image)}  />
                                  <Button className="btn-remove-file" onClick={handleImageUploadRemove}>
                                      <i className="icon-close"></i>
                                  </Button>
                              </div>
                            </div>
                            :
                            <GeneralUpload accept="image/*" isMultiple="false" TitleLabel={"Subir imagen"} handleOnChangeFiles={handleOnChangeFile} />
                    }
              </Col>
            </Row>
            <Row>
              <Col sm={12}><h4>Información salarial</h4></Col>
              <Col md={6} sm={12}>
                <InputGroup className="group-append">
                  <InputGroupText>Salario bruto
                    <Input type="radio" name="exampleRadios" />
                  </InputGroupText>
                  <Input type="number" min={0} step="0.01" name="infoSalarial" onChange={handleInputChange} required={true}/>
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <h4>Descripción de la empresa</h4>
              </Col>
              <Col sm={12}>
                <Editor
                  onEditorStateChange={onEditorStateChange}
                  wrapperClassName="wrapper__editor_text"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                  toolbar={{
                    options: [
                      "inline",
                      "colorPicker",
                      "blockType",
                      "fontSize",
                      // "fontFamily",
                      "list",
                      "textAlign",
                      // "image",
                      "link",
                      "remove",
                      "history",
                    ],
                    inline: {
                      options: [
                        "bold",
                        "italic",
                        "underline",
                        "strikethrough",
                      ],
                    },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: true },
                    history: { inDropdown: true },
                  }}
                />
              </Col>
            </Row>
            <div className="flex-end" style={{marginTop: '.5rem'}}>
              <Button className="btn-gradients-green" type="submit">
                <p>Continuar</p>
                <div className="circle"><i className="icon-chevron-right"></i></div>
              </Button>
            </div>
            <Modal isOpen={modal} toggle={toggle} centered={true} className="modal-images">
              <ModalBody>
                <Button className="btn-close-modal" onClick={toggle}>
                  <FaTimes />
                </Button>
                <img src={imgModal} width="100%" style={{maxHeight: '300px', objectFit: 'contain'}} alt="" />
              </ModalBody>
            </Modal>
          </article>
      </form>
      )
}
