import React, { useState, useEffect } from "react";
import {Button, Collapse} from "reactstrap";
import {BiChevronDown, BiChevronRight, BiChevronUp, BiMinus, BiPlus} from "react-icons/bi";

export function ItemMorePostSecondary({ filter, getPost, months, year, postsCounter}) {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const [counterSelected, setCounterSelected] = useState(0);
    const [yearsCounting, setYearCounting] = useState([]);

    useEffect(() => {
        const anio = new Date().getFullYear();
        if (anio === parseInt(year)) setIsOpen(true);
        countingPostsByYear()
    }, []);

    const formattingDate = (date,index) => {
        getPost(new Date(date));
        setCounterSelected(index);
    }

    const countingPostsByYear = () => {
        let counter = 0;
        months.map((month, i) => (
            counter += month.count
        ))
        setYearCounting(counter)
    }

  return (
    <div key={year}>
         <span onClick={toggle}>
            <div className="text">
                <span>{!isOpen ? <BiPlus /> : <BiMinus />}</span>
                <p>{filter.year} <span>{yearsCounting}</span></p>
            </div>
            <i>{!isOpen ? <BiChevronDown /> : <BiChevronUp />}</i>
        </span>
        <Collapse className="box_collapse" isOpen={isOpen}>
            <ul>
                {filter.months.map((item, i) => (
                    <li key={i}>
                        <Button className={`btn-publication-white ${(i === counterSelected) ? 'active' : ''}`} onClick={() => formattingDate(item.filterMonthDate, i)}>
                            <p>{item.month}
                                {months.map((month, ind) => {
                                    return (
                                        (month.month === item.month) ? <span key={ind}>{month.count}</span> : ''
                                    )
                                })}
                            </p>
                            <BiChevronRight />
                        </Button>
                    </li>
                ))}
            </ul>
        </Collapse>
    </div>
  );
}
