import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { rewardProviderService } from '../../services/control/rewardProvider.service';
import {Table} from "reactstrap";
import {Button} from "rsuite";

export function SearchRewardProvider(props) {
    const [rewardsData, setRewardsData] = useState({ rewards: [] });
    const handleDelete = (id) => {
        rewardProviderService.deleteProvider(id).then((result) => { getRewardProviders(); });
    };

    useEffect(() => {
        getRewardProviders();
    }, [props.refresh]);

    async function getRewardProviders() {
        rewardProviderService.get().then((result) => {
            if (result) {
                setRewardsData({ rewards: result });
            }
        });
    }

    return (
        <div className="table-responsive">
            <Table className="admin-table" hover>

                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Nombre</th>
                        <th>Url logotipo</th>
                        <th>Activo</th>
                    </tr>
                </thead>
                <tbody>
                    {rewardsData.rewards.map(reward => (
                        <tr key={reward.rewardProviderId}>
                            <td>
                                <Link className="btn-link d-flex-center" to={"/operator/details-rewardProvider/" + reward.rewardProviderId}>{reward.rewardProviderId}</Link>
                            </td>
                            <td>
                                <Link className="btn-link d-flex-center" to={"/operator/details-rewardProvider/" + reward.rewardProviderId}>{reward.businessName}</Link>
                            </td>
                            <td>
                                <Link className="btn-link d-flex-center" style={{textAlign: 'center',overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '300px', display: 'flex', justifyContent: 'flex-start'}} to={"/operator/details-rewardProvider/" + reward.rewardProviderId}>{reward.urlLogo}</Link>
                            </td>
                            <td>{reward.active ? "Activo" : "Inactivo"}</td>
                            <td>
                                <Button className="btn-icon-table delete" onClick={() => handleDelete(reward.rewardProviderId)}>
                                    <i className="icon-trash"></i>
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>

    );
}
