import React, { useState } from 'react';
import { EditCompany } from './editCompany'

export function DetailsCompany(props) {

    return (
        <div className="dashboard-container-page">
            <div className="admin-title">
                <h2>Empresas</h2>
                <h6>{props.match.params.id != 0 ? "Editar " : "Nueva "}empresa</h6>
            </div>
            <EditCompany companyId={props.match.params.id} />
        </div>
    );
}   
