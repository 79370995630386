import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { companyService } from '../../services/control/company.service';
import {Table} from "reactstrap";
import {Button} from "rsuite";

export function SearchCompany(props) {
    const [companiesData, setCompaniesData] = useState({ companies: [] });
    const handleDelete = (data) => {
        companyService.deleteCompany(data).then((result) => { getCompanies(); });
    };

    useEffect(() => {
        getCompanies(props.statusValue);
    }, [props.statusValue]);

    async function getCompanies(stat) {
        companyService.get(stat).then((result) => {
            if (result) {
                setCompaniesData({ companies: result });
            }
        });
    }
//comment force publish


    return (
        <div className="table-responsive">
            <Table className="admin-table" hover>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Nombre</th>
                        <th>Dominio</th>
                        <th>Activo</th>
                    </tr>
                </thead>
                <tbody>
                    {companiesData.companies.map(company=> (
                        <tr key={company.companyId}>
                            <td>
                                <Link className="btn-link d-flex-center" to={"/operator/details-company/" + company.companyId}>{company.companyId}</Link>
                            </td>
                            <td>
                                <Link className="btn-link d-flex-center" to={"/operator/details-company/" + company.companyId}>{company.companyName}</Link>
                            </td>
                            <td>
                                <Link className="btn-link d-flex-center" to={"/operator/details-company/" + company.companyId}>{company.primeDomain}</Link>
                            </td>
                            <td>{company.active ? "Activo" : "Inactivo"}</td>
                            <td>
                                {
                                    company.active ? 
                                    <Button className="btn-icon-table delete" onClick={() => handleDelete(company.companyId)}>
                                        <i className="icon-trash"></i>
                                    </Button>
                                    : ""
                                }
                            </td>

                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>

    );
}
