import React, { useState, useEffect } from "react";
import "./surveys.scss";
import { useSurveyUserProfile } from "../../services/surveyUserProfile.service";
import { SideAdvertising } from "../../components/sideAdvertising/sideAdvertising";
import { ListSurveys } from "./listSurveys";
import BoxSurveys from "./boxSurveys";
import Moment from "react-moment";
import "moment/locale/es";
import { useSelector } from "react-redux";
import { AdvertisingSection } from "../../components/advertisingSection/advertisingSection";
import { Col, Row } from "reactstrap";
import { Button } from "rsuite";
import { FooterHome } from "../../components/footer/footerHome";
import { useCompanyHoliday } from "../../services/companyHoliday.service";
import { BsThreeDots } from "react-icons/bs";
import $ from "jquery"

export function Surveys(props) {
  const [activeAsideSurvey, setStateAsideSurvey] = useState("");
  const AsidePurchaseBox = () =>
    setStateAsideSurvey(activeAsideSurvey === "" ? " is-show" : "");

  const [showTempQuestions, setShowTempQuestions] = useState("");
  const handleTempQuestions = () => {
    $('body').addClass('hide-over')
    setShowTempQuestions(showTempQuestions === "" ? "is_show" : "");
  };

  const calendarStrings = useSelector((state) => state.calendarStrings);
  const [selectedSurvey, setSelectedSurvey] = useState();
  const [surveysData, setSurveysData] = useState({
    pendingSurveys: [],
    resolvedSurveys: [],
  });

  useEffect(() => {
    getSurveys();
  }, [props.refresh]);

  const getSurveys = async () => {
    useSurveyUserProfile.getSurveysUserProfile().then((result) => {
      if (result) setSurveysData(result);
    });
  };

  const resetBoxSurveys = async () => setSelectedSurvey(undefined);

  const handleOnSurveySelected = (survey) => {
    resetBoxSurveys().then(() => setSelectedSurvey(survey));
  };

  function getWidthDimensions() {
    const { innerWidth: width } = window;

    return width;
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWidthDimensions()
  );

  useEffect(() => {
    getCompanyHolidays();
    const handleResize = () => setWindowDimensions(getWidthDimensions());
    if (windowDimensions >= 992 && activeAsideSurvey !== "")
      setStateAsideSurvey("");
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [windowDimensions]);

  const [sliderItems, setSliderItems] = useState([]);

  async function getCompanyHolidays() {
    useCompanyHoliday.getCompanyHolidayFront(3).then((result) => {
      if (result) {
        const temp = [];
        result.map((ch) => {
          temp.push({
            key: ch.companyHolidayId.toString(),
            file: ch.imagePath,
            description: ch.description,
            isVideo: ch.isVideo,
            link: ch.iframe,
          });
        });
        setSliderItems(temp);
      }
    });
  } 

  return (
    <section className="dashboard-container-page">
      <div className="row justify-content-center mt-4">
        <div className="col-sm-8">
          <AdvertisingSection HolType={9} />
        </div>
      </div>
      <article className="surveys-container">
        <div className="surveys-container-content">
          <Row>
            <Col sm={12}>
              <h2 className="mt-0 mb-2 opi">
                Tu opinión
                <Button
                  className="btn-ellipsis-open"
                  onClick={AsidePurchaseBox}
                >
                  <BsThreeDots></BsThreeDots>
                </Button>
              </h2>

              <div className="border-pointed">
                <p>
                  Esta encuesta es anónima y personal, lee detenidamente cada
                  ítem, es muy fácil de responder, en la mayoría de las
                  preguntas se pide que elijas entre varias posibilidades.
                </p>
              </div>
            </Col>
          </Row>
          <div className=" surveys-container-content-body">
            <h5 className="mb-3 mt-4">Encuestas disponibles</h5>
            {surveysData.pendingSurveys.length === 0 ? (
              <p>Por el momento no tienes encuestas pendientes</p>
            ) : (
              <div className="surveys-list ">
                <ListSurveys
                  surveys={surveysData.pendingSurveys}
                  onSelectSurvey={handleOnSurveySelected}
                  handleTempQuestions={handleTempQuestions}
                />
              </div>
            )}
            <div className={`surveys-card ${showTempQuestions}`} >
              {selectedSurvey ? (
                <BoxSurveys
                  survey={selectedSurvey}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        <aside className={`surveys-container-aside ${activeAsideSurvey}`}>
          <Button className="btn-ellipsis-close" onClick={AsidePurchaseBox}>
            <i className="icon-close"></i>
          </Button>
          {sliderItems.length === 0 ? "" : <SideAdvertising />}
          <div className="surveys-container-aside-prev">
            <h6>Últimas encuestas realizadas</h6>
            {surveysData.resolvedSurveys.length == 0 ? (
              <p> Por el momento no tienes encuestas realizadas</p>
            ) : (
              surveysData.resolvedSurveys.map((survey) => {
                return (
                  <div className="item" key={survey.surveyId}>
                    <span>
                      <i className="fas fa-check-circle"></i>
                    </span>
                    <div>
                      <div className="xtl_survey">{survey.title}</div>
                      <small>
                        <Moment calendar={calendarStrings}>
                          {survey.modifiedDate}
                        </Moment>
                      </small>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </aside>
      </article>
      <FooterHome />
    </section>
  );
}
