import React from "react";
import "moment/locale/es";
import { Link } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "../carouselMainHome/carouselMainHome.scss";


const typePublicationRoute = [
  { value: 0, route: "/our-company/1/" },
  { value: 1, route: "/blog-company/gallery/" },
];

export function CarouselMainHomeTwo({ lastVideo }) {
  return (
    <div className="prideWorld-container">
      <h3 className="ttls_in_muro">
        <Link to="/course-view">Escuela Romeh</Link>
      </h3>
      <Splide
        options={{
          perPage: 4,
          autoplay: true,
          arrows: true,
          pagination: false,
          perMove: 1,
          width: "100%",
          rewindByDrag: true,
          rewind: true,
          drag: true,
          pauseOnHover: true,
          speed: 500,
          gap: 2,
          breakpoints: {
            1480: {
              perPage: 3,
            },
            720: {
              perPage: 2,
            },
            490: {
              perPage: 1,
            },
          },
        }}
      >
        {lastVideo &&
          lastVideo.map((item, index) => (
            <SplideSlide key={index}>
              <Link
                // to={`/course-view/${item.CourseId}`}
                to={`/course-view`}
                className="card-worldpride"
                key={item.CourseId}
              >
                <div className="card-worldpride-content">
                 <div className="overlay-img" style={{width: 'unset'}}>
                    <div dangerouslySetInnerHTML={{ __html: item.UrlMedia }} />
                </div> 
                  {/* <div className="bg-gradient-dark"></div> */}
                  <div className="info">
                    <h5>
                      {item.Name
                        ? item.Name.length <= 60
                          ? item.Name
                          : item.Name.substring(0, 60) + "..."
                        : ""}
                    </h5>
                    <p>{item.Description}</p>
                  </div>
                </div>
              </Link>
            </SplideSlide>
            // <SplideSlide key={index}>
            //   <Link
            //     to={`${
            //       typePublicationRoute.find((f) => f.value === item.postTypeId)
            //         .route
            //     }${item.companyPostId}`}
            //     className="card-worldpride"
            //     key={item.id}
            //   >
            //     <div className="card-worldpride-content">
            //       <div className="overlay-img">
            //         <img src={item.urlImage} alt="" />
            //       </div>
            //       {/* <div className="bg-gradient-dark"></div> */}
            //       <div className="info">
            //         <h5>
            //           {item.title
            //             ? item.title.length <= 60
            //               ? item.title
            //               : item.title.substring(0, 60) + "..."
            //             : ""}
            //         </h5>
            //         <p>{item.description}</p>
            //       </div>
            //     </div>
            //   </Link>
            // </SplideSlide>
          ))}
      </Splide>
    </div>
  );
}
