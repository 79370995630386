import React from "react";
import { ItemSocial } from "./itemSocial";
import "./interestGroup.scss";
import { IoMail } from "react-icons/io5";

import data from "../../assets/jsons/interestGroup.json";
import { Col, Row } from "reactstrap";
import { FooterHome } from "../../components/footer/footerHome";

export function InterestGroup() {
  return (
    <section className="dashboard-container-page">
      <h2>Testimonios</h2>
      {/* <div className="drawer__bg__sections">
            <img
              src={bannerGroups}
              alt="Grupos donde nuestra comunidad es protagonista"
            />
          </div> */}
      {/* <div className="interest-group-header">
        <div className="title">
          <h3>Grupos donde nuestra comunidad es protagonista</h3>
        </div>
        <div className="imgs">
          <img src={imgHeader} alt="" />
        </div>
      </div> */}

      <div className="interest-group-cards">
        {data.map((item) => {
          return (
            <div className="card-interest-group" key={item.id}>
              <div className="front">
                <img src={item.thumbnail} alt="" />
                <h4>{item.nameDirectory}</h4>
              </div>
              <div className="back">
                <div className="info">
                {item.email ? ( <h6>
                    <span>DATOS DE CONTACTO</span>
                  </h6>) : ("")}
                  <h4>{item.nameDirectory}</h4>
                  {item.email ? (
                    <p>
                      <span>
                        <IoMail />
                      </span>{" "}
                      {item.email}
                    </p>
                  ) : (
                    ""
                  )}
                  <div className="social-media">
                    {item.socialLinks.website ? (
                      <ItemSocial
                        socialIcoName="WebSite"
                        socialIco="globe-outline"
                        socialUrl={item.socialLinks.website}
                      />
                    ) : (
                      ""
                    )}
                    {item.socialLinks.instagram ? (
                      <ItemSocial
                        socialIcoName="Instagram"
                        socialIco="logo-instagram"
                        socialUrl={item.socialLinks.instagram}
                      />
                    ) : (
                      ""
                    )}
                    {item.socialLinks.facebook ? (
                      <ItemSocial
                        socialIcoName="Facebook"
                        socialIco="logo-facebook"
                        socialUrl={item.socialLinks.facebook}
                      />
                    ) : (
                      ""
                    )}
                    {item.socialLinks.twitter ? (
                      <ItemSocial
                        socialIcoName="Twitter"
                        socialIco="logo-twitter"
                        socialUrl={item.socialLinks.twitter}
                      />
                    ) : (
                      ""
                    )}
                    {item.socialLinks.tiktok ? (
                      <ItemSocial
                        socialIcoName="TikTok"
                        socialIco="logo-tiktok"
                        socialUrl={item.socialLinks.tiktok}
                      />
                    ) : (
                      ""
                    )}
                    {item.socialLinks.youtube ? (
                      <ItemSocial
                        socialIcoName="Youtube"
                        socialIco="logo-youtube"
                        socialUrl={item.socialLinks.youtube}
                      />
                    ) : (
                      ""
                    )}
                    {item.socialLinks.webAlternative ? (
                      <ItemSocial
                        socialIcoName="Help"
                        socialIco="earth-outline"
                        socialUrl={item.socialLinks.webAlternative}
                      />
                    ) : (
                      ""
                    )}
                    {item.socialLinks.linkedin ? (
                      <ItemSocial
                        socialIcoName="Linkedin"
                        socialIco="logo-linkedin"
                        socialUrl={item.socialLinks.linkedin}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Row>
        <Col sm={12}>
          <FooterHome />
        </Col>
      </Row>
    </section>
  );
}
