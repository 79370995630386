import React, { useEffect, useState } from "react";
import { RecoverPassword } from "./recoverPassword";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { userService } from "../../services/user.service";
import swal from "sweetalert";
import WOW from "wowjs";
import { TypeAnimation } from "react-type-animation";
import chrome from '../../assets/imgs/chrome.png';

import bgLogin from "../../assets/imgs/landing/bglogin.svg";
import LogoPrideJson from "../../assets/imgs/landing/logo-pridemx.json";
import LogoCompany from "../../assets/imgs/RodrigoRomeh.png";
import { ImgCache } from "../../components/CustonHooks/imgCache"

import {
  IoEyeOffOutline,
  IoEyeOutline,
  IoKey,
  IoMail,
} from "react-icons/io5";
import { Button, Col, Form, Input, Modal} from "reactstrap";
import "./login.scss";
import "animate.css";

export const Login = (props) => {
  const [showLogeo, setShowLogeo] = useState(true);
  const toggleLogeo = (value) => setShowLogeo(value);

  const accountSettings = useSelector((state) => state.accountSettings);
  const companyLogo = useSelector((state) => state.userSettings.companyLogo);

  const [showPassword, setShowPassword] = useState(false);
  const togglePassword = () => setShowPassword(!showPassword);

  const [scrollTop, setScrollTop] = useState(0);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [visible, setVisible] = useState(false);
  const [btnmenu, setBtnmenu] = useState(false);
  const toggleMenu = () => {
    setBtnmenu(!btnmenu);
    setVisible(!visible);
  };

  const CargarLogeo = () => {
    return showLogeo ? (
      <div className="form-login-recover">
        <button type="button" onClick={() => toggleLogeo(false)}>
          Olvidé mi contraseña
        </button>
      </div>
    ) : null;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let params = {
      username: e.target.user.value,
      password: e.target.password.value,
      productSubscription: accountSettings.productSubscription,
    };
    userService.login(params).then((result) => {
      if (result.responseCode === "00") {
        let roleName = result.data.roleName;
        if (roleName === "Operaciones") props.history.push("/homeOps/1");
        else props.history.push("/home");
      } else if (result.responseCode === "11") {
        props.history.push({
          pathname: "/membership",
          state: result.data,
        });
      } else {
        let verifica =
          "Datos incorrectos por favor revisa usuario y contraseña.";
        if (result.responseCode === "09")
          verifica +=
            "\n O no olvides que debes verificar tu cuenta, revisa tu bandeja de entrada o de spam.";
        swal({
          text: verifica,
          icon: "error",
        });
      }
    });
  };

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    centerPadding: "0",
    speed: 900,
    autoplaySpeed: 2750,
    pauseOnHover: true,
    swipeToSlide: true,
    className: "center",
    centerMode: true,
    responsive: [
      {
        breakpoint: 440,
        settings: {
          slidesToShow: 1,
          centerPadding: "40",
          slidesToScroll: 1,
        },
      },
    ],
  };

  const AnioActual = () => {
    var fecha = new Date().getFullYear();
    return <>{fecha}</>;
  };

  const [visibleAlert, setVisibleAlert] = useState(true);
  const [plecaPrideMX, setPlecaPrideMX] = useState('');

  const onDismiss = () => {
    setVisibleAlert(false);
    localStorage.setItem("plecaPrideMX", "true");
  }

  useEffect(() => {
    let plecaPrideMX = localStorage.getItem("plecaPrideMX");
    if(plecaPrideMX === 'true') {
      setVisibleAlert(false);
      setPlecaPrideMX(plecaPrideMX);
    }
    //making scrolling
    const handleScroll = () => setScrollTop(window.scrollY);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [preload, setPreload] = useState(true);

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
    setTimeout(() => setPreload(preload === false), 6750);
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LogoPrideJson,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div
      className="wrapper__landing_v2"
      style={{ backgroundImage: `url(${bgLogin})` }}
    >
      <div className="main__landing">
        <header
          className={`wrapper_header_in_login trans ${
            scrollTop ? "scrolling" : ""
          }`}
        >
          <div className="container-lg">
            <div className="cont_brand">
              <a href="#welcome">
                {/* <img
                  className="trans"
                  src={LogoPrideCom}
                  alt="PrideMx, Todo el poder de la comunidad en un solo espacio"
                /> */}
                <ImgCache src={companyLogo ? companyLogo : LogoCompany} className="trans logoLogin" alt="Kuimby Social" />
              </a>
            </div>
            {/* <nav
              className={`cont_options_links ${btnmenu ? "is__open" : ""}`}
              id="navbar-example"
            >
              <ul role="tablist">
                <li onClick={toggleMenu}>
                  <a className="a_link_options ico_home white" href="#welcome">
                    <RiHomeHeartFill />
                  </a>
                </li>
                <li onClick={toggleMenu}>
                  <a className="a_link_options red" href="#section-a">
                    PrideMx
                  </a>
                </li>
                <li onClick={toggleMenu}>
                  <a className="a_link_options orange" href="#section-b">
                    Pride Music
                  </a>
                </li>
                <li onClick={toggleMenu}>
                  <a className="a_link_options yellow" href="#section-c">
                    Rewards
                  </a>
                </li>
                <li onClick={toggleMenu}>
                  <a className="a_link_options green" href="#section-d">
                    Emprendedores
                  </a>
                </li>
              </ul>
            </nav> */}
            <div className="cont_social">
              <div>
                <div className="slabel">SÍGUEME EN</div>
                <div className="box_link_fa">
                  <a
                    target="_blank"
                    className="facebook"
                    href="https://www.facebook.com/RomehRodrigo/"
                  >
                    <i className="fa fa-facebook-f"></i>
                  </a>

                  <a
                    target="_blank"
                    className="instagram"
                    href="https://instagram.com/Rodrigo_Romeh"
                  >
                    <i className="fa fa-instagram"></i>
                  </a>

                  <a
                    target="_blank"
                    className="twitter"
                    href="https://twitter.com/Rodrigo_Romeh"
                  >
                    <svg style={{marginBottom: '5px'}} viewBox="0 0 512 512" height="16px"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg>
                  </a>
                </div>
              </div>
              {/* <div className="btn_menu_lndng" onClick={toggleMenu}>
                <span className={`btn_menu ${visible ? "visible_menu" : ""}`}>
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </div> */}
            </div>
          </div>
        </header>

        <section className="wrapper__welcome" id="welcome">
          <div className="container-lg">
            <div className="cont_info_welcome animate__animated animate__fadeInUp">
              <h3 className="welcome_ttl xthin">Descubre todo el</h3>
              <h1 className="welcome_ttl xbold mb">
                bienestar de{" "}
                <span className="welcome_ttl xbold c_black">la Comunidad</span>
              </h1>{" "}
              <h3 className="welcome_ttl xthin">en un solo espacio</h3>
            </div>

            <div className="cont_form_login animate__fadeInUp animate__animated">
              <div className="form-login">
                {showLogeo ? (
                  <>
                    <h1 className="landing-header-subtitle">
                      <TypeAnimation
                        sequence={[
                          "¡Bienvenidas!",
                          1500,
                          "¡Bienvenidos!",
                          1500,
                        ]}
                        speed={65}
                        repeat={Infinity}
                      />
                    </h1>
                    <Form onSubmit={handleSubmit} form>
                      <Col sm={12}>
                        <label form="email" className="form-login-input">
                          <span className="form-login-icon">
                            <IoMail />
                          </span>
                          <Input
                            id="email"
                            placeholder="Correo electrónico"
                            type="email"
                            name="user"
                          />
                        </label>
                      </Col>
                      <Col sm={12}>
                        <label form="pass" className="form-login-input">
                          <span className="form-login-icon">
                            <IoKey />
                          </span>
                          <Input
                            id="pass"
                            placeholder="********"
                            type={`${showPassword ? "text" : "password"}`}
                            name="password"
                          />
                          <span
                            className="form-login-password"
                            onClick={togglePassword}
                          >
                            {showPassword ? (
                              <IoEyeOffOutline />
                            ) : (
                              <IoEyeOutline />
                            )}
                          </span>
                        </label>
                      </Col>
                      <Col lg={24}>
                        <CargarLogeo />
                      </Col>
                      <Col lg={24}>
                        <div className="form-login-submit">
                          <Button type="submit" className="btn-login">
                            ENTRAR
                          </Button>
                        </div>
                        <div className="text-center link__regster">
                          <Link className="" to="/register">
                            ¡QUIERO REGISTRARME!
                          </Link>
                        </div>
                      </Col>
                    </Form>
                  </>
                ) : (
                  <RecoverPassword toggleLogeo={toggleLogeo} />
                )}
              </div>
            </div>
          </div>
          {/* <div className="box_scroll_home">
            <p>Scroll me</p>
            <a href="#section-a">
              <img
                src={ImgScroll}
                alt="PrideMx, Todo el poder de la comunidad en un solo espacio"
              />
            </a>
          </div> */}
        </section>

{/* 
        {/* == SOMOSPRIDE == 
        <section className="wrapper__a" id="section-a">
          <div className="item_resource_video wow animate__animated animate__slideInLeft trans">
            <img
              className="btn_icon_play"
              onClick={toggle}
              src={IconPlay}
              alt="PrideMx, Todo el poder de la comunidad en un solo espacio"
            />
          </div>
          <div className="container-lg wow animate__animated animate__fadeInRight">
            <div className="sec_a">
              <div className="content_a">
                <h1 className="ttl_primary">
                  #SOMOS<span className="">PRIDEMX</span>
                </h1>
                <p className="paragraph_lndgn">
                  Un espacio seguro donde nuestra comunidad puede conectarse
                  para{" "}
                  <strong>
                    colaborar, hacer negocios, aprender, entretenerse y
                    empoderarse
                  </strong>
                  .
                </p>
                <div>
                  <Link className="btn_in_section trans" to="/register">
                    ¡Quiero unirme!
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      */}
        {/* == PRIDEMUSIC == 
        <div className="wrapper__b" id="section-b">
          <div className="container-lg">
            <div className="sec__b">
              <div className="content_info wow animate__animated animate__fadeInLeft">
                <h1 className="ttl_primary mb-3">
                  <span className="">PRIDE MUSIC</span>
                </h1>
                <h1 className="ttl_secundary mb-4">
                  ¿Repites las mismas 10 canciones?
                </h1>
                <p className="paragraph_lndgn">
                  Descubre lxs exponentes musicales LGBTQ+ que te volarán la
                  cabeza y agrégales a tu playlist favorita.
                </p>
              </div>
              <div className="content_media ">
                <div className="carta-box mt-4  wow animate__animated animate__fadeInUp ">
                  <div className="carta">
                    <div className="cara">
                      <img src={Pmusic01} />
                    </div>
                    <div className="cara detras">
                      <img src={Pmusic01g} />
                      <div className="disc">
                        <svg
                          id="Layer_2"
                          data-name="Layer 2"
                          viewBox="0 0 95.82 95.82"
                        >
                          <g id="Layer_1-2" data-name="Layer 1">
                            <g>
                              <path d="m47.91,95.82C21.49,95.82,0,74.33,0,47.91S21.49,0,47.91,0s47.91,21.49,47.91,47.91-21.49,47.91-47.91,47.91Zm0-92.82C23.15,3,3,23.15,3,47.91s20.15,44.91,44.91,44.91,44.91-20.15,44.91-44.91S72.67,3,47.91,3Z" />
                              <path d="m47.91,73.27c-13.98,0-25.36-11.38-25.36-25.36s11.38-25.36,25.36-25.36,25.36,11.38,25.36,25.36-11.38,25.36-25.36,25.36Zm0-47.72c-12.33,0-22.36,10.03-22.36,22.36s10.03,22.36,22.36,22.36,22.36-10.03,22.36-22.36-10.03-22.36-22.36-22.36Z" />
                              <path d="m72.17,23.47c-6.49-6.45-15.11-10-24.26-10h-.11s0-3,0-3h.09c9.97,0,19.34,3.86,26.4,10.87l-2.11,2.13Z" />
                            </g>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="carta-box box_middle  wow animate__animated animate__fadeInUp animate__delay-1s">
                  <div className="carta">
                    <div className="cara">
                      <img src={Pmusic02} />
                    </div>
                    <div className="cara detras">
                      <img src={Pmusic02g} />
                      <div className="disc">
                        <svg
                          id="Layer_2"
                          data-name="Layer 2"
                          viewBox="0 0 95.82 95.82"
                        >
                          <g id="Layer_1-2" data-name="Layer 1">
                            <g>
                              <path d="m47.91,95.82C21.49,95.82,0,74.33,0,47.91S21.49,0,47.91,0s47.91,21.49,47.91,47.91-21.49,47.91-47.91,47.91Zm0-92.82C23.15,3,3,23.15,3,47.91s20.15,44.91,44.91,44.91,44.91-20.15,44.91-44.91S72.67,3,47.91,3Z" />
                              <path d="m47.91,73.27c-13.98,0-25.36-11.38-25.36-25.36s11.38-25.36,25.36-25.36,25.36,11.38,25.36,25.36-11.38,25.36-25.36,25.36Zm0-47.72c-12.33,0-22.36,10.03-22.36,22.36s10.03,22.36,22.36,22.36,22.36-10.03,22.36-22.36-10.03-22.36-22.36-22.36Z" />
                              <path d="m72.17,23.47c-6.49-6.45-15.11-10-24.26-10h-.11s0-3,0-3h.09c9.97,0,19.34,3.86,26.4,10.87l-2.11,2.13Z" />
                            </g>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="carta-box mt-4  wow animate__animated animate__fadeInUp animate__delay-2s">
                  <div className="carta">
                    <div className="cara">
                      <img src={Pmusic03} />
                    </div>
                    <div className="cara detras">
                      <img src={Pmusic03g} />
                      <div className="disc">
                        <svg
                          id="Layer_2"
                          data-name="Layer 2"
                          viewBox="0 0 95.82 95.82"
                        >
                          <g id="Layer_1-2" data-name="Layer 1">
                            <g>
                              <path d="m47.91,95.82C21.49,95.82,0,74.33,0,47.91S21.49,0,47.91,0s47.91,21.49,47.91,47.91-21.49,47.91-47.91,47.91Zm0-92.82C23.15,3,3,23.15,3,47.91s20.15,44.91,44.91,44.91,44.91-20.15,44.91-44.91S72.67,3,47.91,3Z" />
                              <path d="m47.91,73.27c-13.98,0-25.36-11.38-25.36-25.36s11.38-25.36,25.36-25.36,25.36,11.38,25.36,25.36-11.38,25.36-25.36,25.36Zm0-47.72c-12.33,0-22.36,10.03-22.36,22.36s10.03,22.36,22.36,22.36,22.36-10.03,22.36-22.36-10.03-22.36-22.36-22.36Z" />
                              <path d="m72.17,23.47c-6.49-6.45-15.11-10-24.26-10h-.11s0-3,0-3h.09c9.97,0,19.34,3.86,26.4,10.87l-2.11,2.13Z" />
                            </g>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
*/}
        {/* == REWARDS == 
        <div className="wrapper__c" id="section-c">
          <div className="container-lg">
            <div className="row align-items-center">
              <div className="col-md-7">
                <div className="sec_c_carousel wow animate__animated animate__fadeInLeft">
                  <Slider {...settings}>
                    <div className="item_picture">
                      <img
                        src={Rewards01}
                        alt="Rewards en PrideMx - Acciones que tienen su recompensa"
                      />
                    </div>
                    <div className="item_picture">
                      <img
                        src={Rewards02}
                        alt="Rewards en PrideMx - Acciones que tienen su recompensa"
                      />
                    </div>
                    <div className="item_picture">
                      <img
                        src={Rewards03}
                        alt="Rewards en PrideMx - Acciones que tienen su recompensa"
                      />
                    </div>
                    <div className="item_picture">
                      <img
                        src={Rewards04}
                        alt="Rewards en PrideMx - Acciones que tienen su recompensa"
                      />
                    </div>
                  </Slider>
                </div>
              </div>
              <div className="col-md-5">
                <div className="sec_c_info wow animate__animated animate__fadeInRight">
                  <div>
                    <h1 className="ttl_primary mb-3">
                      <span className="">REWARDS</span>
                    </h1>
                    <h1 className="ttl_secundary mb-4">
                      ¡Acciones que tienen su recompensa!
                    </h1>
                    <p className="paragraph_lndgn">
                      Secciones que te dan puntos acumulables y canjeables por
                      descuentos y premios exclusivos.
                    </p>
                    <p className="paragraph_lndgn mt-2">
                      <strong>¡Registrarte es solo el principio!</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* == EMPRENDEDORES == 
        <div className="wrapper__d" id="section-d">
          <div className="container-lg">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="sec_d_info wow animate__animated animate__fadeInLeft">
                  <div>
                    <h1 className="ttl_primary mb-3">
                      <span>EMPRENDEDORES</span>
                    </h1>
                    <h1 className="ttl_secundary mb-4">
                      ¡Siempre hay algo para alguien, en cualquier lugar!
                    </h1>
                    <p className="paragraph_lndgn">
                      Ese sitio es aquí, donde emprendedores con servicios o
                      productos buscan crecer sus negocios y encontrar personas
                      que les necesitan.
                    </p>
                    <p className="paragraph_lndgn mt-2">
                      <strong>
                        Regístrate y date de alta, a un precio único.
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="sec_d_custom">
                  <div className="carta-box-emp  ei">
                    <div className="carta">
                      <div className="cara">
                        <img
                          src={Emprendedor01}
                          alt="Emprendedores en PrideMx - Siempre hay algo para alguien, en cualquier lugar"
                        />
                      </div>
                      <div className="cara detras">
                        <div className="back_box ">
                          Nuevas experiencias para negocios y consumidores
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="carta-box-emp eii">
                    <div className="carta">
                      <div className="cara">
                        <img
                          src={Emprendedor02}
                          alt="Emprendedores en PrideMx - Siempre hay algo para alguien, en cualquier lugar"
                        />
                      </div>
                      <div className="cara detras ">
                        <div className="back_box ">
                          Impulsamos tu crecimiento comercial
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="carta-box-emp eiii">
                    <div className="carta">
                      <div className="cara">
                        <img
                          src={Emprendedor03}
                          alt="Emprendedores en PrideMx - Siempre hay algo para alguien, en cualquier lugar"
                        />
                      </div>
                      <div className="cara detras ">
                        <div className="back_box ">
                          Exposición y difusión en nuestras redes sociales
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        */}
        <footer className="wrapper_footer ">
          <div className="container-lg">
            <div className="sec_footer">
              <div className="links">
                <a target="_blank" href="/ambienteseguro.pdf">
                  Decálogo
                </a>
                <a target="_blank" href="/avisoprivacidad.pdf">
                  Aviso de Privacidad
                </a>
                <a target="_blank" href="/terminosycondiciones.pdf">
                  Términos y Condiciones
                </a>
              </div>
              <div>
                <AnioActual /> ©Kuimby. Todos los derechos reservados
              </div>
            </div>
          </div>
        </footer>

        <div className={`alert-chrome ${(plecaPrideMX === 'true' || !visibleAlert) ? 'showing' : ''}`}>
          <button className="btn-close" onClick={onDismiss}><i className="icon-close"></i></button>
          <div className="alert-chrome-content">
            <p>Para una mejor experience de usuario, recomendamos usar Google Chrome</p>
            <img src={chrome} alt="Google Chrome" />
          </div>
        </div>

        {/* <Modal
          isOpen={modal}
          toggle={toggle}
          className="wrapper_modal_cap inLogin"
        >
          <div className="sec_video_pride">
            <button type="button" className="btn-close-login" onClick={toggle}>
              Cerrar<i className="fa fa-times"></i>
            </button>
            <video controls preload autoPlay>
              <source
                src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/pride/site/pridemxvideo.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </Modal> */}

        {/* <div
          className={`wrapper_load_animation ${preload ? "is__show" : ""}`}
        >
          <section className="content_bg_img" style={{ backgroundImage: `url(${BgLoadAnimation})` }}></section>
          <Lottie options={defaultOptions} />
        </div> */}
      </div>
      <div className="particle_container">
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
      </div>
      
    </div>
  );
};
