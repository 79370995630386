import React, {useEffect, useState} from "react";

import "./blogCompany.scss";
import {Button, Col, Row} from "reactstrap";
import {Link} from "react-router-dom";

import './blogCompany.scss';

import bgGallery from '../../assets/imgs/icon-images.png';
import imageEmpty from '../../assets/imgs/no-image.jpeg';
import {companyPostViewService} from "../../services/compayPostView.service";
import "moment/locale/es";
import Moment from 'react-moment';
import {AsideOurCompany} from "../companyPost/asideOurCompany";
import {FooterHome} from "../../components/footer/footerHome";

export function BlogCompanyPrimary() {

    const [activeAsideSurvey, setStateAsideSurvey] = useState("");
    const [posts, setPosts] = useState([]);
    const [countingPosts, setCountingPosts] = useState([]);
    const [selectedPost, setSelectedPost] = useState({});
    let [currentFilter, setCurrentFilter] = useState("NaN-aN-aN");
    const [lastFiter, setLastFiter] = useState({});
    const [sectionActive, setSectionActive] = useState("1");
    const postCategoryId = 1;
    const [comments, setComments] = useState(0);
    const [postComment, setPostComment] = useState({});

    const clearVariable = () => {
        setSelectedPost({});
        setPosts([]);
    };

    const getPosts = async () => {
        clearVariable();
        companyPostViewService
            .getByFilters(currentFilter === "NaN-aN-aN" ? undefined : currentFilter, postCategoryId)
            .then((result) => {
                if (result && result.length > 0) {
                    //let item = {};
                    //item = result[0];
                    //handleSelectPost(item);
                    setPosts(result);
                }
            });
    };

    const handleSelectPost = (item) => {
        if (item.isRead === false) {
            companyPostViewService
                .InsertRead({ CompanyPostId: item.companyPostId })
                .then((result) => {
                    if (result) {
                        setSelectedPost(result);
                        if (posts.length > 0) {
                            let items = posts.map((m) => {
                                if (m.companyPostId === item.companyPostId) m.isRead = true;
                                return m;
                            });
                            setPosts(items);
                        }
                    }
                });
            window.location.href= `/our-company/1/${item.companyPostId}`;
        } else {
            window.location.href= `/our-company/1/${item.companyPostId}`;
        }
    };

    /*const getPost = (id) => {
        companyPostViewService.getById(id).then((result) => {
            if (result.postType === 0) setSelectedPost(result);
            if (result.postType === 1) result.iconPath = result.urlImage;
        });
    };*/

    const formatDate = (date) => {
        let datestring =
            date.getFullYear() +
            "-" +
            ("0" + (date.getMonth() + 1)).slice(-2) +
            "-" +
            ("0" + date.getDate()).slice(-2);
        return datestring;
    };

   useEffect(() => {
        if (lastFiter.filterMonthDate !== undefined && postCategoryId === lastFiter.postType) {
            let datelastFilter = formatDate(new Date(lastFiter.filterMonthDate));
            if (currentFilter === datelastFilter) {
                getPosts();
            } else {
                getPostDateFilter(new Date(lastFiter.filterMonthDate));
            }
        }
    }, [lastFiter]);

    useEffect(() => {
        getPostDateFilter(new Date());
    }, []);

    const getPostDateFilter = (filterDate) => {
        const year = String(filterDate.getFullYear());
        const month = String(filterDate.getMonth() + 1).padStart(2, '0');
        const day = String(filterDate.getDate()).padStart(2, '0');
        const joined = [year,month, day].join('-');
        setCurrentFilter(currentFilter = joined);
        getPosts();
    };

    const goToGallery = () => window.location.href = `/blog-company/gallery`;

    return (
        <article className="dashboard-container-page">
            <Row>
                <Col sm={12}><h2>Preguntas frecuentes</h2></Col>
            </Row>
            <Row className="d-flex-column">
                <Col lg={9} md={8} sm={12}>
                    <div className="blog-company-main-card">
                        {
                            posts.slice(0,1).map((card, index) => {
                                return(
                                    <>
                                        <div className="thumb" key={index}>
                                            {
                                                (card.urlImage) ?
                                                    <img src={card.urlImage} alt="" />
                                                    :
                                                    <img src={imageEmpty} alt="" />
                                            }
                                        </div>
                                        <div className="content">
                                            {/* <h6><Moment date={card.publishDate} format="dddd D [de] MMMM [del] YYYY" /></h6> */}
                                            <h3>{card.title}</h3>
                                            <div className="flex-end">
                                                <Button onClick={() => handleSelectPost(card)} className="btn-show-more">Ver más <span></span></Button>
                                            </div>
                                        </div>
                                    </>
                                );
                            })
                        }
                    </div>
                    <div className="blog-company-cards">
                        <Row>
                            {/* <Col sm={12}><h3>Éste mes</h3></Col> */}
                            <Col lg={12}>
                                {
                                    posts.slice(1).map((card, index) => {
                                        return(
                                            <div className="card-post" key={index+index}>
                                                <div className="thumb">
                                                    {
                                                        (card.urlImage) ?
                                                            <img src={card.urlImage} alt="" />
                                                            :
                                                            <img src={imageEmpty} alt="" />
                                                    }
                                                </div>
                                                <div className="content">
                                                    {/* <h6><Moment date={card.publishDate} format="dddd D [de] MMMM [del] YYYY" /></h6> */}
                                                    <h4>{card.title}</h4>
                                                    <div className="flex-end">
                                                        <Button onClick={() => handleSelectPost(card)} className="btn-show-more">Ver más <span></span></Button>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                                {
                                    (posts.slice(0,-1).length === 0) ?
                                        <div className="card-empty">
                                           <p>No hay publicaciones de éste mes.</p>
                                        </div>
                                    : ''
                                }
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col lg={3} md={4} sm={12}>
                   {/*  <aside className="blog-worldpride-aside">
                        <Link to={`/blog-company/gallery`} className="blog-worldpride-aside-card">
                            <div className="bg-gradient-pride"></div>
                            <img src={bgGallery} alt="" />
                            <h3>Galería de Preguntas frecuentes</h3>
                            <div className="flex-end">
                                <Button onClick={goToGallery} className="btn-outline-white">Galería <span></span></Button>
                            </div>
                        </Link>
                    </aside>*/}
                    <AsideOurCompany
                        getPost={getPostDateFilter}
                        activeAsideSurvey={activeAsideSurvey}
                        postCategoryId={postCategoryId}
                        setLastFiter={setLastFiter}
                        postType={0}
                    />
                </Col> 
            </Row>
            <Row>
                <Col sm={12}>
                    <FooterHome />
                </Col>
            </Row>
        </article>
    );
}
