import React, { useRef, useState } from 'react';
import SearchGalleries from '../../../components/companyPost/searchGalleries';
import './galleries.scss'

import useInputSingleForm from '../../../components/CustonHooks/inputFormHook';
import { storageService } from '../../../services/storage.service';
import { companyPostService } from '../../../services/companyPost.service';
import { SelectUsers } from "../../../components/Helpers/selectUsers";
import { AudienceUsers } from "../../../components/Helpers/audienceUsers";


import { useDropzone } from 'react-dropzone';
import './galleries.scss'
import swal from "sweetalert"
import {Button} from "rsuite";
import {Col, FormGroup, Modal, ModalBody, ModalFooter, Row} from "reactstrap";
import {FaPlus, FaSave, FaTimes, FaTimesCircle} from "react-icons/fa";


export function AdminGallery() {

    let companyInit = { CompanyPostId: 0, Title: '', Subtitle: '', Text: '', DepartmentId: 0, JobRoleId: 0 }
    const [showEdit, setShowEdit] = useState(false);
    const [userSelec, setUserSelec] = useState([]);
    const [images, setImages] = useState([]);
    const [postType, setPostType] = useState(1);
    const gridRef = useRef();
    const imageExt = ['jpg', 'jpeg', 'png'];
    const [valMessage, setValMessage] = useState(true);

    const validateFile = (fileData) => {
        var re = /(?:\.([^.]+))?$/;
        var ext = re.exec(fileData)[1];
        if (imageExt.includes(ext)) {
          return '';
        } else {
          return <span className="mb-1 text-danger">No Valido</span>;
        }
      }

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
    const files = acceptedFiles.map(file => (
        <li key={file.path}>
           <i className="icon-doc"></i> {file.path} - {file.size} bytes { validateFile(file.path) }
        </li>
    ));


    const removeAllFiles = () => {
        acceptedFiles.length = 0
        acceptedFiles.splice(0, acceptedFiles.length)
    }

    const onSave = () => {
        if (userSelec.length > 0) {
            let model = {
                CompanyPostId: parseInt(inputs.CompanyPostId), Title: inputs.Title, Subtitle: inputs.Subtitle, Text: inputs.Text, PostType: postType, ListUser: userSelec
            }

            // acceptedFiles.forEach( x =>
            //     validateFile(x)
            // )
            // if(!valMessage){
            //     swal({ text: "formato no valido", icon: "error" });
            //     return
            // }

            if (parseInt(inputs.CompanyPostId) === 0) {
                companyPostService.create(model, acceptedFiles).then((result) => {
                    gridRef.current.refreshDataGrid();

                });

            } else {
                companyPostService.update(model, acceptedFiles).then((result) => {
                    gridRef.current.refreshDataGrid();
                });
            }
            setUserSelec([]);
            setInputs({});
            setShowEdit(false);
            setPostType(1);
            setImages([]);
        } else
            swal({ text: "Debes elegir al menos un usuario", icon: "error" });
    }

    const { inputs, handleInputChange, handleSubmit, setInputs } = useInputSingleForm(onSave, {});

    const handleOnCancel = () => {
        setInputs({});
        setShowEdit(false);
        setUserSelec([]);
        setPostType(1);
        setImages([]);
    }


    const handleOnNewGallery = () => {
        setInputs(companyInit);
        setShowEdit(true);
        setUserSelec([]);
        setImages([]);
        setPostType(1);
    };
    const handleRowSelect = (gridItem) => {
        removeAllFiles();
        setShowEdit(true);
        setPostType(gridItem.postType);

        setInputs({ CompanyPostId: gridItem.companyPostId, Title: gridItem.title, Subtitle: gridItem.subtitle, Text: gridItem.text, PostType: gridItem.postType });
        companyPostService.getCompanyPostImagesPathById(gridItem.companyPostId).then((result) => {
            setImages(result.files);
        });
    }

    const handleDeleteImageGallery = (entityId, entityTypeId, sequence) => {
        storageService.deleteStorage({ EntityId: entityId, EntityTypeId: entityTypeId, Sequence: sequence })
        setImages(
            images.filter(function (image) {
                return image.sequence !== sequence
            })
        );
    };

    const [modal, setModal] = useState(false);
    const toggle = (img) => {
        setModal(!modal);
        setImageModal(img)
    }

    const [imgModal, setImageModal] = useState('');

    return (
        <div className="dashboard-container-page">
            {!showEdit ?
                <>
                    <div className="admin-subtitle">
                        <h3 className="mb-0">Lista de Álbumes</h3>
                        <Button type="button" className="btn-dark" onClick={handleOnNewGallery}>
                            <p>Agregar álbum</p>
                            <FaPlus />
                        </Button>
                    </div>
                    <SearchGalleries handleRowSelect={handleRowSelect} ref={gridRef} />
                </>
                : null}

            {showEdit ?
                <>
                    <form onSubmit={handleSubmit} className="form-dashboard-group">
                        <div className="admin-heading">
                            <h4>Álbum</h4>
                            <Button className="btn-back" type="button" onClick={handleOnCancel}>
                                <FaTimesCircle />
                                <p>Cancelar</p>
                            </Button>
                        </div>
                        <Row>
                            <Col lg={6} md={12} sm={12}>
                                <FormGroup className="form-dashboard-group">
                                    <label for="Title">Título de álbum </label>
                                    <input type="text" name="Title" onChange={handleInputChange} value={inputs.Title} required />
                                </FormGroup>
                            </Col>
                        </Row>
                        <div className="wrapper_dropzone">
                            <aside>
                                <h6>Archivos seleccionado:</h6>
                                <ul className="list-files">{files}</ul>
                                <div className="gallery-container">
                                    {images.map((image, index) => (
                                        <div key={index} className="gallery-item" onClick={() => toggle(image.path)}>
                                            <img src={image.path} alt={image.fileName} />
                                            <Button className="btn-trash delete" onClick={() => handleDeleteImageGallery(image.entityId, image.entityTypeId, image.sequence)}><i className="far fa-trash-alt"></i></Button>
                                        </div>
                                    ))}
                                </div>
                            </aside>
                            <div {...getRootProps({ className: 'dropzone' })}>
                                <input {...getInputProps()} accept="image/*" />
                                <div className="box_dropzone">
                                    <span><i className="fas fa-download"></i></span>
                                    <p >Arrastre y suelte las imágenes aquí, o haga clic para seleccionar un archivo.</p>
                                </div>
                            </div>
                        </div>
                        {inputs.CompanyPostId > 0 ?
                            <AudienceUsers targetId={inputs.CompanyPostId} eventType="Galeria" selectUser={setUserSelec} />
                            :
                            <SelectUsers selectUser={setUserSelec} title={"Entrada dirigida a:"}/>
                        }
                        <div className="form-dashboard-footer">
                            <Button className="btn-dark" type="submit">
                                <p>Guardar</p>
                                <FaSave />
                            </Button>
                        </div>
                    </form>
                    <Modal isOpen={modal} toggle={toggle} centered={true} className="modal-images">
                        <ModalBody>
                            <Button className="btn-close-modal" onClick={toggle}>
                                <FaTimes />
                            </Button>
                            <img src={imgModal} width="100%" style={{maxHeight: '300px', objectFit: 'contain', borderRadius: '.7rem'}} alt="" />
                        </ModalBody>
                    </Modal>
                </>
                : null}

        </div>
    )
}
