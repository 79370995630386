import React, { useState } from "react";
import useInputForm from "../../components/CustonHooks/FormHook";
import { contactInfoService } from "../../services/helpContact.service";
// import { metaPixelService } from "../../services/metaPixel.service";
import "./help.scss";

import HelpBanner from "../../assets/imgs/banner_help.png";
import {Col, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import {FooterHome} from "../../components/footer/footerHome";

export function Help() {
  const onSave = (e) => {
    contactInfoService.createContact(inputs);
    e.target.reset();
  };

  const [contactInfo, setContactInfo] = useState({
    name: "",
    businessName: "",
    email: "",
    message: "",
  });

  const {inputs} = useInputForm(onSave, contactInfo);
  return (
    <section className="dashboard-container-page">
      <img className="banner-help" src={HelpBanner} alt="Encuestas" />
      <div className="tabs-help">
        <h2>Uso de la Plataforma</h2>
          <Row style={{width: '100%'}}>
            <Col lg={4} md={6} sm={5}>
              <Nav tabs pills={true} className="flex-column">
                <NavItem>
                  <NavLink>
                    <span><i className="fas fa-tv"></i></span>
                    <h5>¿Cómo funciona el portal?</h5>
                  </NavLink>
                </NavItem>
                <Nav.Item>
                  <Nav.Link 
                  // onClick={() => {metaPixelService.simpleEvent('track','Contact')}}
                  >
                    <span><i className="fas fa-archive"></i></span>
                    <h5>Atención a Usuarios</h5>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col lg={8} md={6} sm={7}>
              <TabContent activeTab="1" className="tabs-help-content">
                <TabPane tabId="1" className="tabs-help-content-pane">
                  <div className="tabs-help-content-body">
                    <div className="info">
                      <span><i className="fas fa-tv"></i></span>
                      <h4>¿Cómo funciona el portal?</h4>
                    </div>
                    <div className="video">
                      <video controls preload="true" id="my_video_pride">
                        <source src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/pride/plataforma/Ayuda/pridemxvideo.mp4" type="video/mp4"/>
                      </video>
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="2" className="tabs-help-content-pane">
                  <div className="tabs-help-content-body">
                    <div className="info">
                      <span><i className="fas fa-archive"></i></span>
                      <h4>Atención a Usuarios</h4>
                    </div>
                    <div className="text">
                      <h5>¿Qué sería de la comunidad sin apoyo mutuo?</h5>
                      <p>Cualquier cosa que necesites, por favor escribenos por chat a Adminsitrador Recursos Humanos.</p>
                    </div>
                  </div>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
      </div>
      <FooterHome />
    </section>
  );
}
