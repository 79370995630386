import React, { useEffect, useState } from "react";
import { JobBankService } from "../../services/jobBank.service";
import {Button} from "reactstrap";

export function AsideCategoriesJobs(props) {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    JobBankService.getCategories().then((result) => {
      setCategories(result.slice(0, 10));
    });
  }, [])

  return (
    <aside className="job-bank-categories">
      <h4>Búsquedas populares</h4>
      <ul className="job-bank-categories-content">
        {categories.map((category, i) => (
            <li key={i}>
                <Button className="btn-white sm" key={i}>{category.name}</Button>
            </li>
        ))}
      </ul>
    </aside>
  );
}
