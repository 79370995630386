import React, { useState } from 'react';
import { SearchSurvey } from './searchSurvey';
import './surveyAdmin.scss'
import { Link } from 'react-router-dom';
import {Col, Row} from "reactstrap";
import {FooterHome} from "../../components/footer/footerHome";
import {FaChevronLeft, FaPlus} from "react-icons/fa";

export function AdminSurvey() {
    const [refresh, setRefresh] = useState(0);

    return (
        <section className="dashboard-container-page">
            <div className="admin-subtitle">
                <div>
                    <h2>Tu Opinión</h2>
                    <h6>Listado</h6>
                </div>
                <Link className="btn-back" to={"/admin"}>
                    <FaChevronLeft />
                    <p>Regresar</p>
                </Link>
            </div>
            <div className='admin-heading'>
                <div></div>
                <Link className="btn-dark" to={"/admin/details-survey/0"}>
                    <p>Agregar encuesta</p>
                    <FaPlus />
                </Link>
            </div>
            <SearchSurvey refresh={refresh} />
            <Row>
                <Col sm={12}>
                    <FooterHome />
                </Col>
            </Row>
        </section>
    );
}
