import React from "react";
import {Link} from "react-router-dom";
import {} from "react-share";

import "./footer.scss";

export function FooterHome() {
  return (
      <footer className="dashboard-footer-home">
        <ul>
          <li>
            <Link to="/ambienteseguro.pdf" target="_blank">Decálogo</Link>
          </li>
          <li>
            <Link to="/avisoprivacidad.pdf" target="_blank">Aviso de Privacidad</Link>
          </li>
          <li>
            <Link to="/terminosycondiciones.pdf" target="_blank">Términos y Condiciones</Link>
          </li>
        </ul>
      </footer>
  );
}
