import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { JobBankService } from "../../services/jobBank.service";
import "./jobBank.scss";
import { Button, Col, Row } from "reactstrap";

import bannerBolsaTrabajo from "../../assets/imgs/banner-bolsatrabajo.png";
import {
  BsArrowRight,
} from "react-icons/bs";

export function TtlsJobBank({ tituloInJobs, searchByFilters}) {
  const roleName = useSelector((state) => state.userSettings.roleName);
  const [totalCount, setTotalCount] = useState(0);

  // const [jobs, setJobs] = useState([]);
  // const [pages, setPages] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  // const [order, setOrder] = useState("Fecha");

  // const returnPreviousPage = () => {
  //   if (currentPage > 1) {
  //     searchPage(currentPage - 1);
  //   }
  // };

  // const advanceNextPage = () => {
  //   if (currentPage < totalPages) {
  //     searchPage(currentPage + 1);
  //   }
  // };

  useEffect(() => {
    GetJobsToApprove();
  }, []);

  const GetJobsToApprove = () => {
    JobBankService.GetJobsToApprove(1, 8).then((result) => {
      if (!result) return;
      setTotalCount(result.totalCount);
    });
  };


  // const searchByFilters = () => {
    
  //   let occupationFilter = document.getElementById("occupationFilter").value;
  //   let locationFilter = document.getElementById("locationFilter").value;

  //   searchPage(1, order, occupationFilter, locationFilter);
  // };

  // useEffect(() => {
  //   searchPage();
  // }, []);

  return (
    <Row>
      <Col lg={12}>
        <h2>{tituloInJobs}</h2>
      </Col>
      <Col lg={12}>
        <article className="job-bank-header">
          <div className="job-bank-header-info">
            <h3>
              Te ayudamos a encontrar el empleo ideal para ti de forma rápida
            </h3>
            <div className="job-bank-form">
              <div className="group">
                <label htmlFor="locationFilter">
                  <i className="icon-location"></i>
                </label>
                <input
                  type="text"
                  id="locationFilter"
                  className="location"
                  placeholder="Ubicación"
                />
              </div>
              <div className="group">
                <label htmlFor="occupationFilter">
                  <i className="icon-jobs"></i>
                </label>
                <input
                  type="text"
                  id="occupationFilter"
                  className="job"
                  placeholder="Puesto, profesión, cargo, etc."
                />
              </div>
              <Button className="btn-searcher" onClick={searchByFilters}>
                <BsArrowRight />
              </Button>
            </div>
          </div>
          <div className="job-bank-header-thumb">
            <img
            className="drawer__bg__img"
              src={bannerBolsaTrabajo}
              alt="Ser parte de la comunidad Romeh te da estos beneficios"
            />
            {/* <div
              className="thumb"
              style={{ backgroundImage: `url(${imgHeader})` }}
            ></div> */}
          </div>
        </article>
      </Col>
      <Col lg={12}>
        <div className="job-bank-btns">
          {roleName === "Recursos Humanos" && (
            <Link className="btn-gradient-green" to="/list-new-jobs">
              <span>
                {totalCount === "" ? <span>{totalCount}</span> : ""} por
                autorizar
              </span>
              <span className="min">Administrador</span>
            </Link>
          )}
          <Link className="btn-gradient-green" to="/jobs-published">
            <span>Mis empleos</span>
            <span className="min">Empresas</span>
          </Link>
          <Link className="btn-outline-dark" to="/register-job">
            Publicar empleo
          </Link>
        </div>
      </Col>
    </Row>
  );
}
