import React from "react";
import {Tooltip, Whisper} from "rsuite";
import {
    BsFacebook,
    BsInstagram,
    BsLink,
    BsLinkedin,
    BsTiktok,
    BsTwitter,
    BsYoutube
} from "react-icons/bs";
import {
  BiHelpCircle
} from "react-icons/bi";
export function ItemSocial(props) {
  const renderTooltip = (
    <Tooltip id="button-tooltip" {...props.socialIcoName}>
      {props.socialIcoName}
    </Tooltip>
  );

  return (
    <Whisper placement="top" trigger="hover" speaker={renderTooltip} controlId="button-tooltip">
      <a href={props.socialUrl} target="_blank" className="link">
          {props.socialIcoName === 'Linkedin' ? <BsLinkedin /> : ''}
          {props.socialIcoName === 'Instagram' ? <BsInstagram /> : ''}
          {props.socialIcoName === 'Facebook' ? <BsFacebook /> : ''}
          {props.socialIcoName === 'Twitter' ? <BsTwitter /> : ''}
          {props.socialIcoName === 'TikTok' ? <BsTiktok /> : ''}
          {props.socialIcoName === 'Youtube' ? <BsYoutube /> : ''}
          {props.socialIcoName === 'WebSite' ? <BsLink /> : ''}
          {props.socialIcoName === 'Help' ? <BiHelpCircle /> : ''}
      </a>
    </Whisper>
  );
}
