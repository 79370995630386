import React, { useState, useEffect } from 'react';
import { SelectCustom } from '../../components/Helpers/SelectCustom';
import { helperService } from '../../services/helper.service';
import { userProfileService } from '../../services/backoffice/userProfile.service';
import useInputSingleForm from '../../components/CustonHooks/inputFormHook'
import {Col, FormGroup, Label, Row, Table} from "reactstrap";
import {Button, Pagination} from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css'
import MultiSelect from "react-multi-select-component";

import 'rsuite/dist/styles/rsuite-default.css'
import {FaChevronRight} from "react-icons/fa";

export function SelectUsers({ selectUser, title, filter, usersFilter }) {
    let configInit = { Departments: [], JobRoleId: 0, BranchOfficeId: 0 }
    const [departaments, setDepartaments] = useState([]);
    const [userSelec, setUserSelec] = useState([]);
    const [dataUsers, setDataUsers] = useState({ list: [], totalPages: 1, totalCount: 0, pageIndex: 1 });
    const [usersNotInFilter, setUsersNotInFilter] = useState([]);
    const [dataAllUsers, setDataAllUsers] = useState([]);
    const { inputs, handleInputChange, setInputs } = useInputSingleForm(null, configInit);

    useEffect(() => {
        getDepartaments();
    }, []);

    useEffect(() => {
        handleOnSearch(1);
    }, [usersFilter]);

    const EventFilter = (result, pageIndex) => {
        if (result) {
            if (filter) {
                let users = result;
                setDataUsers({ ...dataUsers, list: users.list, totalCount: result.totalCount, totalPages: result.totalPages, pageIndex: pageIndex });

                let datauser = { list: result.list, totalCount: result.totalCount, totalPages: result.totalPages, pageIndex: pageIndex }
                if (dataAllUsers.length > 0) {
                    let datauser = { list: result.list, totalCount: result.totalCount, totalPages: result.totalPages, pageIndex: pageIndex }
                    JointUser(datauser, dataAllUsers);
                } else if (userSelec.length > 0) { setCheck(datauser); }
            }
            else {
                setDataUsers({ ...dataUsers, list: result.list, totalCount: result.totalCount, totalPages: result.totalPages, pageIndex: pageIndex });
                let datauser = { list: result.list, totalCount: result.totalCount, totalPages: result.totalPages, pageIndex: pageIndex }
                if (dataAllUsers.length > 0) {
                    JointUser(datauser, dataAllUsers);
                } else if (userSelec.length > 0) { setCheck(datauser); }
            }
        }
    }

    const handleOnSearch = (pageIndex) => {
        if (inputs.Departments.length > 0) {
            let model = {
                Departments: inputs.Departments.map(e => e.value).join(","), JobRoleId: parseInt(inputs.JobRoleId === '' ? 0 : inputs.JobRoleId), BranchOfficeId: parseInt(inputs.BranchOfficeId === '' ? 0 : inputs.BranchOfficeId),
                PageIndex: pageIndex,
                PageCount: 20,
            };

            if (usersFilter != undefined) {
                if (usersNotInFilter.length == 0 || pageIndex == 1) {
                    userProfileService.getUsersByFilter(model)
                        .then((result) => {
                            let NotInList = result;
                            let start = (model.PageCount * pageIndex) - model.PageCount;
                            let end = (model.PageCount * pageIndex);

                            usersFilter.map((user) => {
                                NotInList = NotInList.filter((item) => item.userProfileId != user.id);
                            });

                            let totalPa = Math.trunc(NotInList.length / model.PageCount);
                            totalPa = Number.isInteger(totalPa) ? totalPa : Math.trunc(totalPa) + 1;
                            let pageitems = NotInList.slice(start, end);
                            setUsersNotInFilter(NotInList);

                            setDataUsers({ ...dataUsers, list: pageitems, totalCount: NotInList.length, totalPages: totalPa, pageIndex: pageIndex });
                        });
                }
                else {
                    let start = (model.PageCount * pageIndex) - model.PageCount;
                    let end = (model.PageCount * pageIndex);
                    let pageitems = usersNotInFilter.slice(start, end);
                    let totalPa = (usersNotInFilter.length / model.PageCount);
                    totalPa = Number.isInteger(totalPa) ? totalPa : Math.trunc(totalPa) + 1;
                    setDataUsers({ ...dataUsers, list: pageitems, totalCount: usersNotInFilter.length, totalPages: totalPa, pageIndex: pageIndex });
                }
            } else {
                userProfileService.getUsersByFilterPage(model)
                    .then((result) => {
                        EventFilter(result, pageIndex);
                    });
            }
        }
    }

    const handleSelectUser = (event, data) => {
        let localUsers = [];
        data.check = event.target.checked;
        if (data.check) {
            localUsers = [...userSelec, { id: data.userProfileId, name: `${data.middleName} ${data.lastName} ${data.firstName}`, department: data.department?.departmentName }];
            setUserSelec(localUsers);
        } else {
            localUsers = userSelec.filter((item) => item.id != data.userProfileId);
            setUserSelec(localUsers);
        }
        selectUser(localUsers);
        if (dataAllUsers.length > 0) {
            let itemAll = dataAllUsers.find((x) => x.userProfileId == data.userProfileId);
            itemAll.check = data.check;
        }
        event.stopPropagation();
    };

    const JointUser = (userData, usersAll) => {
        userData.list.map(data => {
            let itemAll = usersAll.find((item) => item.userProfileId == data.userProfileId);
            data.check = itemAll.check;
        });

        setDataUsers({ ...dataUsers, list: userData.list, totalCount: userData.totalCount, totalPages: userData.totalPages, pageIndex: userData.pageIndex });
    }

    const setCheck = (userData) => {
        userData.list.map(data => {
            let itemAll = userSelec.find((item) => item.id == data.userProfileId);
            if (itemAll) {
                data.check = true;
            }
        });

        setDataUsers({ ...dataUsers, list: userData.list, totalCount: userData.totalCount, totalPages: userData.totalPages, pageIndex: userData.pageIndex });
    }

    const handleSelectUserAll = (event) => {
        setUserSelec([]);
        let localUsers = [];
        let status = event.target.checked;
        dataUsers.list.map(data => {
            data.check = event.target.checked;
        });

        if (dataAllUsers.length === 0) {
            let model = {
                Departments: inputs.Departments.map(e => e.value).join(","), JobRoleId: parseInt(inputs.JobRoleId === '' ? 0 : inputs.JobRoleId), BranchOfficeId: parseInt(inputs.BranchOfficeId === '' ? 0 : inputs.BranchOfficeId)
            };

            userProfileService.getUsersByFilter(model)
                .then((result) => {
                    if (result) {
                        let users = result;
                        users.map(data => {
                            if (status && !data.check) {
                                userSelec.push({ id: data.userProfileId, name: `${data.middleName} ${data.lastName} ${data.firstName}`, department: data.department?.departmentName })
                                setUserSelec([...userSelec]);
                                localUsers = userSelec;
                            }

                            data.check = status;
                        });
                        selectUser(localUsers);
                        setDataAllUsers(users);
                    }
                });
        } else {
            let users = dataAllUsers;
            users.map(data => {
                if (status && !data.check) {
                    userSelec.push({ id: data.userProfileId, name: `${data.middleName} ${data.lastName} ${data.firstName}`, department: data.department?.departmentName })
                    setUserSelec([...userSelec]);
                    localUsers = userSelec;
                }

                data.check = status;
            });
            selectUser(localUsers);
            setDataAllUsers(users);
            JointUser(dataUsers, users);
        }

    }

    const handleSelectPage = (pageIndex) => {
        handleOnSearch(pageIndex);
    }

    const getDepartaments = () => {
        helperService.getGenericCatalog("/backoffice/Department")
            .then((result) => {
                if (result)
                    setDepartaments(result.map(item => ({ label: item.departmentName, value: item.departmentId })))
                else
                    setDepartaments([]);
            });
    }

    return (
        <>
            <Row style={{marginLeft: '0'}}>
                <Col sm={12} style={{paddingLeft: '0', paddingRight: '0'}} className="mb-3">
                    <h5>{title}</h5>
                </Col>
            </Row>
            <Row className="flex-start-end">
                <Col md={4} sm={12} style={{paddingLeft: '0'}}>
                    <FormGroup className="form-dashboard-group">
                        <Label for="DepartmentId">Grupos</Label>
                        <MultiSelect
                            name="DepartmentId"
                            options={departaments}
                            value={inputs.Departments}
                            onChange={(e) => setInputs({ ...inputs, Departments: e })}
                            overrideStrings={{
                                "allItemsAreSelected": "Todos los grupos.",
                                "clearSearch": "Borrar búsqueda",
                                "noOptions": "Sin opciones",
                                "search": "Buscar",
                                "selectAll": "Seleccionar todo",
                                "selectSomeItems": "Selecciona una opción"
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col md={4} sm={12} style={{paddingLeft: '0'}}>
                    <FormGroup className="form-dashboard-group">
                        <Label for="JobRoleId">Categorías</Label>
                        <SelectCustom selectName="JobRoleId" handleOnChange={handleInputChange} selectValue={inputs.JobRoleId}
                                      method="JobRole" textField="name" valueField="jobRoleId" defaultOption="Todos" />
                    </FormGroup>
                </Col>
                <Col md={4} sm={12} style={{paddingLeft: '0'}}>
                    <FormGroup className="form-dashboard-group">
                        <Label for="BranchOfficeId">Sucursal</Label>
                        <SelectCustom selectName="BranchOfficeId" handleOnChange={handleInputChange} selectValue={inputs.BranchOfficeId}
                                      method="BranchOffice" textField="branchOfficeName" valueField="branchOfficeId" defaultOption="Todos" />
                    </FormGroup>
                </Col>
                <Col sm={12} style={{paddingLeft: '0'}}>
                    <FormGroup className="flex-end">
                        <Button type="button" className="btn-dark" onClick={() => handleOnSearch(1)} style={{marginTop: '.7rem'}}>
                            Consultar
                            <FaChevronRight />
                        </Button>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={12} style={{paddingLeft: '0', paddingRight: '0'}}>
                    <div className="table-responsive">
                        <Table className="admin-table" hover>
                            <thead>
                            <tr>
                                <th>No. Usuario</th>
                                <th>Nombre de Usuario</th>
                                <th>Grupo</th>
                                <th>Sucursal</th>
                                <th>
                                    {dataUsers.list.length > 0 ?
                                        <label className="toggler-wrapper dark" htmlFor="checkboxAll">
                                            <input type="checkbox" id="checkboxAll" onClick={handleSelectUserAll} />
                                            <div className="toggler-slider">
                                                <div className="toggler-knob"></div>
                                            </div>
                                            <p>Seleccionar</p>
                                        </label>
                                        : "Seleccionar"}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {dataUsers.list.length > 0 ? dataUsers.list.map(item => (
                                <tr key={item.userProfileId}>
                                    <td>{item.employeeNo}</td>
                                    <td>{item.middleName} {item.lastName} {item.firstName}</td>
                                    <td>{item.department?.departmentName}</td>
                                    <td>{item.branchOffice?.branchOfficeName}</td>
                                    <td>
                                        <label className="toggler-wrapper dark" htmlFor={`check-${item.userProfileId}`}>
                                            <input type="checkbox" id={`check-${item.userProfileId}`} onClick={(event) => handleSelectUser(event, item)} checked={item.check} />
                                            <div className="toggler-slider">
                                                <div className="toggler-knob"></div>
                                            </div>
                                        </label>
                                    </td>
                                </tr>
                            )) : 
                            <tr key={1}>
                                <td colSpan="4" className="text-center"><p>No hay registros</p></td>
                            </tr>
                            }
                            </tbody>
                        </Table>
                        {dataUsers.list.length > 0 ?
                            <Pagination prev last next first size="lg" ellipsis maxButtons={10} boundaryLinks pages={dataUsers.totalPages} activePage={dataUsers.pageIndex} onSelect={handleSelectPage} />
                            : ""}
                    </div>
                </Col>
            </Row>
        </>

    );
}
