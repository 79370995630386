import React, { useState } from 'react';
import { EditReward } from './editReward'

export function DetailsReward(props) {

    return (
        <div className="dashboard-container-page">
            <div className="admin-title">
                <h2>Recompensas</h2>
                <h6>{props.match.params.id != 0 ? "Editar recompensa" : "Nueva recompensa"}</h6>
            </div>
            <EditReward rewardProductId={props.match.params.id} />
        </div>
    );
}   
