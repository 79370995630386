import React from "react";
import { Legals } from "../../components/legals/legals";

export function Aside ({message}) {

    let gradients = ["animationBg1", "animationBg2", "animationBg3"];
    const colorLoaded = Math.floor(Math.random() * gradients.length);

    return (
        <div className="register-container-img">
            <div className={`bg-gradients ${gradients[colorLoaded]}`}>
                <h1>{message}</h1>
                <Legals />
            </div>
        </div>
    );
}
