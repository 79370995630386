import { callApi } from "./service";

export const companyPostViewService = {
  InsertRead,
  getAll,
  DeleteReadPost,
  InsertLike,
  DeleteLikePost,
  getDateFilters,
  getByFilters,
  getByMonthFilters,
  getById,
};

async function InsertRead(params) {
  return callApi("/backend/InsertReadPost", "POST", params).then((result) =>
    handleRsponse(result)
  );
}

async function DeleteReadPost(params) {
  return callApi("/backend/DeleteReadPost", "POST", params).then((result) =>
    handleRsponse(result)
  );
}

async function InsertLike(params) {
  return callApi("/backend/InsertLikePost", "POST", params).then((result) =>
    handleRsponse(result)
  );
}

async function DeleteLikePost(params) {
  return callApi("/backend/DeleteLikePost", "POST", params).then((result) =>
    handleRsponse(result)
  );
}

async function getAll() {
  return callApi("/backend/CompanyPostDetail", "GET").then((result) => {
    if (result.responseCode == "00") {
      return result.data;
    } else {
      alert(result.responseMessage);
      return null;
    }
  });
}

//info por meses
async function getDateFilters(postType, postCategoryId) {
  //let type = postType === 1 ? 0 : postType === 2 ? 1 : 0;
  return callApi(
    "/backend/CompanyPost/DateFilters/" + postType + "/" + postCategoryId,
    "GET"
  ).then((result) => handleRsponse(result));
}

//obtener los posts
async function getByFilters(filterDate, postCategoryId) {
  // 2023-03-01
  // Preguntas frecuentes --> 1
  //orgullo --> 2
  return callApi(
    "/backend/CompanyPost/ByFilters/" + filterDate + "/" + postCategoryId,
    "GET"
  ).then((result) => handleRsponse(result));
}

async function getByMonthFilters(filterDate) {
  // 2023-03-01
  return callApi(
      "/backend/CompanyPost/ByMonthFilters/" + filterDate,
      "GET"
  ).then((result) => handleRsponse(result));
}

async function getById(id) {
  return callApi("/backend/CompanyPost/ById/" + id, "GET").then((result) =>
    handleRsponse(result)
  );
}

function handleRsponse(result) {
  if (result.responseCode === "00") return result.data;
  else {
    alert(result.responseMessage);
    return null;
  }
}
