import React, { Fragment, useEffect, useState } from "react";
import useInputSingleForm from "../components/CustonHooks/inputFormHook";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { GeneralUpload } from "../components/Helpers/generalUpload";
import { SelectUsers } from "../components/Helpers/selectUsers";
import { messageService } from "./message.service";
import { EditorMessage } from "../pages/messages/editorMessage";
import { MessageAttachment } from "../pages/messages/messageAttachment";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import { callApi, callApiFileStorage } from './service'

export const welcomeMessage = {
    sendMessage
}

const welcomeText = `
<div><p style="text-align:left;">
<img style="max-width:550px; width:100%" src="https://storagervadmindev.blob.core.windows.net/ctnprimecontentmx/Pride/BannerBienvenida.png" alt="undefined" style="height: auto;width: auto">
<p></p>
<p><span style="color: rgb(147,101,184);"><em>Autor: Luis Villavicencio<br></em></span></p>
<p><span style="color: rgb(147,101,184);"><em>Fecha de Publicación: 11 de abril de 2022</em></span><br></p>
<p style="text-align:justify;">Me llena de orgullo darte la bienvenida a <strong>PrideMx</strong>, un <strong>espacio seguro </strong>creado<br>por y para la <strong>comunidad LGBTQ+</strong>, donde podrás conectarte para <strong>colaborar,<br>hacer negocios, aprender, entretenerte y empoderarte</strong>  con la posibilidad<br>de <strong>ayudar </strong>a los miembros <strong>menos visibles o vulnerados</strong> de nuestra<br>comunidad.<br></p>
<p style="text-align:justify;">En los últimos meses hemos trabajado arduamente para hacer de <strong>PrideMx<br></strong>una plataforma tecnológica en la que puedes acceder a información<br>interesante y relevante para cualquier momento de tu día; desde historias<br>que nos llenan de orgullo hasta el plan para el fin de semana con tus amigxs.<br>En nuestro espacio de <strong>Emprendedores </strong> encontrarás profesionistas, especialistas y<br>proveedores, de quienes podrás adquirir productos o contratar servicios de<br>manera segura además de apoyar a los emprendedores de nuestra<br>comunidad y aliadxs.<br></p>
<p style="text-align:justify;">Sabemos que <strong>PrideMx </strong>no es un lugar perfecto, pero tenemos la seguridad de<br>que con la participación de cada miembro de nuestra comunidad será posible<br>mejorar día con día. Estamos convencidos de que gracias a este proyecto<br> podremos apoyar al desarrollo y la mejora de nuestra comunidad, para<br>hacerla más sólida, incluyente, unida y con beneficios para todxs porque<br>nuestra diversidad es lo que nos une y fortalece al mismo tiempo.<br></p>
<p style="text-align:justify;">Creo firmemente que la vida se basa en esas pequeñas acciones que<br> impulsan lo mejor de nosotros… un consejo, una compra a un incipiente<br>emprendedor, una referencia para alguien que inicia un nuevo proyecto o<br>quizás un simple saludo puede hacer un mejor día para alguien y por ende<br>para nosotrxs mismxs. Como expresaba Nelson Mandela: “Cuando dejamos<br>que nuestra propia luz brille, inconscientemente damos permiso a otras<br>personas para hacer lo mismo”.<br></p>
<p>Disfruta, brilla y comparte en <strong> PrideMx</strong><br></p>
<p><span style="font-size: 12px;">Luis V.<br></span></p>
<p><span style="font-size: 12px;">Director General PrideMx</span>&nbsp;</p>
</div>
`

const messageData = {
    "subject": "¡Te doy la bienvenida a PrideMx!",
    "messageText": ""
}

async function sendMessage(user) {
    let usersTo = [user];
    messageData.messageText = welcomeText;
    let data = { message: messageData, usersTo: usersTo };
    messageService.createWelcomeMessage(data, false).then((data) => {
        if (data != false) {
        } 
    });
}
